import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';
import { authHeader, GetURL, handleJsonResponse, handleCatch } from '@lib';

export const baseContributorService = {
    getContributor,
    getContributorMembership,
    getContributorMemberships,
    createContributorMembership,
    updateContributorMembership,
    deleteContributorMembership,
    getAllContributors,
    sendEmailLink,
    getContributorFileByFileId,
    getAllContributorFilesForCustomer,
    deleteContributorFile,
    rejectContributorFile,
    addContributor,
    updateContributor,
    deleteContributor,
}

function getContributorMembership(membershipId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/Memberships/${membershipId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getContributorMemberships(contributorId, boardId, customerId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    var params = [];
    if (contributorId) { params.push(`contributorId=${contributorId}`) }
    if (boardId) { params.push(`boardId=${boardId}`) }
    if (customerId) { params.push(`customerId=${customerId}`) }

    return fetch(GetURL() + `Contributors/Memberships?${params.join("&")}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function createContributorMembership(membership) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(membership)
    };

    return fetch(GetURL() + `Contributors/Memberships`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                if (response.json) {
                    throw response.json();
                } else {
                    throw '';
                }
            }
            return response.ok;
        })
        .catch(handleCatch);
}
function updateContributorMembership(membership) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(membership)
    };

    return fetch(GetURL() + `Contributors/Memberships/${membership.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) { throw response.json(); }
            return response.ok;
        })
        .catch(handleCatch);
}
function deleteContributorMembership(membershipId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/Memberships/${membershipId}`, requestOptions)
        .then((response) => {
            if (!response.ok) { throw response.json(); }
            return response.ok;
        })
        .catch(handleCatch);
}

function getContributor(contributorId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/${contributorId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getAllContributors(customerId, email) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + 'Contributors' + `?customerId=${customerId}${email ? `&email=${email}` : ''}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getContributorFileByFileId(fileId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/Files/${fileId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getAllContributorFilesForCustomer(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/Files?customerId=${customerId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function deleteContributorFile(fileId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/Files/${fileId}`, requestOptions)
        .then((response) => { return response.ok })
        .catch(handleCatch);
}

function rejectContributorFile(fileId, reason) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ reason: reason })
    };

    return fetch(GetURL() + `Contributors/Files/${fileId}/Reject`, requestOptions)
        .then((response) => { return response.ok })
        .catch(handleCatch);
}

function sendEmailLink(contributorId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/${contributorId}/EmailLinks`, requestOptions)
        .then((response) => { return response.ok; })
        .catch((error) => { throw error; });
}

function addContributor(contributorDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contributorDetails)
    };

    return fetch(GetURL() + `Contributors`, requestOptions)
        .then(async (response) => {
            if (!response.ok) {
                throw await response.json();
            }
            return await response.json();
        })
        .catch((e) => { throw e });
}

function updateContributor(contributorDetails) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(contributorDetails)
    };

    return fetch(GetURL() + `Contributors/${contributorDetails.id}`, requestOptions)
        .then((response) => {
            return response.ok;
        })
        .catch(handleCatch);
}

function deleteContributor(contributorId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Contributors/${contributorId}`, requestOptions)
        .then((response) => {
            return response.ok;
        })
        .catch(handleCatch);
}