/* global appConfig */
import React, {useState, useEffect} from 'react';
import { useDispatch, connect } from 'react-redux';
import { userActions, kvpActions, popoverAction } from '@actions/admin';
import { history } from '@lib';
import { DeviceFingerprint, AnsaradaCredentials, convertCountryToCode, convertCodeToCountry, isValidMobile, TrackEvent } from '@lib/simpletools';
import PhoneInput from '@common/phoneinput';
import { AuthCode } from '@common';
import { isValidNumber } from 'libphonenumber-js'
import { RoutesConstants } from '@constants/common.constants';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FreemiumUserDetails, FreemiumCosecDetails, GetCustomerLogo, AppVideo, AppDownload, FreemiumPrimaryNeed, FreemiumNextMeeting, FreemiumSayHelloToBoard, FreemiumWhatsNext, TrialCompanyDetails } from './FreemiumSection';
import { 
  Stack, Step, StepLabel, Stepper, TextField, Grid, FormControlLabel, CircularProgress, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions 
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiButton, MuiCheckbox, CityCountrySelect } from '@common/MUI';
import moment from "moment";
import ansaradaLogo from '../../assets/images/logo/Ansarada Logo Inline Black RGB.svg';
import freemiumPW from '@image/freemium/freemium_pw.svg';
import './freemium.css';
import { renderReadyPage, renderSidePanelImage } from './FreemiumSignUp';
import { baseUserService } from '../../services/user.base.service';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const UserDetailsPage = (props) => {
  const { userRole } = props.directorData
  const [role, setRole] = useState(userRole ? userRole : "companySecretary")
  const [isCheckingEmail, setCheckingEmail] = useState(false)

  const handleSubmit = () => {
    const data = {
      userRole: role,
    }
    if(role === 'Director'){
      props.handleDirector(data)
    }else {
      props.handleNext(data)
    }
  };

  return (
    <div>
      <FreemiumUserDetails
        role={role}
        loading={isCheckingEmail}
        error={props.error}
        setRole={value => setRole(value)}
        handleBack={() => props.handleBack()}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

const CosecDetailsPage = (props) => {
  const { Email, FirstName, LastName, Company } = props.cosecData
  const [email, setEmail] = useState(Email || "")
  const [firstName, setFirstName] = useState(FirstName || "")
  const [lastName, setLastName] = useState(LastName || "")
  const [company, setCompany] = useState(Company || "")
  const [isChecking, setChecking] = useState(false)

  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = () => {
    executeRecaptcha("directorexistingpage").then(token=>{
      setChecking(true)
      dispatch(userActions.trailSaveCoSecDetails({
        googleRecaptchaResponse: token,
        Email: email,
        FirstName: firstName.trim(),
        LastName: lastName.trim(),
        Company: company.trim(),
        Source: 1,
      }))
    }).catch(err=>console.log(err))
  };

  useEffect(()=>{
    if(props.cosecLoading !== undefined && props.cosecLoading === false && isChecking){
      setChecking(false)
      if(!props.error){
        props.handleNext({
          Email: email,
          FirstName: firstName.trim(),
          LastName: lastName.trim(),
          Company: company.trim(),
        })
      }
    }
  },[props.cosecLoading, props.error, isChecking])

  return (
    <div>
      <FreemiumCosecDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        company={company}
        loading={isChecking}
        error={props.error}
        setFirstName={value => setFirstName(value)}
        setLastName={value => setLastName(value)}
        setEmail={value => setEmail(value)}
        setCompany={value => setCompany(value)}
        handleBack={() => props.handleBack()}
        handleNext={() => props.handleNext()}
        handleSubmit={handleSubmit}
      />
      <div className="signUpAlready">
        <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
      </div>
    </div>
  )
}

const SendPWResetEmail = (props) => {
  const [isSending, setIsSending] = useState(false);
  const [response, setResponse] = useState(null);

  if (!props.email) { return null; }

  function onPWResetClicked() {
    if (isSending) { return; }
    setIsSending(true);
    setResponse(null);
    baseUserService.sendAnsaradaUserPWResetEmail(props.email)
      .then(() => {
        setResponse({ successful: true, message: `We've just sent you an email to reset your password.` });
      })
      .catch(() => {
        setResponse({ successful: false, message: `Too many password reset requests. Please try again later.` });
        setTimeout(() => {
          setResponse(null);
        }, 5000);
      })
      .finally(() => { setIsSending(false); });
  }

  return (
    <div>
      <div className='passwordResetWrapper' onClick={() => { onPWResetClicked() }}>
        {isSending ? <div style={{ width: '22ch', textAlign: 'center' }}><CircularProgress size={18} color='success' /></div> : <div>Send reset password email</div>}
      </div>
      {response ? <div className={`passwordResetWrapper-message ${response.successful ? 'passwordResetWrapper-success-message' : 'passwordResetWrapper-error-message'}`}>{response.message}</div> : null}
    </div>
  )
}

const FurtherDetailsRequiredModal = (props) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const requiredFields = JSON.parse(props.requiredFields).filter(p => p.isRequired);
  const [fieldValues, setFieldValues] = useState({});

  return (
    <Dialog key={'freemium-flow-missing-required-fields-dialog'} open={true} maxWidth='sm' fullWidth={true}>
      <DialogTitle>We need a few more details to create your account</DialogTitle>
      <DialogContent>
        <p>It looks like your Ansarada profile is missing a few details that we need in order to create your Board account.</p>
        <Stack direction='column' spacing={2}>
          {requiredFields.map(f => {
            return (
              <TextField
                label={f.description}
                name={f.name}
                required={true}
                fullWidth
                onChange={(e) => {
                  const newFieldValues = { ...fieldValues, [f.name]: e.currentTarget.value };
                  setFieldValues(newFieldValues);
                  setCanSubmit(requiredFields.every(f => Boolean(newFieldValues[f.name])));
                }}
              />
            )
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <MuiButton variant='contained' disabled={!canSubmit} onClick={() => { if (props.onSubmit) { props.onSubmit(fieldValues) } }}>Create account</MuiButton>
      </DialogActions>
    </Dialog>
  )
}

class FreemiumSignUpExistingUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: '',
      city: '',
      country: '',
      mobile: '',
      token: '',
      username: '',
      deviceHash: '',
      hasError: false,
      hasAccepted: false,

      useLogo: true,
      companyLogo: '',

      completed: false,

      citiesList: [],
      password: "",
      isFileCompleted: false,
      error: "",
      tcChecked: false,
      signupSteps: [
        {name: "Account login", value: 0},
        {name: "Tell us what you do", value: 1},
        {name: "Complete your profile", value: 2, show: true},
        {name: "Final questions", value: 3, show: true},
        {name: "Ready to go", value: 4, show: true},
        {name: "Say hello to Ansarada Board", value: 2, show: false},
        {name: "What's next?", value: 3, show: false},
      ],
      currentStep: 0,
      email: "",
      location: '',
      locationError: '',
      mobileError: '',
      countryCode: '',
      primaryNeed: '',
      canSubmit: false,
      showPassword: false,
      isDirector: false,
      directorData: {},
      cosecData: {},
      nextMeetingDate: null,
      meetingFrequency: '',

      implementationDate: null,
      boardCount: 1,
      employeeCount: null,
      directorCount: 1,

      loginLoading: false,
    }

    this.list = []

    this.authSubmitSend = this.authSubmitSend.bind(this);
  }

  componentDidMount() {
    if (this.props.existingUser === false || this.props.clientId === undefined || this.props.username === undefined
      || this.props.clientId === "" || this.props.username === "") {
      window.open(RoutesConstants.trial,"_self")
    }
    if (localStorage.getItem('acceptCookies'))
      this.setState({ hasAccepted: true })

    TrackEvent("f_board_verify-email-button.clicked",{
      signup_email: this.props.username,
      plan: "board.freemium",
      is_existing: true,
    })

    DeviceFingerprint('login')
      .then((hash) => {
        this.setState({ deviceHash: hash });
      })
      .catch(function (error) {
      });
  }

  static getDerivedStateFromProps(nextProps, state) {
    state.completed = false
    state.canSubmit = false

    state.completed = state.mobile.length <= 4 || (state.mobile && isValidNumber(state.mobile) && isValidMobile(state.mobile));

    if (state.completed && state.primaryNeed !== "" && state.directorData.hasOwnProperty('userRole')
      && state.meetingFrequency && state.nextMeetingDate && moment(state.nextMeetingDate).isValid()
      && state.directorCount && state.directorCount > 0 && state.employeeCount && state.boardCount && state.boardCount > 0 
      && (!state.implementationDate || (state.implementationDate && moment(state.implementationDate).isValid()))) {
      state.canSubmit = true
    }

    if(nextProps.auth0Result !== false && nextProps.auth0Result !== undefined && state.currentStep === 0){
      state.currentStep = 1
    }

    if(state.currentStep === 3 && !state.isDirector && state.loginLoading){
      state.currentStep = 4
    }

    if(nextProps.username !== undefined && nextProps.username !== ""){
      state.email = nextProps.username
    }

    return state
  }

  handleChange = (e) => {
    let { name, value } = e.target
    if(/^\s/.test(value)) return;//no space in the start of input
    this.setState({ [name]: value })
  }

  acceptCookies() {
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  clearAuth = () => {
    window.open(RoutesConstants.trial,"_self")
  }

  onTcChange = () => {
    this.setState({
      tcChecked: !this.state.tcChecked
    });
  }

  mobileValidation = () => {
    if (this.state.mobile.length > 4 && (!isValidNumber(this.state.mobile) || !isValidMobile(this.state.mobile))) {
      this.setState({ mobileError: "Mobile number is invalid" })
    } else {
      this.setState({ mobileError: '' });
    }
  }

  locationValidation = () => {
    if (this.state.location === null && this.state.city === "") {
      this.setState({ locationError: "Please select your city from the list" });
    } else {
      this.setState({ locationError: '' });
    }
  }

  async authSubmitSend(authCode){
    const { username, registrationCode, deviceId, auth0Result, athenaUsername } = this.props;

    var profileId = auth0Result && auth0Result.profileId ? auth0Result.profileId : null;
    if (this.ssoAuthDetails && this.ssoAuthDetails.user && !profileId) {
      var profileIdKey = Object.keys(this.ssoAuthDetails.user).find(k => k.includes('profile/id'));
      if(profileIdKey) {
        profileId = this.ssoAuthDetails.user[profileIdKey];
      }
    } else if(auth0Result && (auth0Result.profileId === undefined || auth0Result.profileId === "" || athenaUsername === undefined)){
      this.clearAuth()
    }

    var KBDF2 = await AnsaradaCredentials(athenaUsername, profileId)
    var regReqest = {
      alias: username,
      username: athenaUsername,
      //customerName: "", //TODO REMOVE
      password: registrationCode,
      newpassword: KBDF2,
      // cardSerial: [],
      deviceId: deviceId,
      deviceHash: this.state.deviceHash,
      authCode: authCode,
      checkTermsAndConditions: null,
      id: profileId,
      mode: 2,
    }
    this.setState({ loginLoading: true })

    if (this.state.useLogo) {
      regReqest.companyLogo = this.state.companyLogo;
    }

    this.props.dispatch(userActions.registerExistingUser(regReqest));
  }

  handleLocation = (newValue) => {
    this.setState({
      city: newValue !== null ? newValue.city : '',
      country: newValue !== null ? newValue.country : '',
      location: newValue !== null ? newValue.value : null
    })
    if (newValue !== null && newValue.country) {
      const countryCode = convertCountryToCode(newValue.country)
      this.setState({ countryCode }, () => {
        this.locationValidation();
      });
    }
  };

  handleNext = (data) => {
    try { document.getElementsByClassName("freemium-container-inner")[0].scroll(0, 0) } catch { }
    const step = this.state.currentStep
    let state = { currentStep: step + 1 }
    if(data !== undefined){
      if(step === 1){
        state.directorData = data
      }else if(step === 2 && this.state.isDirector){
        // state.cosecData = data
      }
    }
    this.setState(state)
  }

  handleBack = () => {
    const step = this.state.currentStep
    let state = { currentStep: step - 1 }
    if(step === 2 && this.state.isDirector){
      let steps = this.state.signupSteps
      steps.forEach(o => {
        if (o.hasOwnProperty('show')) {
          o.show = !o.show
        }
      })
      state.isDirector = false
      state.signupSteps = steps
      state.cosecData = {}
    }
    this.setState(state)
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleDirector = (data) => {
    let steps = this.state.signupSteps
    steps.forEach(o => {
      if(o.hasOwnProperty('show')){
        o.show = !o.show
      }
    })
    this.setState({ directorData: data, isDirector: true, signupSteps: steps })
    this.handleNext()
  }

  handleSubmit = (requiredFields = null) => {
    const { userRole, meetingFrequency, nextMeetingDate } = this.state.directorData;
    const countryCode = convertCountryToCode(this.state.country);
    var authToken = this.props.auth0Result ? this.props.auth0Result.token : null;
    // check for SSO login and grab auth0 token
    let ssoLogin = localStorage.getItem('SSO_trial');
    if (ssoLogin && !authToken) {
      try {
        ssoLogin = JSON.parse(ssoLogin);
        this.ssoAuthDetails = { ...ssoLogin };
        if (ssoLogin.token) {
          authToken = ssoLogin.token;
        }
        try {
          localStorage.removeItem('SSO_trial');
        } catch {}
      } catch (e) { }
    }

    var details = {
      company: this.state.company.trim(),
      city: this.state.city,
      country: countryCode,
      primaryNeed: this.state.primaryNeed,
      role: userRole,
      token: authToken,
      deviceHash: this.state.deviceHash,
      username: this.props.username,
      boardCount: this.state.boardCount,
      directorCount: this.state.directorCount,
    };

    if (this.state.implementationDate && moment(this.state.implementationDate).isValid()) {
      details.implementationDate = moment(this.state.implementationDate).utc().format();
    }

    if (this.state.employeeCount) {
      var employeeCountRangeStart, employeeCountRangeEnd;
      if (this.state.employeeCount.includes("+")) {
        employeeCountRangeStart = this.state.employeeCount.replaceAll("+", "");
        employeeCountRangeEnd = null;
      } else if (this.state.employeeCount.includes("-")) {
        try {
          var optionRange = this.state.employeeCount.split("-");
          employeeCountRangeStart = optionRange[0];
          employeeCountRangeEnd = optionRange[1];
        } catch { }
      }
      details.employeeCountRangeStart = employeeCountRangeStart;
      details.employeeCountRangeEnd = employeeCountRangeEnd;
    }

    if (this.state.mobile && this.state.mobile.length > 4) {
      details.mobile = this.state.mobile;
    }

    if (this.state.nextMeetingDate && moment(this.state.nextMeetingDate).isValid()) {
      details.nextMeetingDate = moment(this.state.nextMeetingDate).utc().format();
    }

    if (this.state.meetingFrequency) {
      details.meetingFrequency = this.state.meetingFrequency;
    }

    if (requiredFields) {
      details = { ...details, ...requiredFields };
    }

    this.props.dispatch(kvpActions.set_kvp({ key: 'freemium_primary_need', value: { show: false, primaryNeed: this.state.primaryNeed } }));

    this.props.dispatch(userActions.completeExistingUserSignUp(details))
      .then((payload, item) => {
        this.setState({
          currentStep: 4
        });
      })
      .catch((error) => {
        if (error && (error.Code == 119 || error.code == 119)) {
          this.props.dispatch(popoverAction.add({
            id: 'freemium-flow-missing-required-fields-dialog',
            el: <FurtherDetailsRequiredModal requiredFields={error.Detail} onSubmit={(fieldValues) => { this.props.dispatch(popoverAction.remove('freemium-flow-missing-required-fields-dialog')); this.handleSubmit(fieldValues); }} />
          }));
        }
      });
  }

  renderLoginPage(){
    return (
      <div>
        <h1>{this.props.usesSSO ? 'Continue with SSO' : 'Enter your password'}</h1>
        {this.props.error !== undefined && this.props.error !== "" && (
          <div className="login-error-msgapp-freemium input-container">
            <span className="animated fadeInUp">
              <span>{this.props.error}</span>
            </span>
          </div>
        )}
        <div className='top35 input-container'>
          <div>
            <TextField
              label="Enter your email address"
              name='email'
              fullWidth
              value={this.state.email}
              disabled={true}
            />
          </div>
          {!this.props.usesSSO
            ? <div className='top35'>
              <TextField
                type={this.state.showPassword ? 'text' : 'password'}
                label="Enter your password"
                name='password'
                onChange={this.handleChange}
                fullWidth
                value={this.state.password}
                disabled={this.props.loggingIn === true}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
                inputProps={{ autoComplete: 'off', "data-lpignore": true }}
              />
              <SendPWResetEmail email={this.state.email} />
            </div>
            : null
          }
        </div>
        {this.props.usesSSO
          ? <div style={{marginTop: '35px', fontSize: '16px', color: '#363636'}}>
            <div>Authenticating via SSO will open a new window.</div>
            <div>This screen will automatically continue when it has been completed.</div>
          </div>
          : null
        }
        <div className='top35'>
          <Stack direction='row' spacing={2}>
            <MuiButton
              variant='outlined'
              disabled={this.props.loggingIn === true}
              onClick={() => window.open(RoutesConstants.trial, "_self")}
            >
              Back
            </MuiButton>
            <MuiButton
              variant='contained'
              disabled={!this.props.usesSSO && this.state.password === ""}
              loading={this.props.loggingIn === true}
              onClick={() => {
                if (this.props.usesSSO) {
                  localStorage.setItem('SSO_trial', JSON.stringify({
                    alias: this.props.username,
                    deviceHash: this.state.deviceHash,
                    clientId: this.props.clientId,
                    usesMFA: this.props.usesMFA,
                    usesSSO: this.props.usesSSO,
                    existingUser: this.props.existingUser,
                    date: moment().utc().format(),
                  }));

                  var win = window.open((RoutesConstants.login_signin + "?u=" + this.props.username), '_blank');
                  if (win) { win.focus(); /*Browser has allowed it to be opened*/ } else { alert('Ansarada Board - Single-Sign-On popup was blocked. Please allow popups for this website.'); /*Browser has blocked it*/ }

                  var bc = new BroadcastChannel('ansarada#board_trial_sso_bc');
                  const broadCastOnMessage = (event) => { 
                    if (event && event.origin == window.origin) {
                      if (event.data.token && event.data.user) {
                        localStorage.setItem('SSO_trial', JSON.stringify({
                          alias: this.props.username,
                          deviceHash: this.state.deviceHash,
                          clientId: this.props.clientId,
                          usesMFA: this.props.usesMFA,
                          usesSSO: this.props.usesSSO,
                          existingUser: this.props.existingUser,
                          token: event.data.token,
                          user: event.data.user,
                          date: moment().utc().format(),
                        }));
                        if (win && win.close) {
                          win.close();
                        }
                        this.handleNext();
                      }
                    }
                    bc.close();
                  };
                  bc.onmessage = broadCastOnMessage.bind(this);
                } else {
                  this.props.dispatch(userActions.Auth0Login({
                    alias: this.state.email,
                    username: this.state.email,
                    password: this.state.password
                  }));
                }
              }}
            >
              {this.props.usesSSO ? <span>Authenticate <OpenInNewIcon /></span> : 'Next'}
            </MuiButton>
          </Stack>
        </div>
      </div>
    )
  }

  renderProfilePage(){
    return (
      <div>
        <h1>Complete your profile</h1>
        <div className='bottom10'>You're signing up with <span className='bold'>{this.state.email}</span></div>
        <div className='top25 input-container'>
          <div className='top25'>
            <TextField
              label="Company name"
              name='company'
              onChange={this.handleChange}
              fullWidth
              required
              value={this.state.company}
              inputProps={{ autoComplete: 'new-password', "data-lpignore": true }}
            />
            <GetCustomerLogo
              email={this.state.email}
              useLogo={this.state.useLogo}
              toggleLogoUse={() => { this.setState({ useLogo: !this.state.useLogo }) }}
              setLogo={(base64Logo) => { this.setState({ companyLogo: base64Logo }) }}
            />
          </div>
          <div className='top25'>
            <CityCountrySelect
              label="Select your location or closest city"
              value={this.state.location || null}
              onChange={(newValue) => this.handleLocation(newValue)}
              fullWidth={true}
              required={true}
              error={this.state.locationError}
            />
          </div>
          <div className='top25'>
            <div className='text16s'>
              <PhoneInput
                size='large'
                placeholder="Mobile number"
                value={this.state.mobile}
                onChange={(value)=>{
                  this.setState({ mobile: value }, () => { this.mobileValidation() })
                }}
                defaultCountry={this.state.countryCode.toLowerCase()}
                error={this.state.mobileError !== ""}
              />
            </div>
            <div className='error'>{this.state.mobileError}</div>
          </div>
        </div>
        <div className='top25 freemium-check'>
          <FormControlLabel control={<MuiCheckbox background='white' checked={this.state.tcChecked} onChange={this.onTcChange} />} label={<div>
            <span>I have read and I agree to the&nbsp;
              <a href="https://www.ansarada.com/terms-conditions" target="_blank">Terms and Conditions</a>,&nbsp;
              <a href="https://www.ansarada.com/cookie-policy" target="_blank">Cookie Policy</a>&nbsp;
              and <a href="https://www.ansarada.com/privacy" target="_blank">Privacy Policy</a>.
            </span>
          </div>}
          />
        </div>
        <div className='top25'>
          <MuiButton
            variant='contained'
            disabled={!this.state.completed || !this.state.tcChecked || !this.state.location || !this.state.company}
            onClick={() => this.handleNext()}
          >
            Next
          </MuiButton>
        </div>
      </div>
    )
  }

  renderExistingUserLogin(){
    return (
      <div>
        <h1>You already have a Board account</h1>
        <div className='top25' style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Hello <span className='bold'>{this.state.email}</span></label>
          <label><a href={RoutesConstants.login} className="colorAnsarada bold">Log in</a> to access your Ansarada Board profile</label>
        </div>
      </div>
    )
  }

  render() {
    const steps = this.state.signupSteps.filter(o => o.show !== false)

    return (
      <div className="freemiumSignupContainer shadeWhite">
        <div className='topbar'>
          <div className='topbar-content'>
            <Stepper activeStep={this.state.currentStep}>
              {steps.map((item, index) => (
                <Step key={index} className="freemium-stepper">
                  <StepLabel>{item.name}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className='freemium-container'>
          <div className='freemium-container-inner'>
            <div className='freemium-section'>
              <img className='Asarada-logo' src={ansaradaLogo} />
              {this.state.currentStep === 0 &&
                this.renderLoginPage()
              }
              {this.state.currentStep === 1 &&
                <UserDetailsPage
                  handleBack={this.handleBack}
                  handleNext={this.handleNext}
                  handleDirector={this.handleDirector}
                  directorData={this.state.directorData}
                  {...this.props}
                />
              }
              {this.state.currentStep === 2 && !this.state.isDirector &&
                this.renderProfilePage()
              }
              {this.state.currentStep === 3 && !this.state.isDirector && (this.props.error === 'H215' ?
                this.renderExistingUserLogin() :
                <React.Fragment>
                  <FreemiumPrimaryNeed
                    loading={this.props.loading}
                    disabled={!this.state.canSubmit || !this.state.tcChecked}
                    error={this.props.error}
                    selectedNeed={this.state.primaryNeed}
                    setNeed={value => this.setState({ primaryNeed: value })}
                  />
                  <FreemiumNextMeeting
                    loading={this.props.loading}
                    frequency={this.state.meetingFrequency}
                    meetingDate={this.state.nextMeetingDate}
                    setFrequency={(newFrequency) => { this.setState({ meetingFrequency: newFrequency }) }}
                    setMeetingDate={(newDate) => { this.setState({ nextMeetingDate: newDate }) }}
                  />
                  <TrialCompanyDetails
                    implementationDate={this.state.implementationDate}
                    setImplementationDate={(newVal) => { this.setState({ implementationDate: newVal }) }}
                    boardCount={this.state.boardCount}
                    setBoardCount={(newVal) => { this.setState({ boardCount: newVal }) }}
                    employeeCount={this.state.employeeCount}
                    setEmployeeCount={(newVal) => { this.setState({ employeeCount: newVal }) }}
                    directorCount={this.state.directorCount}
                    setDirectorCount={(newVal) => { this.setState({ directorCount: newVal }) }}
                  />
                  <div className='top35'>
                    <Stack direction='row' spacing={2}>
                      <MuiButton
                        variant='outlined'
                        disabled={this.state.currentStep !== 3 && (!this.state.canSubmit || !this.state.tcChecked)}
                        onClick={() => this.handleBack()}
                      >
                        Back
                      </MuiButton>
                      <MuiButton
                        variant='contained'
                        disabled={!this.state.canSubmit || !this.state.tcChecked}
                        loading={this.props.loading}
                        onClick={() => this.handleSubmit()}
                      >
                        Next
                      </MuiButton>
                    </Stack>
                  </div>
                </React.Fragment>
              )
              }
              {this.state.currentStep === 4 && !this.state.isDirector &&
                renderReadyPage('', this.props.error)
              }
              {this.state.currentStep === 2 && this.state.isDirector &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  {/* <CosecDetailsPage
                    handleBack={this.handleBack}
                    handleNext={this.handleNext}
                    cosecData={this.state.cosecData}
                    {...this.props}
                  /> */}
                  <FreemiumSayHelloToBoard />
                  <div className='top35'>
                    <Stack direction='row' spacing={2}>
                      <MuiButton
                        variant='outlined'
                        onClick={() => this.handleBack()}
                      >
                        Back
                      </MuiButton>
                      <MuiButton
                        variant='contained'
                        onClick={() => this.handleNext()}
                      >
                        Next
                      </MuiButton>
                    </Stack>
                    <div className="signUpAlready">
                      <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
                    </div>
                  </div>
                </GoogleReCaptchaProvider>
              }
              {this.state.currentStep === 3 && this.state.isDirector &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  <FreemiumWhatsNext sentByEmail={this.state.email} dispatch={this.props.dispatch}/>
                </GoogleReCaptchaProvider>
              }
            </div>
          </div>
          <div className="freemium-right-section" hidden={this.state.currentStep === 2 && this.state.isDirector}>
            <div className='freemium-right-section-svg-outer'>
              {renderSidePanelImage(this.state.currentStep, this.state.isDirector)}
            </div>
          </div>
        </div>
        {this.state.hasAccepted &&
          <div className="cookieAccept">
            <div className="cookieText">
              <label>Use of this site accepts the <a href="https://www.ansarada.com/cookie-policy" className="cookieLink" target="_blank">use of cookies</a>.</label>
              <div className="cookieBut">
                <MuiButton variant='contained' onClick={this.acceptCookies.bind(this)}>Ok</MuiButton>
              </div>
            </div>
          </div>
        }
        {this.doAskforCode() && !this.props.loading &&
          <AuthCode
            onSubmit={this.authSubmitSend}
            onExit={this.clearAuth}
            isFreemium={true}
            {...this.props}
          />
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    username: state.authentication.username,
    existingUser: state.authentication.existingUser,
    usesSSO: state.authentication.usesSSO,
    usesMFA: state.authentication.usesMFA,
    clientId: state.authentication.clientId,
    loggingIn: state.authentication.loggingIn,
    auth0Result: state.authentication.auth0Result,
    error: state.authentication.error,
    loading: state.authentication.loading,
    askforCode: state.authentication.askforCode,
    mfaId: state.authentication.mfaId,
    mfaType: state.authentication.mfaType,
    deviceId: state.authentication.deviceId,
    registrationCode: state.authentication.registrationCode,
    athenaUsername: state.authentication.athenaUsername,
    cosecLoading: state.authentication.cosecLoading,
    alert: state.alert,
  };
}

const connectedSignUpPage = connect(mapStateToProps)(FreemiumSignUpExistingUser);
export { connectedSignUpPage as FreemiumSignUpExistingUser };
