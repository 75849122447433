import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { copySettings, BLANK_GUID } from '@lib/simpletools';
import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';

export const customNotificationService = {
    getBinderNotificationVariables,
    getNotificationsByBoardId,
    getNotificationsByCustomerId,
    saveNotification,
    updateNotification,
    deleteNotification
}

function getBinderNotificationVariables() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + 'CustomNotificationMessage/Variables/binder', requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

// public Guid? Id { get; set; }
// public string Message { get; set; }
// public Guid? CustomerId { get; set; }
// public Guid? BoardId { get; set; }
// public Guid CreatedBy { get; set; }
// public Guid UpdatedBy { get; set; }
// public DateTime? CreatedAt { get; set; }
// public DateTime? UpdatedAt { get; set; }
// public List<NotificationMessageType> MessageTypes { get; set; }

function getNotificationsByBoardId(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `CustomNotificationMessage/Board/${boardId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getNotificationsByCustomerId(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `CustomNotificationMessage/Customer/${customerId}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function saveNotification(notification) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(notification)
    };

    return fetch(GetURL() + 'CustomNotificationMessage', requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function updateNotification(notification) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(notification)
    };

    return fetch(GetURL() + `CustomNotificationMessage/${notification.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function deleteNotification(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `CustomNotificationMessage/${id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}