import React from 'react';
import { BroadcastChannelName, UserAccountTask } from "../../constants";
import { adminPermissionsConstants, userConstants } from "../../constants/admin";
import { AdminMembershipType } from "../../reducers/admin/adminPermissions.reducer";
import { adminPermissionsService, boardService, userService } from '../../services/admin';
import { binderActions } from "./binder.actions";
import { boardActions } from "./board.actions";
import * as CrytpoLib from '@lib/cryptojs';
import { userActions } from "./user.actions";
import { popoverAction } from "./popover.actions";

export const adminPermissionsActions = {
    setNewBoardPermission,
    copyBoardPermissions,
    getAdminPermissionsForUser,
    addPermission,
    removePermission,
    updatePermissionByUserId,
    shouldDeletePermission,
    getPermissions,
    saveChanges,
    checkAndRunBoardRegistrationTasks,
    removePermissionFromState,
}

function setNewBoardPermission(boardId) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.SET_NEW_BOARD_PERMISSIONS,
            payload: { boardId }
        });
    }
}

// copy admin permissions from one board id to another 
function copyBoardPermissions(oldBoardId, newBoardId, deleteOld = true) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.COPY_BOARD_PERMISSIONS,
            payload: { oldBoardId, newBoardId, deleteOld }
        });
    }
}

const isLoadingAdminPermissionForUser = {};
function getAdminPermissionsForUser(userId) {
    return (dispatch, getState) => {
        if (isLoadingAdminPermissionForUser[userId]) { return isLoadingAdminPermissionForUser[userId]; }
        isLoadingAdminPermissionForUser[userId] = new Promise((resolve, reject) => {
            adminPermissionsService.getAdminMembershipsForUser(userId)
                .then((result) => {
                    if (result) {
                        for (var ap of result) {
                            dispatch({
                                type: adminPermissionsConstants.ADD_PERMISSION,
                                payload: { ...ap, isSaved: true }
                            });
                        }
                    }
                    resolve(result);
                })
                .finally(() => {
                    delete isLoadingAdminPermissionForUser[userId];
                })
        });
        return isLoadingAdminPermissionForUser[userId];
    }
}

// Get Admins with User membership and normal Admins/Owners of the boardId 
function getPermissions(boardId) {
    return (dispatch, getState) => {
        var userData = getState().users.data;
        return new Promise((resolve, reject) => {
            return Promise.allSettled([
                new Promise((res, rej) => {
                    boardService.getMembership(boardId)
                        .then((response) => {
                            if (!response) { return []; }
                            var userMemberships = response.filter(b => { return userData[b.userId].type == "Publish"; }).map(b => { return { id: b.id, savedId: b.id, boardId: b.boardId, userId: b.userId, type: AdminMembershipType.User, savedType: AdminMembershipType.User, shouldDelete: false, isSaved: true, shouldUpdate: false } });
                            res(userMemberships);
                        }).catch(() => { rej([]); })
                }),
                adminPermissionsService.getAdminMembershipsForBoard(boardId)
            ])
                .then((results) => {
                    var userMembershipsResult = results[0];
                    var adminMembershipsResult = results[1];
                    var memberships = [];
                    if (adminMembershipsResult.status == "fulfilled" && Array.isArray(adminMembershipsResult.value)) {
                        memberships = adminMembershipsResult.value.map(b => { return { ...b, savedId: b.id, savedType: b.type, shouldDelete: false, isSaved: true, shouldUpdate: false } })
                    }
                    if (userMembershipsResult.status == "fulfilled" && Array.isArray(userMembershipsResult.value)) {
                        userMembershipsResult.value.forEach((b) => {
                            memberships.push({ id: b.id, savedId: b.id, boardId: b.boardId, userId: b.userId, type: AdminMembershipType.User, savedType: AdminMembershipType.User, shouldDelete: false, isSaved: true, shouldUpdate: false });
                        });
                    }
                    dispatch({
                        type: adminPermissionsConstants.SET_PERMISSIONS,
                        payload: { permissions: memberships, boardId: boardId }
                    });
                    resolve(memberships);
                })
                .catch((e) => { reject(); })
        })
    }
}

function addPermission(userId, boardId, properties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.ADD_PERMISSION,
            payload: { userId, boardId, properties }
        });
    }
}

function saveChanges(boardId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var allPermissions = getState().adminPermissions && getState().adminPermissions[boardId] ? getState().adminPermissions[boardId] : null;
            if (!allPermissions) { return Promise.reject(); }

            var adminPermissionsToCreate = allPermissions.filter(p => (p.type !== AdminMembershipType.User && !p.shouldDelete && (!p.isSaved || (p.isSaved && p.savedType == AdminMembershipType.User))));
            var adminPermissionsToUpdate = allPermissions.filter(p => (p.type !== AdminMembershipType.User && p.savedId && !p.shouldDelete && p.shouldUpdate && (p.isSaved && p.savedType !== AdminMembershipType.User)));
            var adminPermissionsToDelete = allPermissions.filter(p => ((p.shouldDelete && p.savedType !== undefined && p.savedType !== AdminMembershipType.User) || (p.type == AdminMembershipType.User && p.savedType != undefined && p.savedType !== AdminMembershipType.User)) && p.isSaved);

            var userMembershipsToCreate = allPermissions.filter(p => (!p.shouldDelete && p.type == AdminMembershipType.User && (p.savedType == undefined || p.savedType !== AdminMembershipType.User)));
            var userMembershipsToDelete = allPermissions.filter(p => (p.savedType == AdminMembershipType.User && p.type !== AdminMembershipType.User) || (p.type == AdminMembershipType.User && p.shouldDelete && p.savedType == AdminMembershipType.User && p.savedId));

            var promises = [];

            var membershipDeletePromises = [];
            var membershipsThatFailed = [];

            // Delete memberships first
            adminPermissionsToDelete.forEach(item => {
                membershipDeletePromises.push(
                    new Promise((resolve, reject) => {
                        adminPermissionsService.removePermission(item.savedId, Boolean(userMembershipsToCreate.find(i => i.boardId == item.boardId)))
                            .then(() => {
                                // If it is not being created remove it from redux
                                if (!userMembershipsToCreate.find(i => i.boardId == item.boardId)) {
                                    dispatch(removePermissionFromState(item));
                                    resolve();
                                    return;
                                }
                                resolve();
                            }).catch(() => {
                                membershipsThatFailed.push(item.userId);
                                reject();
                            })
                    }));
            });
            userMembershipsToDelete.forEach(item => {
                if (adminPermissionsToCreate.find(i => i.boardId == item.boardId)) { return; }
                membershipDeletePromises.push(
                    new Promise((resolve, reject) => {
                        dispatch(boardActions.removeMembership(item.boardId, item.savedId, item.userId))
                            .then(() => {
                                // If it is not being created remove it from redux
                                if (!adminPermissionsToCreate.find(i => i.boardId == item.boardId)) {
                                    dispatch(removePermissionFromState(item));
                                    resolve();
                                    return;
                                }
                                resolve();
                            })
                            .catch(() => { 
                                membershipsThatFailed.push(item.userId);
                                reject(); 
                            })
                    }));
            });

            // console.log('Admin memberships to create', adminPermissionsToCreate.map(i => { try { return i.userId } catch { return i.userId; } }));
            // console.log('Admin memberships to update', adminPermissionsToUpdate.map(i => { try { return i.userId } catch { return i.userId; } }));
            // console.log('Admin memberships to delete', adminPermissionsToDelete.map(i => { try { return i.userId } catch { return i.userId; } }));
            // console.log('User memberships to create', userMembershipsToCreate.map(i => { try { return i.userId } catch { return i.userId; } }));
            // console.log('User memberships to delete', userMembershipsToDelete.map(i => { try { return i.userId } catch { return i.userId; } }));

            Promise.allSettled(membershipDeletePromises)
                .then((memDeleteResults) => {
                    adminPermissionsToCreate.forEach(p => {
                        promises.push(new Promise((resolve, reject) => {
                            adminPermissionsService.savePermission({
                                id: p.id,
                                type: p.type,
                                boardId: p.boardId,
                                userId: p.userId
                            })
                                .then((result) => {
                                    dispatch(updatePermissionByUserId(p.boardId, p.userId, { savedType: p.type, savedId: p.id, isSaved: true, shouldUpdate: false, shouldDelete: false }));
                                })
                                .catch((e) => { membershipsThatFailed.push(p.userId); reject(p); })
                                .finally(() => { resolve() });
                        }));
                    });

                    adminPermissionsToUpdate.forEach(p => {
                        promises.push(new Promise((resolve, reject) => {
                            adminPermissionsService.updatePermission({
                                id: p.id,
                                type: p.type,
                                boardId: p.boardId,
                                userId: p.userId
                            })
                                .then((result) => {
                                    dispatch(updatePermissionByUserId(p.boardId, p.userId, { savedType: p.type, savedId: p.id, isSaved: true, shouldUpdate: false, shouldDelete: false }));
                                })
                                .catch((e) => { reject(p); })
                                .finally(() => { resolve() });
                        }));
                    });

                    userMembershipsToCreate.forEach(item => {
                        // Since we create this on admin membership delete via ?replaceWithNonAdminMembership above we just update redux
                        if (adminPermissionsToDelete.find(i => i.boardId == item.boardId)) {
                            dispatch(updatePermissionByUserId(item.boardId, item.userId, { savedType: item.type, savedId: item.id, isSaved: true, shouldUpdate: false, shouldDelete: false }));
                            return;
                        }

                        promises.push(new Promise((resolve, reject) => {
                            dispatch(boardActions.addMembership(item.boardId, item.userId, false, '', true))
                                .then(() => {
                                    dispatch(updatePermissionByUserId(item.boardId, item.userId, { savedType: item.type, savedId: item.id, isSaved: true, shouldUpdate: false, shouldDelete: false }));
                                    resolve();
                                }, (reason) => {
                                    membershipsThatFailed.push(reason);
                                    reject(item);
                                })
                                .catch((e) => {
                                    reject(item);
                                })
                        }));
                    });

                    return Promise.allSettled(promises)
                        .then((results) => {
                            console.log(results);
                            if (membershipsThatFailed && membershipsThatFailed.length) {
                                dispatch(popoverAction.showError({
                                    title: 'Error saving memberships',
                                    body: <div>
                                        <div>Error saving memberships for:</div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '20px' }}>
                                            {membershipsThatFailed.map(u => {
                                                var fName = "";
                                                try { fName = getState().users.data[u].firstName + " " + getState().users.data[u].lastName } catch { }
                                                return fName ? <div>{fName}</div> : null;
                                            })}
                                        </div>
                                        <div style={{ paddingTop: '20px' }}>Please try again later.</div>
                                    </div>
                                }))
                            }
                            dispatch(checkAndRunBoardRegistrationTasks())
                                .then(() => { resolve(); })
                                .catch(() => { reject(); })
                        }).catch((e) => {
                            reject();
                        });
                });
        })
    }
}

const startedTaskIds = [];
function checkAndRunBoardRegistrationTasks(suppliedTaskList) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!suppliedTaskList) {
                    dispatch(requestTask());
                }
                var boardIds = [];
                var restrictAdminAccessEnabled = false;
                var customer = getState().company[getState().authentication.customerId];
                if (!customer || customer.loading || !customer.pUserGenSec) {
                    await new Promise((res, rej) => {
                        var checkCustomerLoaded = setInterval(() => {
                            try {
                                // console.log('checking', getState().authentication.keys[getState().authentication.customerId].pUserGenSec);
                                if (!getState().company[getState().authentication.customerId].loading && getState().authentication.keys[getState().authentication.customerId].pUserGenSec) {
                                    clearInterval(checkCustomerLoaded);
                                    res();
                                }
                            } catch { }
                        }, 300);
                    })
                }
                try { restrictAdminAccessEnabled = getState().company[getState().authentication.customerId].restrictedAdminAccessEnabled; } catch { }
                if (restrictAdminAccessEnabled) {
                    var mems = await adminPermissionsService.getAdminMembershipsForUser(getState().authentication.userId).catch(e => null);
                    if (mems) {
                        boardIds = mems.map(m => m.boardId);
                    }
                }

                (suppliedTaskList ? Promise.resolve(suppliedTaskList) : userService.getAllTask())
                    .then(async tasklist => {
                        if (!suppliedTaskList) {
                            dispatch(successTask(tasklist))
                        }
                        // const auth = getState().authentication;
                        // const companies = getState().company;
                        // const boards = getState().board ? getState().board.boards : {};
                        // const userItems = getState().users ? getState().users.data : {};
                        // var taskIds = [], memberIds = [];

                        var tasksToComplete = tasklist.filter(t => {
                            if (t.type == UserAccountTask.BoardRegistration || t.type == UserAccountTask.UserRegistration) { // || t.type == UserAccountTask.AdminRegistration) {
                                if (!t.dateCompleted && (!t.lockedByUserId || getState().authentication.userIds.includes(t.lockedByUserId))) {
                                    return true;
                                    // if (!restrictAdminAccessEnabled || (restrictAdminAccessEnabled && Boolean(boardIds.some(bid => t.dataId.includes(bid))))) {

                                    // }
                                }
                            }
                            return false;
                        });

                        var bc = new BroadcastChannel(BroadcastChannelName.BoardRegistration.name);
                        if (tasksToComplete && tasksToComplete.length) {
                            bc.postMessage({ message: BroadcastChannelName.BoardRegistration.messages.uploadingFiles })
                        }

                        for (var e of tasksToComplete) {
                            // console.log(e);
                            var isBoardId = e.dataId.includes(":");
                            var taskBoardId, userId, memberShipId;
                            if (startedTaskIds.includes(e.id)) { continue; }
                            startedTaskIds.push(e.id);
                            
                            try {
                                if (e.type == UserAccountTask.UserRegistration) {
                                    // if (e.type == UserAccountTask.UserRegistration || e.type == UserAccountTask.AdminRegistration) {
                                    // get boardIds for membership t.dataId when UserRegistration/AdminRegistration
                                    var userBoardIds = [];
                                    var adminMemberships = [];
                                    var userMemberships = await boardService.getUserMemberships(e.dataId).catch(() => { return null; })
                                    if (userMemberships && userMemberships.length) { userBoardIds = userMemberships.map(b => b.boardId); }
                                    if (e.type == UserAccountTask.AdminRegistration) {
                                        adminMemberships = await adminPermissionsService.getAdminMembershipsForUser(e.dataId).catch(e => null);
                                        if (adminMemberships && adminMemberships.length) { userBoardIds = userBoardIds.concat(adminMemberships.map(b => b.boardId)); }
                                    }
                                    
                                    userBoardIds = userBoardIds.filter(u => Boolean(u));

                                    if (userBoardIds) {
                                        // userBoardIds = userBoardIds.map(ub => ub.boardId);
                                        try {
                                            if (!e.userId) { continue; }
                                            try {
                                                var user = null;
                                                user = getState().users.data[userId];
                                                if (!user) {
                                                    await dispatch(userActions.getUserById(userId));
                                                    try { user = getState().users.data[userId]; } catch { }
                                                }
                                                if (!user || !user.hasRegistered) { continue; }
                                            } catch { continue; }

                                            var fullUserName = dispatch(userActions.getFullUserName(e.userId));
                                            if (!fullUserName) { continue; }

                                            try { bc.postMessage({ message: BroadcastChannelName.BoardRegistration.messages.completingTask, value: e.id }) } catch { }
                                            dispatch(userActions.updateTask({ id: e.id, start: true }));
                                            dispatch(userActions.lockTask(e.id));

                                            var fileCreationPromises = [];
                                            for (var i = 0; i < userBoardIds.length; i++) {
                                                fileCreationPromises.push(new Promise(async (res, rej) => {
                                                    var boardName = dispatch(boardActions.getBoardName(userBoardIds[i]));
                                                    dispatch(popoverAction.showToast('info', userBoardIds[i] + 'started', [`Creating files for ${fullUserName}${boardName ? ` for ${boardName}` : ''}.`]));
                                                    try {
                                                        await dispatch(boardActions.addUserToBoardFiles(userBoardIds[i], e.customerId, [e.userId])).catch(e => { console.log(e); rej(); return; });
                                                    } catch (e) { console.log(e); rej(); return; }
                                                    dispatch(popoverAction.showToast('success', userBoardIds[i] + 'finished', [`Finished creating files for ${fullUserName}${boardName ? ` for ${boardName}` : ''}.`]));
                                                    res();
                                                }));
                                            }

                                            await Promise.allSettled(fileCreationPromises);

                                            dispatch(userActions.completedTask(e.id));
                                        } catch (e) {
                                            console.log("task failed" + e);
                                            dispatch(failureTask(e));
                                        }
                                    }
                                } else if (e.type == UserAccountTask.BoardRegistration) {
                                    var isBoardId = e.dataId.includes(":");
                                    var taskBoardId, userId, memberShipId;
                                    if (isBoardId) {
                                        taskBoardId = e.dataId.split(":")[0];
                                        userId = e.dataId.split(":")[1];
                                        // var found = boards[taskBoardId].memberIds.incli((mid) => {
                                        //     return (userId === mid);
                                        // });
                                        // if (found) {
                                        // memberIds.push(userId)
                                        // }
                                    } else {
                                        memberShipId = e.dataId;
                                        await new Promise((resolve, reject) => {
                                            boardService.getMembershipByMembershipId(memberShipId)
                                                .then((response) => {
                                                    userId = response.userId;
                                                    resolve(response);
                                                })
                                                .catch(() => { reject(); })
                                        });
                                    }

                                    try {
                                        if (!userId) { continue; }
                                        try {
                                            var user = null;
                                            user = getState().users.data[userId];
                                            if (!user) {
                                                await dispatch(userActions.getUserById(userId));
                                                try { user = getState().users.data[userId]; } catch { }
                                            }
                                            if (!user || !user.hasRegistered) { continue; }
                                        } catch { continue; }

                                        var fullUserName = dispatch(userActions.getFullUserName(userId));
                                        if (!fullUserName) { continue; }
                                        
                                        dispatch(userActions.updateTask({ id: e.id, start: true }));
                                        dispatch(userActions.lockTask(e.id));

                                        var boardName = dispatch(boardActions.getBoardName(taskBoardId));

                                        dispatch(popoverAction.showToast('info', taskBoardId + "started", [`Creating files for ${fullUserName}${boardName ? ` for ${boardName}` : ''}.`]));
                                        await dispatch(boardActions.addUserToBoardFiles(taskBoardId, e.customerId, [userId]));
                                        dispatch(popoverAction.showToast('success', taskBoardId + "finished", [`Finished creating files for ${fullUserName}${boardName ? ` for ${boardName}` : ''}.`]));

                                        dispatch(userActions.completedTask(e.id));
                                    } catch (e) {
                                        console.log("Task failed" + e);
                                        dispatch(failureTask(e));
                                    }

                                }
                            } catch { }
                            try {
                                if (!userId) { continue; }
                                dispatch(userActions.updateTask({ id: e.id, start: true }));
                                dispatch(userActions.lockTask(e.id));

                                // console.log("Task started: " + taskBoardId);
                                await dispatch(boardActions.addUserToBoardFiles(taskBoardId, e.customerId, [userId]));
                                // console.log("Task complete: " + taskBoardId);
                                dispatch(userActions.completedTask(e.id));
                            } catch (e) {
                                console.log("task failed" + e);
                                dispatch(failureTask(e));
                            }
                        };

                        try { bc.postMessage({ message: 'complete' }); bc.close(); } catch (e) { console.log(e); }

                        resolve();
                    }, error => {
                        reject();
                    }
                    );
            } catch (e) { reject(); console.log("task error " + e) }
        })

        function requestTask() { return { type: userConstants.LIST_ALL_TASK_REQUEST } }
        function successTask(list) { return { type: userConstants.LIST_ALL_TASK_SUCCESS, list } }
        function failureTask(error) { return { type: userConstants.LIST_ALL_TASK_FAILURE, error } }
    }
}

function updatePermissionByUserId(boardId, userId, newProperties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.UPDATE_PERMISSION_BY_USERID,
            payload: { boardId, userId, newProperties }
        })
    }
}

function shouldDeletePermission(permission, shouldDelete = true) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.SHOULD_DELETE_PERMISSION,
            payload: { permission, shouldDelete }
        })
    }
}

function removePermissionFromState(permission) {
    return (dispatch) => {
        dispatch({
            type: adminPermissionsConstants.REMOVE_PERMISSION,
            payload: { permission }
        });
    }
}

function removePermission(permission) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (permission.isSaved) {
                adminPermissionsService.removePermission(permission.id)
                    .then((result) => {
                        dispatch({
                            type: adminPermissionsConstants.REMOVE_PERMISSION,
                            payload: { permission }
                        });
                        resolve();
                    })
                    .catch((e) => { reject(permission); })
            } else {
                dispatch({
                    type: adminPermissionsConstants.REMOVE_PERMISSION,
                    payload: { permission }
                });
                resolve();
            }
        })
    }
}