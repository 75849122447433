import React from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, Tooltip } from '@mui/material';
import { MuiButton } from '@common/MUI';
import './styles/ButtonWithDropdownMenu.css';
import { LoadingButton } from '@mui/lab';


export class ButtonWithDropdownMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            anchorEl: null
        }

        this.styles = {};
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.isOpen !== undefined && nextProps.isOpen !== state.isOpen) {
            state.isOpen = nextProps.isOpen;
        }
        return state;
    }

    handleClick = (event) => {
        if (event) {
            event.stopPropagation();
        }
        if (this.props.onOpen) {
            this.props.onOpen();
        }
        if (this.props.isDisabled) { return; }
        this.setState({ anchorEl: event.currentTarget, isOpen: true });
    };

    handleClose = (event, option) => {
        if (event) {
            event.stopPropagation();
        }

        if (option.isDisabled || option.disableClick) {
            return;
        }

        this.setState({ isOpen: false });

        if (option.callback) {
            option.callback();
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    renderMenuButton = () => {
        if (this.props.icon) {
            return (
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={this.state.isOpen ? 'file-options-menu' : undefined}
                    aria-expanded={this.state.isOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    disabled={this.props.isDisabled}
                >
                    {this.props.icon}
                </IconButton>
            )
        }

        if (this.props.title) {
            if (this.props.textOnly) {
                return (
                    <div onClick={this.handleClick}>
                        {this.props.title}
                    </div>
                );
            } else {
                return (
                    <MuiButton
                        type="clear"
                        onClick={this.handleClick}
                        sx={this.styles}
                        variant={this.props.variant || 'contained'}
                        disabled={this.props.isDisabled}
                        {...this.props.buttonProps}
                    >
                        {this.props.title}
                    </MuiButton>
                );
            }
        }
    }

    getAnchorOrigin = () => {
        if (this.props.anchorOrigin) {
            return this.props.anchorOrigin;
        }
        return {
            vertical: 'bottom',
            horizontal: 'right'
        }
    }

    getTransformOrigin = () => {
        if (this.props.transformOrigin) {
            return this.props.transformOrigin;
        }
        return {
            vertical: 'top',
            horizontal: 'right',
        };
    }

    getMenuWidth = () => {
        if (this.props.width) {
            return this.props.width;
        }

        return '20ch';
    }

    render() {
        if (this.props.isHidden || this.props.hidden) {
            return null;
        }

        if (this.props.loading) {
            return (
                <MuiButton variant={this.props.variant || 'contained'} loading={true}><span>Loading</span></MuiButton>
            )
        }

        if (this.props.styles) {
            this.styles = { ...this.styles, ...this.props.styles };
        }

        return (
            <div style={{ width: this.props.wrapperWidth || '100%' }}>
                {this.renderMenuButton()}
                {this.props.options ?
                    <Menu
                        key={this.props.title || ''}
                        id={"file-options-menu"}
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorOrigin={this.getAnchorOrigin()}
                        transformOrigin={this.getTransformOrigin()}
                        anchorEl={this.state.anchorEl}
                        open={this.state.isOpen || this.props.isOpen || false}
                        onClose={this.handleClose}
                        disabled={this.props.isDisabled}
                        PaperProps={{
                            style: {
                                // maxHeight: 30,
                                width: this.getMenuWidth(),
                            },
                        }}
                    >
                        <span
                            onMouseLeave={(event) => { if (this.props.onMouseLeave) { this.props.onMouseLeave(event) } }}
                            onMouseEnter={(event) => { if (this.props.onMouseEnter) { this.props.onMouseEnter(event) } }}
                            onTouchMove={(event) => { if (this.props.onMouseLeave) { this.props.onMouseLeave(event) } }}
                        >
                            {this.props.helpText ? <li key='dropdown-helptext' className='button-with-dropdown-menu-helptext'><span>{this.props.helpText}</span></li> : ''}
                            {this.props.options.filter((o) => !o.hidden).map((option) => (
                                option.divider ? <Divider key={option.key || option.title} /> :
                                    option.tooltip ?
                                        <Tooltip key='option.title' title={option.tooltip || ''} placement={option.tooltipPlacement || 'bottom'} disableInteractive={true}>
                                            <span onClick={(e) => { if (option.isDisabled) { e.stopPropagation(); } }}>
                                                <MenuItem key={option.title} sx={{ paddingTop: option.noVerticalPadding ? 0 : '6px', paddingBottom: option.noVerticalPadding ? 0 : '6px' }} onClick={(e) => this.props.isSubMenu ? null : this.handleClose(e, option)} disabled={option.isDisabled}>
                                                    {option.element}
                                                </MenuItem>
                                            </span>
                                        </Tooltip>
                                        : <MenuItem key={option.key || option.title} sx={{ paddingTop: option.noVerticalPadding ? 0 : '6px', paddingBottom: option.noVerticalPadding ? 0 : '6px' }} onClick={(e) => this.props.isSubMenu ? null : this.handleClose(e, option)} disabled={option.isDisabled}>
                                            {option.element}
                                        </MenuItem>
                            ))}
                        </span>
                    </Menu>
                    : null}
            </div>
        );
    }
}