export const companyConstants = {
  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  GET_COMPANYDETAILS_REQUEST: 'GET_COMPANYDETAILS_REQUEST',
  GET_COMPANYDETAILS_SUCCESS: 'GET_COMPANYDETAILS_SUCCESS',
  GET_COMPANYDETAILS_FAILURE: 'GET_COMPANYDETAILS_FAILURE',

  GET_COMPANYPROPERTIES_REQUEST: 'GET_COMPANYPROPERTIES_REQUEST',
  GET_COMPANYPROPERTIES_SUCCESS: 'GET_COMPANYPROPERTIES_SUCCESS',
  GET_COMPANYPROPERTIES_FAILURE: 'GET_COMPANYPROPERTIES_FAILURE',

  POPULATE_COMPANY_REQUEST: 'POPULATE_COMPANY_REQUEST',
  POPULATE_COMPANY_SUCCESS: 'POPULATE_COMPANY_SUCCESS',
  POPULATE_COMPANY_FAILURE: 'POPULATE_COMPANY_FAILURE',

  GET_ALL_COMPANY_REQUEST: 'GET_ALL_COMPANY_REQUEST',
  GET_ALL_COMPANY_SUCCESS: 'GET_ALL_COMPANY_SUCCESS',
  GET_ALL_COMPANY_FAILURE: 'GET_ALL_COMPANY_FAILURE',

  GET_COMPANY_LIMITS_REQUEST: 'GET_COMPANY_LIMITS_REQUEST',
  GET_COMPANY_LIMITS_SUCCESS: 'GET_COMPANY_LIMITS_SUCCESS',
  GET_COMPANY_LIMITS_FAILURE: 'GET_COMPANY_LIMITS_FAILURE',

  ADD_NEW_COMPANY_REQUEST: 'ADD_NEW_COMPANY_REQUEST',
  ADD_NEW_COMPANY_SUCCESS: 'ADD_NEW_COMPANY_SUCCESS',
  ADD_NEW_COMPANY_FAILURE: 'ADD_NEW_COMPANY_FAILURE',
  ADD_NEW_COMPANY_UPDATE: 'ADD_NEW_COMPANY_UPDATE',

  UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

  ADD_NEW_COMPANY_LOGIN_SUCCESS: 'ADD_NEW_COMPANY_LOGIN_SUCCESS',
  ADD_NEW_COMPANY_LOGIN_FAILURE: 'ADD_NEW_COMPANY_LOGIN_FAILURE',

  DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILURE: 'DELETE_COMPANY_FAILURE',

  CLEARDELETE_COMPANY_SUCCESS: 'CLEARDELETE_COMPANY_SUCCESS',

  CHECK_COMPANY_NAME_REQUEST: 'CHECK_COMPANY_NAME_REQUEST',
  CHECK_COMPANY_NAME_SUCCESS: 'CHECK_COMPANY_NAME_SUCCESS',
  CHECK_COMPANY_NAME_FAILURE: 'CHECK_COMPANY_NAME_FAILURE',
  CHECK_COMPANY_NAME_CLEAR: 'CHECK_COMPANY_NAME_CLEAR',

  DELETE_PERSON_REQUEST: 'DELETE_PERSON_REQUEST',
  DELETE_PERSON_SUCCESS: 'DELETE_PERSON_SUCCESS',
  DELETE_PERSON_FAILURE: 'DELETE_PERSON_FAILURE',

  AUDIT_POPULATEFILELIST_REQUEST: 'AUDIT_POPULATEFILELIST_REQUEST',
  AUDIT_POPULATEFILELIST_SUCCESS: 'AUDIT_POPULATEFILELIST_SUCCESS',
  AUDIT_POPULATEFILELIST_FAILURE: 'AUDIT_POPULATEFILELIST_FAILURE',
  AUDIT_POPULATEFILELIST_STATUS: 'AUDIT_POPULATEFILELIST_STATUS',
  AUDIT_POPULATEFILELIST_CANCEL: 'AUDIT_POPULATEFILELIST_CANCEL',

  COMPANY_SUSPEND_REQUEST: 'COMPANY_SUSPEND_REQUEST',
  COMPANY_SUSPEND_SUCCESS: 'COMPANY_SUSPEND_SUCCESS',
  COMPANY_SUSPEND_FAILURE: 'COMPANY_SUSPEND_FAILURE',

  COMPANY_ACTIVE_REQUEST: 'COMPANY_ACTIVE_REQUEST',
  COMPANY_ACTIVE_SUCCESS: 'COMPANY_ACTIVE_SUCCESS',
  COMPANY_ACTIVE_FAILURE: 'COMPANY_ACTIVE_FAILURE',

  SET_USER_COUNT: 'SET_USER_COUNT',
  SET_ADMIN_COUNT: 'SET_ADMIN_COUNT',
  INCREASE_USER_COUNT: 'INCREASE_USER_COUNT',
  DECREASE_USER_COUNT: 'DECREASE_USER_COUNT',
  INCREASE_ADMIN_COUNT: 'INCREASE_ADMIN_COUNT',
  DECREASE_ADMIN_COUNT: 'DECREASE_ADMIN_COUNT',
};
