import React from "react";
import moment from "moment";
import { TimePicker, DatePicker, DateTimePicker, CalendarPicker } from "@mui/lab";
import { CustomTimePicker } from "./CustomTimePicker.js";
import { Popover, TextField } from "@mui/material";

export class CustomDateTimePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tempMeetingDate: props.meetingDate,
            tempMeetingTime: props.meetingDate ? moment(props.meetingDate).format("HH:mm") : '10:00'
        }
        this.customMeetingDateTimePickerRef = React.createRef();
    }

    onClose = () => {
        // this.setState({ tempMeetingDate: this.props.meetingDate });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        console.log(this.props);
        return (
            <div className={`custom-date-time-datepicker-container ${this.props.fullWidth ? 'custom-date-time-datepicker-container-fullWidth' : ''}`}>
                <DateTimePicker
                    label={this.props.label}
                    id='new-binder-wizard-binder-meeting-date'
                    value={this.props.meetingDate}
                    minDate={this.props.minDate || undefined}
                    renderInput={(params) => <TextField {...params} helperText={this.props.helperText} fullWidth={true} error={this.props.error} />}
                    onChange={this.props.onMeetingDateChange}
                    onOpen={() => { this.props.onOpen() }}
                    inputFormat="DD/MM/YYYY hh:mm a"
                    open={false}
                    readOnly={this.props.readonly}
                />
                <span
                    ref={this.customMeetingDateTimePickerRef}
                    style={{ height: 0, width: 0, overflow: 'hidden', display: 'inline-block' }}
                >
                    <Popover
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            sx: {
                                '.Mui-selected': {
                                    background: 'var(--ansarada-green) !important'
                                }
                            }
                        }}
                        anchorEl={this.customMeetingDateTimePickerRef && this.customMeetingDateTimePickerRef.current}
                        open={this.props.meetingDatePickerOpen}
                        onClose={() => { this.onClose(); }}
                    >
                        <CalendarPicker
                            readOnly={this.props.readonly}
                            date={this.state.tempMeetingDate}
                            onChange={(newValue) => {
                                if (newValue && moment.isMoment(newValue) && newValue.isValid() && !newValue.isSame(this.state.tempMeetingDate)) {
                                    this.setState({ tempMeetingDate: newValue });
                                }
                            }}
                            minDate={this.props.minDate || null}
                        />
                        <div style={{ padding: '5px 25px' }}>
                            <CustomTimePicker
                                onChange={(newTimeValue, save) => {
                                    if (this.props.onCustomMeetingTimeChange) {
                                        this.props.onCustomMeetingTimeChange(newTime, save);
                                    }

                                    if (newTimeValue) {
                                        var newTime = moment(newTimeValue, 'HH:mm', true);
                                        var newMeetingDate = this.state.tempMeetingDate
                                            ? this.state.tempMeetingDate.clone().hour(newTime.hour()).minute(newTime.minute())
                                            : moment().hour(newTime.hour()).minute(newTime.minute());

                                        this.setState({ tempMeetingDate: newMeetingDate, tempMeetingTime: newTimeValue });

                                        if (save) {
                                            this.props.onMeetingDateChange(newMeetingDate);
                                            this.onClose();
                                        }
                                    }
                                }}
                                onClose={() => { this.onClose(); }}
                                value={this.state.tempMeetingTime}
                            />
                        </div>
                    </Popover>
                </span>
            </div>
        )
    }
}