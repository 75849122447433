exports.defaultSettings = {
  id: '',
  canPrint:false,
  showsWatermarkOnPrintedDocs:true,
  showsNavigation:true,
  requiresLocation:false,
  requiresNotifications:false,
  canAnnotate:true,
  canAnnotateFiles:true,
  canTakeNotes:true,
  canPrintNotes:true,
  canExportNotes:true,
  canSpeak:true,
  canShareAnnotations:true,
  canReceiveSharedAnnotations:true,
  canShareDocuments: false,
  canShareDocumentsIfAnnotated: false,
  canShareWithoutPassword: false,
  canUsePIN: true,
  canUseFingerprint: true,
  disableInAppTimeoutDuringMeeting: false,
  allowInAppTimeoutDisable: false,
  hidesWhenBackground:true,
//  annotationsDeleteAfterMeeting:false,
//  notesForBinderDeleteAfterMeeting:false,
  allowAnnotationDeletion:true,
  allowNotesDeletion:true,
  allowFileIndexing:true,
  allowBinderIndexing:true,
  allowScreenshot:false,
  allowScreenRecording:false,
  allowExternalRecording:false,
  allowPDFLinks:true,
  //minimumOSVersion:{"Windows":"1709.0.0", "iOS": "12.4", "macOS": "10.15"},
  secondsForTimeout:300,
  secondsForInAppTimeout: 900,
  daysUntilBoardDataDeletedUponLostDevice: 14,
  immediateBoardDataDeletedUponLostDevice: false,
  autoHideItems: false,
  includeRecipientsInVotesByDefault: false,
  includeRecipientsInResolutionsByDefault: false,
}

exports.defaultCountryBlackList = ['AF','BI','CD','CF','CO','CM','CN','CU','DZ','EG','ER','HK','HN','HT','IQ','IR','KE','KP','LB','LY','NE','NG','ML','MR','MX','PH','PK','RU','SA','SD','SO','SS','SY','SV','TD','TH','UA','YE','VE','ZW'];
