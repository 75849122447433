import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { copySettings, BLANK_GUID } from '@lib/simpletools';
import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';
import { ReplaceSanitizedCharacters } from '../../lib';

export const surveyAndEvaluationService = {
    getSurveysByBoardId,
    getSurveyById,
    saveSurvey,
    updateSurvey,
    putSurvey,
    deleteSurvey,
    postSurveyResponse,
}

function FixSanitizedCharactersInSurvey(s, resanitize = false) {
    if (!s) { return; }

    s.title = ReplaceSanitizedCharacters(s.title, resanitize);

    if (Array.isArray(s.components) && s.components.length) {
        s.components.forEach(sc => {

            sc.title = ReplaceSanitizedCharacters(sc.title, resanitize);
            sc.description = ReplaceSanitizedCharacters(sc.description, resanitize);

            if (Array.isArray(sc.options) && sc.options.length) {
                sc.options.forEach(o => {
                    if (!o || !o.label) { return; }
                    o.label = ReplaceSanitizedCharacters(o.label, resanitize);
                });
            }

        })
    }

    if (Array.isArray(s.responses) && s.responses.length) {
        s.responses.forEach(r => {
            if (Array.isArray(r.componentResponses) && r.componentResponses.length) {
                r.componentResponses.forEach(cr => {
                    if (Array.isArray(cr.values) && cr.values.length) {
                        cr.values.forEach(v => { v = ReplaceSanitizedCharacters(v, resanitize); })
                    }
                })
            }
        })
    }
}

function getSurveysByBoardId(boardId, formType, token) {
    const requestOptions = {
        method: 'GET',
        headers: token ? {
            'Authorization': token
        } : authHeader(),
    };

    return fetch(GetURL() + `Forms?boardId=${boardId}&formType=${formType}`, requestOptions)
        .then((response) => {
            if (response) {
                if (token && !response.ok) { throw ''; }
                if (!response.ok) { return handleJsonResponse(response); }
                return response.json();
            }
        })
        .then(response => {
            if (Array.isArray(response) && response.length) {
                response.forEach(s => {
                    FixSanitizedCharactersInSurvey(s);
                })
            }
            return response;
        })
        .catch((e) => {
            if (token) { throw false; }
            handleCatch(e);
        });
}

function getSurveyById(surveyId, token) {
    const requestOptions = {
        method: 'GET',
        headers: token ? {
            'Authorization': token
        } : authHeader(),
    };

    return fetch(GetURL() + `Forms/${surveyId}`, requestOptions)
        .then((response) => {
            if (token && !response.ok) { throw ''; }
            if (!response.ok) { return handleJsonResponse(response); }
            return response.json();
        })
        .then(response => {
            FixSanitizedCharactersInSurvey(response);
            return response;
        })
        .catch((e) => {
            if (token) { throw false; }
            handleCatch(e);
        });
}

function saveSurvey(survey) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ ...survey })
    };

    return fetch(GetURL() + 'Forms', requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function updateSurvey(survey, isAlreadyPublished = false) {
    var payload = JSON.parse(JSON.stringify(survey));
    FixSanitizedCharactersInSurvey(payload, true);
    // BE Json serialise checker thinks components have changed, not sure why
    if (isAlreadyPublished) {
        delete payload.components;
    }
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(GetURL() + `Forms/${survey.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function putSurvey(survey, isAlreadyPublished = false) {
    var payload = JSON.parse(JSON.stringify(survey));
    FixSanitizedCharactersInSurvey(payload, true);
    // BE Json serialise checker thinks components have changed, not sure why
    if (isAlreadyPublished) {
        delete payload.components;
    }
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(GetURL() + `Forms/${survey.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function deleteSurvey(surveyId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `Forms/${surveyId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function postSurveyResponse(survey, token) {
    const requestOptions = {
        method: 'POST',
        headers: token ? {
            'Authorization': token,
            'Content-Type': 'application/json'
        } : authHeader(),
        body: JSON.stringify({ ...survey })
    };

    return fetch(GetURL() + 'Forms/Responses', requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw '';
            }
            return response.ok;
        })
        .catch(handleCatch);
}
