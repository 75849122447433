import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as CrytpoLib from '@lib/cryptojs';
import { queueService } from '../../services/admin';
import { BLANK_GUID, basename } from '../../lib';
import { webConstants } from '../../constants/admin';
import { queueActions } from './queue.actions';
import { commonConstants } from '../../constants';
import { popoverAction } from './popover.actions';
import { surveyAndEvaluationService } from '../../services/admin/surveyAndEvaluation.service';
import { MuiButton } from '../../pages/common/MUI';
import { Stack } from '@mui/material';

export const SurveyFormType = {
    Survey: 1,
    Evaluation: 2,
}

export const SurveyFormTypeString = {
    Survey: "1",
    Evaluation: "2",
}

export const surveyAndEvaluationActions = {
    deleteSurvey,
    closeSurvey,
}

function deleteSurvey(surveyId, surveyName, callback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(popoverAction.showDialog({
                dialogId: 'survey-delete-survey-check',
                width: 'sm',
                title: <div>Are you sure you want to delete {surveyName ? surveyName : 'this Survey'}?</div>,
                content: <div>
                    <div>Deleting {surveyName ? surveyName : 'this survey'} will also delete user responses.</div>
                </div>,
                dialogActions: <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove("survey-delete-survey-check")) }}>Cancel</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => { if (callback) { callback(); } surveyAndEvaluationService.deleteSurvey(surveyId).then(() => { resolve(); }).catch(() => { reject(); }); dispatch(popoverAction.remove("survey-delete-survey-check")); }}>Delete</MuiButton>
                </Stack>
            }));
        })
    }
}

function closeSurvey(survey, callback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let surveyName = survey && survey.title || '';
            dispatch(popoverAction.showDialog({
                dialogId: 'survey-close-survey-check',
                width: 'sm',
                title: <div>Are you sure you want to close {<b>{surveyName}</b> || 'this Survey'}?</div>,
                content: <div>
                    {<div>Closing {<b>{surveyName}</b> || 'this survey'} will no longer allow users to respond to this survey.</div>}
                    <div>This survey will no longer be able to be edited.</div>
                    <div>Any unsaved changes to this survey will be lost.</div>
                </div>,
                dialogActions: <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove("survey-close-survey-check")) }}>Cancel</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => { if (callback) { callback(); } resolve(); dispatch(popoverAction.remove("survey-close-survey-check")); }}>Close Survey</MuiButton>
                </Stack>
            }));
        })
    }
}
