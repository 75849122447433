import { statusConstants } from '@constants/appuser';
import { statusService } from '@services/appuser';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

let status_queue = {}, current_queue = [], usageLog = []

export const statusActions = {
  getStatus,
  setStatus,
  changeStatus,
  setUsageLog,
  uploadStatus,
  uploadUsageLogs,
  setBookMark,
  setCurrentPage,
}

function getStatus(){
  return (dispatch, getState) => {
    dispatch(request());

    statusService.getStatus()
      .then(
        payload => dispatch(success(payload)),
        error => {
          dispatch(failure(error));
        }
      );
  }

  function request() { return { type: statusConstants.GET_STATUS_REQUEST } }
  function success(payload) { return { type: statusConstants.GET_STATUS_SUCCESS, payload } }
  function failure(error) { return { type: statusConstants.GET_STATUS_FAILURE } }
}

function setStatus(items, token){
  return (dispatch, getState) => {
    dispatch(request(items));

    statusService.setStatus(items, token)
      .then(
        () => dispatch(success()),
        error => {
          dispatch(failure(error));
        }
      );
  }

  function request(payload) { return { type: statusConstants.SET_STATUS_REQUEST, payload } }
  function success() { return { type: statusConstants.SET_STATUS_SUCCESS } }
  function failure(error) { return { type: statusConstants.SET_STATUS_FAILURE } }
}

function setBookMark(documentId, page, remove = false){
  return (dispatch, getState) => {
    let bookmarks = getState().appStatus.bookmarks

    const s = bookmarks.findIndex(doc => doc.bookmarkDocumentId === documentId)
    if(remove){
      if(s !== -1){
        const p = bookmarks[s].pages.indexOf(page)
        if(p !== -1){
          bookmarks[s].pages.splice(p, 1)
          if(bookmarks[s].pages.length === 0){
            bookmarks.splice(s, 1)
          }
        }
      }
    }else{
      if(s === -1){
        bookmarks.push({bookmarkDocumentId: documentId, pages: [page]})
      }else{
        const p = bookmarks[s].pages.indexOf(page)
        if(p === -1){
          bookmarks[s].pages.push(page)
        }
      }
    }

    dispatch(request(bookmarks));

    statusService.setStatus({
      bookmarks: bookmarks
    })
      .then(
        () => dispatch(success()),
        error => {
          dispatch(failure(error));
        }
      );
  }

  function request(bookmark) { return { type: statusConstants.SET_BOOKMARK_REQUEST, bookmark } }
  function success() { return { type: statusConstants.SET_BOOKMARK_SUCCESS } }
  function failure(error) { return { type: statusConstants.SET_BOOKMARK_FAILURE } }
}

function setCurrentPage(documentId, page, remove = false){
  return (dispatch, getState) => {
    let displaySettings = getState().appStatus.displaySettings
    let currentPage = getState().appStatus.currentPage

    if(displaySettings.returnToCurrentPage === false) return

    const s = currentPage.findIndex(doc => doc.currentPageDocumentId === documentId)

    const f = current_queue.find(o => o.currentPageDocumentId === documentId)
    if(f){
      f.page = page
    }else{
      current_queue.push({currentPageDocumentId: documentId, page: 0})
    }

    if(remove){
      if(s !== -1){
        currentPage.splice(s, 1)
      }
    }else{
      if(s === -1){
        currentPage.push({currentPageDocumentId: documentId, page: page})
      }else{
        currentPage[s].page = page
      }
    }

    dispatch(request(currentPage));
  }

  function request(currentPage) { return { type: statusConstants.SET_CURRENTPAGE_REQUEST, currentPage } }
  //function success() { return { type: statusConstants.SET_CURRENTPAGE_SUCCESS } }
  //function failure(error) { return { type: statusConstants.SET_CURRENTPAGE_FAILURE } }
}

function changeStatus(id, state){
  return (dispatch, getState) => {
    const s = getState().appStatus.idArray[id]
    if(s !== undefined && s === state) return
    status_queue[id] = state
    dispatch(success(id, state));
  }

  function success(id, value) { return { type: statusConstants.SET_STATE_SUCCESS, id, value } }
}

function setUsageLog(log, sendUsageLogNow = false, setAuth = true) {
  return (dispatch, getState) => {
    const authentication = getState().authentication

    if (setAuth) {
      log.id = uuidv4();
      if (log.customerId === undefined) log.customerId = authentication.customerId
      if (log.boardId === undefined) log.boardId = authentication.boardId
      if (log.customerId !== undefined && log.userId === undefined) {
        if (authentication.customers !== undefined && authentication.customers[log.customerId] !== undefined)
          log.userId = authentication.customers[log.customerId].userId
      }
    }

    if (log.usageDate !== undefined) {
      log.usageDate = moment().utc().format()
    }
    if (log.usageInformation === undefined) {
      log.usageInformation = log.usageType
    }


    if (sendUsageLogNow) {
      statusService.uploadUsageLog(log);
    } else {
      usageLog.push(log)
    }
  }
}

function uploadStatus(){
  return (dispatch) => {
    var u = {}
    if(current_queue.length !== 0){
      u.currentPage = current_queue.slice(0)
      current_queue = []
    }
    if(Object.keys(status_queue).length !== 0){
      u.idArray = Object.assign({}, status_queue)
      status_queue = {}
    }

    if(Object.keys(u).length === 0) return

    statusService.setStatus(u)
      .then(
        () => dispatch(success()),
        error => {

        }
      );
  }

  function success() { return { type: statusConstants.UPLOAD_STATUS_SUCCESS } }
}

function uploadUsageLogs(){
  return (dispatch) => {
    if(usageLog.length === 0) return
    const u = usageLog.slice(0)
    try {
      const uIds = u.map(u => u.id).filter(u => Boolean(u));
      usageLog = usageLog.filter(ul => !uIds.includes(ul.id));
    } catch { }
    statusService.uploadUsageLogs(u)
      .then(
        () => dispatch(success()),
        error => {

        }
      );
  }

  function success() { return { type: statusConstants.UPLOAD_STATUS_SUCCESS } }
}
