import { v4 as uuidv4 } from 'uuid';

export default function fetchretry(url, options, ignoreJson = false){
  var retries = 3;
  var retryDelay = 3000;
  var retryOn = [502,400];//http 400 - with internal 108
  var retryDelay202 = 10000;
  var delayMulti = 0;
  var startTime = new Date();
  var timerPost = 10 * 60;//7
  var timerGet = 10 * 60;//3
  var retry202Count = 0;

  //202
  //retry every 10sec
  //up to 5 minutes

  if (options && options.retries !== undefined) {
    retries = options.retries;
  }

  if (options && options.populateBinder) {
    timerGet = timerPost;
  }

  if (options && options.retryDelay) {
    retryDelay = options.retryDelay;
  }

  if (options.timerGet) {
    timerGet = options.timerGet;
  }

  if (options && options.retryOn) {
    if (options.retryOn instanceof Array) {
      retryOn = options.retryOn;
    } else {
      throw {
        name: 'ArgumentError',
        message: 'retryOn property expects an array'
      }
    }
  }

  return new Promise(function(resolve, reject) {
    var wrappedFetch = function(n) {
      fetch(url, options)
      .then((response) => {
        if(response.status === 202 && options.ignore202 !== true){
          var asyncToken = response.headers.get('asynctoken')
          //check the time if longer then 5 minutes
          var c = new Date();
          var difference = (c - startTime) / 1000;
          var t = timerPost;
          if(options.method === "GET"){
            t = timerGet;
          }
          retry202Count++;
          if (options.timerGet && retry202Count > 2) {
            if (retry202Count == 2) {
              n = 3;
            }
            t = 60;
          }
          if(difference > t){
            if(n > 0){
              options.headers['AsyncToken'] = uuidv4();
              startTime = new Date();
              retry(n);
              return;
            }
            reject('Timeout - 202');
            return;
          }
          if(ignoreJson) {
            if (n < 0) { reject('202'); return; }
            retry202(n-1, retryDelay202);
            return;
          }
          //get the ID from reponse
          response.json().then(function(data) {
            if(asyncToken !== null)
              options.headers['AsyncToken'] = asyncToken;
            if(data.id !== undefined && data.id !== "")
              options.headers['AsyncToken'] = data.id;
            retry202(n, retryDelay202);
          }).catch((e)=>{
            reject('202');
          });
        //}else if(response.status === 206){
        }else if(response.status === 206){
          var asyncToken = response.headers.get('asynctoken')
          reject({
            code: 'F206',
            asyncToken,
            response,
          });
        }else if(response.status === 429){ //rate limiting
          if(retries === 0)
            return reject({
              code: 'F429',
              asyncToken,
              response,
            });

          retry202(retries, 60000 + Math.floor((Math.random() * 30000) + 1));
        }else if(response.status === 404){
          response.json().then(function(data) {
            if(data.Code === 401){
              reject(data);
            }else{
              if (n > 0) {
                retry(n);
              } else {
                reject(data);
              }
            }
          }).catch((e)=>{
            reject('404');
          });
        }else if(response.status === 400){
          //If error code 108 do a retry once
          //get the ID from reponse
          var r = response.clone()
          response.json().then(function(data) {
            if(data.Code === 112){
              resolve(r);
            }else if(data.Code === 108 || data.Code === 111 || data.Code === 110){
              options.headers['AsyncToken'] = uuidv4();
              if (n > 0) {
                retry202(0, retryDelay202);
              } else {
                reject(data);
              }
            }else
              reject(data);
          }).catch((e)=>{
            reject('400');
          });
        } else if (retryOn.indexOf(response.status) === -1 || (response.status === 504 && options.method === "GET")) {
          if (response.status == 500) {
            var r = response.clone()
            response.json().then((err) => {
              if (err.ErrorCode == 606 || err.errorCode == 606) {
                if (n > 0) {
                  retry(n);
                } else {
                  resolve(response);
                }
              } else {
                resolve(response);
              }
            })
            .catch(() => {
              resolve(response);
            })
          } else {
            resolve(response);
          }
        } else {
          if (n > 0) {
            retry(n);
          } else {
            resolve(response);
          }
        }
      })
      .catch(function(error) {
        if (n > 0) {
          setTimeout(function(){
            retry(n);
          }, 10000 * (3 - n));
        } else {
          reject(error);
        }
      });
    };

    function retry(n) {
      setTimeout(function() {
          wrappedFetch(--n);
        }, retryDelay);
    }

    function retry202(n, delay) {
      setTimeout(function() {
          wrappedFetch(n);
        }, delay);
    }

    wrappedFetch(retries);
  });
}
