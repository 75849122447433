import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {capitaliseFirstLetter, GetTypeName} from '@lib/simpletools';
import { BinderItemType, UsageType, DateFormat } from '@constants/common.constants';
import moment from 'moment';
import Pie from '@common/pieGraph';
import { renderToString } from 'react-dom/server'
/*import { pdf, Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import html2canvas from 'html2canvas'
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';*/
import * as CrytpoLib from '@lib/cryptojs';
import PDFLogoBlue from '@image/logo/AnsaradaBoardLogoCharcoal.png';
import IconAnnotation from '@image/icon/status-annotation.svg';
import IconSigned from '@image/icon/status-signed.svg';
import IconTick from '@image/icon/icon-tick.svg';
import IconTickGrey from '@image/icon/icon-tick-grey.svg';
import IconDownload from '@image/icon/icon-download.svg';
import IconRead from '@image/icon/icon-open.svg';
import IconShared from '@image/icon/ison-shared.svg';
import IconPrint from '@image/icon/icon-printed.svg';
import IconCircle from '@image/icon/icon-circle.svg';
//import draftToHtml from 'draftjs-to-html';

import { BLOCK_TYPE, INLINE_STYLE, getEntityRanges } from 'draft-js-utils';
import { convertFromRaw, convertToRaw } from 'draft-js';
import pdfMake from "pdfmake/build/pdfmake";// ../../../../custom_modules/
//import pdfFonts from "pdfmake/build/vfs_fonts"; //../../../../custom_modules/
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

//import pdfFonts from "@common/vfs_fonts"; //../../../../custom_modules/
//pdfMake.vfs = pdfFonts
// pdfMake.fonts = {
//   Roboto: {
//     normal: 'Roboto-Regular.ttf',
//     bold: 'Roboto-Medium.ttf',
//     italics: 'Roboto-Italic.ttf',
//     bolditalics: 'Roboto-MediumItalic.ttf'
//   },
//   Helvetica: {
//     normal: 'Helvetica-Regular.ttf',
//     bold: 'Helvetica-Medium.ttf',
//     italics: 'Helvetica-Italic.ttf',
//     bolditalics: 'Helvetica-MediumItalic.ttf'
//   }
// };


import { Document, ImageRun, Packer, Paragraph, TextRun, Table, TableRow, TableCell, VerticalAlign, HeadingLevel, WidthType, ShadingType, AlignmentType, Footer, Header, Media, BorderStyle } from "docx";
import { CoITypeToString } from '../conflictsRegisterPage/ConflictsOfInterestShared';

const { BOLD, CODE, ITALIC, STRIKETHROUGH, UNDERLINE } = INLINE_STYLE;

class StateToPdfMake {
  constructor(contentState) {
    this.contentState = contentState;//convertFromRaw(contentState);
    this.currentBlock = 0;
    this.output       = { content : [] };
    this.blocks       = null;
    this.listOlAcc    = [];
    this.listUlAcc    = [];
    this.listOfFonts  = [];
  }

  generate() {
    this.blocks = this.contentState.getBlockMap().toArray();

    while(this.currentBlock < this.blocks.length) {
      this._processBlock();
    }

    return this.output;
  }

  _processBlock() {
    const block = this.blocks[this.currentBlock];

    const defaultHeaderStyle = { bold : true, margin : [ 0, 5, 0, 0 ] };

    if(block.getType() !== BLOCK_TYPE.UNORDERED_LIST_ITEM && !!this.listUlAcc.length) {
      this._updateAndResetUlList();
    }

    if(block.getType() !== BLOCK_TYPE.ORDERED_LIST_ITEM && !!this.listOlAcc.length) {
      this._updateAndResetOlList();
    }

    switch (block.getType()) {
      case BLOCK_TYPE.HEADER_ONE:
        this.output.content.push({
          text : block.getText(), fontSize : 24, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_TWO:
        this.output.content.push({
          text : block.getText(), fontSize : 22, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_THREE:
        this.output.content.push({
          text : block.getText(), fontSize : 20, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_FOUR:
        this.output.content.push({
          text : block.getText(), fontSize : 18, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_FIVE:
        this.output.content.push({
          text : block.getText(), fontSize : 16, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_SIX:
        this.output.content.push({
          text : block.getText(), fontSize : 14, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.ORDERED_LIST_ITEM:
        this.listOlAcc.push({ text : [ ...this._renderBlockContent(block) ], depth: block.getDepth() });
      break;

      case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        this.listUlAcc.push({ text : [ ...this._renderBlockContent(block) ], depth: block.getDepth() });
      break;

      default:
        const data = this._renderBlockContent(block);

        this.output.content.push(
          !!data.length ? { text : [ ...data ] } : { text : '\n' }
        );
    }

    // Clear lists when is last block
    if(block.getKey() === this.contentState.getLastBlock().getKey()) {
      if(!!this.listUlAcc.length) {
        this._updateAndResetUlList();
      }

      if(!!this.listOlAcc.length) {
        this._updateAndResetOlList();
      }
    }

    this.currentBlock += 1;
  }

  getFontsList(){
    return this.listOfFonts.slice(0)
  }

  addFonts(f){
    if(!this.listOfFonts.includes(f))
      this.listOfFonts.push(f)
  }

  getFontSize(style){
    if(style._map === undefined) return 0
    if(style._map._list === undefined) return 0
    //if(style._map._list._tail === undefined) return 0
    //if(style._map._list._tail.array === undefined) return 0
    var a = style._map._list
    for(var x=0; x<a.size;a++){
      var p = a.get(x)
      if(p.length < 2) continue
      if(p[1] !== true) continue
      if(p[0].includes('fontsize')){
        var s = p[0].split("-")
        if(s.length === 2)
          return parseInt(s[1])
      }
    }

    return 0
  }

  getFontStyle(style){
    if(style._map === undefined) return {}
    if(style._map._list === undefined) return {}
    var s = {}
    var a = style._map._list
    for(var x=0; x<a.size;a++){
      var p = a.get(x)
      if(p.length === undefined || p.length < 2) continue
      if(p[1] !== true) continue
      if(p[0].includes('fontsize')){
        var sp = p[0].split("-")
        if(sp.length === 2){
          s.fontSize = parseInt(sp[1])*2
        }
      }else if(p[0].includes('fontfamily')){
        var w = p[0].split('-')
        s.font = w[1]
        this.addFonts(s.font)
      }
    }

    return s
  }

  _renderBlockContent(block) {
    if(block.getText() === '') {
      return [];
    }

    const ranges = getEntityRanges(block.getText(), block.getCharacterList());

    const data = block.getData();

    return ranges.reduce((acc, [entityKey, stylePieces]) => {
      acc.push(
        ...stylePieces.map(([ text, style ]) => {
          var j = {
            text       : this._encodeContent(text),
            bold       : style.has(BOLD),
            italics    : style.has(ITALIC),
            decoration : this._getTextDecorations(style)
          }

          j = Object.assign(j, this.getFontStyle(style))

          if(data.has('text-align'))
            j.alignment = data.get('text-align')

          return j;
        }).filter((properties) => properties.text !== ' ')
      );

      return acc;
    }, []);
  }

  _getTextDecorations(style) {
    const object = { [UNDERLINE]: 'underline', [STRIKETHROUGH]: 'lineThrough' };

    return Object.keys(object).reduce((acc, key) => {
      if(style.has(key)) {
        acc.push(object[key]);
      }

      return acc;
    }, []);
  }

  _encodeContent(text) {
    return text.replace(/[*_`]/g, '\\$&');
  }

  _updateAndResetUlList() {
    var levels = { ul : [] }, ref = [levels.ul, null, null, null, null], last = 0

    function getListType(d){
      switch(d){
        case 1:
          return 'circle'
        case 2:
          return 'square'
        case 3:
          return 'square'
        case 4:
          return 'square'
      }
      return ''
    }

    this.listUlAcc.forEach((o, index)=>{
      if(o.depth >= last){
        if(ref[o.depth] !== null){
          ref[o.depth].push(o)
        }else{
          var n = { ul : [], type: getListType(o.depth) }
          ref[last].push(n)
          ref[o.depth] = n.ul
          ref[o.depth].push(o)
        }
      }else{
        ref[last] = null
        ref[o.depth].push(o)
      }

      last = o.depth
    })
    this.output.content.push(levels);
    this.listUlAcc = [];
  }

  _updateAndResetOlList() {
    var levels = { ol : [] }, ref = [levels.ol, null, null, null, null], last = 0

    this.listOlAcc.forEach((o, index)=>{
      if(o.depth >= last){
        if(ref[o.depth] !== null){
          ref[o.depth].push(o)
        }else{
          var n = { ol : [] }
          ref[last].push(n)
          ref[o.depth] = n.ol
          ref[o.depth].push(o)
        }
      }else{
        ref[last] = null
        ref[o.depth].push(o)
      }

      last = o.depth
    })
    this.output.content.push(levels);
    this.listOlAcc = [];
  }
}

/*class StateToReactPDF {
  constructor(contentState) {
    this.contentState = convertFromRaw(contentState);
    this.currentBlock = 0;
    this.output       = { content : [] };
    this.blocks       = null;
    this.listOlAcc    = [];
    this.listUlAcc    = [];
  }

  generate() {
    this.blocks = this.contentState.getBlockMap().toArray();

    while(this.currentBlock < this.blocks.length) {
      this._processBlock();
    }

    return this.output;
  }

  _processBlock() {
    const block = this.blocks[this.currentBlock];

    const defaultHeaderStyle = { bold : true, margin : [ 0, 5, 0, 0 ] };

    if(block.getType() !== BLOCK_TYPE.UNORDERED_LIST_ITEM && !!this.listUlAcc.length) {
      this._updateAndResetUlList();
    }

    if(block.getType() !== BLOCK_TYPE.ORDERED_LIST_ITEM && !!this.listOlAcc.length) {
      this._updateAndResetOlList();
    }

    switch (block.getType()) {
      case BLOCK_TYPE.HEADER_ONE:
        this.output.content.push({
          text : block.getText(), fontSize : 24, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_TWO:
        this.output.content.push({
          text : block.getText(), fontSize : 22, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_THREE:
        this.output.content.push({
          text : block.getText(), fontSize : 20, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_FOUR:
        this.output.content.push({
          text : block.getText(), fontSize : 18, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_FIVE:
        this.output.content.push({
          text : block.getText(), fontSize : 16, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.HEADER_SIX:
        this.output.content.push({
          text : block.getText(), fontSize : 14, ...defaultHeaderStyle
        });
      break;

      case BLOCK_TYPE.ORDERED_LIST_ITEM:
        this.listOlAcc.push({ text : [ ...this._renderBlockContent(block) ] });
      break;

      case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        this.listUlAcc.push({ text : [ ...this._renderBlockContent(block) ] });
      break;

      default:
        const data = this._renderBlockContent(block);

        this.output.content.push(
          !!data.length ? { text : [ ...data ] } : { text : '\n' }
        );
    }

    // Clear lists when is last block
    if(block.getKey() === this.contentState.getLastBlock().getKey()) {
      if(!!this.listUlAcc.length) {
        this._updateAndResetUlList();
      }

      if(!!this.listOlAcc.length) {
        this._updateAndResetOlList();
      }
    }

    this.currentBlock += 1;
  }

  _renderBlockContent(block) {
    if(block.getText() === '') {
      return [];
    }

    const ranges = getEntityRanges(block.getText(), block.getCharacterList());

    return ranges.reduce((acc, [entityKey, stylePieces]) => {
      acc.push(
        ...stylePieces.map(([ text, style ]) => {
          return {
            text       : this._encodeContent(text),
            bold       : style.has(BOLD),
            italics    : style.has(ITALIC),
            decoration : this._getTextDecorations(style)
          };
        }).filter((properties) => properties.text !== ' ')
      );

      return acc;
    }, []);
  }

  _getTextDecorations(style) {
    const object = { [UNDERLINE]: 'underline', [STRIKETHROUGH]: 'lineThrough' };

    return Object.keys(object).reduce((acc, key) => {
      if(style.has(key)) {
        acc.push(object[key]);
      }

      return acc;
    }, []);
  }

  _encodeContent(text) {
    return text.replace(/[*_`]/g, '\\$&');
  }

  _updateAndResetUlList() {
    this.output.content.push({ ul : this.listUlAcc });
    this.listUlAcc = [];
  }

  _updateAndResetOlList() {
    this.output.content.push({ ol : this.listOlAcc });
    this.listOlAcc = [];
  }
}

export class MinutesReport {
  Generate(data){
    return new Promise((resolve, reject) => {
      var pages = [];
      var promisearray = [];

      var ifrm = document.createElement("div");
      ifrm.setAttribute("id", "render");
      ifrm.style.position = "absolute";
      ifrm.style.top = "0px";
      ifrm.style.left = window.innerWidth+"px";
      ifrm.style.zIndex = 0;

      document.body.appendChild(ifrm);

      data.minutes.forEach(function(o){
        promisearray.push(
          new Promise((resolveItem, rejectItem) => {

            var s = new StateToReactPDF(o.editorState.getCurrentContent())
            console.log(s.generate())

            const rawContentState = convertToRaw(o.editorState.getCurrentContent());
            const markup = draftToHtml(
              rawContentState,
              {
                trigger: '#',
                separator: ' ',
              },
            );

            var a = document.createElement("div");
            ifrm.appendChild(a);
            a.innerHTML = '<div style="line-height: 1;">'+markup+'</div>';
            var width = a.clientWidth;
            var height = a.clientHeight;
            html2canvas(a, {scale: window.devicePixelRatio * 2}).then(canvas => {
              const imgData = canvas.toDataURL('image/png');

              resolveItem({
                width,
                height,
                html: imgData,
                positionString: o.positionString,
                name: o.name,
                duration: o.duration,
              })
            })
            .catch((e)=>{
              console.log(e);
              rejectItem(e);
            })
          })
        );
      });

      Promise.all(promisearray)
      .then((listofItems)=>{
        var listofActions = [];

        data.actions.forEach(function(item){
          listofActions.push({
            height: (item.assignee.length + 1)*20,
            description: item.description,
            itemref: item.itemref,
            assignee: item.assignee,
          })
        })

        return ({listofItems, listofActions})
      })
      .then((json)=>{
        var listofItems = json.listofItems;
        var listofActions = json.listofActions;

        var totalpixel = 80+80;

        if(data.apologies.length > data.attendees.length)
          totalpixel += (data.apologies.length+1) * 20
        else
          totalpixel += (data.attendees.length+1) * 20

        if(data.invitees.length > 0)
          totalpixel += (data.invitees.length+1) * 20

        var overflowItems = [], overflowActions = [];
        console.log(totalpixel);
        var pcount = Math.floor(totalpixel / 841.89);
        var l = 841.89 - (totalpixel - pcount * 841.89);

        for(var x=0; x<listofItems.length; x++){
          if(l > listofItems[x].height+40){
            overflowItems.push(listofItems[x]);
            l -= listofItems[x].height+40;
            continue;
          }

          break;
        }

        if(overflowItems.length > 0)
          listofItems.splice(0,overflowItems.length);

        for(var x=0; x<listofActions.length; x++){
          if(l > listofActions[x].height+40){
            overflowActions.push(listofActions[x]);
            l -= listofActions[x].height+40;
            continue;
          }
          break;
        }

        if(overflowActions.length > 0)
          listofActions.splice(0,overflowActions.length);

        pages.push(
          <View style={styles.content}>
            <View style={styles.header}>
              <View>
                <Image style={styles.logo} src={PDFLogoBlue}></Image>
              </View>
              <View style={styles.companyInfo}>
                <Text style={styles.companyTitle}>{data.companyName}</Text>
                {data.abn !== "" &&
                  <Text>ABN: {data.abn}</Text>
                }
              </View>
            </View>
            <View>
              <View style={styles.titleBox}>
                <Text style={styles.title}>{data.name}</Text>
                <Text>{data.location}</Text>
                <Text>{data.meetingDate.format(DateFormat.LLLL)}</Text>
              </View>
              <View style={styles.attended}>
                <View style={styles.attendees}>
                  <Text style={styles.boxTitle}>ATTENDEES</Text>
                  {data.attendees.length === 0 &&
                    <Text>No Attendees</Text>
                  }
                  {data.attendees.length > 0 &&
                    data.attendees.map((item, index) => (
                      <Text key={index} style={index % 2 === 1 ? styles.rowLight : styles.rowDark}>{item}</Text>
                    ))
                  }
                </View>
                <View style={styles.apologies}>
                  <Text style={styles.boxTitle}>APOLOGIES</Text>
                  {data.apologies.length === 0 &&
                    <Text>No Apologies</Text>
                  }
                  {data.apologies.length > 0 &&
                    data.apologies.map((item, index) => (
                      <Text key={index} style={index % 2 === 1 ? styles.rowLight : styles.rowDark}>{item}</Text>
                    ))
                  }
                </View>
              </View>
              {data.invitees.length > 0 &&
                <View style={styles.invitees}>
                  <Text style={styles.boxTitle}>INVITEES</Text>
                  {data.invitees.map((item, index) => (
                    <Text key={index} style={index % 2 === 0 ? styles.rowLight : styles.rowDark}>{item}</Text>
                  ))}
                </View>
              }
              {overflowItems.length > 0 &&
                <View style={styles.minGroup}>
                  <Text style={styles.boxTitle}>Minutes</Text>
                  {overflowItems.map((item, index) => (
                    <View key={index} style={styles.minItem}>
                      <View style={styles.minTop}>
                        <Text style={{width: '20%'}}>{item.positionString}</Text>
                        <Text style={{width: '60%'}}>{item.name}</Text>
                        <Text style={{width: '20%'}}>{item.duration}</Text>
                      </View>
                      <View style={styles.minBottom}>
                        <Image style={{width: item.width, height: item.height}} src={item.html}></Image>
                      </View>
                    </View>
                  ))}
                </View>
              }
              {overflowActions.length > 0 &&
                <View style={styles.minGroup}>
                  <Text style={styles.boxTitle}>Actions</Text>
                  {overflowActions.map((item, index) => (
                    <View key={index} style={styles.minItem}>
                      <View style={styles.minTop}>
                        <Text style={{width: '20%'}}>{item.itemref}</Text>
                        <Text style={{width: '40%'}}>{item.description}</Text>
                        <View style={{width: '40%'}}>
                          {item.assignee.map((name, index) => (
                            <Text key={"p"+index}>{name}</Text>
                          ))}
                        </View>
                      </View>
                    </View>
                  ))}
                </View>
              }
            </View>
          </View>
        )
        return {listofItems, listofActions};
      })
      .then((json)=>{
        var listofItems = json.listofItems;
        var listofActions = json.listofActions;

        var p = [], a = [];
        listofItems.forEach(function(o){
          p.push(
            <View style={styles.minItem}>
              <View style={styles.minTop}>
                <Text style={{width: '20%'}}>{o.positionString}</Text>
                <Text style={{width: '60%'}}>{o.name}</Text>
                <Text style={{width: '20%'}}>{o.duration}</Text>
              </View>
              <View style={styles.minBottom}>
                <Image style={{width: o.width, height: o.height}} src={o.html}></Image>
              </View>
            </View>
          )
        })

        if(p.length > 0)
          pages.push(
            <View style={styles.content}>
              <View style={styles.minGroup}>
                <Text style={styles.boxTitle}>Minutes</Text>
                {p.map((item, index) => (
                  <View key={index}>{item}</View>
                ))}
              </View>
            </View>
          );

        listofActions.forEach(function(o){
          a.push(
            <View style={styles.minItem}>
              <View style={styles.minTop}>
                <Text style={{width: '20%'}}>{o.itemref}</Text>
                <Text style={{width: '40%'}}>{o.description}</Text>
                <View style={{width: '40%'}}>
                  {o.assignee.map((name, index) => (
                    <Text key={"p"+index}>{name}</Text>
                  ))}
                </View>
              </View>
            </View>
          )
        })

        if(a.length > 0)
          pages.push(
            <View style={styles.content}>
              <View style={styles.minGroup}>
                <Text style={styles.boxTitle}>Actions</Text>
                {a.map((item, index) => (
                  <View key={index}>{item}</View>
                ))}
              </View>
            </View>
          );
      })
      .then(()=>{
        document.body.removeChild(ifrm);
        resolve (
          <Document>
            {pages.map((item, index) => (
              <Page key={index}>{item}</Page>
            ))}
          </Document>
        )
      })
      .catch((e)=>{
        console.log(e);
        document.body.removeChild(ifrm);
        reject(e);
      })
    });
  }
}*/

function convertSVG(data){
    return data.replaceAll("%3e",">").replaceAll("%3c","<").replaceAll("data:image/svg+xml,","").replaceAll("\r\n","").replaceAll("\n","").replaceAll("'","\"").replaceAll("%23","#")
}

export class SummarReportPDFMake {
  Generate(data){
    return new Promise((resolve, reject) => {
      var listofItems = {};
      var listofActions = {};
      var listofInvitees = {};
      var listofAttendees = [];
      var listSecondCol = [];
      var listOfFonts = [];

      if(data.attendees === undefined || data.attendees.length === 0){
        listofAttendees = [
          [{text: 'Attendees', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }],
          [{text: 'No Attendees', style: 'rowTable' }],
        ];
      }else{
        listofAttendees = [[{text: 'Attendees', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
        data.attendees.forEach(function(o){
          listofAttendees.push(
            [{text: o, style: 'rowTable' }]
          )
        })
      }

      if(data.apologies === undefined || data.apologies.length === 0){
        listSecondCol = [
          [{text: 'Apologies', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }],
          [{text: 'No Apologies', style: 'rowTable' }],
        ];
      }else{
        listSecondCol = [[{text: 'Apologies', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
        data.apologies.forEach(function(o){
          listSecondCol.push(
            [{text: o, style: 'rowTable' }]
          )
        })
      }

      if((data.recipients === undefined && data.apologies === undefined) || (data.recipients !== undefined && data.recipients.length === 0)){
        listSecondCol = [
          [{text: 'Recipients', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }],
          [{text: 'No Recipients', style: 'rowTable' }],
        ];
      }else if(data.recipients !== undefined && data.recipients.length > 0){
        listSecondCol = [[{text: 'Recipients', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
        data.recipients.forEach(function(o){
          listSecondCol.push(
            [{text: o, style: 'rowTable' }]
          )
        })
      }

      if(data.invitees !== undefined && data.invitees.length > 0){
        var items = [[{text: 'Invitees', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
        data.invitees.forEach(function(o){
          items.push([{text: o, style: 'rowTable'}])
        })
        listofInvitees = {
          table: {
            widths: ['*'],
            body: items
          },
          /*layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            }
          }*/
          style: 'inviteeBox',
          layout: 'noBorders'
        };

      }

      if(data.actions !== undefined && data.actions.length > 0){
        var items = [
          [{text: 'Actions', style: 'boxTitle', fillColor: '#f2f2f2', colSpan: 3, border: [false, false, false, false] },{},{}],
          [
            {text: 'Ref', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Action', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Who', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]}
          ]
        ]
        data.actions.forEach(function(o){
          items.push([
            {text: o.itemref, border: [false, false, false, false]},
            {text: o.description, border: [false, false, false, false]},
            {
              type: 'none',
              ol: o.assignee,
              border: [false, false, false, false]
            },
          ]);
        })
        listofActions = {
          style: 'tableBox',
          table: {
            widths: [40, '*', '*'],
            body: items,
          },
          layout: {
            hLineColor: function (i, node) {
              return '#d4d4d4';
            },
          }
        };
      }

      if(data.minutes !== undefined && data.minutes.length > 0){
        var listitems = [[{text: 'Minutes', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]]
        var minuteItems = [
          [
            {text: 'Item', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Description', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: '', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]}
          ]
        ];

        data.minutes.forEach(function(o){
          var stateToPdfMake  = new StateToPdfMake(o.editorState.getCurrentContent());
          var pdfmakeContents = stateToPdfMake.generate();

          listOfFonts = listOfFonts.concat(stateToPdfMake.getFontsList().filter((item) => listOfFonts.indexOf(item) < 0))

          minuteItems.push([
            {text: o.positionString, border: [false, false, false, false], style: 'miTop'},
            {text: o.name, border: [false, false, false, false], style: 'agendaTitle'},
            {text: o.duration==="0:00"||o.duration===0?"":o.duration, border: [false, false, false, false], style: 'miTop'}
          ]);
          minuteItems.push([
            {text: '',border: [false, false, false, true], style: 'miBot'},
            {
              colSpan: 2,
              stack: pdfmakeContents.content,
              border: [false, false, false, true],
              style: 'miBot'
            }
          ]);
        });

        listitems.push([
          {
            table: {
              widths: [40, '*', 40],
              body: minuteItems
            },
            layout: {
              hLineColor: function (i, node) {
      					return '#d4d4d4';
      				},
            }
          }
        ]);

        listofItems = {
          style: 'tableBox',
          table: {
            widths: ['*'],
            body: listitems,
          },
          layout: 'noBorders'
        };

      }

      if(data.listItems !== undefined && data.listItems.length > 0){
        var listitems = [[{text: 'Items', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]]
        var minuteItems = [
          [
            {text: 'Item', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Description', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
          ]
        ];

        data.listItems.forEach(function(o){
          var p = {text: o.positionString, border: [false, false, false, false], style: 'miTop'}
          var t = {text: o.name, border: [false, false, false, false], style: 'agendaTitle'}
          if(o.link !== undefined){
            p.linkToPage = o.link
            t.linkToPage = o.link
          }
          minuteItems.push([p, t]);
          minuteItems.push([
            {text: '',border: [false, false, false, true], style: 'miBot'},
            {
              table: {
                widths: ['*','*','*'],
                body: [[
                  {text: 'Type: '+ capitaliseFirstLetter(o.type), style: 'itemDetails'},
                  {text: o.pageCount>0?'Page Count: '+o.pageCount:"", style: 'itemDetails'},
                  {text: o.duration===""||o.duration===0?"":"Duration: "+o.duration, style: 'itemDetails'}
                ]]
              },
              layout: 'noBorders',
              border: [false, false, false, true]
            }
          ]);
        });

        listitems.push([
          {
            table: {
              widths: [40, '*'],
              body: minuteItems
            },
            layout: {
              hLineColor: function (i, node) {
      					return '#d4d4d4';
      				},
            }
          }
        ]);

        listofItems = {
          style: 'tableBox',
          table: {
            widths: ['*'],
            body: listitems,
          },
          layout: 'noBorders'
        };

      }

      var footerLogo = []
      if(data.footer !== undefined){
        footerLogo = data.footer
      }

      function defaultLogo(){
        if(data.logo!==undefined && data.logo!=='') return {
          image: data.logo,
          fit: [100, 100],
        }
        if(data.athenaLogo === undefined) return {
          image: PDFLogoBlue,
          fit: [100, 25],
        }
        return { text: '', margin: [100, 25] }
      }

      var abnacn = null
      if(data.abn !== undefined && data.abn !== '')
        abnacn = "ABN: "+data.abn
      if(data.acn !== undefined && data.acn !== '')
        abnacn = "ACN: "+data.acn

      var meetingDate = ""
      if(data.meetingDate !== null && data.meetingDate !== undefined)
        meetingDate = data.meetingDate.format(DateFormat.LLLL)

      var docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20, 30, 20, 30 ],
        defaultStyle: {
          font: 'Montserrat'
        },
        listOfFonts,
        footer: {
          columns: [
            {
              columns: footerLogo
            },
            [
              { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
              { text: data.myName, alignment: 'right', style: 'footerR'},
            ]
          ]
        },
        content: [
          {
            table: {
              widths: [70, '*'],
      				body: [
      					[
                  defaultLogo(),
                  {
                    stack: [
              				data.companyName,
              				{text: abnacn!==''?abnacn:'', style: 'subheader'},
              			],
              			style: BinderItemType.header
                  }
                ],
      				]
      			},
            style: 'headerBox',
      			layout: 'noBorders'
          },
          {
            stack: [
              {text: data.boardName, style: 'boardTitle'},
              {text: data.name, style: 'title'},
              {text: meetingDate, style: 'meetingDate'},
              data.location,
            ],
            style: 'titleBox',
          },
          {
      			style: 'tableBox',
      			table: {
              widths: ['*', 20, '*'],
      				body: [
      					[
                  {
                    table: {
                      widths: ['*'],
              				body: listofAttendees
              			},
                    layout: 'noBorders'
              			/*layout: {
              				fillColor: function (rowIndex, node, columnIndex) {
              					return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
              				}
              			}*/
                  },
                  '',
                  {
                    stack: [
                      {
                        table: {
                          widths: ['*'],
                  				body: listSecondCol
                  			},
                        layout: 'noBorders'
                  			/*layout: {
                  				fillColor: function (rowIndex, node, columnIndex) {
                  					return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                  				}
                  			}*/
                      },
                      listofInvitees,
                    ],
                  }
                ],
      				]
      			},
            layout: 'noBorders'
      		},
          listofItems,
          listofActions,
        ],

        styles: {
          headerBox: {
            marginBottom: 20,
          },
      		header: {
      			fontSize: 16,
      			//bold: true,
      			alignment: 'right',
      		},
      		subheader: {
      			fontSize: 14
      		},
      		superMargin: {
      			margin: [20, 0, 40, 0],
      			fontSize: 15
      		},
          boardTitle: {
            fontSize: 24,
            marginBottom: 20,
          },
          title: {
            fontSize: 20,
            marginBottom: 20,
          },
          meetingDate: {
            fontSize: 16,
            bold: true,
            marginBottom: 10,
          },
          titleBox: {
            fontSize: 16,
            marginBottom: 20,
            padding: 0,
          },
          tableBox: {
            fontSize: 12,
            marginBottom: 20,
          },
          inviteeBox: {
            marginTop: 20,
          },
          tableMinute: {

          },
          rowTable: {
            marginLeft: 10,
          },
          tableHeader: {
            fontSize: 14,
            fontWeight: 'bold',
            color: "#ffffff",
          },
          boxTitle: {
            fontSize: 16,
            bold: true,
            color: "#333333",
            marginLeft: 5,
            padding: 0,
          },
          miBot: {
            marginBottom: 7,
          },
          miTop: {
            marginTop: 5,
          },
          agendaTitle: {
            marginTop: 5,
            fontSize: 12,
            bold: true,
          },
          itemDetails: {
            fontSize: 12,
            color: "#999999",
          },
          footerL: {
            fontSize: 10,
            marginLeft: 10,
            color: "#999999",
          },
          footerText: {
            fontSize: 10,
            marginLeft: 18,
            marginTop: 5,
            color: "#999999",
          },
          footerTextBR: {
            fontSize: 10,
            marginLeft: 15,
            marginTop: 4,
            color: "#999999",
          },
          footerR: {
            fontSize: 10,
            marginRight: 10,
            color: "#999999",
          }
      	}
      }

      //listofItems[0].pdfmakeContents
      resolve(docDefinition)
    });
  }

  item(data, permitted, blocked, myName, datafooter){
    return new Promise((resolve, reject) => {
      var listofViewer = [], listSecondCol = []
      var viewers = {}

      if(permitted !== null && blocked !== null){
        if(permitted.length === 0){
          listofViewer = [
            [{text: 'Viewers', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }],
            [{text: 'None Selected', style: 'rowTable' }],
          ];
        }else{
          listofViewer = [[{text: 'Viewers', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
          permitted.forEach(function(o){
            listofViewer.push(
              [{text: o, style: 'rowTable' }]
            )
          })
        }

        if(blocked.length === 0){
          listSecondCol = [
            [{text: 'Blocked', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }],
            [{text: 'None Selected', style: 'rowTable' }],
          ];
        }else{
          listSecondCol = [[{text: 'Blocked', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]];
          blocked.forEach(function(o){
            listSecondCol.push(
              [{text: o, style: 'rowTable' }]
            )
          })
        }

        viewers = {
          style: 'tableBox',
          table: {
            widths: ['*', 20, '*'],
            body: [
              [
                {
                  table: {
                    widths: ['*'],
                    body: listofViewer
                  },
                  layout: 'noBorders'
                },
                '',
                {
                  stack: [
                    {
                      table: {
                        widths: ['*'],
                        body: listSecondCol
                      },
                      layout: 'noBorders'
                    },
                  ],
                }
              ],
            ]
          },
          layout: 'noBorders'
        }
      }

      var top = [
        {
          columns: [
            {
              text: 'Item: ',
              width: 100,
              style: 'title',
            },
            {
              text: data.positionString
            }
          ]
        },
        {
          columns: [
            {
              text: 'Title: ',
              width: 100,
              style: 'title'
            },
            {
              text: data.name
            }
          ]
        },
        {
          columns: [
            {
              text: 'Type: ',
              width: 100,
              style: 'title'
            },
            {
              text: capitaliseFirstLetter(data.binderType)
            }
          ]
        },
      ]

      if(data.binderName !== undefined && data.binderName !== ""){
        top = [
          {
            columns: [
              {
                text: 'Binder: ',
                width: 100,
                style: 'title',
              },
              {
                text: data.binderName
              }
            ]
          },
          ...top
        ]
      }

      if(data.pageCount > 0){
        top.push({
          columns: [
            {
              text: 'Page Count: ',
              width: 100,
              style: 'title'
            },
            {
              text: data.pageCount
            }
          ]
        })
      }

      var extra = {}, results = {}
      if(data.timing!=="" && data.timing !== 0){
        top.push({
          columns: [
            {
              text: 'Duration: ',
              width: 100,
              style: 'title'
            },
            {
              text: data.timing
            }
          ]
        })
      }
      if(data.data === null && data.binderType !== BinderItemType.video && data.binderType !== BinderItemType.header){
        top.push({
          columns: [
            {
              text: '',
              width: 100,
              style: 'title'
            },
            {
              text: 'No Data'
            }
          ]
        })
      }else if(data.voteData !== undefined && data.binderType === BinderItemType.vote){
        var r = [[
          {},
          {text: 'Question:'},
          {text: data.voteData.question}
        ]]
        if(data.voteData.expiryDate){
          var e = moment(data.voteData.expiryDate)
          r.push([
            {},
            {text: 'Expired:'},
            {text: e.format(DateFormat.LLLL)}
          ])
        }

        var dvote = "No vote option entered"
        try{
          dvote = data.voteData.arrayOfAnswers.join(", ")
        }catch(e){
        }

        r.push([
          {},
          {text: 'Answers:'},
          {text: dvote}
        ])

        extra = {
          table: {
            widths: [70, 70, '*'],
            body: r
          },
          style: 'headerBox',
          layout: 'noBorders'
        }

        if(data.total !== undefined){
          var index = []
          var listOfColours = ['#43A19E', '#7B43A1', '#F2317A', '#FF9824', '#58CF6C'];
          function getColour(k){
            if(k === 'Not Voted') return '#999999'
            var c = listOfColours.splice(0,1)
            return c[0]
          }

          var t = 0
          var answer = {name: "", value: 0, tie: false}
          for(var key in data.total){
            index.push({
              name: key,
              value: data.total[key],
              colour: getColour(key)
            })
            t += data.total[key]
            if(key !== "Not Voted"){
              if(data.total[key] === 0){

              }else if(answer.value < data.total[key]){
                answer.value = data.total[key]
                answer.name = key
                answer.tie = false
              }else if(answer.value === data.total[key]){
                answer.tie = true
              }
            }
          }

          var textl1 = answer.name
          var textl2 = "("+ Math.floor(answer.value/t*100).toString()+"%)"
          if(answer.tie){
            textl1 = "Tie Vote"
            textl2 = ""
          }

          var svg = renderToString(<Pie
              				data={ index }
                      textl1={textl1}
                      textl2={textl2}
              				radius={ 200 }
              				hole={ 150 }
              				labels={ false }
              				percent={ true }
              				strokeWidth={ 3 }
              				stroke={ '#fff' }
                      noAnimation={false}
              			/>)


          var rightside = index.map((item, index) => {
            var names = data.list.filter(o => o.results === item.name).map((p, index)=>{
              return p.name
            })

            return {
              stack: [
                {
                  columns: [
                    {svg: '<svg width="20" height="20"><rect width="20" height="20" style="fill:'+item.colour+';stroke-width:1;stroke:rgb(153,153,153)" /></svg>', width: 10},
                    {text: item.name+": "+item.value+" ("+Math.floor(item.value/t*100)+"%)", style: 'quickIcon'}
                  ],
                  style: ['resultTitle', index>0?"gap":""],
                },
                {
                  ul: names
                }
              ],
              style: 'result',
            }
          })
          results = {
            style: 'pieGraph',
            columns: [
      				{svg: svg,width: 200,height: 200},
      				{
      					stack: rightside
      				}
      			]
          }
        }
      }else if(data.voteData !== undefined && data.binderType === BinderItemType.resolution){
      }

      var footerLogo = []
      if(datafooter !== undefined){
        footerLogo = datafooter
      }

      var docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20, 30, 20, 30 ],
        defaultStyle: {
          font: 'Montserrat'
        },
        footer: {
          columns: [
            {
              columns: footerLogo
            },
            [
              { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
              { text: myName, alignment: 'right', style: 'footerR'},
            ]
          ]
        },
        content: [
          {
            stack: top,
          },
          extra,
          viewers,
          results,
        ],

        styles: {
          title: {
            bold: true,
          },
          tableBox: {
            marginTop: 20,
          },
          footerL: {
            fontSize: 10,
            marginLeft: 10,
            color: "#999999",
          },
          footerText: {
            fontSize: 10,
            marginLeft: 18,
            marginTop: 5,
            color: "#999999",
          },
          footerTextBR: {
            fontSize: 10,
            marginLeft: 15,
            marginTop: 4,
            color: "#999999",
          },
          footerR: {
            fontSize: 10,
            marginRight: 10,
            color: "#999999",
          },
          pieGraph: {
            marginTop: 20,
          //  marginRight: 20,
          },
          quickIcon: {
            marginLeft: 10,
          },
          resultTitle: {
            marginBottom: 5,
          },
          result: {
            marginLeft: 20,

          },
          gap: {
            marginTop: 20,
          }
      	}
      }

      resolve(docDefinition)
    });
  }

  logs(data){
    return new Promise((resolve, reject) => {
      var logs = []

      var pos=0
      for(var y=0; y<data.groupBy.length; y++){
        var dayEnd = moment(data.groupBy[y])
        dayEnd.hours(23)
        dayEnd.minutes(59)
        dayEnd.seconds(59)

        var dayStart = dayEnd.clone()
        dayStart.hours(0)
        dayStart.minutes(0)
        dayStart.seconds(0)


        var inner = [];
        var lastPerson = ""
        for(var x=pos; x<data.list.length; x++){
          var o = moment(new Date(data.list[x].date))
          if(o <= dayStart ){///&& o >= dayStart
            pos = x
            break
          }

          if(lastPerson !== data.list[x].name){
            lastPerson = data.list[x].name
            inner.push({
              text: data.list[x].name,
              style: 'nameTitle'
            })
          }
          if(data.list[x].type === UsageType.DocumentShared){
            var sentTo = data.list[x].information.split(",")
            inner.push({
              text: o.format('hh:mm a')+": "+GetTypeName(data.list[x].type)+" item "+
                    data.list[x].itemName+" to "+ sentTo[0]+" "+sentTo[1]+" email "+sentTo[2]
            })
          }else{
            inner.push({
              text: o.format('hh:mm a')+": "+GetTypeName(data.list[x].type)+" item "+
                  data.list[x].itemName
            })
          }
        }

        logs.push({
          text: moment(dayEnd).format('LL'),
          style: 'dateTitle'
        })
        logs = [...logs, ...inner]
      }

      var footerLogo = []
      if(data.footer !== undefined){
        footerLogo = data.footer
      }

      var docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20, 30, 20, 30 ],
        defaultStyle: {
          font: 'Montserrat'
        },
        footer: {
          columns: [
            {
              columns: footerLogo
            },
            [
              { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
              { text: data.myName, alignment: 'right', style: 'footerR'},
            ]
          ]
        },
        content: [
          {
            text: 'Usage Logs',
            style: 'title'
          },
          {
            stack: logs,
          },
        ],

        styles: {
          title: {
            fontSize: 16,
            bold: true,
            marginTop: 5,
            marginBottom: 20,
          },
          dateTitle: {
            bold: true,
            marginBottom: 10,
          },
          nameTitle: {
            bold: true,
            marginTop: 3,
            marginBottom: 3,
          },
          footerText: {
            fontSize: 10,
            marginLeft: 18,
            marginTop: 5,
            color: "#999999",
          },
          footerTextBR: {
            fontSize: 10,
            marginLeft: 15,
            marginTop: 4,
            color: "#999999",
          },
          footerL: {
            fontSize: 10,
            marginLeft: 10,
            color: "#999999",
          },
          footerR: {
            fontSize: 10,
            marginRight: 10,
            color: "#999999",
          },
      	}
      }

      resolve(docDefinition)
    });
  }

  summaryLogs(data){
    return new Promise((resolve, reject) => {
      var p = Math.floor(data.header.length/20)
      var r = data.header.length - p*20
      if(r > 0 ) p++

      var columns = data.header.map((item, index)=>{
        return {text: item.positionString}
      })

      var pages = []

      for(var x=0; x<p; x++){
        var tablesize = [100]
        var col = [{text: 'Attendee/Recipient', style: 'cellBold'}]

        var iCount = 0, iInitial = x * 20
        if(x !== (p-1)){
          iCount = 20
          var c = columns.splice(0, 20)
          col = col.concat(c)
        }else{
          iCount = columns.length
          col = col.concat(columns)
        }

        tablesize = tablesize.concat(Array(iCount).fill('*'))
        var cells = []

        data.users.forEach((u)=>{
          var r = []
          if(data.sortUser)
            r.push({text: u.firstName+" "+u.lastName})
          else r.push({text: u.lastName+", "+u.firstName})

          for(var y=iInitial; y<iInitial+iCount; y++){
            var c = u.cell[y]

            // if(c === undefined){
            //   r.push({svg: convertSVG(IconCircle),width: 10,height: 10})
            //   continue
            // }

            switch(c[0]){
              case 0:
                r.push({svg: convertSVG(IconCircle),width: 10,height: 10})
                break
              case 1:
                r.push({svg: convertSVG(IconDownload),width: 10,height: 10})
                break
              case 2:
                r.push({svg: convertSVG(IconRead),width: 10,height: 10})
                break
              case 3:
                r.push({svg: convertSVG(IconTick),width: 10,height: 10})
                break
              case 4:
                r.push({svg: convertSVG(IconSigned),width: 10,height: 10})
                break
              case 5:
                r.push({svg: convertSVG(IconAnnotation),width: 10,height: 10})
                break
              case 6:
                r.push({svg: convertSVG(IconShared),width: 10,height: 10})
                break
              case 7:
                r.push({svg: convertSVG(IconPrint),width: 10,height: 10})
                break
              default:
                r.push({svg: convertSVG(IconCircle),width: 10,height: 10})
            }
          }
          cells.push(r)
        })

        var footerLogo = []
        if(data.footer !== undefined){
          footerLogo = data.footer
        }

        pages.push({
          pageSize: 'A4',
          pageMargins: [ 20, 30, 20, 30 ],
          pageOrientation: 'landscape',
          defaultStyle: {
            font: 'Montserrat'
          },
          footer: {
            columns: [
              {
                columns: footerLogo
              },
              [
                { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
                { text: data.myName, alignment: 'right', style: 'footerR'},
              ]
            ]
          },
          content: [
            {
              text: 'Status',
              style: 'title'
            },
            {
              table: {
                widths: [12, 60, 12, 60, 12, 60, 12, 60, 12, 60, 12, 60, 12, 60],
        				body: [
        					[
                    {svg: convertSVG(IconDownload),width: 8,height: 8},
                    {text: UsageType.Download, style: 'iconText'},
                    {svg: convertSVG(IconRead),width: 8,height: 8},
                    {text: UsageType.Open, style: 'iconText'},
                    {svg: convertSVG(IconTick),width: 8,height: 8},
                    {text: UsageType.Voted, style: 'iconText'},
                    {svg: convertSVG(IconSigned),width: 8,height: 8},
                    {text: UsageType.Signed, style: 'iconText'},
                    {svg: convertSVG(IconAnnotation),width: 8,height: 8},
                    {text: UsageType.Annotated, style: 'iconText'},
                    {svg: convertSVG(IconShared),width: 8,height: 8},
                    {text: UsageType.Shared, style: 'iconText'},
                    {svg: convertSVG(IconPrint),width: 8,height: 8},
                    {text: UsageType.Printed, style: 'iconText'},
                  ],
        				]
        			},
              layout: 'noBorders'
            },
            {
              table: {
                widths: tablesize,
        				body: [
                  col,
                  ...cells
        				]
        			},
              layout: 'lightHorizontalLines'
            },
          ],

          styles: {
            title: {
              fontSize: 16,
              bold: true,
              marginBottom: 20,
            },
            iconText: {
              fontSize: 10,
              marginBottom: 10,
            },
            cellBold: {
              bold: true,
            },
            footerText: {
              fontSize: 10,
              marginLeft: 18,
              marginTop: 5,
              color: "#999999",
            },
            footerTextBR: {
              fontSize: 10,
              marginLeft: 15,
              marginTop: 4,
              color: "#999999",
            },
            footerL: {
              fontSize: 10,
              marginLeft: 10,
              color: "#999999",
            },
            footerR: {
              fontSize: 10,
              marginRight: 10,
              color: "#999999",
            },
          }
        })
      }

      resolve(pages)
    })
  }
}

export class ConflictOfInterestPDFMake {
  Generate(data){
    return new Promise((resolve, reject) => {
      var listofActions = {};
      var listOfFonts = [];

      if (data.declarations && data.declarations.length) {
        var items = [
          // [{ text: 'Conflicts of Interest', style: 'boxTitle', fillColor: '#f2f2f2', colSpan: 4, border: [false, false, false, false] }],
          [
            { text: 'Name', style: 'tableHeader', fillColor: '#333333', border: [false, false, false, false] },
            { text: 'Type', style: 'tableHeader', fillColor: '#333333', border: [false, false, false, false] },
            { text: 'Interest', style: 'tableHeader', fillColor: '#333333', border: [false, false, false, false] },
            { text: 'Date', style: 'tableHeader', fillColor: '#333333', border: [false, false, false, false] }
          ]
        ]
        data.declarations.forEach(function (ua) {
          if (ua.interests && ua.interests.length) {
            ua.interests
              .sort((a, b) => { return new Date(a.date, b.date) })
              .forEach(uai => {
                items.push([
                  { text: ua.userName, border: [false, false, false, false] },
                  { text: CoITypeToString(uai.type), border: [false, false, false, false] },
                  {
                    // type: 'none',
                    text: uai.value,
                    border: [false, false, false, false]
                  },
                  { text: moment(uai.date).format("LLL"), border: [false, false, false, false] },
                ]);
              })
          }
        })
        listofActions = {
          style: 'tableBox',
          table: {
            widths: ['25%', '15%', '40%', '20%'],
            body: items,
          },
          layout: {
            hLineColor: function (i, node) {
              return '#d4d4d4';
            },
          }
        };
      }

      var docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [ 10, 20, 10, 20 ],
        defaultStyle: {
          font: 'Montserrat'
        },
        listOfFonts,
        footer: {
          columns: [
            {
              columns: [
                { image: PDFLogoBlue, width: 45, height: 20, style: 'footerL' },
                { text: 'Generated by board.ansarada.com', style: 'footerText'}
              ],
            },
            [
              { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
              { text: data.myName, alignment: 'right', style: 'footerR'},
            ]
          ]
        },
        content: [
          // {
          //   table: {
          //     widths: [70, '*'],
      		// 		body: [
      		// 			[
          //         defaultLogo(),
          //         {
          //           stack: [
          //     				data.companyName,
          //     				{text: abnacn!==''?abnacn:'', style: 'subheader'},
          //     			],
          //     			style: BinderItemType.header
          //         }
          //       ],
      		// 		]
      		// 	},
          //   style: 'headerBox',
      		// 	layout: 'noBorders'
          // },
          // {
          //   stack: [
          //     {text: data.boardName, style: 'boardTitle'},
          //     {text: data.name, style: 'title'},
          //     {text: meetingDate, style: 'meetingDate'},
          //     data.location,
          //   ],
          //   style: 'titleBox',
          // },
          // {
      		// 	style: 'tableBox',
      		// 	table: {
          //     widths: ['*', 20, '*'],
      		// 		body: [
      		// 			[
          //         {
          //           table: {
          //             widths: ['*'],
          //     				body: listofAttendees
          //     			},
          //           layout: 'noBorders'
          //     			/*layout: {
          //     				fillColor: function (rowIndex, node, columnIndex) {
          //     					return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
          //     				}
          //     			}*/
          //         },
          //         '',
          //         {
          //           stack: [
          //             {
          //               table: {
          //                 widths: ['*'],
          //         				body: listSecondCol
          //         			},
          //               layout: 'noBorders'
          //         			/*layout: {
          //         				fillColor: function (rowIndex, node, columnIndex) {
          //         					return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
          //         				}
          //         			}*/
          //             },
          //             listofInvitees,
          //           ],
          //         }
          //       ],
      		// 		]
      		// 	},
          //   layout: 'noBorders'
      		// },
          // listofItems,
          listofActions,
        ],

        styles: {
          headerBox: {
            marginBottom: 20,
          },
      		header: {
      			fontSize: 16,
      			//bold: true,
      			alignment: 'right',
      		},
      		subheader: {
      			fontSize: 14
      		},
      		superMargin: {
      			margin: [20, 0, 40, 0],
      			fontSize: 15
      		},
          boardTitle: {
            fontSize: 24,
            marginBottom: 20,
          },
          title: {
            fontSize: 20,
            marginBottom: 20,
          },
          meetingDate: {
            fontSize: 16,
            bold: true,
            marginBottom: 10,
          },
          titleBox: {
            fontSize: 16,
            marginBottom: 20,
            padding: 0,
          },
          tableBox: {
            fontSize: 12,
            marginBottom: 20,
          },
          inviteeBox: {
            marginTop: 20,
          },
          tableMinute: {

          },
          rowTable: {
            marginLeft: 10,
          },
          tableHeader: {
            fontSize: 14,
            fontWeight: 'bold',
            color: "#ffffff",
          },
          boxTitle: {
            fontSize: 16,
            bold: true,
            color: "#333333",
            marginLeft: 5,
            padding: 0,
          },
          miBot: {
            marginBottom: 7,
          },
          miTop: {
            marginTop: 5,
          },
          agendaTitle: {
            marginTop: 5,
            fontSize: 12,
            bold: true,
          },
          itemDetails: {
            fontSize: 12,
            color: "#999999",
          },
          footerL: {
            fontSize: 10,
            marginLeft: 10,
            color: "#999999",
          },
          footerText: {
            fontSize: 10,
            marginLeft: 18,
            marginTop: 5,
            color: "#999999",
          },
          footerTextBR: {
            fontSize: 10,
            marginLeft: 15,
            marginTop: 4,
            color: "#999999",
          },
          footerR: {
            fontSize: 10,
            marginRight: 10,
            color: "#999999",
          }
      	}
      }

      //listofItems[0].pdfmakeContents
      resolve(docDefinition)
    });
  }
}

/*export class SummaryReportWord {
  Generate(data){
    return new Promise((resolve, reject) => {
      var listofItems = {};
      var listofActions = {};
      var listofInvitees = {};

      var listofAttendees = [], listSecondCol = []
      var secondColHeader = ""


      if(data.attendees === undefined || data.attendees.length === 0){
        listofAttendees = ["<tr><td>No Attendees</td></tr>"]
      }else{
        data.attendees.forEach(function(o){
          listofAttendees.push(
            "<tr><td>"+o+"</td></tr>"
          )
        })
      }

      if(data.apologies === undefined || data.apologies.length === 0){
        secondColHeader = "Apologies"
        listSecondCol = ["<tr><td>No Apologies</td></tr>"]
      }else{
        secondColHeader = "Apologies"
        data.apologies.forEach(function(o){
          listSecondCol.push("<tr><td>"+o+"</td></tr>")
        })
      }

      if((data.recipients === undefined && data.apologies === undefined) || (data.recipients !== undefined && data.recipients.length === 0)){
        secondColHeader = "Recipients"
        listSecondCol = ["<tr><td>No Recipients</td></tr>"]
      }else if(data.recipients !== undefined && data.recipients.length > 0){
        secondColHeader = "Recipients"
        data.recipients.forEach(function(o){
          listSecondCol.push("<tr><td>"+o+"</td></tr>")
        })
      }

      if(data.invitees !== undefined && data.invitees.length > 0){
        var items = ['<tr><th>Invitees</th></tr>']
        data.invitees.forEach(function(o){
          items.push("<tr><td>"+o+"</td></tr>")
        })
        listofInvitees = "<table><tbody>"+items.join("")+"</tbody></table>"
      }

      if(data.actions !== undefined && data.actions.length > 0){
        var items = [
          [{text: 'Actions', style: 'boxTitle', fillColor: '#f2f2f2', colSpan: 3, border: [false, false, false, false] },{},{}],
          [
            {text: 'Ref', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Action', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Who', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]}
          ]
        ]
        data.actions.forEach(function(o){
          items.push([
            {text: o.itemref, border: [false, false, false, false]},
            {text: o.description, border: [false, false, false, false]},
            {
              type: 'none',
              ol: o.assignee,
              border: [false, false, false, false]
            },
          ]);
        })
        listofActions = {
          style: 'tableBox',
          table: {
            widths: [40, '*', '*'],
            body: items,
          },
          layout: {
            hLineColor: function (i, node) {
              return '#d4d4d4';
            },
          }
        };
      }

      if(data.minutes !== undefined && data.minutes.length > 0){
        var minuteItems = []
        data.minutes.forEach(function(o){
          draftToHtml
          var stateToPdfMake  = draftToHtml(
            convertToRaw(o.editorState.getCurrentContent()),
            {}
          )

          var s = '<tr><td>'+o.positionString+'</td><td>'+o.name+'</td><td>'
          if(o.duration!=="0:0") s+=o.duration
          s+='</td></tr>'
          minuteItems.push(s)
          minuteItems.push('<tr style="border-bottom: 1px solid #d4d4d4;margin-bottom: 7px;"><td></td><td colspan="2">'+stateToPdfMake+'</td></tr>')
        });

        listofItems = '<table><tbody><tr><th colspan="3">Minutes</th></tr><tr class="tableHeader"><td>Item</td><td>Description</td><td style="width:30px;"></td></tr>'+minuteItems.join('')+'</tbody></table>'
      }

      if(data.listItems !== undefined && data.listItems.length > 0){
        var listitems = [[{text: 'Items', style: 'boxTitle', fillColor: '#f2f2f2', border: [false, false, false, false] }]]
        var minuteItems = [
          [
            {text: 'Item', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
            {text: 'Description', style: 'tableHeader', fillColor: '#333333' , border: [false, false, false, false]},
          ]
        ];

        data.listItems.forEach(function(o){
          var p = {text: o.positionString, border: [false, false, false, false], style: 'miTop'}
          var t = {text: o.name, border: [false, false, false, false], style: 'agendaTitle'}
          if(o.link !== undefined){
            p.linkToPage = o.link
            t.linkToPage = o.link
          }
          minuteItems.push([p, t]);
          minuteItems.push([
            {text: '',border: [false, false, false, true], style: 'miBot'},
            {
              table: {
                widths: ['*','*','*'],
                body: [[
                  {text: 'Type: '+ capitaliseFirstLetter(o.type), style: 'itemDetails'},
                  {text: o.pageCount>0?'Page Count: '+o.pageCount:"", style: 'itemDetails'},
                  {text: o.duration===""||o.duration===0?"":"Duration: "+o.duration, style: 'itemDetails'}
                ]]
              },
              layout: 'noBorders',
              border: [false, false, false, true]
            }
          ]);
        });

        listitems.push([
          {
            table: {
              widths: [40, '*'],
              body: minuteItems
            },
            layout: {
              hLineColor: function (i, node) {
                return '#d4d4d4';
              },
            }
          }
        ]);

        listofItems = {
          style: 'tableBox',
          table: {
            widths: ['*'],
            body: listitems,
          },
          layout: 'noBorders'
        };

      }

      var footerLogo = []
      if(data.footer !== undefined){
        footerLogo = data.footer
      }

      function defaultLogo(){
        if(data.logo!==undefined) return '<img src="'+data.logo+'" class="logoImg"/>'
        if(data.athenaLogo === undefined) return '<img src="'+PDFLogoBlue+'" class="logoImg"/>'
        return ""
      }

      var docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20, 30, 20, 30 ],
        footer: {
          columns: [
            {
              columns: footerLogo
            },
            [
              { text: moment().format('LLL'), alignment: 'right', style: 'footerR'},
              { text: data.myName, alignment: 'right', style: 'footerR'},
            ]
          ]
        },
        content: [
          {
            table: {
              widths: [70, '*'],
              body: [
                [
                  defaultLogo(),
                  {
                    stack: [
                      data.companyName,
                      {text: data.abn!==''?'ABN: '+data.abn:'', style: 'subheader'},
                    ],
                    style: BinderItemType.header
                  }
                ],
              ]
            },
            style: 'headerBox',
            layout: 'noBorders'
          },
          {
            stack: [
              {text: data.boardName, style: 'boardTitle'},
              {text: data.name, style: 'title'},
              {text: data.meetingDate.format(DateFormat.LLLL), style: 'meetingDate'},
              data.location,
            ],
            style: 'titleBox',
          },
          {
            style: 'tableBox',
            table: {
              widths: ['*', 20, '*'],
              body: [
                [
                  {
                    table: {
                      widths: ['*'],
                      body: listofAttendees
                    },
                    layout: 'noBorders'
                    // layout: {
                    //   fillColor: function (rowIndex, node, columnIndex) {
                    //     return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    //   }
                    // }
                  },
                  '',
                  {
                    stack: [
                      {
                        table: {
                          widths: ['*'],
                          body: listSecondCol
                        },
                        layout: 'noBorders'
                        // layout: {
                        //   fillColor: function (rowIndex, node, columnIndex) {
                        //     return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                        //   }
                        // }
                      },
                      listofInvitees,
                    ],
                  }
                ],
              ]
            },
            layout: 'noBorders'
          },
          listofItems,
          listofActions,
        ],

        styles: {
          headerBox: {
            marginBottom: 20,
          },
          header: {
            fontSize: 16,
            //bold: true,
            alignment: 'right',
          },
          subheader: {
            fontSize: 14
          },
          superMargin: {
            margin: [20, 0, 40, 0],
            fontSize: 15
          },
          boardTitle: {
            fontSize: 24,
            marginBottom: 20,
          },
          title: {
            fontSize: 20,
            marginBottom: 20,
          },
          meetingDate: {
            fontSize: 16,
            bold: true,
            marginBottom: 10,
          },
          titleBox: {
            fontSize: 16,
            marginBottom: 20,
            padding: 0,
          },
          tableBox: {
            fontSize: 12,
            marginBottom: 20,
          },
          inviteeBox: {
            marginTop: 20,
          },
          tableMinute: {

          },
          rowTable: {
            marginLeft: 10,
          },
          tableHeader: {
            fontSize: 14,
            fontWeight: 'bold',
            color: "#ffffff",
          },
          boxTitle: {
            fontSize: 16,
            bold: true,
            color: "#333333",
            marginLeft: 5,
            padding: 0,
          },
          miBot: {
            marginBottom: 7,
          },
          miTop: {
            marginTop: 5,
          },
          agendaTitle: {
            marginTop: 5,
            fontSize: 12,
            bold: true,
          },
          itemDetails: {
            fontSize: 12,
            color: "#999999",
          },
          footerL: {
            fontSize: 10,
            marginLeft: 10,
            color: "#999999",
          },
          footerText: {
            fontSize: 10,
            marginLeft: 18,
            marginTop: 5,
            color: "#999999",
          },
          footerTextBR: {
            fontSize: 10,
            marginLeft: 15,
            marginTop: 4,
            color: "#999999",
          },
          footerR: {
            fontSize: 10,
            marginRight: 10,
            color: "#999999",
          }
        }
      }

      var docHtml = `
        <html>
        <head>
          <style>
            <!--
              @page
              {
                  size:21cm 29.7cmt;  // A4
                  margin:1cm 1cm 1cm 1cm; // Margins: 2.5 cm on each side
                  mso-page-orientation: portrait;
              }
              @page Section1 { }
              div.Section1 { page:Section1; }
              .logoImg {
                width: 50px;
                height: 50px;
              }
             .titlebox {
            	  font-size: 16px;
            		margin-bottom: 20px;
            		display:flex;
            		flex-direction:column;
            	}
            	.titlebox div{
            		font-size: 24px;
            		margin-bottom: 20px;
            	}
            	.titlebox label{
            		font-size: 20px;
            		margin-bottom: 20px;
            	}
              table {
            		width: 100%;
                border-collapse: collapse;
            	}
            	th {
            	  font-size: 16px;
            	  font-weight: bold'
            	  color: #333333;
                padding: 0px;
                margin: 0px;
                margin-left: 5px;
            	  background-color: #f2f2f2;
                vertical-align: center;
                text-align: left;
            	}
              td {
                padding: 0;
                margin: 0;
              }
              .tableHeader {
                background-color: #333333;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                text-align: left;
                margin-left: 5px;
              }
            -->
          </style>
        </head>
        <body>
          <table style="margin-bottom: 20px;">
            <tbody>
              <tr>
                <td style="width:70px;">
                  `+defaultLogo()+`
                </td>
                <td style="width:20px;"></td>
                <td style="text-align: right;">
                  <label>`+data.companyName+`</label><br/>
                  <label style="font-size: 16px">`+(data.abn!==''?'ABN: '+data.abn:'')+`</label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="titlebox">
          	<div>`+data.boardName+`</div>
          	<p style="font-size: 20px;">`+data.name+`</p>
          	<p><span style="font-weight:bold">`+data.meetingDate.format(DateFormat.LLLL)+`</span>
          	<br/>`+data.location+`</p>
          </div>
          <table style="margin-bottom: 20px;">
          	<tbody>
          	  <tr>
            		<td style="vertical-align:top;">
                  <table>
                    <tbody>
                      <tr>
                        <th>Attendees</th>
                      </tr>
                      `+listofAttendees.join('')+`
                    </tbody>
                  </table>
                </td>
            		<td style="width: 20px;"></td>
            		<td style="vertical-align:top;">
                  <table style="margin-bottom: 20px;">
                    <tbody>
                      <tr>
                        <th>`+secondColHeader+`</th>
                      </tr>
                      `+listSecondCol.join('')+`
                    </tbody>
                  </table>
                  `+listofInvitees+`
                </td>
          	  </tr>
          	</tbody>
          </table>
          `+listofItems+`
        </body>
        </html>
      `



//       <html>
//       <head>
//         <style>
//           <!--
//             @page
//             {
//                 size:21cm 29.7cmt;  //A4
//                 margin:1cm 1cm 1cm 1cm; // Margins: 2.5 cm on each side
//                 mso-page-orientation: portrait;
//             }
//             @page Section1 { }
//             div.Section1 { page:Section1; }
//           -->
//         </style>
//       </head>
//       <body>
//         <div style="mso-element:header;" id="h1">
//           <p class=MsoHeader>Ttest header name</p>
//         </div>
//         <div class=Section1>
// I'm page 1.
// <br clear=all style='mso-special-character:line-break;page-break-before:always'>
// I'm page 2.
// </div>
//       </body>
//       </html>

      resolve(docHtml)
    });
  }
}*/

class StateToDocXMake {
  constructor(contentState) {
    this.contentState = contentState;//convertFromRaw(contentState);
    this.currentBlock = 0;
    this.output       = { content : [] };
    this.blocks       = null;
    this.listOlAcc    = [];
    this.listUlAcc    = [];
  }

  generate() {
    this.blocks = this.contentState.getBlockMap().toArray();

    while(this.currentBlock < this.blocks.length) {
      this._processBlock();
    }

    return this.output.content
  }

  _processBlock() {
    const block = this.blocks[this.currentBlock];

    const defaultHeaderStyle = { bold : true, margin : [ 0, 5, 0, 0 ] };

    if(block.getType() !== BLOCK_TYPE.UNORDERED_LIST_ITEM && !!this.listUlAcc.length) {
      this._updateAndResetUlList();
    }

    if(block.getType() !== BLOCK_TYPE.ORDERED_LIST_ITEM && !!this.listOlAcc.length) {
      this._updateAndResetOlList();
    }

    switch (block.getType()) {
      case BLOCK_TYPE.HEADER_ONE:
        this.output.content.push(new TextRun({text: block.getText(), size: 24*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.HEADER_TWO:
        this.output.content.push(new TextRun({text: block.getText(), size: 22*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.HEADER_THREE:
        this.output.content.push(new TextRun({text: block.getText(), size: 20*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.HEADER_FOUR:
        this.output.content.push(new TextRun({text: block.getText(), size: 18*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.HEADER_FIVE:
        this.output.content.push(new TextRun({text: block.getText(), size: 16*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.HEADER_SIX:
        this.output.content.push(new TextRun({text: block.getText(), size: 14*2, ...defaultHeaderStyle}));
      break;

      case BLOCK_TYPE.ORDERED_LIST_ITEM:
        this.listOlAcc.push(...this._renderBlockContent(block, {numbering: true}));
      break;

      case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        this.listUlAcc.push(...this._renderBlockContent(block, {bullet: true}));
      break;

      default:
        const data = this._renderBlockContent(block);
        if(data.length === 0)
          this.output.content.push(new Paragraph({text: ""}));
        else
          this.output.content.push(...data);
    }

    // Clear lists when is last block
    if(block.getKey() === this.contentState.getLastBlock().getKey()) {
      if(!!this.listUlAcc.length) {
        this._updateAndResetUlList();
      }

      if(!!this.listOlAcc.length) {
        this._updateAndResetOlList();
      }
    }

    this.currentBlock += 1;
  }

  getFontSize(style){
    if(style._map === undefined) return 12
    if(style._map._list === undefined) return 12
    var a = style._map._list
    for(var x=0; x<a.size;a++){
      var p = a.get(x)
      if(p.length < 2) continue
      if(p[1] !== true) continue
      if(p[0].includes('fontsize')){
        var s = p[0].split("-")
        if(s.length === 2)
          return parseInt(s[1])
      }
    }

    return 12
  }

  getFontStyle(style){
    if(style._map === undefined) return {}
    if(style._map._list === undefined) return {}
    var s = {}
    var a = style._map._list
    for(var x=0; x<a.size;a++){
      var p = a.get(x)
      if(p.length < 2) continue
      if(p[1] !== true) continue
      if(p[0].includes('fontsize')){
        var sp = p[0].split("-")
        if(sp.length === 2){
          s.size = parseInt(sp[1])*2
        }
      }else if(p[0].includes('fontfamily')){
        var w = p[0].split('-')
        s.font = w[1]
        this.addFonts(s.font)
      }
    }

    return s
  }

  _renderBlockContent(block, opt = null) {
    if(block.getText() === '') {
      return [];
    }

    const ranges = getEntityRanges(block.getText(), block.getCharacterList());

    const data = block.getData();

    return ranges.reduce((acc, [entityKey, stylePieces]) => {
      acc.push(
        ...stylePieces.map(([ text, style ]) => {
          var j = {
            text       : this._encodeContent(text),
            bold       : style.has(BOLD),
            italics    : style.has(ITALIC),
            font       : { name: 'Arial'},
          }

          if(style.has(UNDERLINE))
            j.underline = {}
          if(style.has(STRIKETHROUGH))
            j.strike = true

          j = Object.assign(j, this.getFontStyle(style))

          return new TextRun(j);

        }).filter((properties) => properties.text !== ' ')
      );

      var p = {
        children: acc,
      }
      if(data.has('text-align'))
        p.alignment = data.get('text-align')

      if(opt !== null && opt.bullet === true)
        p.bullet = {
          level: block.getDepth(),
        }
      if(opt !== null && opt.numbering === true)
        p.numbering = {
          reference: "my-crazy-reference",
          level: block.getDepth(),
        }

      return [new Paragraph(p)];
    }, []);
  }

  _encodeContent(text) {
    return text.replace(/[*_`]/g, '\\$&');
  }

  _updateAndResetUlList() {
    this.output.content.push(...this.listUlAcc);
    this.listUlAcc = [];
  }

  _updateAndResetOlList() {
    this.output.content.push(...this.listOlAcc);
    this.listOlAcc = [];
  }
}

export class SummaryReportDocx {
  Generate(data){
    return new Promise((resolve, reject) => {
      var footerImage = []

      function border(){
        return {top:{style: BorderStyle.NIL}, bottom: {style: BorderStyle.NIL}, right: {style: BorderStyle.NIL}, left: {style: BorderStyle.NIL}}
      }

      function line(text, option = {}, parOption = {}){
        return new Paragraph({
          children: [
            new TextRun(Object.assign({text: text, font: { name: 'Arial'}}, option)),
          ],
          ...parOption
        })
      }

      function baseRow(o){
        return new TableRow({
          children: [
            new TableCell(o)
          ]
        })
      }

      // Create document
      const doc = new Document({
        numbering: {
          config: [
            {
              levels: [
                {
                  level: 0,
                  format: "decimal",
                  text: "%1",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                        indent: { left: 720, hanging: 260 },
                    },
                  },
                },
                {
                  level: 1,
                  format: "decimal",
                  text: "%2",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                        indent: { left: 1080, hanging: 260 },
                    },
                  },
                },
                {
                  level: 2,
                  format: "decimal",
                  text: "%3",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                        indent: { left: 1440, hanging: 260 },
                    },
                  },
                },
                {
                  level: 3,
                  format: "decimal",
                  text: "%4",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                        indent: { left: 1860, hanging: 260 },
                    },
                  },
                },
                {
                  level: 4,
                  format: "decimal",
                  text: "%5",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                        indent: { left: 2160, hanging: 260 },
                    },
                  },
                },
              ],
              reference: "my-crazy-reference",
            },
          ],
        },
        sections: []
      });

      if(data.footer !== undefined){
        // const image1 = Media.addImage(doc, data.footer);
        const image1 = new ImageRun({
          data: data.footer,
        });
        footerImage = [new Paragraph({children: [image1]})]
      }

      function defaultLogo() {
        if (data.logo !== undefined) {
          // const image1 = Media.addImage(doc, data.logo, 100, 100);
          const image1 = new ImageRun({
            data: data.logo,
            transformation: {
              width: 100,
              height: 100,
            },
          });
          return [new Paragraph({children: [image1]})]
        }
        if (data.athenaLogo === undefined) {
          // const image1 = Media.addImage(doc, PDFLogoBlue, 100, 25);
          const image1 = new ImageRun({
            data: PDFLogoBlue,
            transformation: {
              width: 100,
              height: 25,
            },
          });
          return [new Paragraph({children: [image1]})]
        }

        return []
      }

      var footer = [
        new Table({
          rows: [new TableRow({
            children: [
              new TableCell({
                children: footerImage,
                width: {
                  size: 100,
                  type: WidthType.DXA,
                },
                borders: border()
              }),
              new TableCell({
                children: [
                  line(moment().format('LLL'), { size: 10*2 }, {alignment: AlignmentType.RIGHT}),
                  line(data.myName, { size: 10*2 }, { alignment: AlignmentType.RIGHT })
                ],
                borders: border()
              })
            ]
          })],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: border(),
        })
      ]

      var meetingDate = ""
      if(data.meetingDate !== null && data.meetingDate !== undefined)
        meetingDate = data.meetingDate.format(DateFormat.LLLL)

      var page = [
        new Table({
          rows: [new TableRow({
            children: [
              new TableCell({
                children: defaultLogo(),
                width: {
                  size: 100,
                  type: WidthType.DXA,
                },
                borders: border()
              }),
              new TableCell({
                children: [
                  line( data.companyName, { size: 16*2 }, { alignment: AlignmentType.RIGHT }),
                  line(data.abn!==''?'ABN: '+data.abn:'', { size: 14*2 }, { alignment: AlignmentType.RIGHT })
                ],
                borders: border()
              })
            ]
          })],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: border()
        }),
        line(data.boardName, { size: 24*2 }, { spacing: { before: 200, after: 200 }}),
        line(data.name, { size: 20*2 }, { spacing: { after: 200 }}),
        line(meetingDate, { size: 16*2, bold: true }, { spacing: { after: 100 }}),
        line(data.location, { size: 16*2 }, { spacing: { after: 200 }}),
      ]

      if(data.attendees !== undefined){
        var rightTableRow = [], leftTableRows = [
          baseRow({
            children: [
              line("Attendees", {size: 16*2, bold: true, color: "333333"})
            ],
            margins: {
              left: 100,
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              fill: "f2f2f2",
              val: ShadingType.PERCENT_100,
              color: "auto",
            },
            borders: border()
          })
        ]
        if(data.attendees.length === 0){
          leftTableRows.push(
            baseRow({
              children: [
                line("No Attendees")
              ],
              margins: {
                left: 200,
              },
              borders: border()
            })
          )
        }else{
          data.attendees.forEach(function(o){
            leftTableRows.push(
              baseRow({
                children: [
                  line(o, {size: 12*2})
                ],
                margins: {
                  left: 200,
                },
                borders: border()
              })
            )
          })
        }

        if(data.apologies === undefined || data.apologies.length === 0){
          rightTableRow.push(
            baseRow(
              {
                children: [
                  line("Apologies", {size: 16*2, bold: true, color: "333333"})
                ],
                margins: {
                  left: 100,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  fill: "f2f2f2",
                  val: ShadingType.PERCENT_100,
                  color: "auto",
                },
                borders: border()
              }
            )
          )
          rightTableRow.push(
            baseRow({
              children: [
                line("No Apologies", {size: 12*2})
              ],
              margins: {
                left: 200,
              },
              borders: border()
            })
          )
        }else{
          rightTableRow.push(
            baseRow({
              children: [
                line("Apologies", {size: 16*2, bold: true, color: "333333"})
              ],
              margins: {
                left: 100,
              },
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: "f2f2f2",
                val: ShadingType.PERCENT_100,
                color: "auto",
              },
              borders: border()
            })
          )
          data.apologies.forEach(function(o){
            rightTableRow.push(
              baseRow({
                children: [
                  line(o, {size: 12*2})
                ],
                margins: {
                  left: 200,
                },
                borders: border()
              })
            )
          })
        }

        if((data.recipients === undefined && data.apologies === undefined) || (data.recipients !== undefined && data.recipients.length === 0)){
          rightTableRow.push(
            baseRow({
              children: [
                line("Recipients", {size: 16*2, bold: true, color: "333333"})
              ],
              margins: {
                left: 100,
              },
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: "f2f2f2",
                val: ShadingType.PERCENT_100,
                color: "auto",
              },
              borders: border()
            })
          )

          rightTableRow.push(
            baseRow({
              children: [
                line("No Recipients", {size: 12*2})
              ],
              margins: {
                left: 200,
              },
              borders: border()
            })
          )
        }else if(data.recipients !== undefined && data.recipients.length > 0){
          rightTableRow.push(
            baseRow({
              children: [
                line("Recipients", {size: 16*2, bold: true, color: "333333"})
              ],
              margins: {
                left: 100,
              },
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: "f2f2f2",
                val: ShadingType.PERCENT_100,
                color: "auto",
              },
              borders: border()
            })
          )
          data.recipients.forEach(function(o){
            rightTableRow.push(
              baseRow({
                children: [
                  line(o, {size: 12*2})
                ],
                margins: {
                  left: 200,
                },
                borders: border()
              })
            )
          })
        }

        if(data.invitees !== undefined && data.invitees.length > 0){
          if(rightTableRow.length > 0){
            rightTableRow.push(
              baseRow({
                children: [],
                borders: border()
              })
            )
          }
          rightTableRow.push(
            baseRow({
              children: [
                line("Invitees", {size: 16*2, bold: true, color: "333333"})
              ],
              margins: {
                left: 100,
              },
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: "f2f2f2",
                val: ShadingType.PERCENT_100,
                color: "auto",
              },
              borders: border()
            })
          )
          data.invitees.forEach(function(o){
            rightTableRow.push(
              baseRow({
                children: [
                  line(o, {size: 12*2})
                ],
                margins: {
                  left: 200,
                },
                borders: border()
              })
            )
          })
        }

        var table = new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Table({
                      rows: leftTableRows,
                      cantSplit: true,
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      borders: border()
                    })
                  ],
                  width: {
                    size: 49,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: border()
                }),
                new TableCell({
                  children: [],
                  width: {
                    size: 2,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: border()
                }),
                new TableCell({
                  children: [
                    new Table({
                      rows: rightTableRow,
                      cantSplit: true,
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      borders: border()
                    })
                  ],
                  width: {
                    size: 49,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: border()
                })
              ]
            })
          ],
          cantSplit: true,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: border()
        });

        page.push(table)
        page.push(line("",{},{spacing:{after: 200}}))
      }

      if(data.minutes !== undefined && data.minutes.length > 0){
        var rows = [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  line("Minutes", {color: "333333", size: 16*2, bold: true}),
                ],
                margins: {
                  left: 100,
                },
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: [3],
                shading: {
                  fill: "f2f2f2",
                  val: ShadingType.PERCENT_100,
                  color: "auto",
                },
                borders: border()
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [line("Item", {color: "ffffff", size: 14*2, bold: true})],
                verticalAlign: VerticalAlign.CENTER,
                margins: {
                  left: 100,
                },
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                borders: border()
              }),
              new TableCell({
                children: [line("Description", {color: "ffffff", size: 14*2, bold: true})],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                borders: border()
              }),
              new TableCell({
                children: [new Paragraph({text:""})],
                width: {
                  size: 40,
                  type: WidthType,
                },
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                borders: border()
              }),
            ],
          }),
        ]

        data.minutes.forEach(function(o){
          var stateToPdfMake  = new StateToDocXMake(o.editorState.getCurrentContent());
          var pdfmakeContents = stateToPdfMake.generate();

          rows.push(
            new TableRow({
              children: [
                new TableCell({
                  children: [line(o.positionString, {size: 12*2}, {spacing: {before: 50}})],
                  margins: {
                    left: 100,
                  },
                  borders: border()
                }),
                new TableCell({
                  children: [line(o.name, {size: 12*2, bold: true}, {spacing: {before: 50, after: 50}})],
                  borders: border()
                }),
                new TableCell({
                  children: [line(o.duration==="0:00"?"":o.duration, {size: 12*2}, {spacing: {before: 50}})],
                  borders: border()
                }),
              ]
            })
          )

          rows.push(
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph({text:""})],
                  borders: {top:{style: BorderStyle.NIL}, bottom: {style: BorderStyle.THICK, size: 12, color: "f2f2f2"}, right: {style: BorderStyle.NIL}, left: {style: BorderStyle.NIL}}
                }),
                new TableCell({
                  children: pdfmakeContents,
                  columnSpan: [2],
                  borders: {top:{style: BorderStyle.NIL}, bottom: {style: BorderStyle.THICK, size: 12, color: "f2f2f2"}, right: {style: BorderStyle.NIL}, left: {style: BorderStyle.NIL}}
                }),
              ]
            })
          )
        });

        var table = new Table({
          rows: rows,
          cantSplit: true,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: border()
        });

        page.push(
          table
        )
        page.push(line("",{},{spacing:{after: 200}}))
      }

      if(data.actions !== undefined && data.actions.length > 0){
        var rows = [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  line("Actions", {color: "333333", size: 16*2, bold: true}),
                ],
                margins: {
                  left: 100,
                },
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: [3],
                shading: {
                  fill: "f2f2f2",
                  val: ShadingType.PERCENT_100,
                  color: "auto",
                },
                borders: border()
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [line("Ref", {color: "ffffff", size: 14*2, bold: true})],
                margins: {
                  left: 100,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                width: {
                  size: 10,
                  type: WidthType.PERCENTAGE,
                },
                borders: border()
              }),
              new TableCell({
                children: [line("Action", {color: "ffffff", size: 14*2, bold: true})],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: border()
              }),
              new TableCell({
                children: [line("Who", {color: "ffffff", size: 14*2, bold: true})],
                shading: {
                  fill: "333333",
                  val: ShadingType.PERCENT_100,
                },
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: border()
              }),
            ],
          }),
        ]

        data.actions.forEach(function(o){
          var people = []
          o.assignee.forEach((n)=>{
            people.push(
              new Paragraph({
                text: n,
              })
            )
          })
          rows.push(
            new TableRow({
              children: [
                new TableCell({
                  children: [line(o.itemref)],
                  margins: {
                    left: 100,
                  },
                  borders: border()
                }),
                new TableCell({
                  children: [line(o.description)],
                  borders: border()
                }),
                new TableCell({
                  children: people,
                  borders: border()
                }),
              ],
            })
          )
        })


        var table = new Table({
          rows: rows,
          cantSplit: true,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: border()
        });

        page.push(
          table
        )
      }

      // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
      // This simple example will only contain one section
      doc.addSection({
        margins: {
          top: 300,
          right: 300,
          bottom: 300,
          left: 300,
        },
        properties: {
        },
        footers: {
          default: new Footer({
            children: footer,
          }),
        },
        children: page,
      });

      Packer.toBlob(doc).then((blob) => {
        resolve(blob)
      })
    });
  }
}

export function GeneratePDFBuffer(pdfcode, blobStatus = false){
  return new Promise((resolve, reject) => {
    var names = [
      {
        title: "Montserrat",
        files: [
          {type: "normal", file: 'Montserrat-Regular.ttf'},
          {type: "bold", file: 'Montserrat-Bold.ttf'},
          {type: "italics", file: 'Montserrat-Italic.ttf'},
          {type: "bolditalics", file: 'Montserrat-BoldItalic.ttf'}
        ],
      }
    ]

    if(pdfcode.listOfFonts !== undefined){
      pdfcode.listOfFonts.forEach((f)=>{
        var nspace = f.replace(/\s/g, "")
        names.push({
          title: f,
          files: [
            {type: "normal", file: nspace+'-Regular.ttf'},
            {type: "bold", file: nspace+'-Bold.ttf'},
            {type: "italics", file: nspace+'-Italic.ttf'},
            {type: "bolditalics", file: nspace+'-BoldItalic.ttf'}
          ],
        })
      })
    }

    //fetch correct fonts
    var promiseArray = []
    names.forEach((font)=>{
      promiseArray.push(
        new Promise((resolveFont, rejectFont) => {
          var innerArray = []
          font.files.forEach((f)=>{
            innerArray.push(
              new Promise((resolveFiles, rejectFiles) => {
                if (window.exportFonts && window.exportFonts[f.type] && window.exportFonts[f.type][f.file]) {
                  resolveFiles({
                    type: f.type,
                    file: f.file,
                    data: window.exportFonts[f.type][f.file]
                  });
                  return;
                }

                const requestOptions = {
                  method: 'GET',
                };
                var url = self.location.protocol + '//' + self.location.host + '/lib/fonts/'+f.file

                return fetch(url, requestOptions)
                .then((response)=>{
                  if (!response.ok) {
                    return []
                  }else return response.arrayBuffer()
                })
                .then((data)=>{
                  if(data.byteLength === 0){
                    // define custom font
                    rejectFiles("file empty")
                    return
                  }
                  try {
                    if (!window.exportFonts) { window.exportFonts = {}; }
                    if (!window.exportFonts[f.type]) { window.exportFonts[f.type] = {}; }
                    window.exportFonts[f.type][f.file] = CrytpoLib.arrayBufferToBase64String(data);
                  } catch { }
                  resolveFiles({
                    type: f.type,
                    file: f.file,
                    data: CrytpoLib.arrayBufferToBase64String(data)
                  })
                })
                .catch((e)=>{
                  rejectFiles(e)
                });
              })
            )
          })

          Promise.all(innerArray)
          .then((files)=>{
            font.files = files
            resolveFont(font)
          })
          .catch((e)=>{
            rejectFont(e)
          })
        })
      )
    })

    Promise.all(promiseArray)
    .then((fonts)=>{
      var f = {}, vm = {}
      fonts.forEach((font)=>{
        f[font.title] = {}

        font.files.forEach((file)=>{
          f[font.title][file.type] = file.file

          vm[file.file] = file.data
        })
      });

      pdfMake.fonts = f
      pdfMake.vfs = vm

      const pdfDocGenerator = pdfMake.createPdf(pdfcode)
      pdfDocGenerator.getBlob((blob) => {
        if(blobStatus){
          resolve(blob)
        }else{
          var fileReader = new FileReader()
          fileReader.onload = function(event) {
              resolve(event.target.result)
          };
          fileReader.readAsArrayBuffer(blob)
        }
      });
    })
    .catch((e)=>{
      reject(e)
    })
  })
}
