import { GetURL } from './url';
import moment from 'moment';
import { RoutesConstants } from '@constants/common.constants';

let badcons = 0;
let blockLogout = false;
let viewAsUser = "";

export class CodeError extends Error {
  constructor(className = 'Unknown', code = '', msg = '', data = '', ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CodeError)
    }

    this.name = 'CodeError'
    // Custom debugging information
    this.className = className
    this.code = code
    this.message = msg
    this.data = data
    this.date = new Date()

    log("CodeError", this)
  }
}

export function setViewAs(set){
  viewAsUser = set
}

export function isViewAs(){
  if(viewAsUser !== "") return true
  return false
}

export function viewAs(insert = "&"){
  if(viewAsUser !== "")
    return insert+"viewAsUser="+viewAsUser
  return ""
}

export function authHeader(headers = {'Content-Type': 'application/json'}, frontendHelper = false) {
  //console.log("authHeader", window.athenaAppID,localStorage.getItem(window.athenaAppID))
  var u = undefined;
  try { u = localStorage.getItem(window.athenaAppID) } catch { };
  let user = u !== undefined ? JSON.parse(u) : null;

  if (user && user.sessionToken && !headers.hasOwnProperty("Authorization")) {
    return Object.assign({
      'Authorization': `${frontendHelper ? 'BoardSession ' : ''}${user.sessionToken}`,
      'AthenaAppID': window.athenaAppID,
    }, headers);
  } else {
    return headers;
  }
}

export function BlockLogout(status) {
  blockLogout = status
}

export function LogoutAndRedirect() {
//return
  if(blockLogout) return
  if(window.location.pathname === RoutesConstants.signupnewuser 
    || window.location.pathname === RoutesConstants.signupexistinguser
    || window.location.pathname === RoutesConstants.directtrial
    || window.location.pathname === RoutesConstants.freemium
    || window.location.pathname === RoutesConstants.trial
    || window.location.pathname === RoutesConstants.login
  ) return
  localStorage.removeItem('AppWeb');
  localStorage.removeItem('AthenaWeb');
  setTimeout(()=>{
    window.location.href = RoutesConstants.login
  }, 1000)
}

export function handleJsonRes(response) {
  badcons = 0;
  if (!response.ok) {
    if(response.status === 404){
      var url = response.url.replace(GetURL(), "");
      return Promise.reject(response.statusText + ' URL:'+url);
    }
    return response.text().then(data => {
      try{
        var j = JSON.parse(data.toLowerCase());
        if(j.hasOwnProperty('code')) return Promise.reject(j);
      }catch(err){

      }

      return Promise.reject(response.statusText);
    });

  }else if(response.status === 204){
    return {};
  }else return response.json();
}

export function handleJsonResponse(response, params = { ignore409: false }) {
  badcons = 0;
  if (!response.ok) {
    if(response.status === 404){
      var url = response.url.replace(GetURL(), "");
      return Promise.reject(response.statusText + ' URL:'+url);
    }
    if(response.status === 401){
      LogoutAndRedirect();
    }
    return response.text().then(data => {
      try{
        var j = JSON.parse(data.toLowerCase());
        if (params.ignore409 && j && (j.httpcode == 409 || j.httpCode == 409)) { return data; }
        if(j.hasOwnProperty('code')) return Promise.reject(j);
      }catch(err){

      }

      return Promise.reject(response.statusText);
    });

  }else if(response.status === 204){
    return {};
  }else return response.json();
}

export function handleDataResponse(data) {
  if(!window.demo){
    if(data.HttpCode === 401){
      if(data.Code === 202)
        return Promise.reject('202');
      return Promise.reject('H401');
    }
    return data;
  }

  String.prototype.replaceBetween = function(start, end, what) {
    return this.substring(0, start) + what + this.substring(end);
  };

  try {
    let string = JSON.stringify(data);

    let pos = 0;
    while(pos !== -1){
      pos = string.indexOf('$DATE$', pos)
      if(pos === -1) break
      let e = string.indexOf('#', pos)
      if(e === -1) continue

      let s = string.substring(pos, e)
      let word = s.split('_');
      if(word.length !== 3) continue
      const days = parseInt(word[1])
      let m = moment(word[2],'HH:mm a').add(days,"days")
      string = string.replaceBetween(pos, e+1, m.utc().format())
    }

    data = JSON.parse(string)
  }catch(e){
    log("handleDataResponse",e)
  }

  return data;
}

export function handleStandResponse(response, ignore404 = false){
  badcons = 0;
  if (!response.ok) {
    if(response.status === 404){
      if (ignore404) { return Promise.resolve(response); }
      var url = response.url.replace(GetURL(), "");
      return Promise.reject(response.statusText + ' URL:'+url);
    }
    if(response.status === 401){
       LogoutAndRedirect();
    }

    if(response.status === 500){
      return Promise.reject(response.statusText);
    }
  }
  return Promise.resolve(response);
}

export function ErrorType(error){
  if(error === undefined)
    return 'Ansarada Board detected a problem, please try again';
  if(error.hasOwnProperty('Code')){
    error = JSON.parse(JSON.stringify(error).toLowerCase());
  }
  if(error.hasOwnProperty('code')){
    if (error.code == 303 || error.Code == 303) { return error; }
    if (error.code == 123 || error.Code == 123) { return error; }

    if(error.code === 203 || error.code === 200){
    //  LogoutAndRedirect();
    }else if(error.code === 201){
      var string = "Login is temporarily disabled. Please try again ";
      if (error.error == 'H201-EmailCheck') {
        string = 'Too many failed requests. Please try again ';
      }
      if (!error.detail) { string += 'later.'; }
      if(error.detail !== undefined && error.detail !== null){
        string += 'in ';
        try{
          if(error.detail < 60)
            string += error.detail.toString() + " seconds";
          else if(error.detail < 3600)
            string += (Math.ceil(error.detail/60)).toString() + " minutes";
          else if(error.detail < 86400)
            string += (Math.ceil(error.detail/3600)).toString() + " hours";
          else
            string += (Math.floor(error.detail/86400)).toString() + " days";
          }catch(e){
            string += 'soon'
          }
      }
      return {code: '201', message: string, time: error.detail, now: new Date()};
    } else if (error.code === 202) {
      return 'Incorrect Authorisation code.'
    } else if (error.code === 238) {
      return 'Invalid invite code.'
    } else if (error.code === 209) {
      return 'Login failed as the password reset process has not yet been completed by all board administrators. Company yet to complete is: ' + error.detail;
      //return {code: '209', message: error.detail};
    }else if(error.code === 210){
      if(error.detail === 'initialAdmin')
        return 'Credentials have expired, please contact Ansarada Board Support';
      return 'Credentials have expired, please contact company administrator';
    }else if(error.code === 217){
      return 'H217';
    }else if(error.code === 237){
      return 'It seems that someone within your organisation has already signed up for a free trial. Please reach out to our support team for assistance or use a different email address to proceed.';
    } else if (error.code == 240) {
      return 'Your demo data is currently being setup, login is locked until this is complete';
    }else if(error.code === 115 && error.httpcode === 400){
      return 'H218';
    }else if(error.code === 500 && error.httpcode === 409){
      return error.detail
    }else if(error.code === 501 && error.httpcode === 409){
      return 'H803';
    }else if(error.code === 234 && error.httpcode === 401){
      return 'Thank you for completing trial signup. We will notify you when your account is ready.'
    }else if(error.code === 221 && error.httpcode === 401){
      return 'F103';
    }else if(error.code === 'F429'){
      return 'Your account has been blocked due to multiple unsuccessful login attempts. We’ve emailed you further instructions.';
    }else if(error.code === 'F206'){
      return error;
    }else if(error === 103)
      return 'Admin user limit exceeded. Please contact support for more.';
    error = error.code.toString();
  }else error = error.toString();
  if(error === 'H401' || error === 'Unauthorized' || error === '200')
    return "The username or password you’ve entered is incorrect.";
  else if(error.includes('Failed to fetch') || error === '404'|| error === 'H404'){
    if(navigator.onLine == false) {
      badcons++;
      if(badcons >= 3){
        return "H001";
      }
    }
    return "Unable to connect to Ansarada Board Server.";
  }else if(error === 'H400')
    return "Unauthorised registration.";
  else if(error === 'H101')
    return 'Unable to connect to Ansarada Board Servers.';
  else if(error === 'H800')
    return "Incorrect Recovery card code.";
  else if(error === 'H801')
    return "Incorrect Recovery card code or password.";
  else if(error === 'H239')
    return "You must use your company email address to signup to Ansarada Board.";
  else if(error === 'H409')
    return "Verification email has already been sent to you recently. Please check your inbox and spam folder.";
  else if(error === 'H403')
    return "Freemium signup is only for admin users. Please use a different email.";
  else if(error === 'H236')
    return "Please complete the Ansarada invite email you have been previously sent.";
  else if(error === 'H237')
    return "This account is not valid to use. Please go to Dataroom and complete the signup process for this account.";
  else if(error === '103')
    return 'Admin user limit exceeded. Please contact support for more.';
  else if(error === '202')
    return 'Incorrect Authorisation code.';
  else if(error === '203')
    return 'Session has timeout.';
  else if(error === '204')
    return 'Recovery card is already registered to another user.';
  else if(error === '205')
    return 'Account has been disabled.';
  else if(error === '206')
    return 'Login failed due to Invalid device.';
  else if(error === '233')
    return "maintenace";
  else if(error === '207')
    return 'Device has already been registered.';
  else if(error === '208')
    return 'Entered an invalidated recovery card.';
  else if(error === '211')
    return 'Login is currently being used on another device. Active session will expire after 15 minutes.';
  else if(error === '217')
    return 'Expired Authorisation code.';
  else if(error === '218')
    return 'Incorrect Authorisation code.';
  else if(error === '219')
    return 'The username you’ve entered is incorrect.';
  else if(error === '500' || error === '103' || error === '105' || error === '602')
    return error;
  else if(error === 'register')
    return "register";
  else if(error === 'maintenace')
    return "maintenace";

  return 'Ansarada Board detected a problem, please try again';
}

export function handleCatchCode(error){
  var returnError = false;
  if (error && error.resetPassword) { returnError = true; }
  return Promise.reject({
    code: error,
    msg: returnError ? error : ErrorType(error)
  });
}

export function handleCatch(error){
  if (!error) { return Promise.reject(ErrorType()); }
  if(error.code !== undefined && error.code === "F206"){}
  else
    log("handleCatch",error);
  if (error && error.resetPassword) { return Promise.reject(error); }
  return Promise.reject(ErrorType(error));
}

export function handleCodeCatch(error){
  log("handleCodeCatch",error.code, error);
  if (error && error.resetPassword) { return Promise.reject(error); }
  error.message = ErrorType(error.message)
  return Promise.reject(error);
}
