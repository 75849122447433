import { fileConstants, webConstants, userConstants, boardConstants } from '@constants/admin';
import { fileService, userService, boardService } from '@services/admin';
import { alertActions, boardActions, userActions, queueActions, kvpActions } from './';
import { history } from '@lib';
//import { PostWebWorker } from './../webworker/webcontroller';
import { v4 as uuidv4 } from 'uuid';
import { CheckInternet, DownloadBlob } from '@lib/simpletools';
import moment from 'moment';

export const fileActions = {
  demoGetImage,
  GetImage,
  GetFileProperties,
  AddFile,
  AddFiles,
  AddFilesUpdate,
  DownloadFile,
  RenameFolder,
  PopulateFile,
  //SetErrorFile,
//  UpdateProgressFile,
  UpdateFileDetails,
  //CompletedFileUpload,
  CompletedFileDownload,
  Download,
  DeleteFile,
  DeleteFiles,
  DeleteFileNoUpdate,
  DeleteImage,
//  workerFileUpdate,
  checkDocumentExists,
//  workerFileUpdateBoard,
};

function demoGetImage(id, data){
  return dispatch => {
    dispatch(success({
      image: data, id: id
    }))
  };

  function success(payload) { return { type: fileConstants.DOWNLOAD_IMAGE_SUCCESS, payload } }
}

function GetImage(imageid) {
  return dispatch => {
    dispatch(request(imageid));
    fileService.GetImage(imageid)
      .then(
        payload => dispatch(success(payload)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(imageid,error))
        }
      );
  };

  function request(imageid) { return { type: fileConstants.DOWNLOAD_IMAGE_REQUEST, imageid } }
  function success(payload) { return { type: fileConstants.DOWNLOAD_IMAGE_SUCCESS, payload } }
  function failure(imageid, error) { return { type: fileConstants.DOWNLOAD_IMAGE_FAILURE, imageid, error } }
}

function GetFileProperties(fileid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(fileid));
      fileService.GetFileProperties(fileid)
        .then(
          filestat => {
            dispatch(success(filestat))
            resolve(filestat);
          },
          error => {
            error = CheckInternet(error, dispatch);
            dispatch(failure(fileid, error));
            reject(error);
          }
        );
    })
  };

  function request(fileid) { return { type: fileConstants.GET_FILESTATS_REQUEST, fileid } }
  function success(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
  function failure(fileid, error) { return { type: fileConstants.GET_FILESTATS_FAILURE, fileid, error } }
}

function AddFile(fileitem) {
  return (dispatch) => {
    fileitem.processType = webConstants.ADD_FILE;
    dispatch(queueActions.uploadFile(fileitem));
//    PostWebWorker({type:webConstants.ADD_FILE, data: fileitem, sessionToken: sessionToken});
  };

//  function uploadFile(fileitem) { return { type: queueConstants.ADD_QUEUE_FILE_REQUEST, fileitem } }
}

function AddFilesUpdate(fileitem) {
  return (dispatch) => {
    if(window.demo){
      fileitem.forEach(o => {
        if(o.fileId === ""){
          o.fileId = o.id
          o.documentId = uuidv4()
          o.demopublished = true
          dispatch(CompletedFileDownload(o));
          dispatch(boardActions.addBoardFile(o))
          dispatch(Download(o.id));
        }
      })
      dispatch(request(fileitem));
      return
    }
    dispatch(request(fileitem));
    fileitem.processType = webConstants.ADD_FILES;
    dispatch(queueActions.uploadFiles(fileitem));
  };

  function request(fileitem) { return { type: fileConstants.UPDATE_FILES_SUCCESS, fileitem } }
}

function AddFiles(fileitem) {
  return (dispatch) => {
    fileitem.processType = webConstants.ADD_FILES;
    dispatch(queueActions.uploadFiles(fileitem));
    //PostWebWorker({type:webConstants.ADD_FILES, data: fileitem, sessionToken: sessionToken});
  };
}

function DownloadFile(fileitem) {
  return (dispatch) => {
    if(window.demo){
      fileService.Download(fileitem.documentId)
      .then(
        file => {
          fileitem.data = file.data
          dispatch(CompletedFileDownload(fileitem))
          DownloadBlob(
            fileitem.fileName,
            file.data
          );
        },
          error => dispatch(failure(error))
      );
      return
    }
    fileitem.processType = webConstants.DOWNLOAD_FILE
    dispatch(queueActions.downloadFile(fileitem));
    };
}

function PopulateFile(fileitem) {
  return (dispatch) => {
    fileitem.processType = webConstants.DOWNLOAD_STORE
    dispatch(queueActions.downloadFile(fileitem));
  };
}

/*function SetErrorFile(item) {
  return dispatch => {
    dispatch(request(item));
    //dispatch(alertActions.error(item.error));
  };

  function request(item) { return { type: fileConstants.SET_FILE_ERROR_REQUEST, item } }
}*/

/*function UpdateProgressFile(fileitem) {
  return dispatch => {
    dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: fileConstants.UPDATE_STATS_FILE_REQUEST, fileitem } }
}*/

/*function CompletedFileUpload(fileitem){
  return dispatch => {
    dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: fileConstants.COMPLETE_UPLOAD_FILE_REQUEST, fileitem } }
}*/

function CompletedFileDownload(fileitem){
  return dispatch => {
    dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: fileConstants.COMPLETE_DOWNLOAD_FILE_REQUEST, fileitem } }
}

function UpdateFileDetails(fileitem) {
  return (dispatch) => {
    dispatch(request(fileitem));
    if (window.demo) {
      return
    }
    return new Promise((resolve, reject) => {
      fileService.UpdateFileDetails(fileitem)
        .then(() => {
          fileitem.uploaded = true;
          fileitem.updating = false;
          dispatch(request(fileitem));
          dispatch(success(fileitem));
          resolve(true);
        })
        .catch((error) => {
          dispatch(failure(error))
          reject(false);
        });
    });
  }

  function request(fileitem) { return { type: fileConstants.UPDATE_FILE_REQUEST, fileitem } }
  function success(filestat) { return { type: fileConstants.UPDATE_FILE_SUCCESS, filestat } }
  function failure(fileid, error) { return { type: fileConstants.UPDATE_FILE_FAILURE, fileid, error } }
}

function DeleteFileNoUpdate(fileId, boardId) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(fileId, boardId, customerId));
    if(window.demo){
      dispatch(success(fileId, boardId, customerId));
      return
    }
    fileService.DeleteFile(fileId)
      .then(
        filestat => {
        dispatch(success(fileId, boardId, customerId));
        },
        error => dispatch(failure(fileId, error, customerId))
      );
  };

  function request(fileId, boardId, customerId) { return { type: fileConstants.DELETE_FILE_REQUEST, fileId, boardId, customerId  } }
  function success(fileId, boardId, customerId) { return { type: fileConstants.DELETE_FILE_SUCCESS, fileId, boardId, customerId } }
  function failure(fileId, error, customerId) { return { type: fileConstants.DELETE_FILE_FAILURE, fileId, error, customerId } }
}

function DeleteFile(fileitem, boardId) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(fileitem.id, boardId, customerId));
    if(window.demo){
      dispatch(success(fileitem.id, customerId));
      return
    }
    return new Promise((resolve, reject) => {
      fileService.DeleteFile(fileitem.id, boardId)
        .then(
          filestat => {
            dispatch(success(fileitem.id, boardId, customerId));
            dispatch(kvpActions.selectFileId(fileitem.id, false));
            resolve();
            setTimeout(() => { //Delay because the server take times to update
              dispatch(boardActions.getBoardFileList(boardId));
            }, 2000)
          },
          error => {
            dispatch(failure(fileitem.id, error, customerId));
            dispatch(kvpActions.selectFileId(fileitem.id, false));
            reject();
          }
        );
    });
  };

  function request(fileId, boardId, customerId) { return { type: fileConstants.DELETE_FILE_REQUEST,fileId,  boardId, customerId  } }
  function success(fileId, boardId, customerId) { return { type: fileConstants.DELETE_FILE_SUCCESS, fileId, boardId, customerId } }
  function failure(fileId, error, customerId) { return { type: fileConstants.DELETE_FILE_FAILURE, fileId, error, customerId } }
}

function DeleteFiles(fileitems, boardId, tag) {
  return (dispatch, getState) => {
    const fileIds = fileitems.map(f => f.id);
    const customerId = getState().authentication.customerId
    dispatch(request(fileIds, boardId, customerId));
    if(window.demo){
      dispatch(success(fileIds, customerId));
      return
    }
    return new Promise((resolve, reject) => {
      fileService.DeleteFiles(boardId, tag)
        .then(
          response => {
            resolve(response);
            dispatch(success(fileIds, boardId, customerId));
            setTimeout(() => { //Delay because the server take times to update
              dispatch(boardActions.getBoardFileList(boardId));
            }, 2000)
          },
          error => dispatch(failure(fileIds, error, customerId))
        );
    });
  };

  function request(fileIds, boardId, customerId) { return { type: fileConstants.DELETE_FILES_REQUEST, fileIds,  boardId, customerId  } }
  function success(fileIds, boardId, customerId) { return { type: fileConstants.DELETE_FILES_SUCCESS, fileIds, boardId, customerId } }
  function failure(fileIds, error, customerId) { return { type: fileConstants.DELETE_FILES_FAILURE, fileIds, error, customerId } }
}

function RenameFolder(boardId, oldTag, newTag) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      fileService.RenameFolder(boardId, oldTag, newTag).then(
        (response) => {
          resolve(response);
          dispatch(success(boardId, oldTag, newTag));
          setTimeout(() => { //Delay because the server take times to update
            dispatch(boardActions.getBoardFileList(boardId));
          }, 2000)
        },
        error => dispatch(failure(oldTag, newTag))
      );
    });
  }

  function request(oldTag, newTag, boardId, customerId) { return { type: fileConstants.RENAME_FOLDER_REQUEST, oldTag, newTag, boardId, customerId } }
  function success(oldTag, newTag, boardId, customerId) { return { type: fileConstants.RENAME_FOLDER_SUCCESS, oldTag, newTag, boardId, customerId } }
  function failure(oldTag, newTag, boardId, customerId) { return { type: fileConstants.RENAME_FOLDER_FAILURE, oldTag, newTag, boardId, customerId } }

}

function DeleteImage(fileId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(window.demo){
      dispatch(success(fileId, customerId))
      return
    }
    dispatch(request(fileId, customerId));
    fileService.DeleteDocument(fileId)
      .then(
        filestat => {
          dispatch(success(fileId, customerId));
        },
        error => dispatch(failure(fileId, error, customerId))
      );
  };

  function request(customerId) { return { type: fileConstants.DELETE_FILE_REQUEST, customerId } }
  function success(fileId, customerId) { return { type: fileConstants.DELETE_FILE_SUCCESS, fileId, customerId } }
  function failure(fileId, error, customerId) { return { type: fileConstants.DELETE_FILE_FAILURE, fileId, error, customerId } }
}

function Download(documentId) {
  return dispatch => {
    dispatch(request(documentId));
    fileService.Download(documentId)
      .then(
        file => {
          file.id = documentId
          dispatch(success(file))
        },
        error => dispatch(failure(error))
      );
  };

  function request(documentId) { return { type: fileConstants.DOWNLOAD_REQUEST, documentId } }
  function success(file) { return { type: fileConstants.DOWNLOAD_SUCCESS, file } }
  function failure(documentId, error) { return { type: fileConstants.DOWNLOAD_FAILURE, documentId, error } }
}

function checkDocumentExists(items){
  return dispatch => {
    dispatch(request(items));
    fileService.checkDocumentExists(items)
      .then(
        results => {
          items.results = results;
          dispatch(success(items))
        },
        error => {
          items.results = false;
          dispatch(failure(error))
        }
      );
  };

  function request(items) { return { type: fileConstants.CHECK_DOCUMENT_EXISTS_REQUEST,items } }
  function success(items) { return { type: fileConstants.CHECK_DOCUMENT_EXISTS_SUCCESS, items } }
  function failure(items) { return { type: fileConstants.CHECK_DOCUMENT_EXISTS_SUCCESS, items } }
}

/*
function workerFileUpdate(userItem, sessionToken, filesOnly, isAdmin){
  return dispatch => {
    console.log('workerFileUpdate',userItem);
    dispatch(request());
    userService.populateListofUsers(userItem.customerId)
    .then(
      users => {
        dispatch(successUsers(users))
        //get board IDs
        var promisearry = [];
        for(var x=0; x<users.boards.length; x++){
          var boardId = users.boards[x].id;
          promisearry.push(
            new Promise(function(resolve, reject) {
              var boardIdA = boardId;
              boardService.getMembership(boardIdA)
              .then(
                membership => {
                  dispatch(successMembers(boardIdA, membership));
                  //check the membership against task
                  var found = userItem.taskId.find(function(e) {
                    var member = membership.find(function(b) {
                      return e.dataId === b.id;
                    });
                    return ((e.type === 'BoardRegistration' && member !== undefined) || e.type !== 'BoardRegistration');
                  });
                  if(!found){
                    resolve();
                  }else{
                    boardService.getBoardFileList(boardIdA)
                      .then(
                        list => {
                          var bId = boardIdA;
                          dispatch(successBoardFiles({boardId: bId, fileIds: list}));
                          //get the file IDs
                          var promisefiles = [];
                          for(var y=0; y<list.length; y++){
                            var fileId = list[y];
                            promisefiles.push(
                              new Promise(function(resolvefile, rejectfile) {
                                  fileService.GetFileProperties(fileId)
                                  .then(
                                    filestat => {
                                      dispatch(successFileDetails(filestat));
                                      filestat.boardId = bId;
                                      resolvefile({boardId: bId, membership: membership, filestat: filestat});
                                    },
                                    error => {
                                      console.log('error3',error);
                                      rejectfile(error)
                                    }
                                  )
                              })
                            );
                          }
                          Promise.all(promisefiles)
                          .then((filesData)=>{
                            var boardId = "";
                            var membership = [];
                            var Data = [];
                            for(var y=0; y<filesData.length; y++){
                              boardId = filesData[y].boardId;
                              membership = filesData[y].membership;
                              Data.push(filesData[y].filestat);
                            }
                            resolve({boardId: boardId, membership: membership, filesData: Data});
                          })
                          .catch((error)=>{
                            console.log('error2',error);
                            dispatch(failure(error));
                            dispatch(alertActions.error(error));
                          });
                        },
                        error => {
                          console.log('error1',error);
                          reject(error)
                        }
                      );
                  }
                },
                error => {
                  dispatch(failure(error))
                }
              );
            })
          );
        }

        function GetTaskId(membership){
          for(var x=0; x<membership.length; x++){
            var membershipId = membership[x].id;
            var found = userItem.taskId.find(function(e) {
              return ((e.type === 'BoardRegistration' && e.dataId === membershipId) || e.type !== 'BoardRegistration');
            });
            if(found)
              return found.id;
          }
          return "";
        }

        Promise.all(promisearry)
        .then((data)=>{
          console.log('users',users);
          console.log('data',data);
          var ProcessOthers = true;
          var files = [];
          var jobQueue = {};
          var newUserId = userItem.userId;
          var userMap = users.users.map(e => e.id);
          for(var y=0; y<data.length; y++){
            if(data[y] !== undefined){
              if(data[y].boardId !== "" && data[y].filesData.length > 0){
                for(var z=0; z<data[y].filesData.length; z++){
                  var file = data[y].filesData[z];
                  var tid = GetTaskId(data[y].membership);
                  var fileitem = {
                    id: file.id,
                    fileId: file.id,
                    customerId: userItem.customerId,
                    kUser: userItem.kUser,
                    myId: userItem.meIds,
                    documentId: file.documentId,
                    boardId: data[y].boardId,
                    creationDate: file.creationDate,
                    published: file.published===undefined?false:file.published,
                    file: null,
                    key: file.key,
                    userFiles: file.userFiles.slice(0),
                    taskId: tid,
                  }

                  if(file.hasOwnProperty('tag'))
                    fileitem.tag = file.tag;
                  if(file.hasOwnProperty('displayName'))
                    fileitem.displayName = file.displayName;
                  if(file.hasOwnProperty('type'))
                    fileitem.type = file.type;
                  if(file.hasOwnProperty('status'))
                    fileitem.status = file.status;
                  if(file.hasOwnProperty('fileName'))
                    fileitem.fileName = file.fileName;
                  if(file.hasOwnProperty('fileSize'))
                    fileitem.fileSize = file.fileSize;

                  if(!isAdmin && !fileitem.published){
                    continue;
                  }

                  var curDate = moment(new Date());
                  fileitem.updateDate = curDate.utc().format();

                  var foundUser = false;
                  var haveDetails = userMap.indexOf(newUserId);
                  var onBoard = data[y].membership.map(e => e.userId).indexOf(newUserId);
                  if(haveDetails !== -1 && onBoard !== -1){
                    fileitem.userFiles.push({userId: newUserId, documentId:"", fileSize: 0, key: ""});
                    foundUser = true;
                  }else if(haveDetails !== -1){
                    if(users.users[haveDetails].type !== 'User'){
                      if(users.users[haveDetails].hasOwnProperty('kUserPublic')){
                        if(users.users[haveDetails].kUserPublic !== ''){
                          //we admin staff
                          fileitem.userFiles.push({userId: newUserId, documentId:"", fileSize: 0, key: ""});
                          foundUser = true;
                        }
                      }
                    }
                  }
                  if(ProcessOthers){
                    //loop through members Ids and see if there any one missing
                    for(var x=0; x<data[y].membership.length; x++){
                      var id = data[y].membership[x].userId;
                      var index = userMap.indexOf(id);
                      if(index === -1) continue;
                      if(!users.users[haveDetails].hasRegistered) continue;
                      if(!users.users[haveDetails].hasOwnProperty('kUserPublic')) continue;
                      if(users.users[haveDetails].kUserPublic === '') continue;

                      var userindex = fileitem.userFiles.map(e => e.userId).indexOf(id);
                      if(index !== -1) continue;
                      foundUser = true;

                      fileitem.userFiles.push({userId: id, documentId:"", fileSize: 0, key: ""});
                    }
                  }

                  var keys = [];
                  for(var x=0; x<fileitem.userFiles.length; x++){
                    var id = fileitem.userFiles[x].userId;
                    if(id === "") continue;

                    var index = userMap.indexOf(id);
                    if(index !== -1){
                      if(!users.users[index].hasOwnProperty('kUserPublic')) continue;
                      if(users.users[index].kUserPublic === '') continue;

                      keys.push({userId: id, key: users.users[index].kUserPublic});
                    }
                  }

                  fileitem.keyList = keys;
                  if(foundUser){
                    files.push(fileitem);
                    if(jobQueue[tid] === undefined)
                      jobQueue[tid] = [];
                    jobQueue[tid].push(fileitem.id);
                  }
                }
              }
            }
          }

          //add jobs to queue
          console.log(jobQueue);
          var doUpload = false;
          for(var x=0; x<userItem.taskId.length; x++){
            var id = userItem.taskId[x].id;
            if(jobQueue[id] !== undefined){
              dispatch(userActions.updateTask({
                id: id,
                jobTodo: jobQueue[id],
                files: true
              }));
              doUpload = true;
            }else{
              if(filesOnly) dispatch(userActions.completedTask(id));
            }
          }
          if(doUpload)
            dispatch(AddFiles(files));
        })
        .catch((error)=>{
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        })
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

    function successUsers(items) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items } }
    function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
    function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
    function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }

    function request() { return { type: fileConstants.WORKER_FILE_UPDATE_REQUEST } }
    function success() { return { type: fileConstants.WORKER_FILE_UPDATE_SUCCESS } }
    function failure(error) { return { type: fileConstants.WORKER_FILE_UPDATE_FAILURE, error } }
  };
}*/
