import fetch from '@lib/fetch-retry';
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType, BlockLogout } from '@lib/';
import { v4 as uuidv4 } from 'uuid';

export const adminPermissionsService = {
    getAdminMembershipsForUser,
    getAdminMembershipsForBoard,
    savePermission,
    updatePermission,
    removePermission,
}

function getAdminMembershipsForUser(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'AsyncToken': uuidv4() },
    };

    return fetch(GetURL() + `AdminMemberships/User/${userId}`, requestOptions)
        .then((response) => {
            if (response.status === 403) {
                return Promise.reject("")
            }
            return response.json();
        })
        .catch(handleCatch);
}

function getAdminMembershipsForBoard(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'AsyncToken': uuidv4() },
    };

    return fetch(GetURL() + `AdminMemberships/Board/${boardId}`, requestOptions)
        .then((response) => {
            if (response.status === 403) {
                return Promise.reject("")
            }
            return response.json();
        })
        .catch(handleCatch);
}

function savePermission(permission) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(permission)
    };

    return fetch(GetURL() + 'AdminMemberships', requestOptions)
        .then((response) => {
            if (response.ok) { return response.json(); }
            // if (!response.ok) {
            //     if (response.status == 409) { return response.json(); }
            // }
            return Promise.reject(permission);
        })
        .catch((e) => {
            if (e && e.boardId) { return Promise.reject({ membershipType: 'admin', ...e }); }
            return handleCatch(e);
        });
}

function updatePermission(permission) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(permission)
    };

    return fetch(GetURL() + `AdminMemberships/${permission.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function removePermission(adminPermissionId, replaceWithNonAdminMembership = false) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `AdminMemberships/${adminPermissionId}?replaceWithNonAdminMembership=${replaceWithNonAdminMembership}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                if (response.status == 404) { return true; }
                return Promise.reject();
            }
            return response.ok;
        })
        .catch((e) => {
            if (e == 404) { return true; }
            return handleCatch(e);
        });
}
