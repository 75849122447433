import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';

import {FileStorage} from '@lib/indexeddb';
import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';

import * as PDFBoardRoom from '@common/boardroompdfs';
import * as PDFAthena from '@common/athenapdfs';
import { baseFileService } from '@services/file.base.service';

export const fileService = {
    ...baseFileService,
    GetFileProperties,
    UpdateFileDetails,
    PatchFile,
    DeleteFile,
    DeleteFiles,
    DeleteDocument,
    RenameFolder,
    checkDocumentExists,
    checkBinderDocumentExists,
    //getWelcomeFile,
    generateWelcomePdf,
    workerFileUpdate,
};

function DeleteDocument(fileId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'Document/'+fileId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function GetFileProperties(fileid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'File/'+fileid, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function UpdateFileDetails(fileitem) {
  return new Promise((resolve, reject) => {
    //Do we have the data
    if(fileitem.id === ""){
      resolve([]);
      return
    }
    var newUsers = [];
    if(fileitem.userFiles !== undefined){
      //Do we have any update user file
      newUsers = fileitem.userFiles.filter(obj => {
        return obj.id === undefined || obj.id === "";
      })
    }
    if(newUsers.length === 0){
      resolve([]);
      return
    }

    //get AES Key
    CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, fileitem.kUser, CrytpoLib.base64StringToArrayBuffer(fileitem.key))
    .then((aesKey) => {
      var promarray = [];
      newUsers.forEach((u) => {
        var k = fileitem.keyList.find(o => o.userId === u.userId);
        if(k && k.key){
          promarray.push(
            new Promise((resolveEnc, rejectEnc) => {
              var i = {
                userFile: u,
              }
              CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
              .then((iUserKey) => {
                CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aesKey)
                .then((KAESEncpyt) => {
                  i.rsa = KAESEncpyt;
                  resolveEnc(i);
                })
                .catch((error) => {
                  rejectEnc("RSAEncrypt" + error);
                })
              })
              .catch((error) => {
                rejectEnc("importPublicKey" + error);
              });
            })
          )
        }
      })

      Promise.all(promarray)
      .then((newUserFile)=>{
        var append = [];
        newUserFile.forEach((item) => {
          var f = fileitem.userFiles.find(o => o.userId === item.userFile.userId)
          if(f){
            f.documentId = fileitem.documentId
            f.fileSize = fileitem.fileSize
            f.key = CrytpoLib.arrayBufferToBase64String(item.rsa);
            append.push(f);
          }
        })
        resolve(append);
      })
      .catch((e)=>{
        console.log("encryption error",e);
        reject("encryption error");
      })
    })
    .catch((e) => {
      console.log("decryption error",e);
      reject("decryption error");
    });
  })
  .then((userFiles)=>{
    return new Promise((resolve, reject) => {
      var updateitem = {
        id: fileitem.id,
      }

      if(userFiles.length > 0)
        updateitem.UserFileAppend = userFiles;
      if(fileitem.tag !== undefined)
        updateitem.tag = fileitem.tag;
      if(fileitem.updateDate !== undefined)
        updateitem.updateDate = fileitem.updateDate;
      if(fileitem.displayName !== undefined)
        updateitem.displayName = fileitem.displayName;
      if(fileitem.published !== undefined)
        updateitem.published = fileitem.published;

      const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'File/'+fileitem.id, requestOptions)
      .then(handleStandResponse)
      .then(() => {
        resolve();
      })
      .catch((e)=>{
        reject(e);
      });
    });
  })
  .catch(handleCatch);
}

function checkDocumentExists(items){
  const requestOptions = {
    method: 'GET',
    headers: authHeader({
      'Content-Type': 'application/json',
      'DocumentChunkSize': 100
    })
  };

  let url = 'Document/'+items.documentId+"/0"
  if(window.demo){
    url = 'Document/'+items.documentId
  }

  return fetch(GetURL() + url, requestOptions)
  .then((response)=>{
    if (!response.ok) {
      if(response.status === 404){
        var url = response.url.replace(GetURL(), "");
        return Promise.reject(response.statusText + ' URL:'+url);
      }
      if(response.status === 401){
        return Promise.reject(response.status);
      }
      return response.text().then(data => {
        try{
          var j = JSON.parse(data.toLowerCase());
          if(j.hasOwnProperty('code')) return Promise.reject(j);
        }catch(err){

        }

        return Promise.reject(response.statusText);
      });

    }else if(response.status === 204){
      return {};
    }else return response.json();
  })
  .then((json)=>{
    if(json.data === undefined)
      return false;
    return true;
  })
  .catch(handleCatch);
}

function checkBinderDocumentExists(item) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader({
      'Content-Type': 'application/json',
      'DocumentChunkSize': 100
    })
  };

  let url = 'Document/' + item.documentId + "/0"

  return fetch(GetURL() + url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return { ...item, exists: false };
      } else {
        return { ...item, exists: true };
      }
    })
    .catch((e) => { return { ...item, exists: false } });
}

function DeleteFile(fileId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'File/'+fileId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function DeleteFiles(boardId, tag) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'File/Board/' + boardId + '?tag=' + tag, requestOptions)
    .then(handleStandResponse)
    .then((response) => {
      return response;
    })
    .catch(handleCatch);
  // return fetch(GetURL() + 'File/DeleteFiles'+fileIds, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function PatchFile(updateitem, usePUT = false) {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: usePUT ? 'PUT' : 'PATCH',
      headers: authHeader(),
      body: JSON.stringify(updateitem)
    };

    fetch(GetURL() + 'File/' + updateitem.id, requestOptions)
      .then((response) => {
        if (response && !response.ok) { reject(); return; }
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  })
}

function RenameFolder(boardId, oldTag, newTag) {
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify({
      name: newTag
    }),
  };

  return fetch(GetURL() + 'File/Board/' + boardId + '/Tags/' + oldTag, requestOptions)
  .then(handleStandResponse)
  .catch(handleCatch);
}

/*
function getWelcomeFile(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  var url = window.location.protocol + '//' + window.location.host + '/lib/Athena_Welcome_Email_Blank.pdf';
  return fetch(url, requestOptions)
  .then((response) => {
    if(response.ok) {
      return response.blob();
    }
    throw new Error('Network response was not ok.');
  })
  .catch((error) => {
    return Promise.reject("Error: "+error);
  });
}*/

function generateWelcomePdf(userdetails){
  return new Promise((resolve, reject) => {
    var path = null
    if(userdetails.branding === "BoardRoom")
      path = import('@common/boardroompdfs')

    if(path === null) path = import('@common/athenapdfs')
    path.then(PDF => {
      PDF.pdfclass.Generate(userdetails)
      .then((pdf)=>{
        resolve(pdf);
      })
    });
/*
    if(userdetails.branding === "BoardRoom"){
      PDFBoardRoom.pdfclass.Generate(userdetails)
      .then((pdf)=>{
        resolve(pdf);
      })
    }else{
      PDFAthena.pdfclass.Generate(userdetails)
      .then((pdf)=>{
        resolve(pdf);
      })
    }
*/
  });
}

function workerFileUpdate(){
  return new Promise(function(resolve, reject) {
  });
}
