import { v4 as uuidv4 } from 'uuid';
import { customNotificationsConstants } from '@constants/admin';
import { customNotificationService } from '@services/admin';
import moment from 'moment';

export const customNotificationActions = {
    getFromReduxById,
    getSelectedNotificationFromRedux,
    getCustomNotificationsByBoard,
    addNewNotification,
    deleteNotification,
    selectNotification,
    patchNotification,
    updateNotification,
    getBinderNotificationVariables,
};

function getSelectedNotificationFromRedux() {
    return (dispatch, getState) => {
        try {
            const selectedNotifId = getState().customNotifications.selectedNotificationId;
            return getState().customNotifications.customNotifications.find(c => c.id == selectedNotifId);
        } catch { return null; }
    }
}

function getFromReduxById(id) {
    return (dispatch, getState) => {
        try {
            return getState().customNotifications.customNotifications.find(c => c.id == id);
        } catch { return null; }
    }
}

// types: [binder]
function getBinderNotificationVariables(){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            try {
                if (getState().customNotifications.variables.length) { resolve(); return; }
            } catch { }
            customNotificationService.getBinderNotificationVariables()
                .then((response) => {
                    dispatch({
                        type: customNotificationsConstants.SET_VARIABLES,
                        payload: [...response, '{{Company_Name}}']
                    })
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    };
}

function getCustomNotificationsByBoard(boardId) {
    return (dispatch, state) => {
        return new Promise((resolve, reject) => {
            customNotificationService.getNotificationsByBoardId(boardId)
                .then((response) => {
                    response.forEach(n => {
                        n.isSaved = true;
                        n.shouldUpdate = false;
                    });
                    dispatch({
                        type: customNotificationsConstants.GET_CUSTOM_NOTIFICATIONS,
                        payload: response
                    })
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    };
}

function addNewNotification(notif) {
    return (dispatch, state) => {
        return new Promise((resolve, reject) => {
            const currentBoard = state().board.currentBoard;
            const newId = uuidv4();
            const date = moment().utc().format();
            dispatch({
                type: customNotificationsConstants.ADD_CUSTOM_NOTIFICATION,
                payload: {
                    id: newId,
                    message: notif.message,
                    title: notif.title,
                    customerId: currentBoard.customerId,
                    boardId: currentBoard.id,
                    isSaved: false,
                    shouldUpdate: false,
                    messageTypes: []
                }
            })
            resolve(newId);
        })
    }
}

function updateNotification(id, newMessage, title) {
    return (dispatch, state) => {
        dispatch({
            type: customNotificationsConstants.UPDATE_CUSTOM_NOTIFICATION,
            payload: { id, newMessage, title }
        });
    }
}

function selectNotification(id) {
    return dispatch => {
        dispatch({
            type: customNotificationsConstants.SET_CUSTOM_NOTIFICATION_ID,
            payload: id
        })
    }
}

function patchNotification(id) {
    return (dispatch, state) => {
        return new Promise((resolve, reject) => {
            const notif = state().customNotifications.customNotifications.find(n => n.id == id);
            
            const payload = {
                id: id,
                title: notif.title,
                message: notif.message,
                boardId: notif.boardId,
                customerId: notif.customerId,
                messageTypes: []
            };

            (notif.isSaved
                ? customNotificationService.updateNotification(payload)
                : customNotificationService.saveNotification(payload)
            )
                .then((response) => {
                    notif.shouldUpdate = false;
                    notif.isSaved = true;
                    dispatch({
                        type: customNotificationsConstants.SET_SAVED_CUSTOM_NOTIFICATION,
                        payload: id
                    });
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }
}

function deleteNotification(notificationId) {
    return (dispatch, state) => {
        return new Promise(async (resolve, reject) => {
            const notif = state().customNotifications.customNotifications.find(n => n.id == notificationId);
            if (notif && notif.isSaved) {
                await customNotificationService.deleteNotification(notificationId)
                    .catch(() => { reject(); });
            }
            dispatch({
                type: customNotificationsConstants.DELETE_CUSTOM_NOTIFICATION,
                payload: notificationId
            });
            resolve();
        });
    }
}