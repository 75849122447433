/* global appConfig */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../actions/login';

import { AnsaradaLoading, Auth0CallBack } from '@lib/simpletools';
import { RoutesConstants } from '../../constants';
import { history } from '../../lib';

const stateOptions = {
  trial_SSO_success: 'trial_SSO_success',
}
let auth0CallbackAttempted = false;
export const CompletePageHook = (props) => {
  console.log("CompletePageHook", props.alias, props.authentication)

  let trialData = localStorage.getItem('SSO_trial');
  if (!auth0CallbackAttempted) {
    auth0CallbackAttempted = true;
    if (trialData) {
      try {
        localStorage.removeItem('SSO_trial');
        var bc = new BroadcastChannel('ansarada#board_trial_sso_bc');
        Auth0CallBack()
          .then((auth0) => {
            auth0.getTokenSilently()
              .then(token => {
                bc.postMessage({
                  token,
                  user: auth0.user
                });
              });
          })
          .catch((error) => {
            console.log(error)
          })
        return
      } catch (e) {
        history.push(RoutesConstants.login);
      }
    } else {
      Auth0CallBack()
        .then((auth0) => {
          props.Auth0Complete(auth0)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  var s = document.getElementById('loading')
  if (s !== null) s.style.display = 'none';

  return (
    <div>
      <AnsaradaLoading />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    alias,
  } = state.authentication;

  return {
    alias,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    Auth0Complete: (auth0) => dispatch(userActions.Auth0CompleteDevice(auth0)),

  }
}

export const CompletePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePageHook);
