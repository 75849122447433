import React, { useState } from "react";
import { TextField, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import './PasswordTextField.css';

export function PasswordTextField(props) {
    const [showPassword, setShowPassword] = useState(false);
    return <TextField
        InputProps={{
            endAdornment: <div className="ansarada-board-password-text-field">
                {showPassword ?
                    <Tooltip title='Hide password'><span className="ansarada-board-password-text-field-endinput" onClick={() => { setShowPassword(false) }}><VisibilityOffOutlinedIcon /></span></Tooltip>
                    : <Tooltip title='Show password'><span className="ansarada-board-password-text-field-endinput" onClick={() => { setShowPassword(true) }}><VisibilityOutlinedIcon /></span></Tooltip>
                }</div>
        }}
        sx={{
            '& .MuiOutlinedInput-root': {
                'padding-right': '0px',
            },
            '& .MuiFilledInput-root': {
                'box-shadow': '0 0 0px 1000px white inset'
            },
            '& .MuiOutlinedInput-input': {
                'box-shadow': '0 0 0px 1000px white inset'
            }
        }}
        {...props}
        type={showPassword ? 'text' : 'password'}
    />
}