import { customNotificationsConstants } from "@constants/admin";

// public Guid? Id { get; set; }
// public string Message { get; set; }
// public Guid? CustomerId { get; set; }
// public Guid? BoardId { get; set; }
// public Guid CreatedBy { get; set; }
// public Guid UpdatedBy { get; set; }
// public DateTime? CreatedAt { get; set; }
// public DateTime? UpdatedAt { get; set; }
// public List<NotificationMessageType> MessageTypes { get; set; }
// isSaved: boolean
// shouldUpdate: boolean
// title: string

var INITIAL_STATE = {
    lastSentNotificationId: '',
    selectedNotificationId: 'default',
    customNotifications: [
        {
            isDefault: true,
            id: 'default',
            title: 'Ansarada Board default',
            message: `You have been given access to binder {{binder_name}} on board {{board_name}} of organisation {{company_name}}.`,
        }
    ],
    variables: []
}

function addNotifications(state, notifications) {
    const newState = { ...state };
    // Remove existing notifs with the same id as the new ones
    const newNotifIds = notifications.map(n => n.id);
    newState.customNotifications = newState.customNotifications.filter(n => !newNotifIds.includes(n.id));

    newState.customNotifications = newState.customNotifications.concat(notifications);

    return newState;
}

function deleteNotification(state, notificationId) {
    const newState = { ...state };
    newState.customNotifications = newState.customNotifications.filter(n => n.id !== notificationId);
    return newState;
}

function setSaved(state, id) {
    const newState = { ...state };
    const notif = newState.customNotifications.filter(n => n.id == id);
    if (!notif) { return state; }
    notif.isSaved = true;
    notif.shouldUpdate = false;
    return newState;
}

function saveNotifications(state) {
    const newState = { ...state };
    newState.customNotifications.forEach(n => { n.isSaved = true; n.shouldUpdate = false });
    return newState;
}

function updateNotification(state, notificationId, newMessage, title, updatedBy) {
    const newState = { ...state };

    const notifToUpdate = newState.customNotifications.find(n => n.id == notificationId);
    if (!notifToUpdate) { return state; }

    newState.selectedNotificationId = notifToUpdate.id;
    notifToUpdate.message = newMessage;
    notifToUpdate.title = title;

    if (notifToUpdate.isSaved) {
        notifToUpdate.shouldUpdate = true;
    }

    return newState;
}

export function customNotifications(state = INITIAL_STATE, action) {
    switch (action.type) {
        case customNotificationsConstants.GET_CUSTOM_NOTIFICATIONS:
            return addNotifications(state, action.payload);
        case customNotificationsConstants.ADD_CUSTOM_NOTIFICATION:
            return addNotifications(state, [action.payload]);
        case customNotificationsConstants.SET_CUSTOM_NOTIFICATION_ID:
            return { ...state, selectedNotificationId: action.payload };
        case customNotificationsConstants.DELETE_CUSTOM_NOTIFICATION:
            return deleteNotification(state, action.payload);
        case customNotificationsConstants.SAVE_CUSTOM_NOTIFICATION:
            return saveNotifications(state);
        case customNotificationsConstants.UPDATE_CUSTOM_NOTIFICATION:
            return updateNotification(state, action.payload.id, action.payload.newMessage, action.payload.title);
        case customNotificationsConstants.SET_SAVED_CUSTOM_NOTIFICATION:
            return setSaved(state, action.payload);
        case customNotificationsConstants.SET_VARIABLES:
            return { ...state, variables: action.payload }
        default:
            return state;
    }
}