import React from 'react';
import { authHeader, GetURL, GetFrontendHelperURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { v4 as uuidv4 } from 'uuid';
import * as CrytpoLib from '@lib/cryptojs';
import fetchRetry from '@lib/fetch-retry';
import { getDocumentRevisionTypes } from '../../lib';
import { store } from '../../apps/admin/store';
import { popoverAction } from '../../actions/admin';
import { Stack } from '@mui/material';
import { MuiButton } from '../../pages/common/MUI';

export const queueService = {
    convertToPdf,
    checkConvertRequest,
};

function checkConvertRequest(requestId, abortController = new AbortController(), headerOptions = null) {
    return new Promise(function (resolve, reject) {
        if (!requestId) { reject(); }
        const requestOptions = {
            method: 'GET',
            headers: headerOptions ? headerOptions : authHeader({ 'Content-Type': 'application/json' }, true),
            retries: 6,
            signal: abortController.signal,
            retryDelay: 5000,
        };

        fetchRetry(GetFrontendHelperURL() + 'Requests/' + requestId, requestOptions, true)
            .then((response) => {
                if (!response.ok) {
                    reject();
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function convertToPdf(data, customerId, uploadToBoard = true, docId, requestId, abortController = new AbortController(), headerOptions = null) {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: 'GET',
            headers: headerOptions ? headerOptions : authHeader({ 'Content-Type': 'application/json' }, true),
            signal: abortController.signal
        };

        fetch(GetFrontendHelperURL() + 'OneShotKey', requestOptions)
            .then(handleJsonResponse)
            .then((res) => resolve(res))
            .catch(error => {
                reject(error)
            })
    })
        .then((response) => {
            return new Promise(async (resolve, reject) => {
                let item = {
                    uploadToBoard: uploadToBoard,
                    returnResult: true,
                    action: 1,
                    oneShotKeyId: response.id,
                    checkDocumentForRevisions: data && data.type ? getDocumentRevisionTypes().includes(data.type) : false
                }

                if (item.uploadToBoard) {
                    item.boardUploadDocumentId = docId || uuidv4();
                    item.boardUploadCustomerId = customerId;
                }

                const blobToData = (blob) => {
                    return new Promise((resolve) => {
                        const reader = new FileReader()
                        reader.onloadend = () => resolve(reader.result)
                        reader.readAsArrayBuffer(blob)
                    })
                }

                let aeskey1 = CrytpoLib.GenerateRandom(32);
                let aeskey2 = CrytpoLib.GenerateRandom(32);
                try {
                    const resData = await blobToData(data);
                    var Uint8View = new Uint8Array(resData);
                    const encryptedData = await CrytpoLib.AESEncrypt(aeskey1, Uint8View)
                    //encrypt data with kData
                    item.data = CrytpoLib.arrayBufferToBase64String(encryptedData)

                    let publicKey = await CrytpoLib.importPublicKey(response.publicKey, CrytpoLib.defaultRSAAlgorithmMethod)
                    const kData = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicKey, aeskey1)
                    const kResult = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicKey, aeskey2)
                    item.kData = CrytpoLib.arrayBufferToBase64String(kData)
                    item.kResult = CrytpoLib.arrayBufferToBase64String(kResult)
                } catch (e) {
                    reject(e)
                }

                var reqId = requestId || uuidv4();
                var checkRequest = true;
                var failedAttempts = 0;
                const requestOptions = {
                    method: 'POST',
                    headers: headerOptions ? { ...headerOptions, 'RequestId': reqId } : authHeader({ 'Content-Type': 'application/json', 'RequestId': reqId }, true),
                    body: JSON.stringify(item),
                    signal: abortController.signal
                };

                const sendConversionRequest = async () => {
                    return fetch(GetFrontendHelperURL() + 'Document', requestOptions)
                        .then(async (response) => {
                            if (!response.ok) {
                                if (response.status === 404) {
                                    checkRequest = false;
                                    var url = response.url.replace(GetURL(), "");
                                    return Promise.reject(response.statusText + ' URL:' + url);
                                }
                                if (response.status === 401) {
                                    checkRequest = false;
                                    LogoutAndRedirect();
                                    return;
                                }
                                if (response.status === 500 || response.status === 502 || response.status === 504) {
                                    var error = await response.json();
                                    if (error && (error.code == 7000 || error.Code == 7000) && failedAttempts < 3) {
                                        // try again
                                        if (item.boardUploadDocumentId) {
                                            item.boardUploadDocumentId = uuidv4();
                                        }
                                        reqId = uuidv4();
                                        requestOptions.body = JSON.stringify(item);
                                        requestOptions.headers = { ...requestOptions.headers, 'RequestId': reqId };
                                        failedAttempts++;
                                        store.dispatch(popoverAction.remove("document-convert-track-changes-dialog-" + data.name));
                                        return sendConversionRequest();
                                    }else if (error && (error.code == 7002 || error.Code == 7002)) {
                                        return new Promise((res, rej) => {
                                            const dialogId = "document-convert-track-changes-dialog-" + data.name;
                                            if (page == 'contribute') {
                                                return reject({ trackChangesError: true });
                                            }
                                            store.dispatch(popoverAction.showDialog({
                                                dialogId: dialogId,
                                                title: <b>Tracked changes are not supported for document conversion</b>,
                                                content: <div>
                                                    <div style={{ fontWeight: 'bold', fontSize: '17px', paddingBottom: '5px' }}>File: {data.name}</div>
                                                    <div style={{ paddingBottom: '10px' }}>Word documents with track changes enabled are not supported for document conversion.</div>
                                                    <div>To include tracked revisions please print to PDF via the Microsoft Word print feature.</div>
                                                    {/* <div style={{ paddingTop: '20px' }}>To include tracked revisions via Microsoft Word:</div>
                                                    <div>Export the document to PDF, click options and ensure "Document showing markup" is selected.</div>
                                                    <div style={{ paddingTop: '20px' }}>Otherwise click Continue to convert to pdf without tracked revisions.</div> */}
                                                </div>,
                                                dialogActions: <Stack direction="row" spacing={2}>
                                                    <MuiButton variant='contained' onClick={() => {
                                                        checkRequest = false;
                                                        store.dispatch(popoverAction.remove(dialogId));
                                                        return reject({ trackChangesError: true });
                                                    }}>Ok</MuiButton>
                                                    {/* <MuiButton variant='outlined' onClick={() => {
                                                        item.checkDocumentForRevisions = false;
                                                        reqId = uuidv4();
                                                        requestOptions.body = JSON.stringify(item);
                                                        requestOptions.headers = { ...requestOptions.headers, 'RequestId': reqId };
                                                        store.dispatch(popoverAction.remove(dialogId));
                                                        return sendConversionRequest();
                                                    }}>Continue</MuiButton> */}
                                                </Stack>
                                            }))
                                        });
                                    }
                                    return reject(response);
                                }
                            } else if (response.status === 204) {
                                return {};
                            } else return response.json();
                        })
                        .then((response) => {
                            let res = {
                                documentId: item.boardUploadDocumentId,
                                data: response.data,
                                size: response.boardUploadSize || 0,
                                key: item.kResult,
                                docAES: aeskey2
                            };
                            resolve(res)
                        })
                        .catch((error) => {
                            if (checkRequest) {
                                checkConvertRequest(reqId, abortController, headerOptions)
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((response) => {
                                        let res = {
                                            documentId: item.boardUploadDocumentId,
                                            data: response.data,
                                            size: response.boardUploadSize || 0,
                                            key: item.kResult,
                                            docAES: aeskey2
                                        };
                                        resolve(res);
                                    })
                                    .catch(() => {
                                        reject();
                                    });
                            }
                        })
                }

                sendConversionRequest();
            })
        })
        .catch((error) => {
            if (error && error.trackChangesError) { return Promise.reject(error) }
            return handleCatch(error);
        })
}