/* global appConfig */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions, alertActions } from '@actions/login';
//import { Button } from '@common';
import { TextInput, AuthCode } from '@common';
import { FaTimes as ExitIcon, FaExternalLinkAlt as LinkIcon, FaQuestionCircle as HelpIcon, FaPencilAlt as PenIcon } from 'react-icons/fa';
import { RoutesConstants, DateFormat } from '@constants/common.constants';

//const fpInstance = new Fingerprint();
import {getVersion} from '../../lib/url';
import {AnsaradaLoading, detectPrivateMode, checkForUpdate, DownloadBlob, getSupportURL, detectIE, setCookie, getPositionString, basename, ReplaceValue, DeviceFingerprint} from '@lib/simpletools';

import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux'
import globalTranslations from '../../translation/global.js';
import { confirmAlert } from 'react-confirm-alert';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import logo from '../../assets/images/logo/Ansarada Board Product Logo Black RGB.svg';
import AnsaradaLogo from '../../assets/images/logo/Ansarada Logo Inline Black RGB.svg';
import './loginpage.css';

import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import {keysStorage, SettingStorage } from '@lib/indexeddb';

import { LoadingIndicator, Button } from '@ansarada/ace-react';
import { LoadingOverlay } from '@common/MUI';
import { popoverAction } from '../../actions/admin';
import { Stack, TextField } from '@mui/material';
import { MuiButton } from '../common/MUI';

export class LoginBasicClass extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    var borwserSupport = true;
    if(navigator.userAgent.indexOf('MSIE')!==-1
        || navigator.appVersion.indexOf('Trident/') > -1){
      borwserSupport = false;
    }

    this.state = {
      deviceHash: "",
      deviceId: "",
      athenname: '',
      phash: '',
      phashfocus: false,
      submitted: false,
      //browserPrivateMode: false,
      showPrivateModeMsg: false,
      lastRateUpdate: null,
      borwserSupport: borwserSupport,
      ieBrowser: false,
      update: false,
      updateCheck: new Date(),
      editUsername: true,
      redirecting: false,

      maintenance: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateRate = this.updateRate.bind(this);
    this.startRate = this.startRate.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    this.initialTimeout = null;
  }

  componentDidMount() {
    try {
      localStorage.removeItem('SSO_trial');
    } catch {}
    this.initialTimeout = setTimeout(this.initialRun.bind(this),200); //wait 200ms for DB to initialise
  }

  componentWillUnmount(){
    clearTimeout(this.initialTimeout);
    if(this.state.lastRateUpdate !== null)
      clearInterval(this.state.lastRateUpdate);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.enabled === false){
      state.maintenance = nextProps.maintenance
      state.phash = ""
      state.submitted = false
    }

    return state
  }

  initialRun(){
    if(window.location.search !== ""){
      log("app redirect to directtrial login",window.location.search)
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('page') === "freemium" || urlParams.get('page') === "trial"){
        this.props.history.push({
          pathname: RoutesConstants.trial,
        });
        return
      }
    }
    DeviceFingerprint('login')
    .then((hash) => {
      this.setState({deviceHash: hash});
    })
    .catch(function(error) {
    });

    keysStorage.Get('lastUser')
    .then((data)=>{
      if(data.key !== undefined && data.key.alias !== undefined && data.key.alias !== ""){
        if(data.key.userType === "User"){
          window.location = '/appuser.html'
        }else
          window.location = '/admin.html'

        this.setState({redirecting: true, athenname: data.key.alias, editUsername: false})
      }
    })
    .catch((e)=>{
      if(e === "pending"){
        this.initialTimeout = setTimeout(() => {
          keysStorage.Get('lastUser')
          .then((data)=>{
            if(data.key.alias !== undefined && data.key.alias !== ""){
              if(data.key.userType === "User"){
                window.location = '/appuser.html'
              }else
                window.location = '/admin.html'

              this.setState({redirecting: true, athenname: data.key.alias, editUsername: false})
            }
          })
          .catch((e)=>{
          })
        },1000);
      }else{
      }
    })
  }

  handleChange(e) {
    const { name, value } = e.target
    let newVal = value;
    if (newVal && newVal.length > 1) { newVal = newVal.trimStart(); }
    if (name === 'phash' && this.props.error === 'Unauthorised') {
      this.props.dispatch(userActions.loginClearError());
    }

    this.setState({ [name]: newVal });
  }

  checkError(){
    const { athenname, phash, editUsername, submitted, update } = this.state;
    var msg = '';
    var service = '';

    if(submitted && !athenname && phash){
      msg = "Username is required"
    }else if(submitted && !phash && athenname && this.props.error !== 'Unauthorised'){
      msg = "Password is required"
    }else if(submitted && !phash && !athenname){
        msg = "Username and Password are required"
    }else if(this.props.sendResetEmail === true){
      return (
        <div className="login-error-msg colorAnsarada">
          <span className="animated fadeInUp">
            <span>We've just sent you an email to reset your password.</span>
          </span>
        </div>
      )
    }else if(this.props.alert.message === undefined && !submitted){
      if (!("Notification" in window) || !('serviceWorker' in navigator) ||
          (!('PushManager' in window) && !('safari' in window))){
        msg = 'Web Browser Push Notification is not supported.';
        service = 'Some features will not be enabled.';
      }else if (!("crypto" in window) && !("msCrypto" in window)){
        msg = 'Web Browser Cryptography is not detected.';
        service = 'Please either upgrade or choose different browser.';
      }

      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
      if(indexedDB === undefined || localStorage === undefined){
        msg = 'Due to browser limitation';
        service = 'some features will not be enabled.';
      }else if(detectIE()){
        msg = 'Due to Microsoft Edge browser limitations';
        service = 'some features will not be enabled.';
      }
      if(this.state.showPrivateModeMsg){
        msg = 'If browser is in private mode.';
        service = 'Some features will not be enabled.';
      }
    }else{
      msg = this.getErrorMsg()
    }

    if (!this.props.isOnline) {
      msg = 'No internet connection detected.';
    } else if (!this.props.APIConnectionStatus) {
      msg = 'Unable to connect to Board';
      service = 'https://api.athenaboard.com/ may be blocked on your network.';
    }

    if(msg === "" || msg === undefined) return;
    return (
      <div className="login-error-msg">
        <span className="animated fadeInUp">
          <span>{msg}<br/>{service}</span>
        </span>
      </div>
    )
  }

  checkUpdate(){
    var now = new Date();
    var pass = this.state.updateCheck;
    var diff = (now - pass)/1000;

    if(diff > 60 * 5){
      checkForUpdate()
      .then((updateAvailable)=>{
        this.setState({update: updateAvailable, updateCheck: new Date()});
      })
    }
  }

  handleContinue(){
    const { athenname, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname){
      return;
    }
    if(!deviceHash){
      return;
    }

    var loginReqest = {
      alias: athenname ? athenname.trim() : athenname,
      deviceHash: deviceHash,
      keys: true,
    }

    this.props.dispatch(userActions.checkUser(loginReqest, true))
    this.setState({phash: ''})
  }

  onEditUserName(){
    this.props.dispatch(userActions.loginClear())
    this.props.dispatch(alertActions.clear())
    this.setState({editUsername: true, phash: "", submitted: false})
  }

  handleSubmit(e) {
    if(e !== undefined)
      e.preventDefault();

    if(detectIE()){
      var pass = false;
      if(document.cookie !== undefined){
        if(document.cookie === "user=iemessage"){
          pass = true;
        }
      }
      if(!pass){
        setCookie("user", "iemessage", 999);
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Limited Browser Support</h1>
              <p>Due to limitations with Microsoft Edge some features will be disabled.<br/>
              <a href="https://help.ansarada.com/" target="_blank">More Info</a></p>
              <p>Alternatively please use a supported web browser:</p>
              <div className="page">
                <label className="centerVFlex">Google Chrome <a href="https://www.google.com/chrome/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
                <label className="centerVFlex">Mozilla FireFox <a href="https://www.mozilla.org/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
              </div>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <Button onClick={() => {onClose(); this.checkforUpdate()}} style={{marginRight:20}}>OK</Button>
              </div>
            </div>,
        })
        return
      }
    }

    this.checkforUpdate();
  }

  checkforUpdate(){
    const { dispatch } = this.props;
    dispatch(userActions.clearErrorMsg());
    this.setState({ submitted: true });

    const { athenname, phash, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname || !phash || !deviceHash){
      if(this.state.update){
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Update Available</h1>
              <p>Changes has been made that require browser refresh.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <Button type="clear" onClick={() => {onClose(); this.refreshPage()}}>Refresh</Button>
              </div>
            </div>,
        })
      }
      return;
    }

    if(this.state.update){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Update Available</h1>
            <p>Changes has been made that require browser refresh.</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <Button type="clear" onClick={() => {onClose(); this.refreshPage()}}>Refresh</Button>
            </div>
          </div>,
      })
    }
  }

  refreshPage(){
    window.location.reload(true)
    //window.location.href='/';
  }

  clearAuth(){
    if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
    this.setState({ editUsername: true, submitted: false })
  }

  updateRate(){
    if(this.props.alert.message === undefined || this.props.alert.code === undefined){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    if(this.props.alert.code !== '201'){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    var cur = new Date();
    var expire = new Date(this.props.alert.now.getTime() + this.props.alert.time * 1000);
    if(cur >= expire){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      this.props.dispatch(alertActions.clear());
      return;
    }

    var timeLeft = (expire.getTime() - cur.getTime())/1000;

    var string = "Login is disabled. Try again in ";
    if(timeLeft < 60)
      string += Math.ceil(timeLeft).toString() + " seconds";
    else if(timeLeft < 3600)
      string += (Math.ceil(timeLeft/60)).toString() + " minutes";
    else if(timeLeft < 86400)
      string += (Math.ceil(timeLeft/3600)).toString() + " hours";
    else
      string += (Math.floor(timeLeft/86400)).toString() + " days";

    var obj = Object.assign({}, this.props.alert);
    obj.message = string;
    this.props.dispatch(alertActions.error(obj));
  }

  startRate(){
    var i = setInterval(this.updateRate, 10000);
    this.setState({lastRateUpdate: i});
  }

  onDismissError(){
    this.props.dispatch(alertActions.clear());
  }

  onDismiss(){
    this.props.dispatch(userActions.loginClear())
    this.setState(({maintenance: null}))
  }

  getErrorMsg(){
    if(this.state.submitted && !this.state.athenname) return;
//    if(this.state.submitted && !this.state.phash) return;
    if(this.props.alert.hasOwnProperty('code')){
      if(this.props.alert.code === '201'){
        if(this.state.lastRateUpdate === null){
          //cannot set
          setTimeout(this.startRate, 1000);
        }
      }
    }

    return this.props.alert.message;
  }

  serverType(){
    if (appConfig.envLabel !== undefined){
      return <label style={{ color: 'red', margin: 0 }}> ({appConfig.envLabel})</label>;
    }
    return "";
  }

  onSupport(){
    window.open(getSupportURL(), '_blank').focus();
  }

  onCloseFrame(){
    this.props.dispatch(userActions.loginClear())
    this.setState({editUsername: true})
  }

  clearCache = () => {
    try {
      this.props.dispatch(popoverAction.showDialog({
        dialogId: 'ansarada-board-check-clear-cache',
        title: 'Are you sure you want to clear the local cache for Ansarada Board?',
        content: null,
        width: 'sm',
        dialogActions: <Stack direction='row' spacing={2}>
          <MuiButton variant='outlined' onClick={() => { try { this.props.dispatch(popoverAction.remove('ansarada-board-check-clear-cache')) } catch { } }}>Cancel</MuiButton>
          <MuiButton variant='contained' onClick={() => {
            try {
              keysStorage.ClearAll();
              SettingStorage.ClearAll();
              localStorage.clear();
              sessionStorage.clear();
              this.props.dispatch(popoverAction.remove('ansarada-board-check-clear-cache')); location.reload();
              refreshAppBundles(true);
            } catch (e) {
              console.log(e);
            }
          }}>Ok</MuiButton>
        </Stack>
      }));
    } catch (e) { console.log(e); }
  }

  render() {
    const { loggingIn } = this.props;
    const { athenname, phash, editUsername, submitted, update, redirecting } = this.state;
    return (
      <div className="page spacebetween content logContent">
        <div/>
        <div className="logboxContent">
          {this.state.borwserSupport?
            <div>
              <div className="loginbox">
                <div className="login-lock-header" style={{ width: '100%' }}>
                  <img style={{ width: '175px', objectFit: 'contain' }} src={AnsaradaLogo} />
                  <h1 style={{ margin: '24px 0 16px 0px', fontSize: '19px' }}>Board Admin Portal {this.serverType()}</h1>
                  <div style={{ fontSize: '16px' }}>Welcome</div>
                </div>
                {this.checkError()}

                <div className="login-field" style={{ padding: '20px 0 40px 0' }}>
                  {/* <label>Username</label>
                    <div className={'maxWidth form-group' + (submitted && !athenname ? ' has-error' : '')}>
                      <TextInput
                        name="athenname"
                        type='login'
                        stylenormal="f-control"
                        styleempty="f-controlempty"
                        onChange={this.handleChange}
                        onFocusIn={this.checkUpdate}
                        onReturn={this.handleContinue.bind(this)}
                        value={athenname}
                        initial={this.props.intl.formatMessage({id:"username", defaultMessage:"Username"})}
                      />
                    </div> */}
                  <TextField
                    color='success'
                    onChange={this.handleChange}
                    fullWidth={true}
                    inputProps={{ style: { 'background': 'white !imporatant' } }}
                    disabled={!editUsername}
                    sx={{
                      '& .MuiFilledInput-root': {
                        'box-shadow': '0 0 0px 1000px white inset'
                      },
                      '& .MuiOutlinedInput-input': {
                        'box-shadow': '0 0 0px 1000px white inset'
                      }
                    }}
                    // sx={{ padding: '10px' }}
                    // multiline={true}
                    // onFocus={this.checkUpdate}
                    // InputProps={{ endAdornment: athenname ? <div style={{ color: 'var(--ansarada-green)', paddingLeft: '10px' }}>Edit</div> : null }}
                    name='athenname'
                    label="Username"
                    onKeyPress={(event) => { console.log(event); }}
                    value={athenname}
                  />
                </div>
                
                <div className="login-button-group">
                  {update &&
                    <Button variant="Primary" style={{backgroundColor: '#9d2408f0'}} onClick={this.handleSubmit}>Update Available</Button>
                  }
                  {!update && editUsername &&
                    <MuiButton variant='contained' fullWidth={true} loading={this.props.userChecking === true} onClick={this.handleContinue.bind(this)}>Continue</MuiButton>
                    // <Button variant="Primary"  loading={this.props.userChecking === true} onClick={this.handleContinue.bind(this)}>Continue</Button>
                  }
                </div>
                {(loggingIn || redirecting) &&
                  <LoadingOverlay />
                }
              </div>
              <p id="privacy-note">Read our <a target="_blank" href={appConfig.ansaradaTerms}>terms of use</a> and <a target="_blank" href={appConfig.ansaradaPrivacy}>privacy policy</a>.</p>
            </div>
          :
            <div className="loginbox">
              <img className='Athena-logo' src={logo}/>
              <h1><FormattedMessage id="app.title" defaultMessage="Ansarada Board Portal" /> {this.serverType()}</h1>
              <div>Unsupported Browser: Please upgrade or use modern browser such as Chrome, Firefox or Safari.</div>
            </div>
          }
        </div>
        {this.state.maintenance !== null &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Maintenance in progress</h1>
              <p>{this.state.maintenance.statusMessage}</p>
              <p>Estimated end time: {moment(this.state.maintenance.endTime).format(DateFormat.LLLL)}</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <Button variant="Primary" onClick={this.onDismiss.bind(this)} style={{marginRight:20}}>OK</Button>
              </div>
            </div>
          </div>
        }
        {this.props.alert.message !== undefined && this.props.alert.message === 'H101' &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Unable to connect to Ansarada Board Servers</h1>
              <p>Please check your network connections.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <Button variant="Primary" onClick={this.onDismissError.bind(this)} style={{marginRight:20}}>OK</Button>
              </div>
            </div>
          </div>
        }
        <div className="loginVersion"><label onClick={() => { this.clearCache(); }}>Version: {getVersion()}</label></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const {
    loggingIn,
    askforCode,
    hasDevice,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    passwordPolicyDescription,
    passwordPolicyRegex,
    requiresRecoveryCard,
    passwordPolicy,
    pendingApproval,
    mfaId,
    mfaType,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    sendResetEmail,
    error,
    hasIncompleteAnsaradaSignup,
  } = state.authentication;
  return {
    alert,
    loggingIn,
    askforCode,
    hasDevice,
    mfaId,
    mfaType,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    requiresRecoveryCard,
    passwordPolicy,
    passwordPolicyDescription,
    passwordPolicyRegex,
    pendingApproval,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    sendResetEmail,
    error,
    APIConnectionStatus: state.keyValuePair ? state.keyValuePair.APIConnectionStatus : null,
    isOnline: state.keyValuePair ? state.keyValuePair.isOnline : null,
    hasIncompleteAnsaradaSignup,
  };
}

const connectedLoginPage = connect(mapStateToProps)(injectIntl(LoginBasicClass));
export { connectedLoginPage as BasicLoginPage };
