import { userKeysConstants } from "../../reducers/admin/userKeys.reducer";
import { userService } from "../../services/admin";


export const userKeysActions = {
    getUserImpersonationKey
}

function getUserImpersonationKey(dataitem) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var keyFromRedux = null;
            try {
                keyFromRedux = getState().userKeys[dataitem.targetUserId];
            } catch { }
            if (keyFromRedux) { resolve(keyFromRedux); return; }
            userService.getUserImpersonationKey(dataitem)
                .then((key) => {
                    dispatch({
                        type: userKeysConstants.ADD_KEY,
                        payload: {
                            userId: dataitem.targetUserId,
                            key: key
                        }
                    })
                    resolve(key);
                })
                .catch(() => {
                    reject();
                })
        });
    }
}