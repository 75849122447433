import { authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import fetch from '@lib/fetch-retry';
import { copySettings } from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';
import { baseBoardService } from '@services/board.base.service';
import moment from 'moment';

export const boardService = {
    ...baseBoardService,
    getMembershipsForCustomer,
    getMembership,
    getUserMemberships,
    addMembership,
    updateMembership,
    removeMembership,
    isMembershipDeleteion,
    getOwnerlessBoards,
    getBoards,
    deleteBoard,
    addBoard,
    updateBoardVisibility,
    updateBoard,
    addUserToBoard,
    getBoardFileList,
    getBoardFilesPopulate,
    getBoardBinders,
    getBoardDrafts,
    getBoardMinutes,
    previewBoardTemplates,
    populateBoardBinders,
    getBoardCurrent,
    getBoardPrevious,
    getBoardArchive,
    getBoardUnPublished,
    getBoardTemplates,
    getBinderCalendars,
    getUserBinders,
    getCalendarItem,
    deleteCalendarItem,
    newCalendarItem,
    editCalendarItem,
    importCalendarItem,
    getBoardUsage,
    getAttendeeDetails,
    getRecipientDetails,
    getInviteeDetails,
    populateAttendeeDetails,
    populateRecipientDetails,
    deleteAttendee,
    deleteRecipient,
    deleteInvitee,
    currentToPrevious,
    getStatus,
    setStatus,
    getBoardsPreview,
    getBoardsAllPreview,
    resetSampleData,
    getMembershipByMembershipId,
    updateBoardSettings,
};

function getMembershipsForCustomer(customerId, includeUserDetails = false) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'AsyncToken': uuidv4() },
  };
  var params = '';
  if(includeUserDetails) {
    params = '?includeUserDetails=true';
  }
  return fetch(GetURL() + 'Memberships/Customer/' + customerId + params, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getMembershipByMembershipId(memberId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'AsyncToken': uuidv4() },
  };
  return fetch(GetURL() + 'Memberships/'+memberId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getUserMemberships(userId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'AsyncToken': uuidv4() },
  };
  return fetch(GetURL() + 'Memberships/User/'+userId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getMembership(boardId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'AsyncToken': uuidv4() },
  };
  return fetch(GetURL() + 'Memberships/Board/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function addMembership(boardId, userId, isGuest = false, isDryRun = false) {
  var item = {
    boardId: boardId,
    userId: userId,
    isGuest: isGuest,
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(item),
    // retryOn: [502,400,409]
  };
  /* Still deleting
  Code: 501
  Detail: null
  HttpCode: 409
  */
  return fetch(GetURL() + 'Memberships?isDryRun='+isDryRun, requestOptions)
  .then(async (response) => {
    if (response) {
      if (response.ok) { return response; }
      if (!response.ok && response.status == 409) { return Promise.reject({ boardId: boardId }); }
      return Promise.reject(response);
    }
  })
  .then(response => handleJsonResponse(response, { ignore409: true }))
  .then(handleDataResponse)
  .catch(e => {
    console.log(e);
    if (e && e.boardId) { return Promise.reject({ membershipType: 'user', boardId: e.boardId }) }
    return handleCatch(e);
  });
}

function updateMembership(membershipId, boardId, userId, isGuest = false) {
  var item = {
    id: membershipId,
    boardId: boardId,
    userId: userId,
    isGuest: isGuest,
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(item)
  };

  return fetch(GetURL() + `Memberships/${membershipId}`, requestOptions).then((response) => {
    console.log(response);
  }).catch(handleCatch);
}

function removeMembership(membershipId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Memberships/'+membershipId, requestOptions)
  .then(response => handleStandResponse(response, true)) //404 when it shows up and its missing, 429 too many request
  .catch(e => {
    if (e == 404) { return true; }
    return handleCatch(e)
  });
}

function isMembershipDeleteion(boardId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Memberships/Board/'+boardId+'/UserDeletions', requestOptions)
  .then(handleJsonResponse)
  .catch(handleCatch);
}

function getOwnerlessBoards(customerId = "", viewMode = 2) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + `Boards/Ownerless?customerId=${customerId}&viewMode=${viewMode}`, requestOptions)
    .then(handleJsonResponse)
    .catch(handleCatch);
}

function getBoards(customerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Boards/Populated/Customer/'+customerId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function deleteBoard(boardId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
    };
    fetch(GetURL() + 'Boards/' + boardId, requestOptions)
      .then(handleStandResponse)
      .then(() => resolve())
      .catch(error => {
        if (error === '404') {
          resolve()
        } else {
          reject(error)
        }
      })
  }).catch(handleCatch)
}

function addBoard(board) {
  console.log("addBoard",board);
  return new Promise(function(resolve, reject) {
    //Do we need to update image?
    if(!board.hasOwnProperty('image')){
      resolve({imageId: board.imageId});
      return;
    }

    var imageId = uuidv4();

    var hash = CrytpoLib.MD5(board.image.data);
    var addheader = {
      DocumentChunkSize: board.image.data.byteLength,
      DocumentHash: hash,
      ChunkHash: hash,
      customerId: board.customerId,
      'Content-Type': 'application/json',
    }

    var Data = {
      data: CrytpoLib.arrayBufferToBase64String(board.image.data)
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(addheader),
        body: JSON.stringify(Data)
    };
    fetch(GetURL() + 'Document/'+imageId, requestOptions)
    .then((response) => {
      if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
            reject();
          }
      }
      if(response.status === 201 || response.status === 200){
        resolve({imageId: imageId});
      }else reject();
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    console.log("step 2",data);
    return new Promise(function(resolve, reject) {
      var newSettings = board.settings ? copySettings(board.settings) : undefined;

      var updateitem = {
        name: board.name,
        boardId: board.boardId,
        customerId: board.customerId,
      //  address: board.address,
        boardSettings: newSettings,
        countryBlacklist: board.countryBlacklist,
        imageId: data.imageId,
      };

      if(board.selectedPreviousMoveMechanism !== undefined)
        updateitem.selectedPreviousMoveMechanism = board.selectedPreviousMoveMechanism;
      if(board.numberOfDaysPrevious !== undefined)
        updateitem.numberOfDaysPrevious = board.numberOfDaysPrevious;
      if(board.endOfBoardYear !== undefined)
        updateitem.endOfBoardYear = board.endOfBoardYear;
      if(board.endOfFinancialYear !== undefined)
        updateitem.endOfFinancialYear = board.endOfFinancialYear;

      if (board.restrictedAdminAccessEnabled !== undefined) {
        updateitem.restrictedAdminAccessEnabled = board.restrictedAdminAccessEnabled;
      }
      
      if (board.isVisibleToAdminsWithoutAccess) {
        updateitem.isVisibleToAdminsWithoutAccess = board.isVisibleToAdminsWithoutAccess;
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Boards', requestOptions)
      .then(handleJsonResponse)
      .then((data)=>{
        console.log('newboard',data);
        resolve(data.id);
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .catch(handleCatch);
}

function updateBoardVisibility(boardId, isVisibleToAdminsWithoutAccess = false) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify({
        id: boardId,
        isVisibleToAdminsWithoutAccess: isVisibleToAdminsWithoutAccess
      })
    };

    fetch(GetURL() + 'Boards/' + boardId, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            LogoutAndRedirect();
            reject();
          }
        }
        resolve();
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function updateBoard(board) {
  console.log("updateBoard",board);
  return new Promise(function(resolve, reject) {
    //Do we need to update image?
    if(!board.hasOwnProperty('image')){
      resolve({imageId: board.imageId});
      return;
    }
    var imageId = uuidv4();

    var hash = CrytpoLib.MD5(board.image.data);
    var addheader = {
      DocumentChunkSize: board.image.data.byteLength,
      DocumentHash: hash,
      ChunkHash: hash,
      customerId: board.customerId,
      'Content-Type': 'application/json',
    }

    var Data = {
      data: CrytpoLib.arrayBufferToBase64String(board.image.data)
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(addheader),
        body: JSON.stringify(Data)
    };
    fetch(GetURL() + 'Document/'+imageId, requestOptions)
    .then((response) => {
      if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
            reject();
          }
      }
      if(response.status === 201 || response.status === 200){
        resolve({imageId: imageId});
      }else if(response.status === 200){
        //TODO more chunks
        reject();
      }else reject();
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    console.log("step 2",data);

    return new Promise(function(resolve, reject) {
      var updateitem = {
        name: board.name,
        boardId: board.boardId,
        customerId: board.customerId,
        address: board.address,
        countryBlacklist: board.countryBlacklist,
      };

      if(data.hasOwnProperty('imageId')){
        if(data.imageId){
          updateitem.imageId = data.imageId;
        }
      }
      if(board.settings)
        updateitem.boardSettings = copySettings(board.settings);;

      if(board.selectedPreviousMoveMechanism !== undefined)
        updateitem.selectedPreviousMoveMechanism = board.selectedPreviousMoveMechanism;
      if(board.numberOfDaysPrevious !== undefined)
        updateitem.numberOfDaysPrevious = board.numberOfDaysPrevious;
      if(board.endOfBoardYear !== undefined)
        updateitem.endOfBoardYear = board.endOfBoardYear;
      if(board.endOfFinancialYear !== undefined)
        updateitem.endOfFinancialYear = board.endOfFinancialYear;

      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Boards/'+board.boardId, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
            }
        }
        resolve({
          imageId: data.imageId
        });
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .catch(handleCatch);
}

function addUserToBoard(userItem){
  return new Promise(function(resolve, reject) {
    if(!userItem.hasOwnProperty('board')){
      resolve();
      return;
    }

    var promisearry = [];
    for(var x=0; x<userItem.board.length; x++){
      var bitem = userItem.board[x];
      promisearry.push(
        new Promise(function(resolveBoard, rejectBoard) {
          var updateitem = {
            userId: userItem.userId,
            boardId: bitem,
          };

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(updateitem)
          };

          fetch(GetURL() + 'Memberships', requestOptions)
          .then(handleJsonResponse)
          .then(newId => {
            resolveBoard();
          })
          .catch(function(error) {
            rejectBoard(error);
          });
        })
      )
    }
    Promise.all(promisearry)
    .then(()=>{
      resolve();
    })
    .catch((error)=>{
      reject(error);
    })
  })
  .catch(handleCatch);
}

function getBoardFileList(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(GetURL() + 'BoardDocuments/Files/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardFilesPopulate(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(GetURL() + 'BoardDocuments/Populated/Files/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}



function getBinderCalendars(binderId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  return fetch(GetURL() + 'Calendar/Item/Binder/'+binderId, requestOptions)
  .then((response)=>{
    if (!response.ok) {
      if(response.status === 404){
        return {};
      }
      return response.text().then(data => {
        try{
          var j = JSON.parse(data.toLowerCase());
          if(j.hasOwnProperty('code')) return Promise.reject(j);
        }catch(err){

        }

        return Promise.reject(response.statusText);
      });

    }else if(response.status === 204){
      return {};
    }else return response.json();
  })
  .catch((error)=>{
    if(error === "404") return {}
    return Promise.reject(ErrorType(error));
  });
}

function getCalendarItem(calItemId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  return fetch(GetURL() + 'Calendar/item/'+calItemId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function deleteCalendarItem(calItemId) {
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
  };
  return fetch(GetURL() + 'Calendar/'+calItemId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function newCalendarItem(calItem) {
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(calItem)
  };

  return fetch(GetURL() + 'Calendar', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function importCalendarItem(calItems){
  return new Promise(function(resolveMain, rejectMain) {
    var promisearry = [];
    calItems.forEach(function(item){
      if(item.error !== "" || !item.enabled){
        return;
      }
      promisearry.push(
        new Promise(function(resolve, reject) {
          const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(item)
          };

          fetch(GetURL() + 'Calendar', requestOptions)
          .then(handleJsonResponse)
          .then((data)=>{
            resolve(data);
          })
          .catch((error)=>{
            reject(error);
          });
        })
      )
    })

    resolveMain(Promise.all(promisearry));
  })
  .then(data => {
    console.log('data',data);

    return;
  })
  .catch(handleCatch);
}

function editCalendarItem(calItem) {
  const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(calItem)
  };

  return fetch(GetURL() + 'Calendar/'+calItem.id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getBoardUsage(boardId, startDate = null, endDate = null) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let start = moment().subtract(1, 'week').startOf("day").utc().format();
  let end = moment().utc().format();

  if (startDate) {
    try {
      if (moment(startDate).isValid()) {
        start = moment(startDate).utc().format();
      }
    } catch { }
  }
  if (endDate) {
    try {
      if (moment(endDate).isValid()) {
        end = moment(endDate).utc().format();
      }
    } catch { }
  }

  //+"&binderId=00000000-0000-0000-0000-000000000000"

  let url = 'Usage?boardId=' + boardId + '&startDate=' + start + '&endDate=' + end
  if (window.demo) {
    url = 'Usage/' + boardId
  }
  return fetch(GetURL() + url, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getBoardBinders(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Binders/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function previewBoardTemplates(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Templates/Preview/'+ boardId + '?includeTransactionIds=true', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateBoardBinders(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Populated/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardCurrent(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Current/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardPrevious(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Previous/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardArchive(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Preview/'+boardId+"?viewAsStatus=archive", requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}


function getUserBinders(boardId, userId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'BoardDocuments/Preview/'+boardId+"?viewAsUser="+userId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardUnPublished(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Unpublished/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardTemplates(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'BoardDocuments/Templates/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateAttendeeDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Attendees/Binder/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateRecipientDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Recipients/Binder/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getAttendeeDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Attendee/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getRecipientDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Recipient/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getInviteeDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Invitee/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function deleteAttendee(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Attendee/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function deleteRecipient(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Recipient/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function deleteInvitee(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(GetURL() + 'People/Invitee/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getBoardDrafts(boardId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'Drafts/Board/'+boardId, requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          return []
        }
        if(response.status === 401){
          LogoutAndRedirect();
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){

          }

          return Promise.reject(response.statusText);
        });

      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
    .catch(handleCatch);
}

function getBoardMinutes(boardId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'Minutes/Preview/Board/'+boardId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function currentToPrevious(boardId){
  const requestOptions = {
      method: 'POST',
      headers: authHeader()
  };

  return fetch(GetURL() + 'Boards/'+boardId+'/UpdateBinderStatus', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getStatus(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'StatusSync', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function setStatus(items){
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'StatusSync', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getBoardsPreview() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'Boards/Preview?includeSettings=false', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardsAllPreview(customerId) {
  return new Promise((resolve, reject) => {
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      };
      fetch(GetURL() + "BoardDocuments/Preview/Customer/" + customerId + '?includeTransactionIds=true', requestOptions)
      .then((response)=>{
        if (!response.ok) {
          if(response.status === 404){
            return []
          }
          if(response.status === 401){
            LogoutAndRedirect();
          }
          return response.text().then(data => {
            try{
              var j = JSON.parse(data.toLowerCase());
              if(j.hasOwnProperty('code')) reject(j);
            }catch(err){}
            reject(response.statusText);
          });
        }else if(response.status === 202 || response.status === 206){
          reject(response.status)
        }else return response.json();
      }).then((data)=>{resolve(data)}).catch(handleCatch);
  })
}

function resetSampleData(customerId) {
  return new Promise((resolve, reject) => {
    if (!customerId) { reject(); return; }
    const requestOptions = {
      method: 'POST',
      headers: authHeader()
    };

    fetch(GetURL() + `SampleData/Customer/${customerId}/Reset`, requestOptions)
      .then((response) => {
        resolve();
      }).catch(() => {
        reject();
      });
  })
}

function updateBoardSettings(boardId, boardSettings) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(boardSettings)
  };

  return fetch(GetURL() + `Boards/${boardId}/Settings`, requestOptions)
    .then((response) => {
      if (!response.ok) { throw response; }
      return response.ok;
    })
    .catch((err) => {
      throw err;
    });
}