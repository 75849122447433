export const CoIType = {
    Investments: 1,
    Employments: 2,
    RelevantRelationShips: 3,
    OtherAssociations: 4,
}

export function CoITypeToString(type) {
    switch (type) {
        case CoIType.Investments: return 'Investment';
        case CoIType.Employments: return 'Employment';
        case CoIType.RelevantRelationShips: return 'Relevant relationship';
        case CoIType.OtherAssociations: return 'Other Association';
    }
}

export const ConflictOfInterestDeclarationState = {
    Draft: 1,
    Published: 2,
    Previous: 3,
    Unpublished: 4, // Where user is no longer is required to provide COI declaration
}
