import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { CopyToClipBoard } from '@lib/simpletools';

import './styles/CopyToClipBoardIcon.css';

export default function CopyToClipBoardIcon(props) {
    const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);

    return (
        <Tooltip title={copiedToClipBoard ? 'Copied!' : 'Copy to clipboard'} disableInteractive={true}>
            <span onClick={(e) => { setCopiedToClipBoard(true); CopyToClipBoard(props.value); setTimeout(() => { setCopiedToClipBoard(false) }, 1500) }}>
                {copiedToClipBoard
                    ? <span className='copy-to-clipboard-copied centerVFlex centerFlex' style={{ alignItems: 'end' }}>Copied! <DoneIcon className='copy-to-clipboard-done-icon' /></span>
                    : <ContentCopyIcon className='copy-to-clipboard-copy-icon' />
                }
            </span>
        </Tooltip>
    )
}