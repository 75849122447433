import React from 'react';
import { connect } from 'react-redux';

class ConnectedPopper extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        if (!this.props.popovers) { return null; }

        return this.props.popovers.map((popover) => {
            return popover.el;
        });
    }
}

function mapStateToProps(state) {
    return {
        popovers: state.popovers ? state.popovers.popovers : null
    };
}

const connectedPopover = connect(mapStateToProps)(ConnectedPopper);
export { connectedPopover as ConnectedPopover };