import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'inline-block', width: props.width || '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant={props.variant || "determinate"} {...props} color={props.color || "primary"} />
        </Box>
        {!props.variant || props.variant == 'determinate' ?
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box> : null
        }
      </Box>
    </Box>
  );
}