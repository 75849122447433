import { boardConstants, binderConstants, userConstants, customerConstants } from '@constants/admin';
import { UserAccountTask, RoutesConstants } from '@constants/common.constants';
import { fileService, boardService, userService } from '@services/admin';
import { alertActions, userActions, fileActions, binderActions, minutesActions, customerActions, adminPermissionsActions } from '@actions/admin';
import { history, GetURL } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { CheckInternet, checkDemo, handle206, TrackEvent, BLANK_GUID } from '@lib/simpletools';
import WorkerPool from '@worker/workerpool';
let bgAdminTask = require("worker-loader?name=admin.worker.js!../../pages/common/adminTask.js");

export const boardActions = {
  getMembershipsForCustomer,
  getMembership,
  addMembership,
  isMembershipDeleteion,
  removeMembership,
  getBoardName,
  getBoard,
  getBoards,
  deleteBoard,
  updateBoard,
  updateBoardVisibility,
  addBoard,
  addUserBoard,
  getBoardFileList,
  addUserToBoardFiles,
  getBoardFilesPopulate,
  getBoardBinders,
  populateBoardBinders,
  previewBoardBinders,
  previewBoardTemplates,
  getBoardCurrent,
  getBoardPrevious,
  getBoardArchive,
  getBoardUnPublished,
  getBoardTemplates,
  getUserBinders,
  getUserFileList,
  getBoardDrafts,
  getBoardMinutes,
  removeBoardFile,
  addBoardFile,
  getBoardCalendars,
  getBinderCalendars,
  getCalendarItem,
  deleteCalendarItem,
  newCalendarItem,
  editCalendarItem,
  importCalendarItem,
  getBoardUsage,
  getAttendeeDetails,
  getRecipientDetails,
  getInviteeDetails,
  populateAttendeeDetails,
  populateRecipientDetails,
  deleteAttendee,
  deleteRecipient,
  deleteInvitee,
  populateBoardData,
  isGettingMembershipsForBoards,
  clearError,
  currentToPrevious,
  setCurrentBoard,
  getCurrentBoard,
  getBoardsPreview,
  updateBoardSettings,
};

function getMembershipsForCustomer(customerId, includeUserDetails = false) {
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      boardService.getMembershipsForCustomer(customerId, includeUserDetails).then(response => {
        resolve(response);
        dispatch({ type: boardConstants.UPDATE_BOARD_MEMBERSHIPS, payload: response })
      });
    });
  }
}

function isGettingMembershipsForBoards(returnBoardIds = false) {
  return returnBoardIds ? Object.keys(existingGetMembershipForBoard) : Object.keys(existingGetMembershipForBoard).length > 0;
}

var existingGetMembershipForBoard = {};
function getMembership(boardId) {
  return (dispatch, getState) => {
    if (existingGetMembershipForBoard[boardId]) { return existingGetMembershipForBoard[boardId]; }
    existingGetMembershipForBoard[boardId] = new Promise((resolve, reject) =>{
      if(checkDemo('getMembership'+boardId))return
      const customerId = getState().authentication.customerId
      dispatch(request(boardId, customerId));
      
      handle206(boardService.getMembership, (data)=> {
        dispatch(update(boardId, data, customerId))
      }, boardId)
      .then((membership) => {
        delete existingGetMembershipForBoard[boardId];
        dispatch(success(boardId, membership, customerId));
        resolve(membership);
      })
      .catch((error)=>{
        delete existingGetMembershipForBoard[boardId];
        error = CheckInternet(error, dispatch);
        dispatch(failure(error, customerId));
        reject();
        //dispatch(alertActions.error(error));
      })
    });

    return existingGetMembershipForBoard[boardId];
  };

  function request(boardId, customerId) { return { type: boardConstants.GETMEMBERSHIP_REQUEST, boardId, customerId } }
  function update(boardId, membership, customerId) { return { type: boardConstants.GETMEMBERSHIP_UPDATE, boardId, membership, customerId } }
  function success(boardId, membership, customerId) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership, customerId } }
  function failure(error, customerId) { return { type: boardConstants.GETMEMBERSHIP_FAILURE, error, customerId } }
}

function addMembership(boardId, userId, isGuest = false, existingMembershipId = '', isAdmin = false, rejectBoardId = false) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

    if(window.demo){
      dispatch(success({boardId: boardId, userId: userId, id: uuidv4()}));
      return
    }

    const authentication = getState().authentication
    const companies = getState().company
    const boards = getState().board.boards
    const userItems = getState().users.data

    dispatch(request());

    (existingMembershipId ? boardService.updateMembership(existingMembershipId, boardId, userId, isGuest) : boardService.addMembership(boardId, userId, isGuest)) ///httpcode: 409, code: 501
      .then(
        membershipId => {
          if (existingMembershipId) {
            membershipId = { id: existingMembershipId };
          }
          dispatch(success({ boardId: boardId, userId: userId, id: membershipId.id, isGuest }));
          dispatch(requestTask());
          resolve({
            boardId: boardId,
            userId: userId,
            id: membershipId.id,
            isGuest: isGuest
          });
          if (isGuest || isAdmin) { return; }
          setTimeout(()=>{
            userService.getAllTask(authentication.customerId)
              .then(
                tasklist => {
                  dispatch(successTask(tasklist))
                  var taskIds = []
                  tasklist.forEach((e)=>{
                    if(e.userId !== userId) return;
                    if(e.dateCompleted !== "" && e.dateCompleted !== undefined) return;
                    if(e.type !== UserAccountTask.BoardRegistration) return;
                    if(e.dataId !== membershipId.id) return;
                    dispatch(userActions.updateTask({id: e.id, start: true}));
                    dispatch(userActions.lockTask(e.id));
                    taskIds.push(e);
                  });

                  // //we got added to board so do file update
                  // var item = {
                  //   customerId: customerId,
                  //   binders: [],
                  //   boardIds: [boardId],
                  //   board: [{userId, boardId, membershipId: membershipId.id}],
                  //   userId: userId,
                  //   kUser: key,
                  //   meIds: getState().authentication.userIds,
                  //   taskIds: taskIds,
                  // }
                  // dispatch(binderActions.workerBinderFileUpdate(item));

                  let userData = [], adminData = []
                  const company = companies[authentication.customerId]
                  if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
                    company.adminUserIds.forEach(userId => {
                      adminData.push(userItems[userId])
                    })

                    company.userIds.forEach(userId => {
                      userData.push(userItems[userId])
                    })
                  }

                  var b = []
                  if(boards[boardId] !== undefined && boards[boardId].memberIds !== undefined && boards[boardId].memberIds !== null)
                    b = boards[boardId].memberIds

                  var kUser = null;
                  var pUserGenSec = null;
                  if(authentication.keys !== undefined){
                    if(authentication.keys[authentication.customerId] !== undefined){
                      kUser = authentication.keys[authentication.customerId].kUser;
                      pUserGenSec = authentication.keys[authentication.customerId].pUserGenSec;
                    }
                  }

                  var userItem = {
                    customerId: authentication.customerId,
                    binders: [],
                    boardIds: [boardId],
                    board: b,
                    memberIds: [membershipId.id],
                    userId: userId,
                    kUser: kUser,
                    pUserGenSec: pUserGenSec,
                    meIds: authentication.userId,
                    taskIds: taskIds,
                    ptype: "bg",
                    userData,
                    adminData
                  };

                  dispatch(userActions.updateTask({
                    id: tasklist.id,
                    start: true
                  }))
                  dispatch(binderActions.runAdminBgTask([userItem]))
                },
                error => {
                  dispatch(failureTask(error));
                }
              );
          },1000)
        },
        error => {
          if (rejectBoardId) {
            reject({ membershipType: 'user', boardId: boardId });
          } else {
            reject(userId);
          }
          dispatch(failure(error))
        }
      );
    })
  };

  function requestTask() { return { type: userConstants.LIST_ALL_TASK_REQUEST } }
  function successTask(list) { return { type: userConstants.LIST_ALL_TASK_SUCCESS, list } }
  function failureTask(error) { return { type: userConstants.LIST_ALL_TASK_FAILURE, error } }

  function request() { return { type: boardConstants.SETMEMBERSHIP_REQUEST } }
  function success(payload) { return { type: boardConstants.SETMEMBERSHIP_SUCCESS, payload } }
  function failure(error) { return { type: boardConstants.SETMEMBERSHIP_FAILURE, error } }
}

function removeMembership(boardId, membershipId, userId = "") {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

    const state = getState();
    var attendees = null, recipients = null;
    if(state.board !== undefined){
      if(state.board.Attendees !== undefined){
        attendees = Object.assign({}, state.board.Attendees);
      }
      if(state.board.Recipients !== undefined){
        recipients = Object.assign({}, state.board.Recipients);
      }
    }
    if(window.board){
      for(var key in attendees){
        if(attendees[key].userId === userId && attendees[key].boardId === boardId){
          dispatch(attendee(attendees[key].binderId, attendees[key].id));
        }
      }
      for(var key in recipients){
        if(recipients[key].userId === userId && recipients[key].boardId === boardId){
          dispatch(recipient(recipients[key].binderId, recipients[key].id));
        }
      }
      dispatch(success(boardId, membershipId, userId));
      return
    }

    dispatch(request());
    boardService.removeMembership(membershipId)
      .then(
        done => {
          for(var key in attendees){
            if(attendees[key].userId === userId && attendees[key].boardId === boardId){
              dispatch(attendee(attendees[key].binderId, attendees[key].id));
            }
          }
          for(var key in recipients){
            if(recipients[key].userId === userId && recipients[key].boardId === boardId){
              dispatch(recipient(recipients[key].binderId, recipients[key].id));
            }
          }
          dispatch(success(boardId, membershipId, userId));
          resolve();
        },
        error => {
          dispatch(failure(error))
          reject();
        }
      );
    })
  };

  function request() { return { type: boardConstants.DELETEMEMBERSHIP_REQUEST } }
  function success(boardId, membershipId, userId) { return { type: boardConstants.DELETEMEMBERSHIP_SUCCESS, boardId, membershipId, userId } }
  function failure(error) { return { type: boardConstants.DELETEMEMBERSHIP_FAILURE, error } }

  function attendee(binderId, attendeId) { return { type: binderConstants.DELETE_ATTENDEE_SUCCESS, binderId, attendeId } }
  function recipient(binderId, recipientId) { return { type: binderConstants.DELETE_RECIPIENT_SUCCESS, binderId, recipientId } }
}

function isMembershipDeleteion(boardId, userId = ""){
  return (dispatch, getState) => {
    if(window.demo){
      dispatch(success(boardId, userId, []));
      return
    }
    dispatch(request(boardId, userId));
    boardService.isMembershipDeleteion(boardId)
      .then(
        deleteIds => {
          dispatch(success(boardId, userId, deleteIds));
        },
        error => {
          dispatch(failure(boardId, userId))
        }
      );
  };

  function request(boardId, userId) { return { type: boardConstants.ISDELETE_MEMBERSHIP_REQUEST, boardId, userId} }
  function success(boardId, userId, deleteIds) { return { type: boardConstants.ISDELETE_MEMBERSHIP_SUCCESS, boardId, userId, deleteIds } }
  function failure(boardId, userId) { return { type: boardConstants.ISDELETE_MEMBERSHIP_FAILURE, boardId, userId } }
}

/*function checkMembership(boardId, userId){
  return (dispatch, getState) => {
    dispatch(request());
    boardService.addMembership(boardId, userId, true)
      .then(
        membershipId => {
          dispatch(success(boardId, userId));
        },
        error => {
          dispatch(failure(boardId, userId))
        }
      );
  };

  function request() { return { type: boardConstants.CHECK_MEMBERSHIP_REQUEST } }
  function success(boardId, userId) { return { type: boardConstants.CHECK_MEMBERSHIP_SUCCESS, boardId, userId } }
  function failure(boardId, userId) { return { type: boardConstants.CHECK_MEMBERSHIP_FAILURE, boardId, userId } }
}*/

function getBoards(customerId) {
  return dispatch => {
    if(checkDemo('getBoards'+customerId))return
    dispatch(request());
    boardService.getBoards(customerId)
      .then(
          boards => dispatch(success(boards)),
          error => {
            error = CheckInternet(error, dispatch)
            dispatch(failure(customerId,error))
          }
      );
  };

  function request() { return { type: boardConstants.BOARD_GETBOARDS_REQUEST } }
  function success(board) { return { type: boardConstants.BOARD_GETBOARDS_SUCCESS, board } }
  function failure(customerId, error) { return { type: boardConstants.BOARD_GETBOARDS_FAILURE, customerId, error } }
}

function getBoardName(boardId) {
  return (dispatch, getState) => {
      try {
        return getState().board.boards[boardId].name;
      } catch { return "" }
  };
}

function getBoard(id) {
  return dispatch => {
    if(checkDemo('getBoard'+id))return
    dispatch(request(id));
    boardService.getBoard(id)
      .then(
          board => dispatch(success(board)),
          error => {
            error = CheckInternet(error, dispatch)
            dispatch(failure(id,error))
          }
      );
  };

  function request(id) { return { type: boardConstants.GETBOARD_REQUEST, id } }
  function success(board) { return { type: boardConstants.GETBOARD_SUCCESS, board } }
  function failure(id, error) { return { type: boardConstants.GETBOARD_FAILURE, id, error } }
}

function addBoard(data, customerId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication
      if (window.demo) {
        data.id = uuidv4()
        if (data.image !== undefined) {
          const id = uuidv4();
          data.imageId = id
          dispatch(fileActions.demoGetImage(id, CrytpoLib.arrayBufferToBase64String(data.image.data)))
        }
        dispatch(success(data, customerId));
        history.push(RoutesConstants.boards);
        return
      }
      dispatch(request(customerId));
      boardService.addBoard(data)
        .then(
          boardId => {
            data.id = boardId

            TrackEvent("f_board_newboard-committee.added", {
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              board_id: boardId,
              is_boardlogo_added: data.image !== undefined && data.image.data !== null ? true : false,
              is_boardcalendar_updated: data.selectedPreviousMoveMechanismChange,
              is_boardsettings_changed: data.settingsChanged,
              // setting_changed: data.settingsDifference
            })

            dispatch(adminPermissionsActions.copyBoardPermissions(data.tempNewBoardId, boardId, true));
            try {
              dispatch(adminPermissionsActions.updatePermissionByUserId(boardId, auth.userId, { isSaved: true }));
              dispatch(adminPermissionsActions.saveChanges(boardId));
            } catch {
              console.log('Error saving admin permissions');
            }

            //Create a new settings
            dispatch(getBoardsPreview())
            dispatch(userActions.getListofUsers(customerId));
            dispatch(setCurrentBoard(boardId, data.name, customerId))
            dispatch(success(data, customerId));
            dispatch({
              type: boardConstants.CREATE_UNPUBLISHED_BINDER_STATE,
              payload: {
                boardId: boardId
              }
            });
            history.push("/");
            setTimeout(() => {
              dispatch(userActions.getListofUsers(customerId));
            }, 5000)
            resolve();
          },
          error => {
            dispatch(failure(error, customerId))
            reject();
          }
        )
        .catch(() => {
          reject();
        });
    })
  };

  function request(customerId) { return { type: boardConstants.SETBOARD_REQUEST, customerId } }
  function success(board, customerId) { return { type: boardConstants.SETBOARD_SUCCESS, board, customerId } }
  function failure(error, customerId) { return { type: boardConstants.SETBOARD_FAILURE, error, customerId } }
}

function addUserBoard(userItem, filesOnly, isAdmin){
  return dispatch => {
    if(userItem.newTask !== undefined)
      dispatch(userActions.updateTask(userItem.newTask));
    if(!filesOnly){
      dispatch(userActions.updateTask({id: userItem.taskIds[0].id, start: true}));
      dispatch(userActions.lockTask(userItem.taskIds[0].id));
    }
    dispatch(binderActions.workerBinderFileUpdate(userItem));
    //dispatch(fileActions.workerFileUpdate(userItem, sessionToken, filesOnly, isAdmin));

    history.push('/');
  };
}

function updateBoard(board, customerId, getPreview = true, redirect = true) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

      const auth = getState().authentication

      if (window.demo) {
        if (board.image !== undefined) {
          const id = uuidv4();
          board.imageId = id
          dispatch(fileActions.demoGetImage(id, CrytpoLib.arrayBufferToBase64String(board.image.data)))
        }
        dispatch(success(board, customerId));
        history.push(RoutesConstants.boards);
        return
      }
      dispatch(request(customerId));
      boardService.updateBoard(board)
        .then(
          payload => {
            TrackEvent("f_board_board-committee.updated", {
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              board_id: board.boardId,
              is_boardlogo_updated: board.imageChanged,
              is_boardcalendar_updated: board.selectedPreviousMoveMechanismChange,
              is_boardsettings_updated: board.settingsChanged,
              is_boardname_updated: board.titleChanged,
              // setting_changed: board.settingsDifference
            })

            // Create a new settings
            if (getPreview) {
              dispatch(getBoardsPreview())
            }
            dispatch(success(board, customerId));
            if (redirect) {
              history.push(RoutesConstants.boards);
            }
            resolve();
          },
          error => {
            dispatch(failure(error, customerId))
            reject();
          }
        )
        .catch(() => {
          reject();
        })
    });
  };

  function request(customerId) { return { type: boardConstants.SETBOARD_REQUEST, customerId } }
  function success(board, customerId) { return { type: boardConstants.SETBOARD_SUCCESS, board, customerId } }
  function failure(error, customerId) { return { type: boardConstants.SETBOARD_FAILURE, error, customerId } }
}

function updateBoardVisibility(boardId, isVisibleToAdminsWithoutAccess = false) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      boardService.updateBoardVisibility(boardId, isVisibleToAdminsWithoutAccess)
        .then(() => {
          dispatch({ type: boardConstants.UPDATE_BOARD_VISIBILITY, payload: { id: boardId, isVisibleToAdminsWithoutAccess } });
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}

function deleteBoard(id, customerId, path, type) {
  return (dispatch, getState) => {
    const auth = getState().authentication
    if(window.demo){
      dispatch(success(id, customerId));
      return
    }
    const selectedId = getState().board.currentBoard.id
    dispatch(request(customerId));
    boardService.deleteBoard(id)
      .then(
          board => {
            TrackEvent("f_board_board-committee.deleted",{
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              board_deleted_timestamp: moment().utc().format(),
              board_id: id,
              deleted_from: type,
            })
            if(selectedId === id){
              dispatch(setCurrentBoard("", "", ""))
            }
            dispatch(success(id, customerId));
            if(path !== ""){
              history.push(path)
            }
            dispatch(userActions.getListofUsers(customerId));
          },
          error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: boardConstants.DELETEBOARD_REQUEST, customerId } }
  function success(id, customerId) { return { type: boardConstants.DELETEBOARD_SUCCESS, id, customerId } }
  function failure(error, customerId) { return { type: boardConstants.DELETEBOARD_FAILURE, error, customerId } }
}

function addUserToBoardFiles(boardId, customerId, userIds = [], callback = () => { }, providedFileList = []) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // var kUserGenSec = getState().authentication.keys[customerId].kUser;
      var pUserGenSec = getState().authentication.keys[customerId].pUserGenSec;
      if (!pUserGenSec) {
        dispatch(userActions.getGenPrivKey(customerId));
        dispatch(userActions.getGenKey(customerId));
        var checkGenSecInterval;
        await new Promise((res, rej) => {
          checkGenSecInterval = setInterval(() => {
            console.log("check");
            if (getState().authentication.keys[customerId].pUserGenSec) {
              console.log("check 2");
              pUserGenSec = getState().authentication.keys[customerId].pUserGenSec;
              res();
            }
          }, 1000);
        });
        console.log("check 3");
        clearInterval(checkGenSecInterval);
      }
      console.log("check 4");

      var fileList = providedFileList || [];
      await new Promise((res, rej) => {
        if (providedFileList) { res(); return; }
        dispatch(getBoardFilesPopulate(boardId))
          .then((result) => {
            fileList = result;
            res();
          })
          .catch(() => {
            rej();
          })
      }).catch(() => { reject("e"); });

      var promises = [];
      for (var ftu of fileList) {
        if (!ftu.published) { continue; }

        var genSecFile = ftu.userFiles.find(fuf => fuf.userId == BLANK_GUID);
        if (!genSecFile) { continue; }

        var updateFile = {
          ...ftu,
          kUser: pUserGenSec,
          key: genSecFile.key,
          keyList: [],
          userFiles: [],
        };

        for (var userId of userIds) {
          if (ftu.userFiles.find(fuf => fuf.userId == userId)) { continue; }

          var user;
          var userKey;
          try { user = getState().users.data[userId]; } catch { }
          if (!user) { user = await dispatch(userActions.getUserDetails(userId)); }
          if (user && !user.hasRegistered) { continue; }
          if (user && !user.key) {
            try {
              userKey = await userService.getUserPublicKey(userId).catch((e) => { return null; });
            } catch { }
          }
          if (!userKey && !user.key) { continue; }

          updateFile.userFiles.push({ userId });
          updateFile.keyList.push({ userId: userId, key: user && user.key ? user.key : userKey ? userKey.kUser : '' });
        }

        if (!updateFile.userFiles || !updateFile.userFiles.length) { continue; }
        promises.push(fileService.UpdateFileDetails(updateFile));
      }

      Promise.all(promises).then(() => { resolve(); if (callback) { callback(); } }).catch(() => { reject(); });
    });
  }
}

function getBoardFilesPopulate(boardId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (checkDemo('getBoardFilesPopulate' + boardId)) return
      const customerId = getState().authentication.customerId
      dispatch(request(boardId, customerId));
      boardService.getBoardFilesPopulate(boardId)
        .then(
          list => {
            resolve(list);
            dispatch(success({ boardId: boardId, files: list }, customerId))
          },
          error => {
            error = CheckInternet(error, dispatch)
            dispatch(failure(error, customerId))
            reject();
          }
        );
    });
  };

  function request(boardId, customerId) { return { type: boardConstants.BOARD_POPULATEFILELIST_REQUEST, boardId, customerId } }
  function success(items, customerId) { return { type: boardConstants.BOARD_POPULATEFILELIST_SUCCESS, items, customerId } }
  function failure(error, customerId) { return { type: boardConstants.BOARD_POPULATEFILELIST_FAILURE, error, customerId } }
}

function getBoardFileList(boardId) {
  return (dispatch, getState) => {
    if(checkDemo('getBoardFileList'+boardId))return
    const customerId = getState().authentication.customerId
    dispatch(request(boardId, customerId));
    boardService.getBoardFileList(boardId)
      .then(
        list => dispatch(success({boardId: boardId, fileIds: list}, customerId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(boardId, customerId) { return { type: boardConstants.BOARD_FILELIST_REQUEST, boardId, customerId } }
  function success(items, customerId) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items, customerId } }
  function failure(error, customerId) { return { type: boardConstants.BOARD_FILELIST_FAILURE, error, customerId } }
}

function removeBoardFile(fileitem){
  return dispatch => {
      dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: boardConstants.BOARD_DELETEFILE_REQUEST, fileitem } }
}

function addBoardFile(fileitem){
  return dispatch => {
      dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: boardConstants.BOARD_ADDFILE_REQUEST, fileitem } }
}

function getCalendarItem(id, boardId){
  return dispatch => {
    if(checkDemo('getCalendarItem'+id))return
    dispatch(request(id, boardId));
    boardService.getCalendarItem(id)
      .then(
        payload => dispatch(success(boardId, payload)),
        error => {
          dispatch(failure(error))
        }
      );
  };

  function request(id, boardId) { return { type: boardConstants.GET_CALENDARITEM_REQUEST, id, boardId  } }
  function success(boardId, payload) { return { type: boardConstants.GET_CALENDARITEM_SUCCESS, boardId, payload } }
  function failure(error) { return { type: boardConstants.GET_CALENDARITEM_FAILURE, error } }
}

function getBoardCalendars(query) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(checkDemo('getBoardCalendars'+query.calendarId)){
      const boards = getState().board.boards
      if(query.boardId !== undefined && boards[query.boardId] && boards[query.boardId].calendar !== undefined){
        let list = []

        var dateStart = moment("2015-01-01 00:00:00");
        var dateFinish = moment();
        try{
          if(query.startDate !== ""){
            dateStart = moment(new Date(query.startDate));
            dateStart.set({
                hour:   0,
                minute: 0,
                second: 0
            });
          }

          if(query.finishDate !== ""){
            dateFinish = moment(new Date(query.finishDate));
            dateFinish.set({
                hour:   23,
                minute: 59,
                second: 59
            });
          }
        }catch(e){

        }

        boards[query.boardId].calendar.forEach(item => {
          try{
            const c = moment(new Date(item.startTime));
            if(c.isAfter(dateStart) && c.isBefore(dateFinish))
              list.push(item)
          }catch(e){

          }
        })
        dispatch(success(list, query.boardId, customerId))
        return
      }
    }
    dispatch(request(query, customerId));
    boardService.getBoardCalendars(query)
      .then(
        list => dispatch(success(list, query.boardId, customerId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(query, customerId) { return { type: boardConstants.GET_CALENDAR_REQUEST, query, customerId } }
  function success(list, boardId, customerId) { return { type: boardConstants.GET_CALENDAR_SUCCESS, list, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.GET_CALENDAR_FAILURE, error, customerId } }
}

function getBinderCalendars(binderId, boardId = ""){
  return dispatch => {
    dispatch(request(binderId));
    boardService.getBinderCalendars(binderId)
      .then(
        list => dispatch(success(list, binderId, boardId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request(binderId) { return { type: boardConstants.GET_CALENDARBINDER_REQUEST, binderId } }
  function success(payload, binderId, boardId) { return { type: boardConstants.GET_CALENDARBINDER_SUCCESS, payload, binderId, boardId } }
  function failure(error) { return { type: boardConstants.GET_CALENDARBINDER_FAILURE, error } }
}

function deleteCalendarItem(boardId, calItemId, query) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(window.demo){
      dispatch(success(boardId, calItemId, customerId));
      dispatch(getBoardCalendars(query));
      return
    }
    dispatch(request(customerId));
    boardService.deleteCalendarItem(calItemId)
      .then(
        item => {
          dispatch(success(boardId, calItemId, customerId));
          dispatch(getBoardCalendars(query));
        },
        error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: boardConstants.DELETE_CALENDAR_REQUEST, customerId } }
  function success(boardId, calItemId, customerId) { return { type: boardConstants.DELETE_CALENDAR_SUCCESS, boardId, calItemId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.DELETE_CALENDAR_FAILURE, error, customerId } }
}

function newCalendarItem(boardId, newItem, query) {
  return (dispatch, getState) => {
    const auth = getState().authentication
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    if(window.demo){
      newItem.id = uuidv4()
      dispatch(success(boardId, newItem), customerId);
      return
    }
    boardService.newCalendarItem(newItem)
      .then(
        item => {
          // TrackEvent("f_board_newcalendarevent.created",{
          //   user_id: auth.userId,
          //   person_id: auth.personId,
          //   company_id: auth.customerId,
          //   alias: auth.alias,
          //   board_id: boardId,
          //   calendar_id: newItem.calendarId,
          //   number_calendar_events_imported: 1,
          // })

          dispatch(success(boardId, newItem, customerId));
          dispatch(getBoardCalendars(query));
        },
        error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: boardConstants.SET_CALENDAR_REQUEST, customerId } }
  function success(boardId, newItem, customerId) { return { type: boardConstants.SET_CALENDAR_SUCCESS, boardId, newItem, customerId } }
  function failure(error, customerId) { return { type: boardConstants.SET_CALENDAR_FAILURE, error, customerId } }
}

function importCalendarItem(newItems, query, boardId){
  return (dispatch, getState) => {
    const auth = getState().authentication
    const customerId = getState().authentication.customerId
    //dispatch(request(customerId));
    boardService.importCalendarItem(newItems)
      .then(
        item => {
          // TrackEvent("f_board_calendar.imported",{
          //   user_id: auth.userId,
          //   person_id: auth.personId,
          //   company_id: auth.customerId,
          //   alias: auth.alias,
          //   board_id: boardId,
          //   number_calendar_events_imported: newItems.length,
          // })
          //dispatch(success(customerId));
          dispatch(getBoardCalendars(query));
        },
        error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: boardConstants.SET_CALENDAR_REQUEST, customerId } }
  function success() { return { type: boardConstants.SET_CALENDAR_SUCCESS } }
  function failure(error, customerId) { return { type: boardConstants.SET_CALENDAR_FAILURE, error, customerId } }
}

function editCalendarItem(newItem, query) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    boardService.editCalendarItem(newItem)
      .then(
        item => {
          dispatch(success(customerId));
          dispatch(getBoardCalendars(query));
        },
        error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: boardConstants.EDIT_CALENDAR_REQUEST, customerId } }
  function success(customerId) { return { type: boardConstants.EDIT_CALENDAR_SUCCESS, customerId } }
  function failure(error, customerId) { return { type: boardConstants.EDIT_CALENDAR_FAILURE, error, customerId } }
}

function getBoardUsage(boardId, startDate = null, endDate = null){
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(boardId));
      boardService.getBoardUsage(boardId, startDate, endDate)
        .then(
          data => {
            dispatch(success(data, boardId))
            resolve();
          },
          error => {
            error = CheckInternet(error, dispatch)
            dispatch(failure(error))
            reject();
          }
        );
    })
  };

  function request(boardId) { return { type: boardConstants.GET_BOARD_USAGE_REQUEST, boardId } }
  function success(data, boardId) { return { type: boardConstants.GET_BOARD_USAGE_SUCCESS, data, boardId } }
  function failure(error, boardId) { return { type: boardConstants.GET_BOARD_USAGE_FAILURE, error, boardId } }
}

function fetchAllBinders(boardId) {
  return (dispatch) => {
    return Promise.all([
      dispatch(previewBoardBinders(boardId)),
//      dispatch(getBoardCurrent(boardId)),
//      dispatch(getBoardPrevious(boardId)),
      dispatch(getBoardArchive(boardId)),
//      dispatch(getBoardUnPublished(boardId)),
      dispatch(getBoardTemplates(boardId)),
      dispatch(previewBoardTemplates(boardId)),
    ]);
  };
}

function getBoardBinders(boardId) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    dispatch(fetchAllBinders(boardId))
    .then(()=>{
      dispatch(success(customerId));
    })
    .catch(()=>{})
  };

  function request(customerId) { return { type: boardConstants.GET_BINDERS_REQUEST, customerId } }
  function success(customerId) { return { type: boardConstants.GET_BINDERS_SUCCESS, customerId } }
  //function failure(error) { return { type: boardConstants.GET_BINDERS_FAILURE, error } }
}

function previewBoardTemplates(boardId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(checkDemo('previewBoardTemplates'+boardId))return
    dispatch(request(boardId, customerId));
    boardService.previewBoardTemplates(boardId)
      .then(
        data => {
          dispatch(success(data, boardId, customerId))
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(boardId, customerId) { return { type: boardConstants.POPULATE_TEMPLATES_REQUEST, boardId, customerId } }
  function success(data, boardId, customerId) { return { type: boardConstants.POPULATE_TEMPLATES_SUCCESS, data, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.POPULATE_TEMPLATES_FAILURE, error, customerId } }
}

function previewBoardBinders(boardId, userId = ""){
  return (dispatch, getState) => {
    if(checkDemo('previewBoardBinders'+boardId+userId))return
    const customerId = getState().authentication.customerId
    dispatch(request(boardId, customerId));
    boardService.previewBoardBinders(boardId)
      .then(
        data => {
          //if(userId !== "")
          //  dispatch(binderActions.getAllCachedBinders(boardId, userId))
          dispatch(success(data, boardId, customerId))
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_REQUEST, boardId, customerId } }
  function success(data, boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.POPULATE_BINDERS_FAILURE, error, customerId } }
}

function populateBoardBinders(boardId){
  return (dispatch, getState) => {
    if(checkDemo('populateBoardBinders'+boardId))return
    const customerId = getState().authentication.customerId
    dispatch(request(boardId, customerId));
    boardService.populateBoardBinders(boardId)
      .then(
        data => dispatch(success(data, boardId, customerId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_REQUEST, boardId, customerId } }
  function success(data, boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.POPULATE_BINDERS_FAILURE, error, customerId } }
}

function getBoardCurrent(boardId) {
  return dispatch => {
    if(checkDemo('getBoardCurrent'+boardId))return
    dispatch(request());
    boardService.getBoardCurrent(boardId)
      .then(
        list => dispatch(success(list, boardId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request() { return { type: boardConstants.GET_BINDERS_CURRENT_REQUEST } }
  function success(list, boardId) { return { type: boardConstants.GET_BINDERS_CURRENT_SUCCESS, list, boardId } }
  function failure(error) { return { type: boardConstants.GET_BINDERS_CURRENT_FAILURE, error } }
}

function getBoardPrevious(boardId) {
  return dispatch => {
    if(checkDemo('getBoardPrevious'+boardId))return
    dispatch(request());
    boardService.getBoardPrevious(boardId)
      .then(
        list => dispatch(success(list, boardId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request() { return { type: boardConstants.GET_BINDERS_PREVIOUS_REQUEST } }
  function success(list, boardId) { return { type: boardConstants.GET_BINDERS_PREVIOUS_SUCCESS, list, boardId } }
  function failure(error) { return { type: boardConstants.GET_BINDERS_PREVIOUS_FAILURE, error } }
}

function getBoardArchive(boardId) {
  return (dispatch, getState) => {
    if(checkDemo('getBoardArchive'+boardId))return
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    boardService.getBoardArchive(boardId)
      .then(
        list => dispatch(success(list, boardId, customerId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(customerId) { return { type: boardConstants.GET_BINDERS_ARCHIVE_REQUEST, customerId } }
  function success(list, boardId, customerId) { return { type: boardConstants.GET_BINDERS_ARCHIVE_SUCCESS, list, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.GET_BINDERS_ARCHIVE_FAILURE, error, customerId } }
}

function getBoardUnPublished(boardId) {
  return dispatch => {
    if(checkDemo('getBoardUnPublished'+boardId))return
    dispatch(request());
    boardService.getBoardUnPublished(boardId)
      .then(
        list => dispatch(success(list, boardId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request() { return { type: boardConstants.GET_BINDERS_UNPUBLISHED_REQUEST } }
  function success(list, boardId) { return { type: boardConstants.GET_BINDERS_UNPUBLISHED_SUCCESS, list, boardId } }
  function failure(error) { return { type: boardConstants.GET_BINDERS_UNPUBLISHED_FAILURE, error } }
}

function getBoardTemplates(boardId) {
  return dispatch => {
    if(checkDemo('getBoardTemplates'+boardId))return
    dispatch(request());
    boardService.getBoardTemplates(boardId)
      .then(
        list => dispatch(success(list, boardId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request() { return { type: boardConstants.GET_TEMPLATES_REQUEST } }
  function success(list, boardId) { return { type: boardConstants.GET_TEMPLATES_SUCCESS, list, boardId } }
  function failure(error) { return { type: boardConstants.GET_TEMPLATES_FAILURE, error } }
}

function getUserFileList(boardId, userId){
  return dispatch => {
    dispatch(request(boardId, userId));
    boardService.getUserFileList(boardId, userId)
      .then(
        payload => dispatch(success(boardId, userId, payload)),
        error => {
          dispatch(failure(error))
        }
      );
  };

  function request(boardId, userId) { return { type: boardConstants.GET_FILELIST_USER_REQUEST, boardId, userId } }
  function success(boardId, userId, payload) { return { type: boardConstants.GET_FILELIST_USER_SUCCESS, boardId, userId, payload } }
  function failure(error) { return { type: boardConstants.GET_FILELIST_USER_FAILURE, error } }
}

function getBoardDrafts(boardId, userId){
  return dispatch => {
    dispatch(request(boardId))
    boardService.getBoardDrafts(boardId)
      .then(
        payload => {
          dispatch(success(boardId, payload))
        },
        error => {
          dispatch(failure(boardId, error))
        }
      );
  };

  function request(boardId) { return { type: boardConstants.GET_BOARD_DRAFTS_REQUEST, boardId } }
  function success(boardId, payload) { return { type: boardConstants.GET_BOARD_DRAFTS_SUCCESS, boardId, payload } }
  function failure(boardId, error) { return { type: boardConstants.GET_BOARD_DRAFTS_FAILURE, boardId, error } }
}

function getBoardMinutes(boardId, userId){
  return dispatch => {
    dispatch(request(boardId))
    boardService.getBoardMinutes(boardId)
      .then(
        payload => {
          if(userId !== "")
            dispatch(minutesActions.getAllCachedMinutes(boardId, userId))
          dispatch(success(boardId, payload))
        },
        error => {
          dispatch(failure(boardId, error))
        }
      );
  };

  function request(boardId) { return { type: boardConstants.GET_BOARD_MINUTES_REQUEST, boardId } }
  function success(boardId, payload) { return { type: boardConstants.GET_BOARD_MINUTES_SUCCESS, boardId, payload } }
  function failure(boardId, error) { return { type: boardConstants.GET_BOARD_MINUTES_FAILURE, boardId, error } }
}

function getUserBinders(boardId, userId){
  return dispatch => {
    dispatch(request(boardId, userId));
    boardService.getUserBinders(boardId, userId)
      .then(
        payload => dispatch(success(boardId, userId, payload)),
        error => {
          dispatch(failure(error))
        }
      );
  };

  function request(boardId, userId) { return { type: boardConstants.GET_BINDERS_USER_REQUEST, boardId, userId } }
  function success(boardId, userId, payload) { return { type: boardConstants.GET_BINDERS_USER_SUCCESS, boardId, userId, payload } }
  function failure(error) { return { type: boardConstants.GET_BINDERS_USER_FAILURE, error } }
}

function populateAttendeeDetails(binderId){
  return dispatch => {
    dispatch(request(binderId));
    boardService.populateAttendeeDetails(binderId)
      .then(
        item => {
          dispatch(success(binderId, item));
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(binderId) { return { type: boardConstants.POPULATE_ATTENDEE_REQUEST, binderId } }
  function success(binderId, item) { return { type: boardConstants.POPULATE_ATTENDEE_SUCCESS, binderId, item } }
  function failure(binderId, error) { return { type: boardConstants.POPULATE_ATTENDEE_FAILURE, binderId, error } }
}

function populateRecipientDetails(binderId){
  return dispatch => {
    dispatch(request(binderId));
    boardService.populateRecipientDetails(binderId)
      .then(
        item => {
          dispatch(success(binderId, item));
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(binderId) { return { type: boardConstants.POPULATE_RECIPIENT_REQUEST, binderId } }
  function success(binderId, item) { return { type: boardConstants.POPULATE_RECIPIENT_SUCCESS, binderId, item } }
  function failure(binderId, error) { return { type: boardConstants.POPULATE_RECIPIENT_FAILURE, binderId, error } }
}

function getAttendeeDetails(id, binderId){
  return dispatch => {
    if(window.demo) return
    dispatch(request(id));
    boardService.getAttendeeDetails(id)
      .then(
        item => {
          item.binderId = binderId;
          dispatch(success(item));
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error))
        }
      );
  };

  function request(id) { return { type: boardConstants.GET_ATTENDEE_REQUEST, id } }
  function success(item) { return { type: boardConstants.GET_ATTENDEE_SUCCESS, item } }
  function failure(id, error) { return { type: boardConstants.GET_ATTENDEE_FAILURE, id, error } }
}

function getRecipientDetails(id, binderId){
  return dispatch => {
    if(window.demo) return
    dispatch(request(id));
    boardService.getRecipientDetails(id)
      .then(
        item => {
          item.binderId = binderId;
          dispatch(success(item));
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error))
        }
      );
  };

  function request(id) { return { type: boardConstants.GET_RECIPIENT_REQUEST, id } }
  function success(item) { return { type: boardConstants.GET_RECIPIENT_SUCCESS, item } }
  function failure(id, error) { return { type: boardConstants.GET_RECIPIENT_FAILURE, id, error } }
}

function deleteAttendee(id){
  return dispatch => {
    dispatch(request(id));
    boardService.deleteAttendee(id)
      .then(
        item => dispatch(success()),
        error => dispatch(failure(id, error))
      );
  };

  function request(id) { return { type: boardConstants.DELETE_ATTENDEE_REQUEST, id } }
  function success() { return { type: boardConstants.DELETE_ATTENDEE_SUCCESS } }
  function failure(id, error) { return { type: boardConstants.DELETE_ATTENDEE_FAILURE, id, error } }
}

function deleteRecipient(id){
  return dispatch => {
    dispatch(request(id));
    boardService.deleteRecipient(id)
      .then(
        item => dispatch(success()),
        error => dispatch(failure(id, error))
      );
  };

  function request(id) { return { type: boardConstants.DELETE_RECIPIENT_REQUEST, id } }
  function success() { return { type: boardConstants.DELETE_RECIPIENT_SUCCESS } }
  function failure(id, error) { return { type: boardConstants.DELETE_RECIPIENT_FAILURE, id, error } }
}

function getInviteeDetails(id, binderId){
  return dispatch => {
    if(window.demo) return
    dispatch(request(id));
    boardService.getInviteeDetails(id)
      .then(
        item => {
          item.binderId = binderId;
          dispatch(success(item));
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error))
        }
      );
  };

  function request(id) { return { type: boardConstants.GET_INVITEE_REQUEST, id } }
  function success(item) { return { type: boardConstants.GET_INVITEE_SUCCESS, item } }
  function failure(id, error) { return { type: boardConstants.GET_INVITEE_FAILURE, id, error } }
}

function deleteInvitee(id){
  return dispatch => {
    dispatch(request(id));
    boardService.deleteInvitee(id)
      .then(
        item => dispatch(success()),
        error => dispatch(failure(id, error))
      );
  };

  function request(id) { return { type: boardConstants.DELETE_INVITEE_REQUEST, id } }
  function success() { return { type: boardConstants.DELETE_INVITEE_SUCCESS } }
  function failure(id, error) { return { type: boardConstants.DELETE_INVITEE_FAILURE, id, error } }
}

function populateBoardData(customerId){
  return (dispatch, getState) => {
    const sessionToken = getState().authentication.sessionToken
    const userIds = getState().authentication.userIds
    const keys = getState().authentication.keys[customerId]
    const customer = getState().authentication.customers.find(o => o.id === customerId)
    if (customer === undefined) return
    const userId = customer.userId
    const userType = getState().authentication.userType[customerId]

    let userData = [], adminData = []

    const userItems = getState().users.data
    const company = getState().company ? getState().company[customerId] : undefined
    if (company !== undefined) {
      if (company.adminUserIds) {
        company.adminUserIds.forEach(userId => {
          adminData.push(userItems[userId])
        })
      }

      if (company.userIds) {
        company.userIds.forEach(userId => {
          userData.push(userItems[userId])
        })
      }
    }

    userService.getAllTask()
      .then(
        tasks => {
          var pool = new WorkerPool()

          pool.onmessage = (event) => {
            if (event.data.populate !== undefined) {
              if (event.data.populate.type === "completedTask") {
                dispatch(userActions.completedTask(event.data.populate.data));
              } else if (event.data.populate.type === "failure") {
                var c = event.data.populate.data1
                c.error = event.data.populate.data2
                log("SEND", JSON.stringify(c))
                // dispatch(userActions.sendFeedBack({
                //   customerId: getState().authentication.customerId,
                //   feedbackTitle: "Error upload",
                //   feedBackType: 'Bug',
                //   feedback: JSON.stringify(event.data.populate.data2),
                // }))
              }
            }
          }

          var promiseArray = []
          //check customerId matches dataId in task list
          var f = tasks.find(o => o.customerId === customerId && o.type === UserAccountTask.UserFileUpload && o.userId === userId && o.dateCompleted === undefined)

          if (f === undefined || keys === undefined) return

          var item = {
            ptype: "boardUpload",
            url: GetURL(),
            sessionToken: sessionToken,
            customerId: customerId,
            task: f,
            taskIds: [f],
            myId: userId,
            kUser: keys.kUser,
            //kUserGenSec: k.kUserGenSec,
            //kUserSigned: k.kUserSigned,
            pUserGenSec: keys.pUserGenSec,
            meIds: userIds,
            userType: userType,
            userData: userData,
            adminData: adminData,
          }
          promiseArray.push(
            new Promise(function (resolve, reject) {
              pool.queueJob(bgAdminTask, item)
                .then((result) => {
                  resolve()
                })
                .catch((error) => {
                  log(customerId, error)
                  var d = JSON.parse(JSON.stringify(item))
                  reject({ msg: error, item: d, customerId: item.customerId })
                });
            })
          )

          Promise.all(promiseArray)
            .then((dataChunks) => {
            })
            .catch((error) => {
              log("Failed populateBoardData", error)
              dispatch(userActions.sendFeedBack({
                customerId: getState().authentication.customerId,
                feedbackTitle: "Error upload",
                feedBackType: 'Bug',
                feedback: JSON.stringify(error),
              }))
            })
        },
        error => {
          //  dispatch(failure(error))
        }
      );
  };

  function success(payload) { return { type: userConstants.GET_PGENERIC_KEY_SUCCESS, payload } }
}

function clearError(){
  return dispatch => {
    dispatch(request())
  }
  function request() { return { type: boardConstants.CLEAR_ERROR_REQUEST } }
}

function currentToPrevious(id){
  return dispatch => {
    dispatch(request(id));
    boardService.currentToPrevious(id)
      .then(
        item => dispatch(success(id)),
        error => dispatch(failure(id, error))
      );
  };

  function request(id) { return { type: boardConstants.BOARD_CURRENTPREVIOUS_REQUEST, id } }
  function success(id) { return { type: boardConstants.BOARD_CURRENTPREVIOUS_SUCCESS, id } }
  function failure(id, error) { return { type: boardConstants.BOARD_CURRENTPREVIOUS_FAILURE, id, error } }
}

function setCurrentBoard(id, name, customerId){
  return dispatch => {
    dispatch(success(id, name, customerId))
    const items = {
      customSettings: { boardId: id, boardName: name, customerId: customerId }
    }
    boardService.setStatus(items)
      .then(
        item => {},
        error => {}
    );
  };

  function success(id, name, customerId) { return { type: boardConstants.BOARD_SETCURRENT_SUCCESS, id, name, customerId } }
}

function getCurrentBoard(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request());
    boardService.getStatus()
    .then(
      payload => {
        let state = getState();
        
        if(payload.customSettings && payload.customSettings['board-description-user-page-description']){
          localStorage.setItem('board-description-user-page-description', payload.customSettings['board-description-user-page-description'] == 'True' ? true : false);
        }
        if(payload.customSettings && payload.customSettings['board-description-admin-page-description']){
          localStorage.setItem('board-description-admin-page-description', payload.customSettings['board-description-admin-page-description'] == 'True' ? true : false);
        }
        if(payload.customSettings && payload.customSettings['board-description-contributor-page-description']){
          localStorage.setItem('board-description-contributor-page-description', payload.customSettings['board-description-contributor-page-description'] == 'True' ? true : false);
        }

        if (state.authentication && state.authentication.customers
          && state.board && state.board.allBoards && state.board.allBoards.length
          && (!payload.customSettings || (payload.customSettings && !payload.customSettings.boardId))
        ) {
          let currentCustomer = state.authentication.customers.find(c => c.id == state.authentication.customerId);
          if (currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('demo')) {
            let valueCorp = state.board.allBoards.find(b => b.name.includes('Value Corp'))
            if (valueCorp) {
              if (!payload.customSettings) { payload.customSettings = {}; }
              payload.customSettings.boardId = valueCorp.id;
              payload.customSettings.name = valueCorp.name;
              payload.customSettings.customerId = valueCorp.customerId;
            }
          }
        }
        if (payload.hasOwnProperty("customSettings")) {
          if (payload.customSettings.boardId) {
            if (state.board.allBoards) {
              const foundBoard = state.board.allBoards.find(b => b.id == payload.customSettings.boardId)
              if (!foundBoard || (foundBoard && foundBoard.hasOwnProperty('canAccess') && !foundBoard.canAccess)) {
                payload.customSettings.boardId = '';
                payload.customSettings.boardName = '';
                payload.customSettings.name = '';
                payload.customSettings.customerId = '';
              }
            }
          }
          if (payload.customSettings.boardId) {
            dispatch(adminPermissionsActions.getPermissions(payload.customSettings.boardId));
          }
          dispatch(success(payload.customSettings))
          dispatch(success_tooltip(payload.customSettings, customerId))
          if (payload.customSettings.customerId !== undefined && payload.customSettings.customerId !== ""
            && payload.customSettings.customerId !== customerId) {
            dispatch(customerActions.switchCustomer(payload.customSettings.customerId))
          }
        }
      },
      error => dispatch(failure(error))
    );
  };

  function request() { return { type: boardConstants.BOARD_GETCURRENT_REQUEST } }
  function success(payload) { return { type: boardConstants.BOARD_GETCURRENT_SUCCESS, payload } }
  function success_tooltip(payload, customerId) { return { type: customerConstants.GET_SETTINGS_STATUS_SUCCESS, payload, customerId } }
  function failure(error) { return { type: boardConstants.BOARD_GETCURRENT_FAILURE, error } }
}

function getBoardsPreview() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      boardService.getBoardsPreview()
        .then(
          payload => {dispatch(success(payload)); resolve();},
          error => {dispatch(failure(error)); reject();}
        );
    })
  };

  function request() { return { type: boardConstants.BOARD_GETPREVIEW_REQUEST } }
  function success(payload) { return { type: boardConstants.BOARD_GETPREVIEW_SUCCESS, payload } }
  function failure(error) { return { type: boardConstants.BOARD_GETPREVIEW_FAILURE, error } }
}

function updateBoardSettings(boardId, boardSettings) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // boardSettings.id = boardId;
      boardService.updateBoardSettings(boardId, boardSettings)
        .then((response) => {
          dispatch({
            type: boardConstants.BOARD_SETTINGS_UPDATE,
            payload: { boardId, boardSettings }
          });
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}