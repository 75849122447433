import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { contributorReducerConstants } from '../reducers/contributors.reducer';
import { baseContributorService } from '../services/contributor.base.service';
import { popoverAction } from './admin';
import { commonConstants } from '../constants';
import { userNameOrder } from '../lib';

export const baseContributorActions = {
    getContributors,
    getContributor,
    addContributor,
    getContributorMembership,
    getContributorMemberships,
    createContributorMembership,
    updateContributorMembership,
    deleteContributorMembership,
    updateContributor,
    deleteContributor,
    getContributorFileByFileId,
    contributorFileRecieved,
    getAllContributorFilesForCustomer,
    deleteContributorFile,
    rejectContributorFile,
    updateContributorFile,
    setAnchorElement,
    setShowMenu,
    getContributorFile,
    getContributorUserName,
    clearSelectedNotifications,
    toggleNotificationSelect,
    deselectNotification,
};

function setAnchorElement(el) {
    return (dispatch, getState) => {
        dispatch({
            type: contributorReducerConstants.SET_ANCHOR_ELEMENT,
            payload: el
        });
    }
}
function setShowMenu(showInbox) {
    return (dispatch, getState) => {
        dispatch({
            type: contributorReducerConstants.SET_SHOW_INBOX,
            payload: showInbox
        });
    }
}

function getContributorUserName(contributorId, returnAsObject = false) {
    return (dispatch, getState) => {
        var contributorName;
        try {
            var contributor = { ...getState().contributors.contributors[getState().authentication.customerId][contributorId] };
            var firstNameOrder = true;
            try {
                firstNameOrder = getState().authentication.displaySettings.userSort == false ? false : true
            } catch { }
            if (contributor.firstName || contributor.lastName) {
                contributorName = userNameOrder(contributor.firstName, contributor.lastName, firstNameOrder);
            }
        } catch { }
        if (returnAsObject) {
            return { firstName: contributor.firstName, lastName: contributor.lastName, email: contributor.email }
        }
        return contributorName;
    }
}

function getContributorFile(fileId, byId = false) {
    return (dispatch, getState) => {
        var contributorFile;
        try {
            contributorFile = { ...getState().contributors.files[getState().authentication.customerId].find(f => (byId ? f.id == fileId : f.documentId == fileId)) };
        } catch { }
        return contributorFile;
    }
}

function updateContributorFile(id, customerId, properties = {}) {
    return (dispatch) => {
        dispatch({
            type: contributorReducerConstants.UPDATE_CONTRIBUTOR_FILE,
            payload: { id, customerId, properties }
        });
    }
}

function deselectNotification(id) {
    return (dispatch) => {
        dispatch({ type: contributorReducerConstants.DESELECT_NOTIFICATION, payload: { id } })
    }
}

function deleteContributorFile(fileId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var customerId = getState().authentication.customerId;
            dispatch(updateContributorFile(fileId, customerId, { isDeleting: true }));
            baseContributorService.deleteContributorFile(fileId)
                .then((response) => {
                    if (!response) { reject(); throw ''; }
                    dispatch({
                        type: contributorReducerConstants.DELETE_CONTRIBUTOR_FILE,
                        payload: { customerId, fileId }
                    })
                    dispatch(deselectNotification(fileId));
                    resolve();
                }, () => {
                })
                .catch((error) => {
                    try {
                        var file = dispatch(getContributorFile(fileId, true));
                        dispatch(popoverAction.showToast('error', 'delete-' + fileId, [`Error deleting ${file.name}.`]))
                    } catch { }
                    dispatch(updateContributorFile(fileId, customerId, { isDeleting: false }));
                    dispatch(deselectNotification(fileId));
                })
                .finally(() => {
                })
        });
    }
}

function rejectContributorFile(fileId, reason) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var customerId = getState().authentication.customerId;
            dispatch(updateContributorFile(fileId, customerId, { isDeleting: true }));
            baseContributorService.rejectContributorFile(fileId, reason)
                .then((response) => {
                    if (!response) { reject(); throw ''; }
                    dispatch({
                        type: contributorReducerConstants.DELETE_CONTRIBUTOR_FILE,
                        payload: { customerId, fileId }
                    })
                    dispatch(deselectNotification(fileId));
                    resolve();
                }, () => {
                })
                .catch((error) => {
                    try {
                        var file = dispatch(getContributorFile(fileId, true));
                        dispatch(popoverAction.showToast('error', 'reject-' + fileId, [`Error rejecting ${file.name}.`]))
                    } catch { }
                    dispatch(updateContributorFile(fileId, customerId, { isDeleting: false }));
                    dispatch(deselectNotification(fileId));
                })
                .finally(() => {
                })
        });
    }
}

function contributorFileRecieved(contributorFile) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(getContributorFileByFileId(contributorFile.contributorFileId, contributorFile.customerId))
                .then((response) => {
                    var boardName = '';
                    var customerName = '';
                    var contributorName = '';
                    try {
                        contributorName = dispatch(getContributorUserName(contributorFile.contributerId));
                    } catch { }
                    try {
                        if (contributorFile.boardId) {
                            var board = getState().board.allBoards.find(b => b.id == contributorFile.boardId);
                            boardName = board.name;
                            customerName = board.customerName;
                        } else {
                            customerName = getState().company[contributorFile.customerId].companyName;
                        }
                    } catch (e) { console.log(e); }
                    var messageParts = [`A file '${contributorFile.contributorFileName}' has been uploaded${contributorName ? ` by ${contributorName}` : ''}${boardName ? ` to ${boardName}` : ''}${customerName ? ` for ${customerName}` : ''}.`];
                    if (response.message) {
                        messageParts.push(response.message)
                    }
                    dispatch(popoverAction.showToast('success', contributorFile.contributorFileId, messageParts));
                });
        });
    }
}

function getContributorFileByFileId(fileId, customerId = '') {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.getContributorFileByFileId(fileId)
                .then((response) => {
                    try {
                        if (!customerId) {
                            customerId = getState().board.allBoards.find(b => b.id == response.boardId).customerId;
                        }
                    } catch { }
                    if (!customerId) { return; }
                    dispatch({
                        type: contributorReducerConstants.POPULATE_CONTRIBUTOR_FILE,
                        payload: {
                            customerId: customerId,
                            contributorFile: response
                        }
                    })
                    resolve(response);
                }, () => { reject() })
                .catch(() => {
                    reject();
                });
        })
    };
}

function getAllContributorFilesForCustomer(customerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.getAllContributorFilesForCustomer(customerId)
                .then((response) => {
                    const state = getState().contributors;
                    try {
                        var contributorIds = response.map(r => r.contributorId);
                        contributorIds = _.uniq(contributorIds);
                        contributorIds.forEach(cid => {
                            if (!state.contributors[customerId][cid]) {
                                dispatch(getContributor(cid));
                            }
                        })
                    } catch { }
                    dispatch({
                        type: contributorReducerConstants.POPULATE_CONTRIBUTOR_FILES,
                        payload: {
                            customerId: customerId,
                            contributorFiles: response
                        }
                    })
                    resolve();
                })
                .catch((error) => {
                    reject();
                    console.log(error);
                })
        });
    }
}

function getContributor(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.getContributor(id)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.ADD_CONTRIBUTOR,
                        payload: {
                            customerId: response.customerId,
                            contributor: response
                        }
                    })
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}

function getContributors(customerId, email) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!customerId) { reject(); return; }
            dispatch({ type: contributorReducerConstants.CREATE_CONTRIBUTORS_STATE, payload: customerId });

            baseContributorService.getAllContributors(customerId, email)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.POPULATE_CONTRIBUTORS,
                        payload: { customerId, contributors: response }
                    });
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                })
        });
    }
}

function addContributor(contributorDetails, sendEmailLink = false) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            baseContributorService.addContributor(contributorDetails)
                .then(async (response) => {
                    if (contributorDetails.enabledUntilDate == commonConstants.SET_NULL_DATE) {
                        contributorDetails.enabledUntilDate = null;
                    }
                    try {
                        contributorDetails.id = response.id;
                    } catch { }
                    dispatch({
                        type: contributorReducerConstants.ADD_CONTRIBUTOR,
                        payload: {
                            customerId: contributorDetails.customerId,
                            contributor: contributorDetails
                        }
                    });
                    resolve(contributorDetails.id);
                })
                .catch((error) => {
                    reject(error);
                    return error;
                })
        })
    }
}

function updateContributor(contributorDetails) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            baseContributorService.updateContributor(contributorDetails)
            .then((response) => {
                // if (contributorDetails.enabledUntilDate == commonConstants.SET_NULL_DATE) {
                //     contributorDetails.enabledUntilDate = null;
                // }
                dispatch({
                        type: contributorReducerConstants.UPDATE_CONTRIBUTOR,
                        payload: {
                            customerId: contributorDetails.customerId,
                            contributor: contributorDetails
                        }
                    })
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })
        })
    }
}

function deleteContributor(contributor) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            baseContributorService.deleteContributor(contributor.id)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.DELETE_CONTRIBUTOR,
                        payload: {
                            customerId: contributor.customerId,
                            contributor: contributor
                        }
                    });
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })
        })
    }
}

function toggleNotificationSelect(notificationId) {
    return (dispatch, getState) => {
        // var customerId = getState().authentication.customerId;
        // dispatch(updateContributorFile(notificationId, customerId, { isDeleting: true }));
        dispatch({
            type: contributorReducerConstants.TOGGLE_SELECTED_NOTIFICATION,
            payload: {
                id: notificationId
            }
        });
    }
}

function clearSelectedNotifications() {
    return (dispatch, getState) => {
        dispatch({ type: contributorReducerConstants.CLEAR_SELECTED_NOTIFICATIONS });
    }
}

function getContributorMembership(membershipId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.getContributorMembership(membershipId)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIP,
                        payload: response
                    })
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}
function getContributorMemberships(contributorId, boardId, customerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.getContributorMemberships(contributorId, boardId, customerId)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIPS,
                        payload: response
                    })
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}
function createContributorMembership(membership) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.createContributorMembership(membership)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIP,
                        payload: membership
                    });
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}
function updateContributorMembership(membership) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.updateContributorMembership(membership)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIP,
                        payload: membership
                    });
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}
function deleteContributorMembership(membership) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseContributorService.deleteContributorMembership(membership.id)
                .then((response) => {
                    dispatch({
                        type: contributorReducerConstants.DELETE_CONTRIBUTOR_MEMBERSHIP,
                        payload: membership
                    });
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
}