import { GetURL } from '@lib';

export const connectionTestService = {
    connectionTest,
    connectionSuccessTest
}

function connectionTest() {
    const requestOptions = {
        method: 'GET',
    };
    try {
        return fetch(GetURL() + "TestConnection", requestOptions)
            .then(response => {
                if (response) {
                    return response.ok || response.status == 401
                }
                return false;
            })
            .catch(error => {
                return false;
            });
    } catch (e) {
        return Promise.reject();
    }
}

function connectionSuccessTest() {
    const requestOptions = {
        method: 'GET',
    };
    try {
        return fetch(GetURL() + "Success", requestOptions)
            .then(response => {
                if (response) {
                    return response.ok || response.status == 401
                }
                return false;
            })
            .catch(error => {
                return false;
            });
    } catch (e) {
        return Promise.reject();
    }
}