import React from 'react';
import { connect } from 'react-redux';

import { kvpActions } from '@actions/admin';
import { connectionTestService } from '@services/connectionTestService';

class ConnectionTest extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.checkBackEndConnection();
        window.addEventListener('online', this.setConnectionStatusActive);
        window.addEventListener('offline', this.setConnectionStatusInactive);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.setConnectionStatusActive);
        window.removeEventListener('offline', this.setConnectionStatusInactive);
    }

    setConnectionStatusActive = () => { this.props.SetConnectionStatus(true) }
    setConnectionStatusInactive = () => { this.props.SetConnectionStatus(false) }

    checkBackEndConnection = () => {
        connectionTestService.connectionSuccessTest()
            .then(response => {
                this.props.SetAPIConnectionStatus(Boolean(response));
            })
            .catch(err => {
                this.props.SetAPIConnectionStatus(false);
            });
    }

    render() { return null; }
}

function mapStateToProps(state, ownProps) {
    return {
        canConnectToBackend: state.keyValuePair && state.keyValuePair.APIConnectionStatus,
        isOnline: state.keyValuePair && state.keyValuePair.isOnline,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        SetAPIConnectionStatus: (connected = false) => dispatch(kvpActions.set_kvp({ key: 'APIConnectionStatus', value: connected })),
        SetConnectionStatus: (connected = false) => dispatch(kvpActions.set_kvp({ key: 'isOnline', value: connected })),
    }
}

const connectedConnectionTest = connect(mapStateToProps, mapDispatchToProps)(ConnectionTest);
export { connectedConnectionTest as ConnectionTest };