import { authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import { FileStorage, CacheStorage } from '@lib/indexeddb';
import { CheckImpersonationPath, BLANK_GUID, getUserKeyPath } from '@lib/simpletools';
import fetch from '@lib/fetch-retry';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { commonConstants } from '@constants';
import { BinderItemType, BinderStatus } from '@constants/common.constants';
import { baseBinderService } from '@services/binder.base.service';

export const binderService = {
  ...baseBinderService,
  getWillMoveToPreviousCheck,
  getTemplateContent,
  populateTemplateContent,
  newTemplateContent,
  createBlankTemplate,
  updateTemplateContent,
  updateTemplatePreviewInfo,
  deleteTemplate,
  deleteBinder,
  getBinderStatus,
  updateBinder,
  updateBinderDash,
  updateBinderPreviewInfo,
  removeImage,
  updateImage,
  commitPublish,
  //getAllCachedBinders,
  //deleteCachedBinder,
  copyDocuments,
  saveCachedBinder,
  getBinderTransactionEvent,
  saveBinderTransactionEvent,
  deleteBinderTransaction,
  createBlankBinder,
  renameCachedBinder,
  createAttendee,
  createRecipient,
  newBinder,
  getBinderItem,
  getBinderUsage,
  deleteBinderItem,
  downloadUserDocument,
  downloadAdminDocument,
  getVote,
  getAllVotes,
  checkBinderContents,
  deleteBinderSettings,
  updateBinderSettings,
};

function getWillMoveToPreviousCheck(boardId, meetingDate) {
  const requestOptions = {
    method: 'GET',
    populateBinder: true,
    headers: authHeader()
  };

  return fetch(GetURL() + `Binders/StatusChangeDate?boardId=${boardId}&meetingDate=${meetingDate}`, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return false;
      }
    })
    .catch(handleCatch);
}

function getTemplateContent(templateId) {
  const requestOptions = {
    method: 'GET',
    populateBinder: true,
    headers: authHeader()
  };

  return fetch(GetURL() + 'Binders/Templates/' + templateId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateTemplateContent(templateId, excludeItems = false) {
  const requestOptions = {
    method: 'GET',
    populateBinder: true,
    headers: authHeader()
  };

  return fetch(GetURL() + 'Binders/Templates/Populated/' + templateId + '?excludeItems=' + excludeItems, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function newTemplateContent(templateItem) {
  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("newTemplate", code, msg, {
      binderId: '',
      objectId: objectId,
      binder: templateItem,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    //search for image we can use
    for (var x = 0; x < templateItem.items.length; x++) {
      if (templateItem.items[x].image !== null) {
        if (templateItem.items[x].image.byteLength > 1) {
          //found an image
          var imageId = uuidv4();

          var hash = CrytpoLib.MD5(templateItem.items[x].image);
          var addheader = {
            DocumentChunkSize: templateItem.items[x].image.byteLength,
            ChunkHash: hash,
            DocumentHash: hash,
            customerId: templateItem.customerId,
            'Content-Type': 'application/json',
          }

          var Data = {
            data: CrytpoLib.arrayBufferToBase64String(templateItem.items[x].image)
          }

          const requestOptions = {
            method: 'POST',
            headers: authHeader(addheader),
            body: JSON.stringify(Data)
          };
          fetch(GetURL() + 'Document/' + imageId, requestOptions)
            .then((response) => {
              if (!response.ok) {
                if (response.status === 401) {
                  LogoutAndRedirect();
                }
                reject(errorReport('635', response.status, imageId, {}, response));
                return;
              }
              if (response.status === 201 || response.status === 200) {
                resolve({ imageId: imageId });
              } else
                reject(errorReport('636', response.status, imageId, {}, response));
            })
            .catch(function (error) {
              reject(errorReport('637', '', imageId, {}, error));
            });
          return;
        }
      }
    }
    resolve({ imageId: '' });
  })
    .then((data) => {
      if (data.imageId !== '')
        templateItem.imageId = data.imageId;
      if ((templateItem.attendees && !templateItem.attendees.length) || templateItem.worker) {
        //No attendees
        return ([]);
      }

      var promisearry = [];
      for (var x = 0; x < templateItem.attendees.length; x++) {
        var item = templateItem.attendees[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new attendee
              var updateitem = {
                userId: item.userId,
                type: item.type,
                //firstName: item.firstName,
                //lastName: item.lastName,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve(newId.id);
                })
                .catch(function (error) {
                  reject(errorReport('638', 'People/Attendee', '', updateitem, error));
                });
            } else {
              //update attendee
              var updateitem = {
                id: item.id,
                userId: item.userId,
                type: item.type,
                //firstName: item.firstName,
                //lastName: item.lastName,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve(item.id);
                })
                .catch(function (error) {
                  reject(errorReport('639', 'People/Attendee', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((attendeeIds) => {
      if (attendeeIds.length !== 0) {
        templateItem.attendeeIds = attendeeIds;
      }
      if (templateItem.recipients.length === 0 || templateItem.worker) {
        //No recipients
        return ([]);
      }
      var promisearry = [];
      for (var x = 0; x < templateItem.recipients.length; x++) {
        var item = templateItem.recipients[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new recipients
              var updateitem = {
                userId: item.userId,
                type: item.type,
                //firstName: item.firstName,
                //lastName: item.lastName,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve(newId.id);
                })
                .catch(function (error) {
                  reject(errorReport('640', 'People/Attendee', '', updateitem, error));
                });
            } else {
              //update recipients
              var updateitem = {
                id: item.id,
                userId: item.userId,
                type: item.type,
                //firstName: item.firstName,
                //lastName: item.lastName,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve(item.id);
                })
                .catch(function (error) {
                  reject(errorReport('641', 'People/Attendee', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((recipientIds) => {
      if (recipientIds.length !== 0) {
        templateItem.recipientIds = recipientIds;
      }

      if (templateItem.invitees.length === 0) {
        //No invitees
        return ([]);
      }
      //loop through all invitees
      var promisearry = [];
      for (var x = 0; x < templateItem.invitees.length; x++) {
        var name = templateItem.invitees[x].name;
        var id = templateItem.invitees[x].id;
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (id !== '') {
              resolve(id);
              return;
            }
            let [first, ...second] = name.split(" ")
            second = second.join(" ")

            var updateitem = {
              boardId: templateItem.boardId,
              firstName: first,
              lastName: second,
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'People/Invitee', requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    LogoutAndRedirect();
                  }
                  reject(errorReport('642', 'People/Invitee', '', updateitem, response.status));
                }
                return response.json();
              })
              .then((newInviteeId) => {
                resolve(newInviteeId.id);
              })
              .catch(function (error) {
                reject(errorReport('643', 'People/Invitee', '', updateitem, error));
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      var inviteesIds = [];
      if (data.length > 0) {
        for (var x = 0; x < data.length; x++)
          inviteesIds.push(data[x]);
      }

      return new Promise(function (resolve, reject) {
        var updateitem = {
          name: templateItem.name,
          boardId: templateItem.boardId,
          meetingDate: templateItem.meetingDate,
          expiryDate: templateItem.expiryDate === null || templateItem.expiryDate === "" ? commonConstants.SET_NULL_DATE : templateItem.expiryDate,
          locationName: templateItem.locationName,
          LocationLattitude: null,
          LocationLongitude: null,
          itemIds: [],
          settings: null,
          //attendeeIds: templateItem.attendeeIds,
          inviteeIds: inviteesIds,
          //recipientIds: templateItem.recipientIds,
          countryBlacklist: [],
          thumbnailImageDownloadId: null,
          usageIds: null,
          binderStatus: templateItem.binderStatus,
          deleteNotesAfterDays: templateItem.deleteNotesAfterDays,
          deleteAnnotationsAfterDays: templateItem.deleteAnnotationsAfterDays,
        }

        if (templateItem.deleteNotesAfterMeeting !== undefined)
          updateitem.deleteNotesAfterMeeting = templateItem.deleteNotesAfterMeeting
        if (templateItem.deleteAnnotationsAfterMeeting !== undefined)
          updateitem.deleteAnnotationsAfterMeeting = templateItem.deleteAnnotationsAfterMeeting

        if (templateItem.hasOwnProperty('recipientIds')) {
          var recipientIds = templateItem.recipientIds;
          updateitem.recipientIds = recipientIds;
        }
        if (templateItem.hasOwnProperty('attendeeIds')) {
          var attendeeIds = templateItem.attendeeIds;
          updateitem.attendeeIds = attendeeIds;
        }

        if (templateItem.hasOwnProperty('imageId'))
          if (templateItem.imageId !== '')
            updateitem.thumbnailImageDownloadId = templateItem.imageId;

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };

        fetch(GetURL() + 'Binders/Templates', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                LogoutAndRedirect();
              }
              reject(errorReport('644', 'Binders/Templates', '', updateitem, response));
              return;
            }
            return response.json();
          })
          .then((newBinderId) => {
            resolve(newBinderId.id);
          })
          .catch(function (error) {
            reject(errorReport('645', 'Binders/Templates', '', updateitem, error));
          });
      });
    })
    .catch(handleCodeCatch);
}

function updateTemplateContent(templateItem) {
  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateTemplate", code, msg, {
      binderId: '',
      objectId: objectId,
      binder: templateItem,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    resolve();
  })
    .then(() => {
      if ((templateItem.attendees && !templateItem.attendees.length) || templateItem.worker) {
        //No attendees
        return ([]);
      }

      var promisearry = [];
      for (var x = 0; x < templateItem.attendees.length; x++) {
        var item = templateItem.attendees[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new attendee
              var updateitem = {
                userId: item.userId,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve({
                    id: newId.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport('646', 'People/Attendee', '', updateitem, error));
                });
            } else {
              //update attendee
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve({
                    id: item.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport('647', 'People/Attendee', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((attendeeIds) => {
      if (attendeeIds.length !== 0) {
        templateItem.attendeeIds = templateItem.attendeeIds.concat(attendeeIds);
        templateItem.binderChange = true;
      }
      if (templateItem.recipients.length === 0 || templateItem.worker) {
        //No recipients
        return ([]);
      }

      var promisearry = [];
      for (var x = 0; x < templateItem.recipients.length; x++) {
        var item = templateItem.recipients[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new recipients
              var updateitem = {
                userId: item.userId,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve({
                    id: newId.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport('648', 'People/Recipient', '', updateitem, error));
                });
            } else {
              //update recipients
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: templateItem.boardId,
                settings: newSettings,
              };

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve({
                    id: item.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport('649', 'People/Recipient', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((recipientIds) => {
      if (recipientIds.length !== 0) {
        templateItem.recipientIds = templateItem.recipientIds.concat(recipientIds);
        templateItem.binderChange = true;
      }
      if (templateItem.items.length < 1 || templateItem.worker) {
        return [];
      }

      //Process any items that need to be uploaded
      var newUserData = [];
      for (var x = 0; x < templateItem.items.length; x++) {
        var templateItems = templateItem.items[x];
        var hasGenKey = templateItems.userItems.find(o => o.userId === BLANK_GUID)

        var newUserItems = [];
        if (templateItems.binderType === BinderItemType.header) {
          templateItems.userItems.forEach(function (uItem) {
            if (uItem.id === undefined)
              newUserItems.push(uItem);
          })
        } else {
          if (hasGenKey.documentId === "" || hasGenKey.key === "" || hasGenKey.size === 0 ||
            hasGenKey.documentId === undefined || hasGenKey.key === undefined || hasGenKey.size === undefined) hasGenKey = undefined
          if (templateItems.documentId === "" || templateItems.key === "" || templateItems.size === 0 ||
            templateItems.documentId === undefined || templateItems.key === undefined || templateItems.size === undefined) {
            if (hasGenKey === undefined)
              continue
          }

          var f = templateItems.userItems.find(o => o.userId === BLANK_GUID)
          if (f) {
            if (f.documentId !== "" && f.key !== "" && f.size !== 0 &&
              f.documentId !== undefined && f.key !== undefined && f.size !== undefined)
              continue
          }

          newUserItems.push({
            userId: BLANK_GUID,
            documentId: '',
            size: 0,
            key: '',
            blank: false,
            enabled: true,
            locked: false,
            viewArchived: true,
            change: true,
          });


          /*for(var y=0; y<templateItems.userItems.length; y++){
            var userItem = templateItems.userItems[y];
            if(!userItem.enabled) continue;

            if(userItem.documentId !== "" && userItem.key !== "" && userItem.size !== 0 &&
              userItem.documentId !== undefined && userItem.key !== undefined && userItem.size !== undefined) continue;
            //Are we an Admin?
            var update = false;
            if(templateItem.adminUsers.includes(userItem.userId) || userItem.userId === BLANK_GUID){
              //Do keys
              update = true;
            }else{
              //If user only update when current, previous
              if(templateItem.binderStatus === BinderStatus.current || templateItem.binderStatus === BinderStatus.previous) update = true;
            }

            if(update){
              var k = templateItem.keyList.find(o => o.userId === userItem.userId);
              if(k){
                newUserItems.push(userItem);
              }
            }
          }*/
        }

        if (newUserItems.length) {
          var type = templateItems
          if (hasGenKey !== undefined) type = hasGenKey
          newUserData.push({
            id: templateItems.id,
            documentId: type.documentId,
            key: type.key,
            size: type.size,
            hasGenKey: hasGenKey === undefined ? false : true,
            binderType: templateItems.binderType,
            userItems: newUserItems,
          })
        }
      }

      if (newUserData.length === 0) return [];

      //Decrypt Item Keys
      var promisearry = [];
      newUserData.forEach(function (item) {
        promisearry.push(
          new Promise(function (resolve, reject) {
            var itt = item;
            var itt2 = templateItem;
            if (item.binderType === BinderItemType.header) {
              resolve(item)
              return;
            }

            var key = templateItem.kUser
            if (item.hasGenKey) key = templateItem.pUserGenSec

            //get AES Key
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.key))
              .then(function (aesKey) {
                var itt3 = itt;
                if (item.binderType === BinderItemType.vote) {
                  const requestOptions = {
                    method: 'GET',
                    headers: authHeader({
                      'Content-Type': 'application/json',
                      'DocumentChunkSize': 3 * 1024 * 1024
                    })
                  };

                  return fetch(GetURL() + 'Document/' + item.documentId + "/0", requestOptions)
                    .then((response) => {
                      if (!response.ok) {
                        if (response.status === 404) {
                          var url = response.url.replace(GetURL(), "");
                          return Promise.reject(errorReport('649', response.statusText, item.documentId, item, response));
                        }
                        if (response.status === 401) {
                          return Promise.reject(errorReport('650', response.status, item.documentId, item, response));
                        }
                        return response.text().then(data => {
                          try {
                            var j = JSON.parse(data.toLowerCase());
                            if (j.hasOwnProperty('code')) return Promise.reject(j);
                          } catch (err) {

                          }

                          return Promise.reject(errorReport('651', response.statusText, item.documentId, item, response));
                        });

                      } else if (response.status === 204) {
                        return {};
                      } else return response.json();
                    })
                    .then((json) => {
                      if (json.data === undefined)
                        return Promise.reject(errorReport('652', 'Failed to download data', item.documentId, item, 'Failed to download data'))
                      CrytpoLib.AESDecrypt(aesKey, CrytpoLib.base64StringToArrayBuffer(json.data))
                        .then(function (decryptedData) {
                          item.aesKey = aesKey;
                          try {
                            item.itemData = JSON.parse(CrytpoLib.arrayBufferToText(decryptedData));
                          } catch (e) {
                            console.log("json conversion error", e);
                            reject(errorReport('653', "json conversion error", item.documentId, item, e));
                          }
                          resolve(item)
                        })
                        .catch(function (e) {
                          console.log("decryption error", e);
                          reject(errorReport('654', "decryption error", item.documentId, item, e));
                        });
                    })
                    .catch(handleCatch);
                } else {
                  item.aesKey = aesKey;
                  resolve(item)
                }
              })
              .catch(function (e) {
                console.log("decryption error", e);
                reject(errorReport('655', "decryption error", item.documentId, item, e));
              });
          })
        )
      })

      return Promise.all(promisearry);
    })
    .then((templateItems) => {
      if (templateItems.length === 0) return [];

      //Encrypt Item Keys
      var promisearray = [];
      templateItems.forEach(function (item) {
        promisearray.push(
          new Promise(function (resolveItem, rejectItem) {
            if (item.binderType === BinderItemType.header) {
              resolveItem(item)
              return;
            }
            var promiseUserItem = [];
            item.userItems.forEach(function (userItem) {
              promiseUserItem.push(
                new Promise(function (resolveUser, rejectUser) {
                  var cItem = item;
                  var k = templateItem.keyList.find(o => o.userId === userItem.userId);
                  if (k === undefined) {
                    rejectUser({ code: "673", msg: "User Keys are missing", item: cItem })
                    return
                  }

                  if (cItem.binderType === BinderItemType.vote) {
                    if (userItem.voteId !== undefined) {
                      if (userItem.voteId === '') {
                        userItem.voteId = uuidv4();
                      }
                    } else userItem.voteId = uuidv4();

                    var jsonObj = Object.assign({}, cItem.itemData);
                    jsonObj.id = userItem.voteId;
                    var jsonString = JSON.stringify(jsonObj);
                    var Uint8View = CrytpoLib.textToArrayBuffer(jsonString);

                    //Create new DocumentId
                    var documentId = uuidv4();

                    //AES Encrypt vote
                    var aeskey = CrytpoLib.GenerateRandom(32);
                    CrytpoLib.AESEncrypt(aeskey, Uint8View)
                      .then(function (encryptedData) {
                        var hash = CrytpoLib.MD5(encryptedData);
                        var uploadData = {
                          data: CrytpoLib.arrayBufferToBase64String(encryptedData)
                        }
                        const requestOptions = {
                          method: 'POST',
                          headers: authHeader({
                            'Content-Type': 'application/json',
                            "DocumentChunkSize": 3 * 1024 * 1024,
                            "DocumentHash": hash,
                            "ChunkHash": hash,
                            "IsLastChunk": true,
                            "customerId": templateItem.customerId,
                          }),
                          body: JSON.stringify(uploadData)
                        };
                        fetch(GetURL() + 'Document/' + documentId + "/0", requestOptions)
                          .then((response) => {
                            if (!response.ok) {
                              if (response.status === 409 || response.status === 400) {
                                return response.json();
                              }
                              return rejectUser({ code: "656", msg: response.statusText, item: cItem });
                            }
                            return {};
                          })
                          .then((jData) => {
                            if (jData.hasOwnProperty('Code') || jData.hasOwnProperty('code'))
                              return rejectUser({ code: "657", msg: jData, item: cItem });
                            CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
                              .then(function (iUserKey) {
                                CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
                                  .then(function (KAESEncpyt) {
                                    userItem.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt);
                                    userItem.documentId = documentId;
                                    userItem.size = encryptedData.byteLength;
                                    resolveUser(userItem);
                                  })
                                  .catch(function (error) {
                                    rejectUser({ code: "658", msg: error, item: cItem });
                                  })
                              })
                              .catch(function (error) {
                                rejectUser({ code: "659", msg: error, item: cItem });
                              });
                          })
                          .catch((error) => {
                            rejectUser({ code: "660", msg: error, item: cItem })
                          })
                      }).
                      catch((error) => {
                        rejectUser({ code: "661", msg: error, item: cItem });
                      });
                  } else {
                    CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
                      .then(function (iUserKey) {
                        CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, cItem.aesKey)
                          .then(function (KAESEncpyt) {
                            userItem.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt);
                            userItem.documentId = cItem.documentId;
                            userItem.size = cItem.size;
                            resolveUser(userItem);
                          })
                          .catch(function (error) {
                            rejectUser({ code: "662", msg: error, item: cItem });
                          })
                      })
                      .catch(function (error) {
                        rejectUser({ code: "663", msg: error, item: cItem });
                      });
                  }
                })
              )
            })

            Promise.all(promiseUserItem)
              .then((userItems) => {
                item.userItems = userItems;
                resolveItem(item);
              })
              .catch((e) => {
                console.log("processItem error", e);
                if (e.code !== undefined && e.msg !== undefined)
                  rejectItem(errorReport(e.code, '', '', e.item, e.msg))
                else
                  rejectItem(errorReport('675', '', '', item, e))
              })
          })
        )
      })

      return Promise.all(promisearray);
    })
    .then((items) => {
      if (items.length === 0) return;

      if (templateItem.binderChange) {
        items.forEach(function (item) {
          var f = templateItem.items.find(o => o.id === item.id);
          if (f) {
            item.userItems.forEach(function (userItem) {
              f.isChanged = true;
              var cItem;
              cItem = f.userItems.find(u => u.userId === userItem.userId);
              if (cItem !== undefined) {
                if (userItem.id !== undefined && userItem.id !== "") cItem.id = userItem.id;
                if (userItem.userId !== undefined) cItem.userId = userItem.userId;
                if (userItem.voteId !== undefined && userItem.voteId !== "") cItem.voteId = userItem.voteId;
                if (userItem.documentId !== undefined && userItem.documentId !== "") cItem.documentId = userItem.documentId;
                if (userItem.resolutionId !== undefined) cItem.resolutionId = userItem.resolutionId;
                if (userItem.size !== undefined && userItem.size !== 0) cItem.size = userItem.size;
                if (userItem.key !== undefined && userItem.key !== "") cItem.key = userItem.key;
                if (userItem.enabled !== undefined) cItem.enabled = userItem.enabled;
                if (userItem.locked !== undefined) cItem.locked = userItem.locked;
                if (userItem.viewArchived !== undefined) cItem.viewArchived = userItem.viewArchived;
              } else {
                f.userItems.push(userItem);
              }
            })
          }
        })
        return;
      }

      var uploadData = {};
      items.forEach(function (item) {
        uploadData[item.id] = item.userItems;
      })
      templateItem.items.forEach(function (item) {
        item.userItems.forEach(function (userItem) {
          if (userItem.id !== "") return;
          var f = uploadData[item.id].find(o => o.userId === userItem.userId);
          if (!f) {
            uploadData[item.id].push(userItem);
          }
        })
      })

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(uploadData)
      };
      return new Promise(function (resolve, reject) {
        fetch(GetURL() + 'Items/UserItems', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 409 || response.status === 400) {
                return response.json();
              }
              return reject(errorReport('664', response.status, '', {}, response));
            }
            return {};
          })
          .then((jData) => {
            if (jData.hasOwnProperty('Code') || jData.hasOwnProperty('code'))
              return reject(errorReport('665', jData, '', {}, jData));
            resolve();
          })
          .catch(handleCatch)
      });
    })
    .then(() => {
      if (templateItem.invitees.length === 0) {
        //No invitees
        return ([]);
      }
      //loop through all invitees
      var promisearry = [];
      for (var x = 0; x < templateItem.invitees.length; x++) {
        var name = templateItem.invitees[x].name;
        var id = templateItem.invitees[x].id;
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (id !== '') {
              resolve(id);
              return;
            }
            let [first, ...second] = name.split(" ")
            second = second.join(" ")

            var updateitem = {
              boardId: templateItem.boardId,
              firstName: first,
              lastName: second,
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'People/Invitee', requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    LogoutAndRedirect();
                  }
                  reject(errorReport('666', response.status, '', updateitem, response));
                }
                return response.json();
              })
              .then((newInviteeId) => {
                resolve(newInviteeId.id);
              })
              .catch(function (error) {
                reject(errorReport('667', '', '', updateitem, error));
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      var inviteesIds = templateItem.inviteesIds;
      if (data.length > 0) {
        if (inviteesIds === undefined)
          inviteesIds = [];
        for (var x = 0; x < data.length; x++)
          inviteesIds.push(data[x]);
        templateItem.binderChange = true;
      }

      if (!templateItem.binderChange) {
        return;
      }
      return new Promise(function (resolve, reject) {
        var updateitem = {
          id: templateItem.id,
          name: templateItem.name,
          boardId: templateItem.boardId,
          locationName: templateItem.locationName,
          //attendeeIds: templateItem.attendeeIds,
          //inviteeIds: inviteesIds,
          //recipientIds: templateItem.recipientIds,
          binderStatus: templateItem.binderStatus,
          // deleteNotesAfterDays: templateItem.deleteNotesAfterDays,
          // deleteAnnotationsAfterDays: templateItem.deleteAnnotationsAfterDays,
        }

        if (templateItem.deleteNotesAfterMeeting !== undefined)
          updateitem.deleteNotesAfterMeeting = templateItem.deleteNotesAfterMeeting
        if (templateItem.deleteAnnotationsAfterMeeting !== undefined)
          updateitem.deleteAnnotationsAfterMeeting = templateItem.deleteAnnotationsAfterMeeting

        if (inviteesIds !== undefined) {
          updateitem.inviteeIds = inviteesIds;
        }
        if (templateItem.hasOwnProperty('recipientIds')) {
          var recipientIds = []
          templateItem.recipientIds.forEach(e => {
            if (e.id) {
              recipientIds.push(e.id)
            }
          })
          if (recipientIds.length > 0) {
            updateitem.recipientIds = recipientIds;
          }
        }
        if (templateItem.hasOwnProperty('attendeeIds')) {
          var attendeeIds = []
          templateItem.attendeeIds.forEach(e => {
            if (e.id) {
              attendeeIds.push(e.id)
            }
          })
          if (attendeeIds.length > 0) {
            updateitem.attendeeIds = attendeeIds;
          }
        }
        let params = "?commitTransactions=true"
        // if(templateItem.commitTransactionIds !== undefined && templateItem.commitTransactionIds.length > 0){
        //   updateitem.commitTransactionIds = templateItem.commitTransactionIds
        // }
        // updateitem.modifiedName = ""

        //If binder worker is going to be executed then set the binderStatus inside there
        if (!templateItem.worker) {
          //Add ItemsIds
          var itemIds = [];
          var items = [];
          for (var x = 0; x < templateItem.items.length; x++) {
            var itemData = templateItem.items[x];
            if (itemData.id !== "" && !itemData.isChanged) {
              itemIds.push(itemData.id);
              continue;
            }

            var userItems = [];
            itemData.userItems.forEach(function (obj) {
              var u = {};
              if (obj.id !== undefined && obj.id !== "") u.id = obj.id;
              if (obj.userId !== undefined) u.userId = obj.userId;
              if (obj.voteId !== undefined && obj.voteId !== "") u.voteId = obj.voteId;
              if (obj.documentId !== undefined && obj.documentId !== "") u.documentId = obj.documentId;
              if (obj.resolutionId !== undefined) u.resolutionId = obj.resolutionId;
              if (obj.size !== undefined && obj.size !== 0) u.size = obj.size;
              if (obj.key !== undefined && obj.key !== "") u.key = obj.key;
              if (obj.enabled !== undefined) u.enabled = obj.enabled;
              if (obj.locked !== undefined) u.locked = obj.locked;
              if (obj.viewArchived !== undefined) u.viewArchived = obj.viewArchived;

              userItems.push(u);
            });

            var newitem = {
              position: itemData.position,
              adminPosition: itemData.adminPosition,
              positionString: itemData.positionString,
              adminPositionString: itemData.adminPositionString,
              indentCount: itemData.indentCount,
              date: itemData.date,
              name: itemData.name,
              timing: itemData.timing,
              fileName: itemData.filename,
              userItems: userItems,
              type: itemData.type,
              style: itemData.style,
            }

            if (itemData.updateDate !== null && itemData.updateDate !== "") {
              newitem.updateDate = itemData.updateDate;
            }
            if (itemData.creationDate !== null && itemData.creationDate !== "") {
              newitem.creationDate = itemData.creationDate;
            }
            if (itemData.createdByUserId) {
              newitem.createdByUserId = itemData.createdByUserId;
            }
            if (itemData.updatedByUserId) {
              newitem.updatedByUserId = itemData.updatedByUserId;
            }

            if (itemData.isConverted) { newitem.isConverted = true; }

            if (itemData.id === "" || itemData.id === undefined) {
              newitem.id = uuidv4();
              templateItem.items[x].id = newitem.id;
            } else {
              newitem.id = itemData.id;
            }

            if (itemData.itemRequiresDecision !== '')
              newitem.itemRequiresDecision = itemData.itemRequiresDecision;
            if (itemData.itemPresentedBy !== '')
              newitem.itemPresentedBy = itemData.itemPresentedBy;

            if (itemData.expiryDate !== null && itemData.expiryDate !== "")
              newitem.expiryDate = itemData.expiryDate;
            if (itemData.binderType !== BinderItemType.video && itemData.binderType !== BinderItemType.vote && itemData.hasOwnProperty('pageCount'))
              newitem.pageCount = itemData.pageCount;

            if (itemData.showItemName !== null)
              newitem.showItemName = itemData.showItemName;

            items.push(newitem);
          }

          if (itemIds.length > 0)
            updateitem.itemIds = itemIds;
          if (items.length > 0)
            updateitem.items = items;
        }

        const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };
        fetch(GetURL() + 'Binders/Templates/' + templateItem.id + params, requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                LogoutAndRedirect();
              }
              reject(errorReport('668', 'Binders/Templates/', templateItem.id, updateitem, response));
            }
            resolve();
          })
          .catch(function (error) {
            reject(errorReport('669', 'Binders/Templates/', templateItem.id, updateitem, error));
          });
      });
    })
    .catch(handleCodeCatch);
}

function updateTemplatePreviewInfo(templateItem) {
  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateTemplate", code, msg, {
      binderId: '',
      objectId: objectId,
      binder: templateItem,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify(templateItem)
    };

    fetch(GetURL() + 'Binders/Templates/' + templateItem.id, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            LogoutAndRedirect();
          }
          reject(errorReport('668', 'Binders/Templates/', templateItem.id, templateItem, response));
        }
        resolve();
      })
      .catch(function (error) {
        reject(errorReport("669", 'Binders/Templates/', templateItem.id, templateItem, error));
      })
  })
  .catch(handleCodeCatch);
}

function deleteTemplate(templateId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Binders/Templates/' + templateId, requestOptions)
  .then((response) => {
    if (!response.ok) {
      if (response.status === 404) {
        return Promise.resolve();
      }
      if (response.status === 401) {
        LogoutAndRedirect();
      }

      if (response.status === 500) {
        return Promise.reject(response.statusText);
      }
      return Promise.reject("Unknown");
    }
  }).catch(handleCatch);
}

function deleteBinder(binderId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
    };

    fetch(GetURL() + 'Binders/' + binderId, requestOptions)
      .then(handleStandResponse)
      .then(() => resolve())
      .catch(error => {
        if (error === '404') {
          resolve()
        } else {
          reject(error)
        }
      })
  }).catch(handleCatch)
}

function getBinderStatus(usageId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Usage/' + usageId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function updateBinderDash(binderItem) {
  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateBinder", code, msg, {
      binderId: binderItem.id,
      objectId: objectId,
      binder: binderItem,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    resolve();
  })
    .then(() => {
      if (!binderItem.hasOwnProperty('recipients')) return ([]);
      if (binderItem.recipients.length === 0) {
        //No recipients
        return ([]);
      }
      var promisearry = [];
      for (var x = 0; x < binderItem.recipients.length; x++) {
        var item = binderItem.recipients[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (item.id === '') {
              //new recipients
              var updateitem = {
                userId: item.userId,
                boardId: binderItem.boardId,
              };

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve(newId.id);
                })
                .catch(function (error) {
                  reject(errorReport("670", 'People/Recipient', '', updateitem, error));
                });
            } else {
              //update recipients
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: binderItem.boardId,
              };

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve(item.id);
                })
                .catch(function (error) {
                  reject(errorReport("671", 'People/Recipient', '', updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((data) => {
      return new Promise(function (resolve, reject) {
        var updateitem = {
          id: binderItem.id,
          boardId: binderItem.boardId,
        };

        let params = "";

        if (binderItem.hasOwnProperty('updateDate')) {
          updateitem.updateDate = binderItem.updateDate;
        }
        if (binderItem.hasOwnProperty('countryBlacklist')) {
          updateitem.countryBlacklist = binderItem.countryBlacklist;
        }
        if (binderItem.hasOwnProperty('binderStatus')) {
          updateitem.binderStatus = binderItem.binderStatus;
        }

        if (binderItem.hasOwnProperty('excludeFromCurrentToPreviousMove')) {
          updateitem.excludeFromCurrentToPreviousMove = binderItem.excludeFromCurrentToPreviousMove;
        }
        if (binderItem.hasOwnProperty('binderMoveDate')) {
          updateitem.binderMoveDate = binderItem.binderMoveDate;
        }
        

        if (data.length) {
          updateitem.recipientIds = updateitem.recipientIds.concat(data);
        }

        if (binderItem.settingsDelete === true) {
          params = "?deleteField=settings"
        }

        if (binderItem.hasOwnProperty('settings')) {
          if (binderItem.settings !== null) {
            var newSettings = {};
            Object.keys(binderItem.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = binderItem.settings[e] });

            updateitem.settings = newSettings;
          }
        }
        console.log('updateitem', updateitem);
        const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };

        fetch(GetURL() + 'Binders/' + binderItem.id + params, requestOptions)
          .then(handleStandResponse)
          .then(() => {
            resolve();
          })
          .catch(function (error) {
            reject(errorReport("672", 'Binders', binderItem.id, updateitem, error));
          })
      });
    })
    .catch(handleCodeCatch);
}

function updateBinderPreviewInfo(binderItem) {
  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateBinder", code, msg, {
      binderId: binderItem.id,
      objectId: objectId,
      binder: binderItem,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify(binderItem)
    };

    fetch(GetURL() + 'Binders/' + binderItem.id, requestOptions)
      .then(handleStandResponse)
      .then(() => {
        resolve();
      })
      .catch(function (error) {
        reject(errorReport("672", 'Binders', binderItem.id, binderItem, error));
      })
  })
  .catch(handleCodeCatch);
}

function archiveBinder(binderItem) {
  return new Promise(function (resolve, reject) {
    resolve();
  })
    .then(() => {
      if (!binderItem.hasOwnProperty('items')) return ([]);
      if (binderItem.items.length === 0) {
        //No items
        return ([]);
      }
      var promisearry = [];
      for (var x = 0; x < binderItem.items.length; x++) {
        var item = binderItem.items[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            item.userItems.forEach(function (e) {
              e.locked = true;
            })
            var updateitem = {
              id: item.id,
              userItems: item.userItems,
            };

            const requestOptions = {
              method: 'PATCH',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'Items/' + item.id, requestOptions)
              .then(handleStandResponse)
              .then(() => {
                resolve(true);
              })
              .catch(function (error) {
                reject(error);
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((data) => {
      return new Promise(function (resolve, reject) {
        var updateitem = {
          id: binderItem.id,
        };

        if (binderItem.hasOwnProperty('updateDate')) {
          updateitem.updateDate = binderItem.updateDate;
        }
        if (binderItem.hasOwnProperty('binderStatus')) {
          updateitem.binderStatus = binderItem.binderStatus;
        }
        const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };

        fetch(GetURL() + 'Binders/' + binderItem.id, requestOptions)
          .then(handleStandResponse)
          .then(() => {
            resolve();
          })
          .catch(function (error) {
            reject(error);
          })
      });
    })
    .catch(handleCatch);
}
/*
function getAllCachedBinders(boardId, userId) {
  return new Promise(function (resolve, reject) {
    CacheStorage.QueryBinders(boardId, userId)
      .then((data) => {
        resolve(data)
      })
      .catch((e) => {
        reject()
      })
  })
    .catch(handleCatch);
}

function deleteCachedBinder(id) {
  return new Promise(function (resolve, reject) {
    CacheStorage.DeleteBinder(id)
      .then(() => {
        resolve();
      })
      .catch(() => {
        resolve();//if error, dont worry just delink it
      })
  })
    .catch(handleCatch);
}*/

function getBinderTransactionEvent(transactionId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    //rangeStart=^1 means get the latest transaction event
    fetch(GetURL() + 'Transactions/' + transactionId + '/Events?rangeStart=%5E1&rangeLength=1', requestOptions)
    .then(handleJsonResponse)
    .then((data) => resolve(data))
      .catch(error => {
        if (error === '404') {
          resolve('404');
        } else {
          reject(error)
        }
      })
  }).catch(handleCatch)
}

function createBlankBinder(item) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        name: item.binderName,
        boardId: item.boardId,
        id: item.binderId,
        binderStatus: BinderStatus.unpublished,
        meetingDate: moment().utc().format()
      })
    };

    fetch(GetURL() + 'Binders', requestOptions)
      .then(handleStandResponse)
      .then(() => {
        resolve(item.binderId)
      })
      .catch(function (error) {
        reject(error);
      });
  }).catch(handleCatch)
}

function createBlankTemplate(item) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        name: item.binderName,
        boardId: item.boardId,
        id: item.binderId,
        binderStatus: "template",
      })
    };

    fetch(GetURL() + 'Binders/Templates', requestOptions)
      .then(handleStandResponse)
      .then(() => {
        resolve(item.binderId)
      })
      .catch(function (error) {
        reject(error);
      });
  }).catch(handleCatch)
}

function copyDocuments(list){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(list)
  };

  return fetch(GetURL() + 'Document/Copy', requestOptions)
    .then(handleStandResponse)
    .catch(handleCatch);
}

function deleteBinderTransaction(transactionId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Transactions/' + transactionId, requestOptions)
    .then(handleStandResponse)
    .catch(handleCatch);
}

function saveBinderTransactionEvent(customerId) {
  return new Promise(function (resolve, reject) {
    if(window.binderSave === undefined || window.binderSave.length === 0 || window.transactionId === undefined){
      resolve()
    }
    let c = window.binderSave.slice(0);
    window.binderSave = [];

    function Exec(snapShot){
      return new Promise((resolve, reject) => {
        const str = JSON.stringify(snapShot)

        let parts = []
        if (snapShot.parts.length) {
          snapShot.parts.forEach(o => {
            parts.push({
              documentId: o
            })
          })
        }

        if(str.length <= 400000){
          resolve({detail: str, parts: parts, dateCreated: snapShot.dateCreated})
          return
        }

        const documentId = uuidv4()
        const d = CrytpoLib.textToArrayBuffer(str)
        var hash = CrytpoLib.MD5(d);
        var uploadData = {
          data: CrytpoLib.arrayBufferToBase64String(d)
        }
        const requestOptions = {
          method: 'POST',
          headers: authHeader({
            'Content-Type': 'application/json',
            "DocumentChunkSize": 3 * 1024 * 1024,
            "DocumentHash": hash,
            "ChunkHash": hash,
            "IsLastChunk": true,
            "customerId": customerId,
          }),
          body: JSON.stringify(uploadData)
        };
        fetch(GetURL() + 'Document/' + documentId + "/0", requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 409 || response.status === 400) {
                return response.json();
              }
              reject({ code: "1015", msg: response.statusText })
              return
            }
            return {};
          })
          .then((jData) => {
            resolve({detailDocumentId: documentId, parts: parts, dateCreated: snapShot.dateCreated})
          })
          .catch((e) => {
            rejectUser({ code: "1016", msg: error })
          })
      })
      .then((d)=>{
        return new Promise((resolve, reject) => {
          var binderData = Object.assign(d , {
            id: uuidv4(),
            transactionId: window.transactionId
          })

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(binderData)
          };
///Transactions/Events/Array,
          fetch(GetURL() + 'Transactions/Events', requestOptions)
            .then(handleJsonResponse)
            .then((res) => {
              log('Transaction event id', res.id)
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        })
      })
    }

    var promiseLimit = require('promise-limit')
    var limit = promiseLimit(1)

    Promise.all(c.map((snapShot) => {
      return limit(() => Exec(snapShot))
    }))
    .then(()=>{
      var binderNameChanges = []
      c.forEach(o => {
        if(o.hasNameDateChange === true && (o.binderStatus === BinderStatus.unpublished || o.binderStatus === BinderStatus.current)){
          const f = binderNameChanges.find(b => b.binderId == o.binderId)

          let count = 0;
          o.ListItem.forEach(i => {
            if (i.binderType != BinderItemType.multipleDoc) {
              count++;
            }
          });

          if(f){
            f.binderName = o.binderName
            f.newdate = o.newdate
            f.newtime = o.newtime
            f.itemCount = o.ListItem.length
            f.displayItemCount = count
          }else{
            binderNameChanges.push({
              binderId: o.binderId,
              binderName: o.binderName,
              newdate: o.newdate,
              newtime: o.newtime,
              itemCount: o.ListItem.length,
              displayItemCount: count
            })
          }
        }
      })

      resolve(binderNameChanges)
    })
    .catch((e)=>{
      reject(e)
    })
  })
  .catch(handleCatch);
}

function saveCachedBinder(id, transactionIds, data, type = 'Binder') {
  return new Promise(function (resolve, reject) {
    if (transactionIds.length > 0) {
      if (window.binderSave && window.binderSave.length > 0 &&
        window.transactionId && window.transactionId === transactionIds[0]
      ) {
        window.binderSave.push(data)
      } else {
        window.binderSave = [data]
      }

      window.transactionId = transactionIds[0]
      resolve(transactionIds)
    } else {
      const transactionId = uuidv4();
      var binderData = {
        id: transactionId,
        objectType: type,
        objectId: id,
        type: 1
      };

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(binderData)
      };

      fetch(GetURL() + 'Transactions', requestOptions)
        .then(handleJsonResponse)
        .then((res) => {
          window.transactionId = transactionId
          if (window.binderSave && window.binderSave.length > 0){
            window.binderSave.push(data)
          } else {
            window.binderSave = [data]
          }
          log('Transaction id', res.id)
          resolve([transactionId]);
        })
        .catch(function (error) {
          reject(error);
        });
    }


    // const c = window.binderSave.slice(0)
    // window.binderSave = []



    //var startTime = new Date();
    //console.log('BEFORE',data);
    // var ListItem = data.ListItem;
    // var CopyListItem = ListItem.slice(0);
    // var promiseItems = [];
    // CopyListItem.forEach(function(o){
    //   promiseItems.push(
    //     new Promise(function(resolveInner, rejectInner) {
    //       if(o.itemdataId === "" && o.itemdata !== null){
    //         const guiid = uuidv4();
    //         var fileReader = new FileReader();
    //         fileReader.onload = (event) => {
    //           var bytes = event.target.result;
    //           CacheStorage.SetBuffer(userkey, guiid, bytes)
    //           .then(()=>{
    //             o.itemdataId = guiid;
    //             //o.itemdata = null;
    //             //console.log("Item loaded");
    //             resolveInner();
    //           })
    //           .catch(function(error) {
    //             resolveInner();
    //           });
    //         };
    //         fileReader.readAsArrayBuffer(o.itemdata);
    //       }else resolveInner();
    //     })
    //   )
    // });
    // Promise.all(promiseItems)
    // .then(()=>{
    //   var promiseImages = [];
    //   CopyListItem.forEach(function(o){
    //     promiseImages.push(
    //       new Promise(function(resolveInner, rejectInner) {
    //         if(o.thumbImageId === "" && o.thumbImage !== null){
    //           const guiid = uuidv4();
    //           CacheStorage.SetBuffer(userkey, guiid, o.thumbImage)
    //           .then(()=>{
    //             o.thumbImageId = guiid;
    //             //o.thumbImage = null;
    //             //console.log("thumbImage loaded");
    //             resolveInner();
    //           })
    //           .catch(function(error) {
    //             resolveInner();
    //           });
    //         }else resolveInner();
    //       })
    //     )
    //   });
    //   Promise.all(promiseImages)
    //   .then(()=>{
    //     data.ListItem = CopyListItem;
    //     CacheStorage.SetBinder(userkey, id, data).then(()=>{
    //       //var c = new Date();
    //       //var difference = (c - startTime) / 1000;
    //       //console.log("EndTime",difference);
    //       resolve();
    //     })
    //     .catch(function(error) {
    //       reject();
    //     });
    //   })
    // });
  })
    .catch(handleCatch);
}

function renameCachedBinder(from, to) {
  return new Promise(function (resolve, reject) {
    CacheStorage.GetBinderNoDecrypt(from)
      .then((data) => {
        data.binderId = to;
        CacheStorage.SetBinderNoDecrypt(to, data)
          .then(() => {
            CacheStorage.DeleteBinderOnlyMain(from)
              .then(() => {
                resolve(true);
              })
              .catch(function (error) {
                resolve(false);
              });
          })
          .catch(function (error) {
            resolve(false);
          });
      })
      .catch(function (error) {
        resolve(false);
      });
  })
    .catch(handleCatch);
}

function removeImage(binderItem, publish = false) {
  return new Promise((resolve, reject) => {
    var updateitem = {
      id: binderItem.id,
      boardId: binderItem.boardId,
    };

    if (publish) {
      updateitem.ThumbnailImageDownloadId = BLANK_GUID;
    } else {
      updateitem.modifiedThumbnailImageDownloadId = BLANK_GUID;
    }

    const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify(updateitem)
    };

    fetch(GetURL() + 'Binders/' + binderItem.id, requestOptions)
      .then(handleStandResponse)
      .then(() => {
        resolve('');
      })
      .catch(function (error) {
        reject(error);
      })
  })
}

function updateImage(binderItem, sendPatch = true, publish = false) {
  return new Promise(function (resolve, reject) {
    //search for image we can use
    if (binderItem.image !== null) {
      if (binderItem.image.byteLength > 1) {
        //found an image
        var imageId = uuidv4();
        if (binderItem.hasOwnProperty("newimageId"))
          imageId = binderItem.newimageId;

        var hash = CrytpoLib.MD5(binderItem.image);
        var addheader = {
          DocumentChunkSize: binderItem.image.byteLength,
          ChunkHash: hash,
          DocumentHash: hash,
          customerId: binderItem.customerId,
          'Content-Type': 'application/json',
        }

        var Data = {
          data: CrytpoLib.arrayBufferToBase64String(binderItem.image)
        }

        const requestOptions = {
          method: 'POST',
          headers: authHeader(addheader),
          body: JSON.stringify(Data)
        };
        fetch(GetURL() + 'Document/' + imageId, requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                LogoutAndRedirect();
              }
              reject();
              return;
            }
            if (response.status === 201 || response.status === 200) {
              resolve({ imageId: imageId, Data: Data.data });
            } else reject();
          })
          .catch(function (error) {
            reject(error);
          });
        return;
      }
    }
    resolve({ imageId: '' });
  })
    .then(data => {
      if (data.imageId === "") return;
      return new Promise(function (resolve, reject) {
        if (!sendPatch) { resolve(data.imageId); return; }
        var curDate = moment(new Date());
        var updateitem = {
          id: binderItem.id,
          boardId: binderItem.boardId,
          updateDate: curDate.utc().format(),
        };

        if (publish) {
          updateitem.ThumbnailImageDownloadId = data.imageId;
          updateitem.modifiedThumbnailImageDownloadId = BLANK_GUID;
        } else {
          updateitem.modifiedThumbnailImageDownloadId = data.imageId;
        }

        console.log('updateitem', updateitem);
        const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };
        fetch(GetURL() + 'Binders/' + binderItem.id, requestOptions)
          .then(handleStandResponse)
          .then(() => {
            //Store in cache
            FileStorage.Set(data.imageId, data.Data);

            resolve(data.imageId);
          })
          .catch(function (error) {
            reject(error);
          })
      });
    })
    .catch(handleCatch);
}

function addItems(updateitem, binderOrg, sendPatch) {
  //Add ItemsIds
  var itemIds = [];
  var items = [];
  var displayItemCount = 0;
  for (var x = 0; x < binderOrg.items.length; x++) {
    var itemData = binderOrg.items[x];
    if (itemData.binderType != BinderItemType.multipleDoc) { displayItemCount++; }
    
    if (itemData.id !== "" && !itemData.isChanged || !sendPatch) {
      itemIds.push(itemData.id);
      continue;
    }

    var userItems = [];
    itemData.userItems.forEach(function (obj) {
      var u = {};
      if (obj.id !== undefined && obj.id !== "") u.id = obj.id;
      if (obj.userId !== undefined) u.userId = obj.userId;
      if (obj.voteId !== undefined && obj.voteId !== "") u.voteId = obj.voteId;
      if (obj.documentId !== undefined && obj.documentId !== "") {
        u.documentId = obj.documentId;
        if (obj.size !== undefined && obj.size !== 0) u.size = obj.size;
        if (obj.key !== undefined && obj.key !== "") u.key = obj.key;
      }
      if (obj.resolutionId !== undefined) u.resolutionId = obj.resolutionId;
      if (obj.enabled !== undefined) u.enabled = obj.enabled;
      if (obj.locked !== undefined) u.locked = obj.locked;
      if (obj.viewArchived !== undefined) u.viewArchived = obj.viewArchived;
      if (obj.resultKey !== undefined) u.resultKey = obj.resultKey;

      if (obj.isChanged !== undefined) u.isChanged = obj.isChanged;
      if (obj.isDeleted !== undefined) u.isDeleted = obj.isDeleted;

      //SAFETY CHECK to see if deleted user remove key and size if documentId
      if (obj.documentId === undefined && (obj.key !== undefined || obj.size !== undefined)) {
        if (obj.key !== undefined) delete obj.key;
        if (obj.size !== undefined) delete obj.size;
      }

      //SAFETY CHECK check to see if userId already exists and if so only pick good one
      var filtered = userItems.find(o => o.userId === obj.userId)
      if (filtered) {
        if (filtered.documentId === undefined && obj.documentId !== undefined && obj.key !== undefined && obj.size !== undefined &&
          obj.documentId !== "" && obj.key !== "" && obj.size !== 0) {
          Object.keys(obj).map(e => filtered[e] = obj[e]);
        } else {
          return;
        }
      }

      userItems.push(u);
    });

    var newitem = {
      position: itemData.position,
      adminPosition: itemData.adminPosition,
      positionString: itemData.positionString,
      adminPositionString: itemData.adminPositionString,
      indentCount: itemData.indentCount,
      date: itemData.date,
      name: itemData.name,
      timing: itemData.timing,
      fileName: itemData.filename,
      userItems: userItems,
      type: itemData.type,
      style: itemData.style,
    }

    if (itemData.isConverted) { newitem.isConverted = true; }

    if (itemData.isChanged !== undefined) newitem.isChanged = itemData.isChanged;

    if (itemData.id === "" || itemData.id === undefined) {
      newitem.id = uuidv4();
      binderOrg.items[x].id = newitem.id;
    } else {
      newitem.id = itemData.id;
    }

    if (itemData.updateDate !== null && itemData.updateDate !== "")
      newitem.updateDate = itemData.updateDate;

    if (itemData.creationDate !== null && itemData.creationDate !== "") {
      newitem.creationDate = itemData.creationDate;
    }
    if (itemData.createdByUserId) {
      newitem.createdByUserId = itemData.createdByUserId;
    }
    if (itemData.updatedByUserId) {
      newitem.updatedByUserId = itemData.updatedByUserId;
    }

    if (itemData.isConverted) { newitem.isConverted = true; }
    
    if (itemData.itemRequiresDecision !== '')
      newitem.itemRequiresDecision = itemData.itemRequiresDecision;
    if (itemData.itemPresentedBy !== '')
      newitem.itemPresentedBy = itemData.itemPresentedBy;

    if (itemData.expiryDate !== null && itemData.expiryDate !== "")
      newitem.expiryDate = itemData.expiryDate;
    if (itemData.binderType !== BinderItemType.video && itemData.binderType !== BinderItemType.vote && itemData.hasOwnProperty('pageCount'))
      newitem.pageCount = itemData.pageCount;

    if (itemData.showItemName !== null)
      newitem.showItemName = itemData.showItemName;

    items.push(newitem);
  }

  updateitem.displayItemCount = displayItemCount;

  if (itemIds.length > 0)
    updateitem.itemIds = itemIds;
  if (items.length > 0)
    updateitem.items = items;
  if (binderOrg.items !== undefined && binderOrg.itemIds !== undefined && binderOrg.items.length === 0 && binderOrg.itemIds.length === 0)
    updateitem.itemIds = itemIds;

  return updateitem
}

function updateBinder(binder_report) {
  var binderOrg = Object.assign({}, binder_report);

  var sendPatch = false;
  if (binderOrg.binderChange) {
    sendPatch = true;
  }

  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateBinder", code, msg, {
      binderId: binder_report.id,
      objectId: objectId,
      binder: binder_report,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    if (binderOrg.imageId !== "") {
      resolve({ imageId: binderOrg.imageId });
      return;
    }
    //search for image we can use
    if (binderOrg.items.length > 0) {
      var x = 0;
      if (binderOrg.items[x].image !== null) {
        if (binderOrg.items[x].image.byteLength > 1) {
          //found an image
          var imageId = uuidv4();

          var hash = CrytpoLib.MD5(binderOrg.items[x].image);
          var addheader = {
            DocumentChunkSize: binderOrg.items[x].image.byteLength,
            DocumentHash: hash,
            ChunkHash: hash,
            customerId: binderOrg.customerId,
            'Content-Type': 'application/json',
          }

          var Data = {
            data: CrytpoLib.arrayBufferToBase64String(binderOrg.items[x].image)
          }

          const requestOptions = {
            method: 'POST',
            headers: authHeader(addheader),
            body: JSON.stringify(Data)
          };
          fetch(GetURL() + 'Document/' + imageId, requestOptions)
            .then((response) => {
              if (!response.ok) {
                if (response.status === 401) {
                  LogoutAndRedirect();
                }
                reject(errorReport("607", response.status, imageId, {}, response));
                return;
              }
              if (response.status === 201 || response.status === 200) {
                sendPatch = true;
                resolve({ imageId: imageId });
              } else reject(errorReport("608", response.status, imageId, {}, response));
            })
            .catch((error) => {
              reject(errorReport("609", '', imageId, {}, error));
            });
          return;
        }
      }
    }
    resolve({ imageId: '' });
  })
    .then((data) => {
      if (data.imageId !== '') {
        binderOrg.imageId = data.imageId;
      }
      if (!binderOrg.hasOwnProperty('attendees')) return ([]);
      if (binderOrg.attendees.length === 0 || binder_report.worker) {
        //No attendees
        return ([]);
      }

      var promisearry = [];
      for (var x = 0; x < binderOrg.attendees.length; x++) {
        var item = binderOrg.attendees[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null && item.settings !== undefined) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new attendee
              var updateitem = {
                userId: item.userId,
                boardId: binderOrg.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve({
                    id: newId.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport("610", '', item.userId, item, error))
                });
            } else {
              //update attendee
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: binderOrg.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve({
                    id: item.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport("611", '', item.userId, item, error))
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((attendeeIds) => {
      if (attendeeIds.length !== 0) {
        binderOrg.attendeeIds = binderOrg.attendeeIds.concat(attendeeIds);
        sendPatch = true;
      }
      if (!binderOrg.hasOwnProperty('recipients')) return ([]);
      if (binderOrg.recipients.length === 0 || binder_report.worker) {
        //No recipients
        return ([]);
      }
      var promisearry = [];
      for (var x = 0; x < binderOrg.recipients.length; x++) {
        var item = binderOrg.recipients[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null && item.settings !== undefined) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new recipients
              var updateitem = {
                userId: item.userId,
                boardId: binderOrg.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;
              if (item.isArchiveViewer !== undefined && binder_report.binderStatus === BinderStatus.previous)
                updateitem.isArchiveViewer = item.isArchiveViewer;

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve({
                    id: newId.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport("612", '', item.userId, item, error))
                });
            } else {
              //update recipients
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: binderOrg.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;
              if (item.isArchiveViewer !== undefined && binder_report.binderStatus === BinderStatus.previous)
                updateitem.isArchiveViewer = item.isArchiveViewer;

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve({
                    id: item.id,
                    isUnavailableToUser: item.isUnavailableToUser,
                    userId: item.userId
                  });
                })
                .catch(function (error) {
                  reject(errorReport("613", '', item.userId, item, error))
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((recipientIds) => {
      if (recipientIds.length !== 0) {
        binderOrg.recipientIds = binderOrg.recipientIds.concat(recipientIds);
        sendPatch = true;
      }
      if (binderOrg.items.length < 1 || binderOrg.worker) {
        return [];
      }

      //Process any items that need to be uploaded
      var newUserData = [];
      for (var x = 0; x < binderOrg.items.length; x++) {
        var binderItems = binderOrg.items[x];
        var hasGenKey = binderItems.userItems.find(o => o.userId === BLANK_GUID)

        var newUserItems = [];
        if (binderItems.binderType === BinderItemType.header) {
          binderItems.userItems.forEach(function (uItem) {
            if (uItem.id === undefined)
              newUserItems.push(uItem);
          })
          //check to see if we have a missing admin userItem
          if (hasGenKey === undefined) {
            binder_report.adminUsers.forEach((userId) => {
              var f = binderItems.userItems.find(o => o.userId === userId)
              if (f) return
              newUserItems.push({
                userId: userId,
                documentId: '',
                size: 0,
                key: '',
                blank: false,
                enabled: true,
                locked: false,
                viewArchived: true,
                isChanged: true,
                isDeleted: false
              })
            })
            if (!binderItems.userItems.find(o => o.userId === BLANK_GUID))
              newUserItems.push({
                userId: BLANK_GUID,
                documentId: '',
                size: 0,
                key: '',
                blank: false,
                enabled: true,
                locked: false,
                viewArchived: true,
                isChanged: true,
                isDeleted: false
              })
          }
        } else {
          if (hasGenKey !== undefined && (hasGenKey.documentId === "" || hasGenKey.key === "" || hasGenKey.size === 0 ||
            hasGenKey.documentId === undefined || hasGenKey.key === undefined || hasGenKey.size === undefined)) hasGenKey = undefined
          if (binderItems.documentId === "" || binderItems.key === "" || binderItems.size === 0 ||
            binderItems.documentId === undefined || binderItems.key === undefined || binderItems.size === undefined) {
            if (hasGenKey === undefined)
              continue
          }

          for (var y = 0; y < binderItems.userItems.length; y++) {
            var userItem = binderItems.userItems[y];
            if (!userItem.enabled) continue;

            var needResultKey = false;
            if (userItem.resultKey !== undefined && userItem.resultKey === "AA==")
              needResultKey = true;

            if (userItem.documentId !== "" && userItem.key !== "" && userItem.size !== 0 &&
              userItem.documentId !== undefined && userItem.key !== undefined && userItem.size !== undefined) {
              if (!needResultKey)
                continue;
              else if (userItem.resultKey !== undefined && userItem.resultKey !== "AA==")
                continue;
            }

            //Are we an Admin?
            var update = false;
            if (binderOrg.adminUsers.includes(userItem.userId)) {
              //Do keys
              update = true;
            } else {
              //If user only update when current, previous
              if (binderOrg.binderStatus === BinderStatus.current || binderOrg.binderStatus === BinderStatus.previous) update = true;
            }

            if (update) {
              var k = binderOrg.keyList.find(o => o.userId === userItem.userId);
              if (k) {
                //if(needResultKey) userItem.resultKey = "";
                newUserItems.push(userItem);
              }
            }
          }
          //check to see if we have a missing admin userItem
          if (hasGenKey === undefined) {
            /*ADD Missing admin userItems
            binder_report.adminUsers.forEach((userId)=>{
              var f = binderItems.userItems.find(o => o.userId === userId)
              var k = binderOrg.keyList.find(o => o.userId === userId)
              if(f) return
              if(k)
                newUserItems.push({
                  userId: userId,
                  documentId: '',
                  size: 0,
                  key: '',
                  blank: false,
                  enabled: true,
                  locked: false,
                  viewArchived: true,
                  change: true,
                })
            })*/
            if (!binderItems.userItems.find(o => o.userId === BLANK_GUID))
              newUserItems.push({
                userId: BLANK_GUID,
                documentId: '',
                size: 0,
                key: '',
                blank: false,
                enabled: true,
                locked: false,
                viewArchived: true,
                isChanged: true,
                isDeleted: false,
              })
          }
        }

        if (newUserItems.length) {
          var type = binderItems
          if (hasGenKey !== undefined) type = hasGenKey

          newUserData.push({
            id: binderItems.id,
            documentId: type.documentId,
            key: type.key,
            hasGenKey: hasGenKey !== undefined || binderItems.genseckey ? true : false,
            size: type.size,
            binderType: binderItems.binderType,
            userItems: newUserItems,
          })
        }
      }

      if (newUserData.length === 0) return [];

      //Decrypt Item Keys
      var promisearry = [];
      newUserData.forEach((item) => {
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (item.binderType === BinderItemType.header) {
              resolve(item)
              return;
            }

            var key = binderOrg.kUser
            if (item.hasGenKey) key = binderOrg.pUserGenSec

            //get AES Key
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.key))
              .then(function (aesKey) {
                if (item.binderType === BinderItemType.vote) {
                  const requestOptions = {
                    method: 'GET',
                    headers: authHeader({
                      'Content-Type': 'application/json',
                      'DocumentChunkSize': 3 * 1024 * 1024
                    })
                  };

                  return fetch(GetURL() + 'Document/' + item.documentId + "/0", requestOptions)
                    .then((response) => {
                      if (!response.ok) {
                        if (response.status === 404) {
                          var url = response.url.replace(GetURL(), "");
                          return Promise.reject(
                            errorReport("604", response.statusText, item.documentId, item, response)
                          )
                        }
                        if (response.status === 401) {
                          return Promise.reject(
                            errorReport("630", response.status, item.documentId, item, response)
                          );
                        }
                        return response.text().then(data => {
                          try {
                            var j = JSON.parse(data.toLowerCase());
                            if (j.hasOwnProperty('code')) return Promise.reject(j);
                          } catch (err) {

                          }

                          return Promise.reject(
                            errorReport("605", response.statusText, item.documentId, item, response)
                          )
                        });

                      } else if (response.status === 204) {
                        return {};
                      } else return response.json();
                    })
                    .then((json) => {
                      if (json.data === undefined)
                        return Promise.reject(
                          errorReport("606", "Failed to download data", item.documentId, item, "bad json")
                        );
                      CrytpoLib.AESDecrypt(aesKey, CrytpoLib.base64StringToArrayBuffer(json.data))
                        .then(function (decryptedData) {
                          item.aesKey = aesKey;
                          try {
                            item.itemData = JSON.parse(CrytpoLib.arrayBufferToText(decryptedData));
                          } catch (e) {
                            reject(
                              errorReport("603", 'Json conversion error', "", item, e)
                            )
                          }
                          resolve(item)
                        })
                        .catch(function (e) {
                          reject(
                            errorReport("602", 'Decryption error', "", item, e)
                          );
                        });
                    })
                    .catch(handleCatch);
                } else {
                  item.aesKey = aesKey;
                  resolve(item)
                }
              })
              .catch(function (e) {
                reject(
                  errorReport("601", 'Decryption error', item.itemId, item, e)
                );
              });
          })
        )
      })

      return Promise.all(promisearry);
    })
    .then((binderitems) => {
      if (binderitems.length === 0) return [];

      //Encrypt Item Keys
      var promisearray = [];
      binderitems.forEach(function (item) {
        promisearray.push(
          new Promise(function (resolveItem, rejectItem) {
            if (item.binderType === BinderItemType.header) {
              resolveItem(item)
              return;
            }
            var promiseUserItem = [];
            item.userItems.forEach(function (userItem) {
              promiseUserItem.push(
                new Promise(function (resolveUser, rejectUser) {
                  var cItem = item;
                  var k = binderOrg.keyList.find(o => o.userId === userItem.userId);
                  if (k === undefined) {
                    rejectUser({ code: "614", msg: "User Keys are missing", objectId: userItem.userId, item: cItem })
                    return
                  }

                  if (cItem.binderType === BinderItemType.vote) {
                    if (userItem.voteId !== undefined) {
                      if (userItem.voteId === '') {
                        userItem.voteId = uuidv4();
                      }
                    } else userItem.voteId = uuidv4();

                    var jsonObj = Object.assign({}, cItem.itemData);
                    jsonObj.id = userItem.voteId;
                    var jsonString = JSON.stringify(jsonObj);
                    var Uint8View = CrytpoLib.textToArrayBuffer(jsonString);

                    //Create new DocumentId
                    var documentId = uuidv4();

                    //AES Encrypt vote
                    var aeskey = CrytpoLib.GenerateRandom(32);
                    CrytpoLib.AESEncrypt(aeskey, Uint8View)
                      .then(function (encryptedData) {
                        var hash = CrytpoLib.MD5(encryptedData);
                        var uploadData = {
                          data: CrytpoLib.arrayBufferToBase64String(encryptedData)
                        }
                        const requestOptions = {
                          method: 'POST',
                          headers: authHeader({
                            'Content-Type': 'application/json',
                            "DocumentChunkSize": 3 * 1024 * 1024,
                            "DocumentHash": hash,
                            "ChunkHash": hash,
                            "IsLastChunk": true,
                            "customerId": binderOrg.customerId,
                          }),
                          body: JSON.stringify(uploadData)
                        };
                        fetch(GetURL() + 'Document/' + documentId + "/0", requestOptions)
                          .then((response) => {
                            if (!response.ok) {
                              if (response.status === 409 || response.status === 400) {
                                return response.json();
                              }
                              rejectUser({ code: "615", msg: response.statusText, objectId: userItem.userId, item: cItem })
                              return
                            }
                            return {};
                          })
                          .then((jData) => {
                            if (jData.hasOwnProperty('Code') || jData.hasOwnProperty('code')) {
                              rejectUser({ code: "616", msg: jData, objectId: userItem.userId, item: cItem })
                              return
                            }
                            CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
                              .then(function (iUserKey) {
                                CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
                                  .then(function (KAESEncpyt) {
                                    userItem.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt);
                                    userItem.documentId = documentId;
                                    userItem.size = encryptedData.byteLength;
                                    userItem.isChanged = true;

                                    //only do resultKey if admin user is an attendee or recipient
                                    if (userItem.resultKey === "AA==") {
                                      CrytpoLib.importPublicKey(binderOrg.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                                        .then(function (iGenKey) {
                                          CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iGenKey, aeskey)
                                            .then(function (KAESEncpytGen) {
                                              userItem.resultKey = CrytpoLib.arrayBufferToBase64String(KAESEncpytGen);
                                              resolveUser(userItem);
                                            })
                                            .catch(function (error) {
                                              rejectUser({ code: "617", msg: error, objectId: userItem.userId, item: cItem })
                                            })
                                        })
                                        .catch(function (error) {
                                          rejectUser({ code: "618", msg: error, objectId: userItem.userId, item: cItem })
                                        });
                                    } else {
                                      resolveUser(userItem);
                                    }
                                  })
                                  .catch(function (error) {
                                    rejectUser({ code: "619", msg: error, objectId: userItem.userId, item: cItem })
                                  })
                              })
                              .catch(function (error) {
                                rejectUser({ code: "620", msg: error, objectId: userItem.userId, item: cItem })
                              });
                          })
                          .catch((e) => {
                            rejectUser({ code: "621", msg: error, objectId: userItem.userId, item: cItem })
                          })
                      }).
                      catch((error) => {
                        rejectUser({ code: "622", msg: error, objectId: userItem.userId, item: cItem })
                      });
                  } else {
                    CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
                      .then(function (iUserKey) {
                        CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, cItem.aesKey)
                          .then(function (KAESEncpyt) {
                            userItem.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt);
                            userItem.documentId = cItem.documentId;
                            userItem.size = cItem.size;
                            userItem.isChanged = true

                            //only do resultKey if admin user is an attendee or recipient
                            if (cItem.binderType === BinderItemType.resolution && userItem.resultKey === "AA==") {
                              CrytpoLib.importPublicKey(binderOrg.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                                .then(function (iGenKey) {
                                  CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iGenKey, cItem.aesKey)
                                    .then(function (KAESEncpytGen) {
                                      userItem.resultKey = CrytpoLib.arrayBufferToBase64String(KAESEncpytGen);
                                      resolveUser(userItem);
                                    })
                                    .catch(function (error) {
                                      rejectUser({ code: "623", msg: error, objectId: userItem.userId, item: cItem })
                                    })
                                })
                                .catch(function (error) {
                                  rejectUser({ code: "624", msg: error, objectId: userItem.userId, item: cItem })
                                });
                            } else
                              resolveUser(userItem);
                          })
                          .catch(function (error) {
                            rejectUser({ code: "625", msg: error, objectId: userItem.userId, item: cItem })
                          })
                      })
                      .catch(function (error) {
                        rejectUser({ code: "626", msg: error, objectId: userItem.userId, item: cItem })
                      });
                  }
                })
              )
            })

            Promise.all(promiseUserItem)
              .then((userItems) => {
                item.userItems = userItems;
                resolveItem(item);
              })
              .catch((e) => {
                if (e.code !== undefined && e.msg !== undefined)
                  rejectItem(errorReport(e.code, '', e.objectId, e.item, e.msg))
                else
                  rejectItem(errorReport('674', '', '', item, e))
              })
          })
        )
      })

      return Promise.all(promisearray);
    })
    .then((items) => {
      if (items.length === 0) return;

      function checkUserItem(userItem) {
        var cItem = {}
        if (userItem.id !== undefined && userItem.id !== "") cItem.id = userItem.id
        if (userItem.userId !== undefined) cItem.userId = userItem.userId
        if (userItem.voteId !== undefined) cItem.voteId = userItem.voteId
        if (userItem.documentId !== undefined && userItem.documentId !== "") cItem.documentId = userItem.documentId
        if (userItem.resolutionId !== undefined) cItem.resolutionId = userItem.resolutionId
        if (userItem.size !== undefined && userItem.size !== 0) cItem.size = userItem.size
        if (userItem.key !== undefined && userItem.key !== "") cItem.key = userItem.key
        if (userItem.enabled !== undefined) cItem.enabled = userItem.enabled
        if (userItem.locked !== undefined) cItem.locked = userItem.locked
        if (userItem.viewArchived !== undefined) cItem.viewArchived = userItem.viewArchived
        if (userItem.resultKey !== undefined) cItem.resultKey = userItem.resultKey
        if (userItem.isChanged !== undefined) cItem.isChanged = userItem.isChanged
        if (userItem.isDeleted !== undefined) cItem.isDeleted = userItem.isDeleted

        return cItem
      }

      if (binderOrg.binderChange) {
        items.forEach(function (item) {
          if(item.id === "") return
          var f = binderOrg.items.find(o => o.id === item.id);
          if (f) {
            item.userItems.forEach((userItem) => {
              f.isChanged = true;
              var cItem = f.userItems.find(u => u.userId === userItem.userId);
              if (cItem !== undefined) {
                if (userItem.id !== undefined && userItem.id !== "") cItem.id = userItem.id;
                if (userItem.userId !== undefined) cItem.userId = userItem.userId;
                if (userItem.voteId !== undefined) cItem.voteId = userItem.voteId;
                if (userItem.documentId !== undefined && userItem.documentId !== "") cItem.documentId = userItem.documentId;
                if (userItem.resolutionId !== undefined) cItem.resolutionId = userItem.resolutionId;
                if (userItem.size !== undefined && userItem.size !== 0) cItem.size = userItem.size;
                if (userItem.key !== undefined && userItem.key !== "") cItem.key = userItem.key;
                if (userItem.enabled !== undefined) cItem.enabled = userItem.enabled;
                if (userItem.locked !== undefined) cItem.locked = userItem.locked;
                if (userItem.viewArchived !== undefined) cItem.viewArchived = userItem.viewArchived;
                if (userItem.resultKey !== undefined) cItem.resultKey = userItem.resultKey;
                if (userItem.isChanged !== undefined) cItem.isChanged = userItem.isChanged
                if (userItem.isDeleted !== undefined) cItem.isDeleted = userItem.isDeleted
              } else {
                f.userItems.push(userItem);
              }
            })
          }
        })
        return;
      }

      var uploadData = {}
      items.forEach(function (item) {
        uploadData[item.id] = []
        item.userItems.forEach((userItem) => {
          uploadData[item.id].push(checkUserItem(userItem))
        })
      })
      binderOrg.items.forEach((item) => {
        item.userItems.forEach((userItem) => {
          if (userItem.id !== "") return
          var f = uploadData[item.id].find(o => o.userId === userItem.userId)
          if (!f) {
            uploadData[item.id].push(checkUserItem(userItem))
          }
        })
      })

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(uploadData)
      };
      return new Promise(function (resolve, reject) {
        fetch(GetURL() + 'Items/UserItems', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 409 || response.status === 400) {
                return response.json();
              }
              return reject(errorReport('627', response.statusText, '', uploadData, ''));
            }
            return {};
          })
          .then((jData) => {
            if (jData.hasOwnProperty('Code') || jData.hasOwnProperty('code'))
              return reject(errorReport('628', jData, '', uploadData, jData));
            resolve();
          })
          .catch(handleCatch)
      });
    })
    .then(() => {
      //if(items.length !== 0){
      //  binderOrg.items = binderOrg.items.concat(recipientIds);
      //}
      if (!binderOrg.hasOwnProperty('invitees')) return ([]);
      if (binderOrg.invitees.length === 0) {
        //No invitees
        return ([]);
      }
      //loop through all invitees
      var promisearry = [];
      for (var x = 0; x < binderOrg.invitees.length; x++) {
        var name = binderOrg.invitees[x].name;
        var id = binderOrg.invitees[x].id;
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (id !== '') {
              resolve(id);
              return;
            }
            let [first, ...second] = name.split(" ")
            second = second.join(" ")

            var updateitem = {
              boardId: binderOrg.boardId,
              firstName: first,
              lastName: second,
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'People/Invitee', requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    LogoutAndRedirect();
                  }
                  reject(errorReport('629', '', '', updateitem, response));
                }
                return response.json();
              })
              .then((newInviteeId) => {
                resolve(newInviteeId.id);
              })
              .catch(function (error) {
                reject(errorReport('630', '', '', updateitem, error));
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      if (binder_report.confirmMeetingDate === undefined || !binder_report.confirmMeetingDate)
        return data
      if (binder_report.calendarId !== undefined && binder_report.calendarId !== "")
        return data
      if (binder_report.boardCalendarId === undefined || binder_report.boardCalendarId === "")
        return data

      return new Promise(function (resolve, reject) {

        var calItem = {
          binderId: binderOrg.id,
          calendarId: binder_report.boardCalendarId,
          //startTime: binder_report.meetingDate,
          duration: binder_report.meetingDuration,
          description: binder_report.name,
        }

        if (binder_report.calendarId !== "") calItem.id = binder_report.calendarId

        var url = GetURL() + 'Calendar'
        var requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(calItem)
        };
        if (binder_report.calendarId !== "" && binder_report.calendarId !== undefined) {
          requestOptions.method = 'PATCH'
          url += '/' + binder_report.calendarId
        }//else{
        //  calItem.startTime = binder_report.meetingDate
        //}

        return fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              reject(errorReport('631', response.status, '', calItem, response));
              return;
            }

            if (binder_report.calendarId !== "") return resolve(data)
            return response.json()
          })
          .then((calendarId) => {
            data.calendarId = calendarId
            resolve(data)
          })
          .catch((error) => {
            reject(errorReport('632', '', '', calItem, error));
          });
      })
    })
    .then(data => {
      var inviteesIds = null;
      if (data.length !== 0) {
        inviteesIds = [];
        for (var x = 0; x < data.length; x++)
          inviteesIds.push(data[x]);
        sendPatch = true;
      }

      if (!binderOrg.binderChange && !sendPatch) {
        return {};
      }

      return new Promise(function (resolve, reject) {
        var updateitem = {
          id: binderOrg.id,
          name: binderOrg.name,
          boardId: binderOrg.boardId,
          meetingDate: binderOrg.meetingDate,
          locationName: binderOrg.locationName,
          deleteNotesAfterDays: binderOrg.deleteNotesAfterDays,
          deleteAnnotationsAfterDays: binderOrg.deleteAnnotationsAfterDays,
        }

        if (binderOrg.deleteNotesAfterMeeting !== undefined)
          updateitem.deleteNotesAfterMeeting = binderOrg.deleteNotesAfterMeeting
        if (binderOrg.deleteAnnotationsAfterMeeting !== undefined)
          updateitem.deleteAnnotationsAfterMeeting = binderOrg.deleteAnnotationsAfterMeeting

        let params = ""

        if (binderOrg.expiryDate !== null && binderOrg.expiryDate !== "") {
          updateitem.expiryDate = binderOrg.expiryDate;
        } else {
          updateitem.expirydate = moment(commonConstants.SET_NULL_DATE);
        }

        if (inviteesIds !== null)
          updateitem.inviteeIds = inviteesIds;

        if (binderOrg.settingsDelete === true) {
          params = "?deleteField=settings"
        }
        if (binderOrg.hasOwnProperty('settings')) {
          if (binderOrg.settings !== null) {
            var newSettings = {};
            Object.keys(binderOrg.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = binderOrg.settings[e] });

            updateitem.settings = newSettings;
          }
        }

        //If binder worker is going to be executed then set the binderStatus inside there
        if (!binderOrg.worker) {
          updateitem.binderStatus = binderOrg.binderStatus;
          if (binderOrg.hasOwnProperty('recipientIds')) {
            var recipientIds = []
            binderOrg.recipientIds.forEach(e => {
              if(e.id){
                recipientIds.push(e.id)
              }
            })
            if(recipientIds.length > 0){
              updateitem.recipientIds = recipientIds;
            }
          }
          if (binderOrg.hasOwnProperty('attendeeIds')) {
            var attendeeIds = []
            binderOrg.attendeeIds.forEach(e => {
              if(e.id){
                attendeeIds.push(e.id)
              }
            })
            if(attendeeIds.length > 0){
              updateitem.attendeeIds = attendeeIds;
            }
          }

          if (binderOrg.hasOwnProperty(BinderStatus.published)) {
            updateitem.published = binderOrg.published;
            updateitem.modifiedName = "";
            updateitem.modifiedThumbnailImageDownloadId = BLANK_GUID;
            updateitem.modifiedMeetingDate = commonConstants.SET_NULL_DATE;
            updateitem.modifiedItemCount = 0;

            // if (params === "") params += '?deleteField=modifiedItemCount'
            // else params += '&deleteField=modifiedItemCount'

            if (params === "") params += '?commitTransactions=true'
            else params += '&commitTransactions=true'

            // if(binderOrg.commitTransactionIds !== undefined && binderOrg.commitTransactionIds.length > 0)
              // updateitem.commitTransactionIds = binderOrg.commitTransactionIds
          }
          if (binderOrg.hasOwnProperty('updateDate'))
            updateitem.updateDate = binderOrg.updateDate;

          updateitem = addItems(updateitem, binderOrg, sendPatch)
        }

        /*if(binderOrg.hasOwnProperty(BinderStatus.published)){
          if(binderOrg.published && binderOrg.items.length === 0){
            updateitem.published = true;
          }else if(binderOrg.published && binderOrg.items.length > 0 && binderOrg.worker){
            //we set the publish in worker task
            updateitem.published = false;
          }else if(!binderOrg.published || binderOrg.items.length === 0 || !binderOrg.worker){
            updateitem.published = binderOrg.published;
          }
          if(binderOrg.hasOwnProperty('updateDate') && binderOrg.published && binderOrg.items.length === 0)
            updateitem.updateDate = binderOrg.updateDate;
          else if(binderOrg.hasOwnProperty('updateDate') && binderOrg.published && !binderOrg.worker)
            updateitem.updateDate = binderOrg.updateDate;
        }else if(binderOrg.hasOwnProperty('updateDate'))
          updateitem.updateDate = binderOrg.updateDate;*/
        if (binderOrg.hasOwnProperty('imageId'))
          if (binderOrg.imageId !== '') {
            updateitem.thumbnailImageDownloadId = binderOrg.imageId;
            if (!binderOrg.modifiedThumbnailImageDownloadId) {
              updateitem.modifiedThumbnailImageDownloadId = BLANK_GUID;
            }
          }

        //?minorChange=true/false
        if (binderOrg.notification !== undefined && binderOrg.items.length > 0 && !binderOrg.worker) {
          if (!binderOrg.notification) {
            if (params === "") params += '?sendNotification=false'
            else params += '&sendNotification=false'
          } else
            updateitem.notifications = binderOrg.notification
        }

        if (!binderOrg.binderChange) {
          if (params === "") params += '?minorChange=false'
          else params += '&minorChange=false'
        }

        let headers = { 'Content-Type': 'application/json' }, ignore202 = false

        if (binderOrg.items.length > 0 && !binderOrg.worker) {
          if (params === "") params += '?notifyOnComplete=true&deleteLock=true'
          else params += '&notifyOnComplete=true&deleteLock=true'
          headers['AsyncWait'] = 10
          ignore202 = true
        }

        var urllink = GetURL() + 'Binders/' + binderOrg.id + params;
        const requestOptions = {
          method: 'PATCH',
          headers: authHeader(headers),
          body: JSON.stringify(updateitem),
          ignore202: ignore202
        };
        fetch(urllink, requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                LogoutAndRedirect();
              }
              reject(errorReport('633', response.status, '', updateitem, response));
            }

            if (binderOrg.imageId !== "")
              resolve({ imageId: binderOrg.imageId });
            else resolve({});
          })
          .catch(function (error) {
            reject(errorReport('634', '', '', updateitem, error));
          });
      });
    })
    .catch(handleCodeCatch);
}

function newBinder(binder_report) {
  var binderItem = Object.assign({}, binder_report);

  function errorReport(code, msg, objectId, item, e) {
    return new CodeError("updateBinder", code, msg, {
      binderId: binder_report.id,
      objectId: objectId,
      binder: binder_report,
      error: e,
      item
    }, e)
  }

  return new Promise(function (resolve, reject) {
    //search for image we can use
    // for (var x = 0; x < binderItem.items.length; x++) {
    //   if (binderItem.items[x].binderType !== BinderItemType.resolution &&
    //     binderItem.items[x].binderType !== BinderItemType.minutes &&
    //     binderItem.items[x].binderType !== BinderItemType.document) continue;

    //   if (binderItem.items[x].image !== null) {
    //     if (binderItem.items[x].image.byteLength > 1) {
    //       //found an image
    //       var imageId = uuidv4();

    //       var hash = CrytpoLib.MD5(binderItem.items[x].image);
    //       var addheader = {
    //         DocumentChunkSize: binderItem.items[x].image.byteLength,
    //         DocumentHash: hash,
    //         ChunkHash: hash,
    //         customerId: binderItem.customerId,
    //         'Content-Type': 'application/json',
    //       }

    //       var Data = {
    //         data: CrytpoLib.arrayBufferToBase64String(binderItem.items[x].image)
    //       }

    //       const requestOptions = {
    //         method: 'POST',
    //         headers: authHeader(addheader),
    //         body: JSON.stringify(Data)
    //       };
    //       fetch(GetURL() + 'Document/' + imageId, requestOptions)
    //         .then((response) => {
    //           if (!response.ok) {
    //             if (response.status === 401) {
    //               LogoutAndRedirect();
    //             }
    //             reject(errorReport("635", response.status, imageId, {}, response));
    //             return;
    //           }
    //           if (response.status === 201 || response.status === 200) {
    //             resolve({ imageId: imageId });
    //           } else
    //             reject(errorReport("636", response.status, imageId, {}, response));
    //         })
    //         .catch(function (error) {
    //           reject(errorReport("637", '', imageId, {}, error));
    //         });
    //       return;
    //     }
    //   }
    //   if (binderItem.items[x].documentId !== '') break;
    // }
    resolve({ imageId: binder_report.imageId || '' });
  })
    .then((data) => {
      if (data.imageId !== '')
        binderItem.imageId = data.imageId;
      if (binderItem.attendees.length === 0 || binderItem.worker) {
        //No attendees
        return ([]);
      }

      var promisearry = [];
      for (var x = 0; x < binderItem.attendees.length; x++) {
        var item = binderItem.attendees[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new attendee
              var updateitem = {
                userId: item.userId,
                boardId: binderItem.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve(newId.id);
                })
                .catch(function (error) {
                  reject(errorReport("638", 'People/Attendee', '', updateitem, error));
                });
            } else {
              //update attendee
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: binderItem.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Attendee/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve(item.id);
                })
                .catch(function (error) {
                  reject(errorReport("639", 'People/Attendee', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((attendeeIds) => {
      if (attendeeIds.length !== 0) {
        binderItem.attendeeIds = binderItem.attendeeIds.concat(attendeeIds);
      }
      if (binderItem.recipients.length === 0 || binderItem.worker) {
        //No recipients
        return ([]);
      }
      var promisearry = [];
      for (var x = 0; x < binderItem.recipients.length; x++) {
        var item = binderItem.recipients[x];
        promisearry.push(
          new Promise(function (resolve, reject) {
            var newSettings = null;
            if (item.settings !== null) {
              newSettings = {};
              Object.keys(item.settings).map(e => { if (e !== 'loading' && e !== 'error' && e !== 'id') newSettings[e] = item.settings[e] });
            }

            if (item.id === '') {
              //new recipients
              var updateitem = {
                userId: item.userId,
                boardId: binderItem.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient', requestOptions)
                .then(handleJsonResponse)
                .then(newId => {
                  resolve(newId.id);
                })
                .catch(function (error) {
                  reject(errorReport("640", 'People/Recipient', '', updateitem, error));
                });
            } else {
              //update recipients
              var updateitem = {
                id: item.id,
                userId: item.userId,
                boardId: binderItem.boardId,
                settings: newSettings,
              };

              if (item.isUnavailableToUser !== undefined)
                updateitem.isUnavailableToUser = item.isUnavailableToUser;

              const requestOptions = {
                method: 'PUT',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'People/Recipient/' + item.id, requestOptions)
                .then(handleStandResponse)
                .then(() => {
                  resolve(item.id);
                })
                .catch(function (error) {
                  reject(errorReport("641", 'People/Recipient', item.id, updateitem, error));
                });
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then((recipientIds) => {
      if (recipientIds.length !== 0) {
        binderItem.recipientIds = binderItem.recipientIds.concat(recipientIds);
      }

      if (binderItem.invitees.length === 0) {
        //No invitees
        return ([]);
      }
      //loop through all invitees
      var promisearry = [];
      for (var x = 0; x < binderItem.invitees.length; x++) {
        var name = binderItem.invitees[x].name;
        var id = binderItem.invitees[x].id;
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (id !== '') {
              resolve(id);
              return;
            }
            let [first, ...second] = name.split(" ")
            second = second.join(" ")

            var updateitem = {
              boardId: binderItem.boardId,
              firstName: first,
              lastName: second,
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'People/Invitee', requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    LogoutAndRedirect();
                  }
                  reject(errorReport("642", response.status, '', updateitem, response));
                }
                return response.json();
              })
              .then((newInviteeId) => {
                resolve(newInviteeId.id);
              })
              .catch(function (error) {
                reject(errorReport("643", 'People/Invitee', '', updateitem, error));
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      var inviteesIds = [];
      if (data.length > 0)
        for (var x = 0; x < data.length; x++)
          inviteesIds.push(data[x]);

      return new Promise(function (resolve, reject) {
        var updateitem = {
          name: binderItem.name,
          boardId: binderItem.boardId,
          creationDate: binderItem.creationDate,
          updateDate: binderItem.creationDate,
          itemIds: [],
          settings: null,
          countryBlacklist: [],
          thumbnailImageDownloadId: null,
          usageIds: [],
          binderStatus: binderItem.worker ? BinderStatus.unpublished : binderItem.binderStatus,
          inviteeIds: inviteesIds,
          meetingDate: binderItem.meetingDate,
          locationName: binderItem.locationName,
          LocationLattitude: null,
          LocationLongitude: null,
          deleteNotesAfterDays: binderItem.deleteNotesAfterDays,
          deleteAnnotationsAfterDays: binderItem.deleteAnnotationsAfterDays,
        }

        if (binderItem.deleteNotesAfterMeeting !== undefined)
          updateitem.deleteNotesAfterMeeting = binderItem.deleteNotesAfterMeeting
        if (binderItem.deleteAnnotationsAfterMeeting !== undefined)
          updateitem.deleteAnnotationsAfterMeeting = binderItem.deleteAnnotationsAfterMeeting

        if (binderItem.expiryDate !== null && binderItem.expiryDate !== "")
          updateitem.expiryDate = binderItem.expiryDate;
        if (binderItem.hasOwnProperty('imageId'))
          if (binderItem.imageId !== '')
            updateitem.thumbnailImageDownloadId = binderItem.imageId;

        if (!binderItem.worker) {
          updateitem.attendeeIds = binderItem.attendeeIds;
          updateitem.recipientIds = binderItem.recipientIds;
          updateitem.updateDate = binderItem.updateDate;

          if (binder_report.hasOwnProperty(BinderStatus.published)) {
            updateitem.published = binder_report.published;
          }

          updateitem = addItems(updateitem, binder_report, true)
        }

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
        };

        console.log("updateitem", updateitem);
        fetch(GetURL() + 'Binders', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                LogoutAndRedirect();
              }
              reject(errorReport("644", response.status, '', updateitem, response));
              reject();
            }
            return response.json();
          })
          .then((newBinderId) => {
            resolve({ binderId: newBinderId.id, imageId: binderItem.imageId });
          })
          .catch(function (error) {
            reject(errorReport("645", 'Binders Post', '', updateitem, error));
          });
      });
    })
    .then(data => {
      if (binderItem.confirmMeetingDate === undefined || !binderItem.confirmMeetingDate)
        return data
      if (binderItem.calendarId !== undefined && binderItem.calendarId !== "")
        return data
      if (binderItem.boardCalendarId === undefined || binderItem.boardCalendarId === "")
        return data

      return new Promise(function (resolve, reject) {

        var calItem = {
          binderId: data.binderId,
          calendarId: binderItem.boardCalendarId,
          //startTime: binder_report.meetingDate,
          duration: binderItem.meetingDuration,
          description: binderItem.name,
        }

        var url = GetURL() + 'Calendar'

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(calItem)
        };

        return fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              reject(errorReport('631', response.status, '', calItem, response));
              return;
            }

            if (binderItem.calendarId !== "") return resolve(data)
            return response.json()
          })
          .then((calendarId) => {
            data.calendarId = calendarId
            resolve(data)
          })
          .catch((error) => {
            reject(errorReport('632', '', '', calItem, error));
          });
      })
    })
    .catch(handleCodeCatch);
}

function commitPublish(transactionId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Transactions/' + transactionId+'/Commit', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getBinderItem(itemId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Items/' + itemId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBinderUsage(boardId, binderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let url = 'Usage?boardId=' + boardId + "&binderId=" + binderId
  if (window.demo) {
    url = 'Usage/' + binderId
  }

  return fetch(GetURL() + url, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function deleteBinderItem(itemId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Items/' + itemId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function downloadUserDocument(dataitem) {
  return new Promise(function (resolve, reject) {
    if (window.demo) return resolve({})
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    fetch(GetURL() + 'UserKeys/Impersonation/' + dataitem.targetUserId, requestOptions)
      .then(handleJsonResponse)
      .then(data => {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  })
    .then(data => {
      console.log("step 1", data, dataitem);
      if (window.demo) return ({})
      return new Promise(function (resolve, reject) {
        var listpaths = [];
        var userId = dataitem.targetUserId;
        for (var id of dataitem.myIds) {
          var detail = CheckImpersonationPath(data, id, userId, data.length);
          if (detail !== false) {
            for (var direction of detail) {
              listpaths.push(direction);
            }
          }
        }

        if (listpaths.length === 0) return;

        var lengths = listpaths.map(function (a) { return a.length; });
        var index = lengths.indexOf(Math.min.apply(Math, lengths));
        resolve(listpaths[index]);
      });
    })
    .then(data => {
      console.log("step 2", data);
      if (window.demo) return ({})
      //function going to decrypt the data
      function asyncFunc(e, last) {
        return new Promise((resolve, reject) => {
          var v_promise = null;
          if (last === undefined) {
            v_promise = new Promise((resolve, reject) => {
              resolve(dataitem.kUser);
            });
          } else {
            v_promise = new Promise((resolve, reject) => {
              CrytpoLib.importPrivateKey(last, CrytpoLib.defaultRSAAlgorithmMethod)
                .then(function (privatekey) {
                  resolve(privatekey);
                })
                .catch(function (error) {
                  reject("importrsakey " + error);
                });
            });
          }

          v_promise
            .then((key) => {
              //kImpersonatorAESKey = RSA(Your kUser, kImpersonatorWrap)
              CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(e.kImpersonatorWrap))
                .then(function (kImpersonatorAESKey) {
                  //Target KUser = AES(kImpersonatorWrap, Target KUser)
                  CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(e.kUser))
                    .then(function (TargetKUser) {
                      resolve(CrytpoLib.arrayBufferToText(TargetKUser));
                    })
                    .catch(function (error) {
                      reject("aesdecypt1 " + error);
                    })
                })
                .catch(function (error) {
                  reject("rsadecypt " + error);
                });
            });

        });
      }

      const arr = data;
      let final = [];

      function workMyCollection(arr) {
        return arr.reduce((promise, pditem) => {
          return promise
            .then((result) => {
              if (result === false) return "failed";
              return asyncFunc(pditem, result)
                .then(result => { final.push(result); return result; })
                .catch((error) => {
                  return false;
                });
            })
            .catch((error) => { return false; });
        }, Promise.resolve());
      }

      return new Promise((resolve, reject) => {
        workMyCollection(arr)
          .then((finalpem) => {
            resolve({
              kUserGenSec: final[0],
              kUserTarget: final[final.length - 1],
            });
            //resolve(finalpem);
          });
      });
    })
    .then(data => {
      console.log("step 3", data);
      if (window.demo) return ({})
      return new Promise((resolve, reject) => {
        CrytpoLib.importPrivateKey(data.kUserTarget, CrytpoLib.defaultRSAAlgorithmMethod)
          .then(function (key) {
            resolve(key);
            /*CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.password))
            .then(function(password) {
              resolve({
                kUserGenSec: data.kUserGenSec,
                password: password,
                kUserTarget: data.kUserTarget,
              });
            })
            .catch(function(error) {
              reject("rsadecypt "+error);
            });*/
          })
          .catch(function (error) {
            reject("importrsakeya " + error);
          });
      });
    })
    .then(key => {
      return new Promise((resolve, reject) => {
        const requestOptions = {
          method: 'GET',
          headers: authHeader()
        };

        return fetch(GetURL() + 'Results/Resolution/' + dataitem.resolutionId, requestOptions)
          .then(handleJsonResponse)
          .then((json) => {
            if (json.previousDocumentId !== dataitem.documentId) return Promise.reject("Incorrect Document Id");
            resolve({
              newDocumentId: json.documentId,
              newKey: key,
            })
          })
          .catch(function (error) {
            reject("unable to get new resolution Id " + error);
          });
      });
    })
    .catch(handleCatch);
}

function downloadAdminDocument(dataitem) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Results/Resolution/' + dataitem.resolutionId, requestOptions)
    .then(handleJsonResponse)
    .then(handleDataResponse)
    .then((json) => {
      if (json.previousDocumentId !== dataitem.documentId) return Promise.reject("Incorrect Document Id");

      return json.documentId
    })
    .catch(handleCatch);
}

function createAttendee(userId, boardId, isUnavailableToUser = false) {
  var updateitem = {
    userId: userId,
    boardId: boardId,
    settings: undefined,
  };

  if (isUnavailableToUser) { updateitem.isUnavailableToUser = isUnavailableToUser; }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(updateitem)
  };

  return fetch(GetURL() + 'People/Attendee', requestOptions)
    .then(handleJsonResponse)
    .then(newId => {
      return newId.id
    })
    .catch(function (error) {
      return;
    });
}
function createRecipient(userId, boardId, isUnavailableToUser = false) {
  var updateitem = {
    userId: userId,
    boardId: boardId,
    settings: undefined,
  };

  if (isUnavailableToUser) { updateitem.isUnavailableToUser = isUnavailableToUser; }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(updateitem)
  };

  return fetch(GetURL() + 'People/Recipient', requestOptions)
    .then(handleJsonResponse)
    .then(newId => {
      return newId.id;
    })
    .catch(function (error) {
      return;
    });
}

function getVote(voteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Results/Vote/' + voteId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getAllVotes(dataItems) {
  return new Promise(function (resolve, reject) {
    resolve();
  })
    .then(() => {
      console.log("step 1", dataItems);
      var promisearry = [];
      dataItems.userItems.forEach(function (itempass) {
        if (!itempass.hasOwnProperty('voteId')) return;
        promisearry.push(
          new Promise(function (resolve, reject) {
            var item = itempass;
            const requestOptions = {
              method: 'GET',
              headers: authHeader()
            };

            return fetch(GetURL() + 'Results/Vote/' + item.voteId, requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 404) {
                    return item;
                  }
                  if (response.status === 401) {
                    LogoutAndRedirect();
                  }
                  return Promise.reject(response.statusText);
                }
                return response.json();
              })
              .then((data) => {
                item.answer = data.answer;
                resolve(item);
              })
              .catch((e) => {
                reject(e);
              });
          })
        );
      })
      return Promise.all(promisearry);
    })
    .then((list) => {
      console.log("step 2", list);

      if (window.demo) return list
      //get Impersonation
      var promisearry = [];
      list.forEach(function (item) {
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (!item.hasOwnProperty('answer')) return resolve(item);
            if (item.answer === undefined || item.answer === "") return resolve(item);
            if (item.resultKey !== undefined) return resolve(item);
            const requestOptions = {
              method: 'GET',
              headers: authHeader(),
            };
            fetch(GetURL() + 'UserKeys/Impersonation/' + item.userId, requestOptions)
              .then(handleJsonResponse)
              .then(handleDataResponse)
              .then(data => {
                var listpaths = [];
                var userId = item.userId;
                for (var x in dataItems.myIds) {
                  var id = dataItems.myIds[x];
                  var detail = CheckImpersonationPath(data, id, userId, data.length);
                  if (detail !== false) {
                    for (var direction of detail) {
                      listpaths.push(direction);
                    }
                  }
                }

                if (listpaths.length === 0) return;

                var lengths = listpaths.map(function (a) { return a.length; });
                var index = lengths.indexOf(Math.min.apply(Math, lengths));
                item.impersonation = data;
                item.impersonationPath = listpaths[index];
                resolve(item);
              })
              .catch(function (error) {
                reject(error);
              });
          })
        );
      })
      return Promise.all(promisearry);
    })
    .then((list) => {
      console.log("step 3", list);
      if (window.demo) return list
      var promisearry = [];
      list.forEach(function (item) {
        promisearry.push(
          new Promise(function (mResolve, mReject) {
            if (!item.hasOwnProperty('answer')) return mResolve(item);
            if (item.answer === undefined || item.answer === "") return mResolve(item);
            if (item.resultKey !== undefined) return mResolve(item);
            if (!item.hasOwnProperty('impersonation') || !item.hasOwnProperty('impersonationPath')) return mResolve(item);
            //function going to decrypt the data
            function asyncFunc(e, last) {
              return new Promise((resolve, reject) => {
                var v_promise = null;
                if (last === undefined) {
                  v_promise = new Promise((resolve, reject) => {
                    resolve(dataItems.kUser);
                  });
                } else {
                  v_promise = new Promise((resolve, reject) => {
                    CrytpoLib.importPrivateKey(last, CrytpoLib.defaultRSAAlgorithmMethod)
                      .then(function (privatekey) {
                        resolve(privatekey);
                      })
                      .catch(function (error) {
                        reject("importrsakey " + error);
                      });
                  });
                }

                v_promise
                  .then((key) => {
                    //kImpersonatorAESKey = RSA(Your kUser, kImpersonatorWrap)
                    CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(e.kImpersonatorWrap))
                      .then(function (kImpersonatorAESKey) {
                        //Target KUser = AES(kImpersonatorWrap, Target KUser)
                        CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(e.kUser))
                          .then(function (TargetKUser) {
                            resolve(CrytpoLib.arrayBufferToText(TargetKUser));
                          })
                          .catch(function (error) {
                            reject("aesdecypt1 " + error);
                          })
                      })
                      .catch(function (error) {
                        reject("rsadecypt " + error);
                      });
                  });

              });
            }

            const arr = item.impersonationPath;
            let final = [];

            function workMyCollection(arr) {
              return arr.reduce((promise, pditem) => {
                return promise
                  .then((result) => {
                    if (result === false) return "failed";
                    return asyncFunc(pditem, result)
                      .then(result => { final.push(result); return result; })
                      .catch((error) => {
                        return false;
                      });
                  })
                  .catch((error) => { return false; });
              }, Promise.resolve());
            }

            workMyCollection(arr)
              .then((finalpem) => {
                item.kUserGenSec = final[0];
                item.kUserTarget = final[final.length - 1];
                if (!finalpem) item.error = true;
                mResolve(item);
              });
          })
        );
      })
      return Promise.all(promisearry);
    })
    .then(list => {
      console.log("step 4", list);

      var promisearry = [];
      list.forEach(function (item) {
        promisearry.push(
          new Promise(function (resolve, reject) {
            if (window.demo) {
              if (item.answer !== undefined) {
                item.answerVote = item.answer
              }
              return resolve(item);
            }
            if ((!item.hasOwnProperty('kUserGenSec') || !item.hasOwnProperty('kUserTarget')) && item.resultKey === undefined) return resolve(item);
            if (!item.hasOwnProperty('answer')) return resolve(item);
            if (item.error === true) return resolve(item);
            if (item.answer === undefined || item.answer === "") return resolve(item);
            if (item.resultKey !== undefined) {
              CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, dataItems.genkUser, CrytpoLib.base64StringToArrayBuffer(item.resultKey))
                .then(function (decryptedKey) {
                  CrytpoLib.AESDecrypt(decryptedKey, CrytpoLib.base64StringToArrayBuffer(item.answer))
                    .then(function (decryptedData) {
                      try {
                        var j = JSON.parse(CrytpoLib.arrayBufferToText(decryptedData));
                        if (j.id === item.voteId)
                          item.answerVote = j.answer;
                      } catch (e) {

                      }
                      resolve(item);
                    })
                    .catch(function (error) {
                      reject("AESDecrypt " + error);
                    });
                })
                .catch(function (error) {
                  reject("RSADecrypt " + error);
                });
            } else {
              CrytpoLib.importPrivateKey(item.kUserTarget, CrytpoLib.defaultRSAAlgorithmMethod)
                .then(function (key) {
                  CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.key))
                    .then(function (decryptedKey) {
                      CrytpoLib.AESDecrypt(decryptedKey, CrytpoLib.base64StringToArrayBuffer(item.answer))
                        .then(function (decryptedData) {
                          try {
                            var j = JSON.parse(CrytpoLib.arrayBufferToText(decryptedData));
                            if (j.id === item.voteId)
                              item.answerVote = j.answer;
                          } catch (e) {

                          }
                          resolve(item);
                        })
                        .catch(function (error) {
                          reject("AESDecrypt " + error);
                        });
                    })
                    .catch(function (error) {
                      reject("RSADecrypt " + error);
                    });
                })
                .catch(function (error) {
                  reject("importrsakey " + error);
                });
            }
          })
        );
      })
      return Promise.all(promisearry);
    })
    .then(list => {
      console.log("step 5", list);
      //get total answer list
      var total = {};
      var group = [];
      var genSec = null;
      list.forEach(function (item) {
        var j = {
          id: item.id,
          userId: item.userId,
          documentId: item.documentId,
          voteId: item.voteId,
          enabled: item.enabled,
          answerVote: item.error === true ? '**error**' : item.answerVote,
        }
        if (item.userId === BLANK_GUID) {
          genSec = j;
          return
        }
        if (item.hasOwnProperty('answerVote')) {
          if (total.hasOwnProperty(item.answerVote))
            total[item.answerVote]++;
          else total[item.answerVote] = 1;
        }
        group.push(j)
      });

      return {
        total: total,
        list: group,
        genSec: genSec,
      }
    })
    .catch(handleCatch);

}

function checkBinderContents(binder, myId, users, customerId, keys) {
  return new Promise((resolve, reject) => {
    if (binder.items === undefined) return resolve(binder)
    if (binder.binderStatus !== BinderStatus.current && binder.binderStatus !== BinderStatus.previous) return resolve(binder)

    //See if we have items without a key to decrpt
    var badList = []
    binder.items.forEach(item => {
      if (item.type === BinderItemType.Header || item.userItems === undefined) return
      var f = item.userItems.find(o => o.userId === BLANK_GUID)
      if (!f)
        f = item.userItems.find(o => o.userId === myId)
      if (f) return

      var l = item.userItems.filter(o => {
        if (users[o.userId] !== undefined && users[o.userId].customerId === customerId)
          return true
        return false
      })

      f = l.find(o => o.documentId !== undefined && o.documentId !== "" &&
        o.key !== undefined && o.key !== "" &&
        o.size !== undefined && o.size !== 0)
      if (f) {
        badList.push({
          id: item.id,
          documentId: f.documentId,
          key: f.key,
          size: f.size,
          userId: f.userId,
        })
      }
    })

    if (badList.length === 0) return resolve(binder)

    var promiseArray = []
    badList.forEach(o => {
      promiseArray.push(
        new Promise((resolveItem, rejectItem) => {
          const requestOptions = {
            method: 'GET',
            headers: authHeader(),
          };

          fetch(GetURL() + 'UserKeys/Impersonation/' + o.userId, requestOptions)
            .then(handleJsonResponse)
            .then(data => {
              var listpaths = [];
              var detail = CheckImpersonationPath(data, myId, o.userId, data.length);
              if (detail !== false) {
                for (var direction of detail) {
                  listpaths.push(direction);
                }
              }
              if (listpaths.length === 0) {
                rejectItem({ code: 802, message: "path not found" })
                return
              }
              var lengths = listpaths.map((a) => { return a.length; });
              var index = lengths.indexOf(Math.min.apply(Math, lengths));

              getUserKeyPath(listpaths[index], kUser)
                .then((finalpem) => {
                  if (finalpem === false) {
                    return rejectItem("Unable to find path");
                  }
                  resolveItem(finalpem.kUserTarget);
                });
            })
            .catch((error) => {
              rejectItem({ code: 901, message: error });
            })
        })
      )
    })

    Promise.all(promiseArray)
      .then(o => {
        debugger
      })
      .catch(error => {
        console.log(error)
        debugger
      })
  })
    .catch(error => {
      console.log(error)
      debugger
    })
}

function deleteBinderSettings(binderId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(GetURL() + `Binders/${binderId}/Settings`, requestOptions)
    .then((response) => {
      if (!response.ok) { throw response; }
      return response.ok;
    })
    .catch((err) => {
      throw err;
    });
}
function updateBinderSettings(binderId, binderSettings) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(binderSettings)
  };

  return fetch(GetURL() + `Binders/${binderId}/Settings`, requestOptions)
    .then((response) => {
      if (!response.ok) { throw response; }
      return response.ok;
    })
    .catch((err) => {
      throw err;
    });
}