import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { intlReducer } from 'react-intl-redux'
import { popovers } from '../popover.reducer';
import { kvp } from '../admin/kvp.reducer';
import { contributors } from '../contributors.reducer';


const rootReducer = combineReducers({
  authentication,
  alert,
  popovers,
  keyValuePair: kvp,
  contributors,
  intl: intlReducer,
});

export default rootReducer;
