import React from 'react';
import { Tooltip } from '@mui/material';
import { abbrvUserRole, fixUserRole } from '../../../lib';

export function UserRoleDisplay(props = { role: '', disabled: false, abbreviate: false, onClick: null, style: {} }) {
    if (!props.role) { return null }
    var role = fixUserRole(props.role);
    var roleStyles = {};
    if (props.disabled) {
        roleStyles = {
            color: 'gray',
            borderColor: 'gray'
        }
    }
    var el = <div
        onClick={(e) => { if (props.disabled || !props.onClick) { return; } props.onClick(e); }}
        style={{
            padding: '2px 5px',
            border: '1px solid var(--ansarada-green)',
            // fontWeight: 'bold',
            color: 'var(--ansarada-green)',
            borderRadius: '5px',
            width: props.abbreviate ? '45px' : 'auto',
            textAlign: 'center',
            cursor: 'default',
            ...roleStyles,
            ...props.style,
        }}
        className={props.className}
    >
        {props.abbreviate ? abbrvUserRole(role) : role}
    </div>

    if (props.abbreviate) {
        return <Tooltip title={role} disableInteractive={true}>{el}</Tooltip>
    }

    return el;
}