import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import { IntlProvider, updateIntl } from 'react-intl-redux'
import { history } from '@lib';
import { RoutesConstants } from '@constants/common.constants';
import { BasicLoginPage, LogoutPage, CompletePage, SignInPage } from '../../pages/loginpage';
import { DirectTrailLogin, FreemiumSignUpExistingUser, FreemiumSignUp, FreemiumSignUpNewUser } from '@pages/registerpage';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { ConnectedPopover } from '../../pages/common/MUI/ConnectedPopper';

import '@styles/base.css';
import { GlobalStyles } from '@mui/material';
import { ConnectionTest } from '../../pages/common/connectionTest';

const App = () => {
  try {
    if (window.location.pathname == '/trial') {
      location.replace("/admin.html?page=trial");
      return;
    }
  } catch { }

  return (
    <IntlProvider>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router history={history}>
          <main>
            <GlobalStyles styles={{
              '.MuiTooltip-tooltip': {
                fontSize: '16px !important'
              },
              '.MuiOutlinedInput-root': {
                background: 'white'
              },
              '.MuiCheckbox-root:hover .MuiSvgIcon-root': {
                background: '#ddfee4'
              }
            }} />
            <Switch>
              <Route exact path={RoutesConstants.login_signin} component={SignInPage} />
              <Route exact path={RoutesConstants.login_callback} component={CompletePage} />
              <Route exact path={RoutesConstants.logout} component={LogoutPage} />
              <Route exact path={RoutesConstants.directtrial} component={DirectTrailLogin} />
              <Redirect from={RoutesConstants.freemium} to={RoutesConstants.trial} />
              <Route exact path={RoutesConstants.trial} component={FreemiumSignUp} />
              <Route exact path={RoutesConstants.signupnewuser} component={FreemiumSignUpNewUser} />
              <Route exact path={RoutesConstants.signupexistinguser} component={FreemiumSignUpExistingUser} />
              <Route path={RoutesConstants.login} component={BasicLoginPage} />
              <Route path="/" component={BasicLoginPage} />
            </Switch>
          </main>
        </Router>
        <ConnectedPopover />
        <ConnectionTest />
      </LocalizationProvider>
    </IntlProvider>
  );
}
export default App;
