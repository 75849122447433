import fetch from '@lib/fetch-retry';
import { viewAs, authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';


export const baseBinderService = {
    getBinderContent,
    populateBinderContent,
    populateUserBinderContent,
    getItemRange,
    setResolution,
}


function getBinderContent(binderId, appUserMode = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const viewAsParam = appUserMode ? viewAs('?') : '';

    return fetch(GetURL() + 'Binders/' + binderId + viewAsParam, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateBinderContent(binderId, excludeItems = false, appUserMode = false) {
    const requestOptions = {
        method: 'GET',
        populateBinder: true,
        headers: authHeader()
    };
    const viewAsParam = appUserMode ? viewAs() : '';

    return fetch(GetURL() + 'Binders/Populated/' + binderId + '?excludeItems=' + excludeItems + viewAsParam, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateUserBinderContent(binderId, userId, appUserMode = false) {
    const requestOptions = {
        method: 'GET',
        populateBinder: true,
        headers: authHeader()
    };
    const viewAsParam = appUserMode ? viewAs() : '';

    return fetch(GetURL() + 'Binders/Populated/' + binderId + "?viewAsUser=" + userId + viewAsParam, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getItemRange(binderId, startpos, range, option, template = false, appUserMode = false) {
    const requestOptions = {
        method: 'GET',
        populateBinder: true,
        headers: authHeader(option),
        timerGet: 20,
    };

    var url = 'Binders/';
    if (template) url += 'Templates/';

    var params = "?rangeStart=" + startpos;
    if (range !== undefined && range > 0) {
        params += '&rangeLength=' + range;
    }

    if (appUserMode) {
        params += viewAs();
    }

    return fetch(GetURL() + url + binderId + '/Items' + params, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function setResolution(item) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                id: item.id,
                previousDocumentId: item.previousDocumentId,
                documentId: item.documentId,
                size: item.size
            })
        };
        return fetch(GetURL() + `Results/Resolution/${item.itemId}`, requestOptions)
            .then(handleStandResponse)
            .then((res) => {
                resolve(res);
            })
            .catch((e) => {
                reject(e)
            });
    })
}