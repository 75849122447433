import { userConstants } from '@constants/login';

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
const initialState = userCache ? Object.assign({ loggedIn: true, askforCode: false, passwordPolicy: {} }, userCache) : {askforCode: false, loggedIn: false};

function getInitial(){
  return {
    sessionToken: '',
    customerId: '',
    customerIds: '',
    userId: '',
    userIds: '',
    username: '',
    firstName: '',
    lastName: '',
    imageId: '',
    companyName: '',
    companyImageId: '',
    deviceId: '',
    lockScreen: true,
    updatePassword: false,
    displaySettings: {},
    customerSettings: {},
    userType: {},
  }
}

function updateCookie(state){
  var userCache = JSON.parse(localStorage.getItem(window.athenaAppID))
  if(userCache !== null){
    userCache.sessionToken = state.sessionToken
    userCache.customerId = state.customerId
    userCache.customerIds = state.customerIds
    userCache.userId = state.userId
    userCache.userIds = state.userIds
    userCache.username = state.username
    userCache.firstName = state.firstName
    userCache.lastName = state.lastName
    userCache.imageId = state.imageId
    userCache.companyName = state.companyName
    userCache.customers = state.customers
    userCache.companyImageId = state.companyImageId
    userCache.deviceId = state.deviceId
    userCache.updatePassword = state.updatePassword
    userCache.lockScreen = state.lockScreen
    userCache.displaySettings = state.displaySettings
    userCache.customerSettings = state.customerSettings
    userCache.userType = state.userType
    localStorage.setItem(window.athenaAppID, JSON.stringify(userCache))

    localStorage.setItem('offline', JSON.stringify({
      customerId: state.customerId
    }))
  }
}

function getUserId(user){
  if(user.length !== 0){
    return user[0];
  }
  return "";
}

function FailedCount(state){
  if(state.failed === undefined) return 0;
  return state.failed+1;
}

function GetUniversal(state){
  let r = {}
  if(state.alias !== undefined) r.alias = state.alias
  if(state.mode !== undefined) r.mode = state.mode
  if(state.universalLogin !== undefined) r.universalLogin = state.universalLogin
  if(state.username !== undefined) r.username = state.username
  if(state.universalRedirect !== undefined) r.universalRedirect = state.universalRedirect
  if(state.requiresPassword !== undefined) r.requiresPassword = state.requiresPassword
  if(state.failed !== undefined) r.failed = state.failed
  if(state.deviceHash !== undefined) r.deviceHash = state.deviceHash
  if(state.deviceId !== undefined) r.deviceId = state.deviceId
  return r
}

export function authentication(state = initialState, action) {
  let r = {}
  switch (action.type) {
    case userConstants.LOGIN_CHECK:
      r = {
        userChecking: true,
        user: action.username,
        failed: state.failed,
        lockScreen: false,
        loggingIn: true,
      }
      return r;
    case userConstants.LOGIN_DEVICE_FAILURE:
      return {
        wrongDevice: true,
        loggedIn: false,
      };
    case userConstants.LOGIN_FAILURE:
      return Object.assign(GetUniversal(state), {
        lockScreen: false,
        loggedIn: false,
        error: "Unauthorised",
        failed: FailedCount(state),
        username: state.username,
        //requiresPassword: undefined,
        //universalLogin: undefined,
        //mode: undefined,
      });
    case userConstants.REGISTER_USERDEVICE_FAILURE:
      return { loggingIn:false, askforCode: false, error: "Unauthorised" };
    case userConstants.HAS_DEVICE_CHECKED_SUCCESS:
      r = Object.assign({}, action.payload, {userChecked: true, loggingIn: true})
      if(r.mode === 2 && !r.requiresPassword && !r.universalLogin && !r.hasDevice) r.askforUserDetails = false
      else if(!r.hasDevice){
        r.askforUserDetails = true
      }
      if(state.failed !== undefined) r.failed = state.failed
      if(action.payload.enabled === false){
        r.maintenance = {
          enabled: action.payload.enabled,
          statusMessage: action.payload.statusMessage,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime
        }
      }
      return r;
    case userConstants.HAS_DEVICE_NOTFY_SUCCESS:
        r = Object.assign({
          loggingIn:false,
          askforUserDetails: !action.payload.hasDevice,
        }, action.payload)
  
        if(action.payload.enabled === false){
          r.maintenance = {
            enabled: action.payload.enabled,
            statusMessage: action.payload.statusMessage,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime
          }
        }  
        return r;
    case userConstants.DIRECT_SIGNUP_REQUEST:
      return Object.assign({}, {loggedIn: false, loading: true, error: ""});
    case userConstants.DIRECT_SIGNUP_SUCCESS:
      return Object.assign({}, state, {username: action.payload.username, deviceId: action.payload.deviceId, deviceHash: action.payload.deviceHas});
    case userConstants.DIRECT_SIGNUP_FAILURE:
      return Object.assign({}, {loggedIn: false, loading: false, error: action.error});

    case userConstants.TRAIL_CHECKEMAIL_REQUEST:
      return Object.assign({}, {loggedIn: false, checkEmail: true, error: ""});
    case userConstants.TRAIL_CHECKEMAIL_SUCCESS:
      return Object.assign({}, {username: action.payload.username, existingUser: action.payload.existingUser, clientId: action.payload.clientId, checkEmail: false, usesMFA: action.payload.usesMFA , usesSSO: action.payload.usesSSO });
    case userConstants.TRAIL_CHECKEMAIL_FAILURE:
      return Object.assign({}, {loggedIn: false, loading: false, error: action.error, checkEmail: false});
    case userConstants.TRAIL_CHECKEMAIL_DELETE:
      return Object.assign({}, { error: "" });

    case userConstants.TRAIL_COMPLETE_REQUEST:
      return Object.assign({}, {loggingIn: true, loggedIn: true, error: ""});
    case userConstants.TRAIL_COMPLETE_SUCCESS:
      return Object.assign({}, {loggingIn: false, username: action.payload.username, existingUser: action.payload.existingUser });
    case userConstants.TRAIL_COMPLETE_FAILURE:
      return Object.assign({}, {loggingIn: false, loggedIn: false, loading: false, error: action.error});

    case userConstants.TRAIL_COSECSAVE_REQUEST:
      return Object.assign({}, state, { cosecLoading: true, error: undefined });
    case userConstants.TRAIL_COSECSAVE_SUCCESS:
      return Object.assign({}, state, { cosecLoading: false, error: "" });
    case userConstants.TRAIL_COSECSAVE_FAILURE:
      return Object.assign({}, state, { cosecLoading: false, error: action.error });

    case userConstants.TRAIL_GETNEWUSERINFO_REQUEST:
      return Object.assign({}, {loadingInfo: true});
    case userConstants.TRAIL_GETNEWUSERINFO_SUCCESS:
      return Object.assign({}, {clientId: action.payload.clientId, username: action.payload.email, passwordPolicy: action.payload.passwordPolicy, existingUser: action.payload.ansaradaProfileAlreadyExists, loadingInfo: false, usesSSO: action.payload.usesSSO || false });
    case userConstants.TRAIL_GETNEWUSERINFO_FAILURE:
      return Object.assign({}, {error: action.error, loadingInfo: false, loadingInfoError: action.error});

    case userConstants.TRAIL_EXISTINGCOMPLETE_REQUEST:
      return Object.assign({}, state, { loggingIn: true, loggedIn: false, loading: true, error: "" });
    case userConstants.TRAIL_EXISTINGCOMPLETE_SUCCESS:
      var mfaId = undefined, mfaType = undefined;
      if(action.mfaId !== undefined && action.mfaId !== ""){
        mfaId = action.mfaId
        if(action.sms === true)
          mfaType = "sms"
      }
      return Object.assign({}, state, { loggingIn: false, loggedIn: true, loading: false, askforCode: true, mfaId: mfaId, mfaType: mfaType, athenaUsername: action.user.username, registrationCode: action.user.registrationCode, deviceId: action.user.deviceId });
    case userConstants.TRAIL_EXISTINGCOMPLETE_FAILURE:
      return Object.assign({}, state, { loggingIn: false, loggedIn: false, loading: false, error: action.error, username: action.username });

    case userConstants.TRAIL_EXISTINGREGISTER_REQUEST:
      return Object.assign({}, state, { loggingIn: true, loading: true, askforCode: false });
    case userConstants.TRAIL_EXISTINGREGISTER_SUCCESS:
      return Object.assign({}, state, { loggingIn: true, loading: false });
    case userConstants.TRAIL_EXISTINGREGISTER_FAILURE:
      return Object.assign({}, state, { loggingIn: false, loading: false, askforCode: true });

    case userConstants.LOGIN_AUTHZERO_REQUEST:
      return Object.assign({}, state, {loggingIn: true, error: ""});
    case userConstants.LOGIN_AUTHZERO_SUCCESS:
      return Object.assign({}, state, {auth0Result: action.payload});
    case userConstants.LOGIN_AUTHZERO_FAILURE:
      return Object.assign({}, state, {auth0Result: false, error: action.error, loggingIn: false});

    default:
      return state
  }
}
