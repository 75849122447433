import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { Button, TextInput } from '@common/admin';
import {FaTimes as ExitIcon } from 'react-icons/fa';
import { LoadingIndicator } from '@ansarada/ace-react';
import * as CrytpoLib from '@lib/cryptojs';
import { ReplaceValue, DeviceFingerprint } from '@lib/simpletools';
import {keysStorage, SettingStorage} from '@lib/indexeddb';
import { RoutesConstants } from '@constants/common.constants';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import { MuiButton } from '@common/MUI';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

import '@pages/loginpage/loginpage.css';
import LoadingOverlay from './MUI/LoadingOverlay';

export class LoginBoxDialogBase extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.state = {
      deviceHash: this.props.deviceHash,
      deviceId: this.props.deviceId,
      phash: '',
      block: false,

      iframeLoaded: false,
      loggingOut: false,
    }

    var iFrameComms = {
      completeAuth0 : function (auth) {
        if(auth.user !== undefined && auth.user['https://platform.ansarada.com/claims/identity/profile/id'] !== undefined){
          this.props.dispatch(userActions.Auth0Complete(auth))
          //this.setState({showAuth0: false})
        }
        this.setState({showFrameExit: false})
      },
      getUsername: function() {
        console.log(this.props)
        return this.props.alias
      }
    };

    window.iFrameComms = Object.create(iFrameComms);
    iFrameComms.completeAuth0 = iFrameComms.completeAuth0.bind(this);
    iFrameComms.getUsername = iFrameComms.getUsername.bind(this);

    this.onExit = this.onExit.bind(this);
    this.Submit = this.Submit.bind(this);
    this.handleChangep = this.handleChangep.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  componentDidMount() {
    this.timerId = setTimeout(this.getKey, 20);
//console.log("LoginBoxDialog",this.props)
    if (this.props.universalLogin === true) { // && this.props.universalRedirect === true){
      try {
        this.props.dispatch(userActions.Auth0Refresh())
        .catch(() => {
          this.props.dispatch(userActions.Auth0Logout());
        })
      } catch {
        this.props.dispatch(userActions.Auth0Logout())
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    this.setState({block: true});
  }

  componentDidCatch(error, info){
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.deviceId !== "" && nextProps.deviceId !== undefined)
      state.deviceId = nextProps.deviceId
    if(nextProps.deviceHash !== "" && nextProps.deviceHash !== undefined)
      state.deviceHash = nextProps.deviceHash
    return state;
  }

  getKey(){
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null){
        if(!_this.state.block){
          _this.setState({deviceId: data.key});
        }
      }
    }).catch((e)=>{
      //try again in few milliseconds as database could be still trying to open
      this.timerId = setTimeout(this.tryAgain, 200);
    })
  }

  tryAgain(){
    clearTimeout(this.timerId);
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null || data.key !== ""){
    //    if(!_this.state.block)
    //      _this.setState({deviceId: data.key});
      }
    }).catch((e)=>{})
  }

  handleChangep(e){
    const { name, value } = e.target;

    //var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();

    this.setState({ [name]: value });
  }

  async onExit(){
    if(this.props.isAppUser) {
      await this.props.dispatch(userActions.logout(this.state.deviceId));
    }
    window.location.href=RoutesConstants.logout;
//    if(this.props.history !== undefined) this.props.history.push("/login");
  }

  Submit(){
    // if (this.props.universalLogin === true) {
    //   this.props.dispatch(userActions.Auth0Refresh());
    //   return;
    // }
    const { phash, deviceId, deviceHash } = this.state;
    var username = this.props.username;
    if(!username){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(user){
        username = user.username;
      }
    }

    if(deviceId === "" || deviceId === undefined || deviceHash === "" || deviceHash === undefined){
      var _this = this;
      keysStorage.Get(username+'deviceId')
      .then((data)=>{
        if(data.key !== null || data.key !== ""){
          _this.setState({deviceId: data.key});
          this.SendPost(username, data.key, "", phash)
        }
      }).catch((e)=>{})

    }else
      this.SendPost(username, deviceId, deviceHash, phash);
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  SendPost(username, deviceId, deviceHash, phash){
    const send = async (id, hash) => {
      if(!this.props.mode) {
        await this.props.dispatch(userActions.checkAlias(this.props.alias));
      }
      var loginReqest = {
        alias: this.props.alias,
        username: username,
        deviceId: id,
        deviceHash: hash,
        password: phash,
        keys: true,
        mode: this.props.mode,
      }
      this.props.dispatch(userActions.loginWithOutRedirect(loginReqest));
      this.setState({phash: ''});
    }
    if(deviceHash === ""){
      DeviceFingerprint('lock')
      .then((ihash) => {
        if(!this.state.block)
          this.setState({deviceHash: ihash});

        send(deviceId, ihash)
      })
      .catch(function(error) {
      });
    }else{
      send(deviceId, deviceHash)
    }
  }

  iFrameLoaded(){
    this.setState({iframeLoaded: true})
  }

  render() {
    if (this.state.loggingOut || this.props.loggingOut) { return null; }
    if(this.props.lockScreen) return (<div/>);
    if(this.props.failed > 1){
      this.props.dispatch(userActions.logout(this.state.deviceId));
      //this.props.history.push("/login");
      return (
        <LoadingOverlay />
      );
    }

    if(this.props.keys !== undefined)
      if(this.props.keys[this.props.customerId] !== undefined)
        if(this.props.keys[this.props.customerId].kUser !== undefined){
          return (<div/>);
        }

    if(this.props.universalLogin === true && this.props.universalRedirect === true){
      return(
        <div>
          {this.props.loggingIn === true &&
            <LoadingOverlay title='Logging in' />
          }
        </div>
      )
    }
    // else if(this.props.universalLogin === true && !this.props.universalRedirect){
    //   return (
    //     <div className="aBoxTop-cover" style={{zIndex: 99}}>
    //       <div>
    //         {!this.props.loggingIn &&
    //           <div className="Auth0OverLay" style={{left: this.state.iframeLoaded? 0: window.innerWidth}}>
    //             <div className="Auth0Centre">
    //               <ExitIcon className="login-iframe-exit" onClick={this.onExit}/>
    //               <iframe
    //                 id="frameParent"
    //                 className="auth0Frame"
    //                 src="/auth0Sign.html" //"/loginSignIn"
    //                 title="Single Sign Login"
    //                 onLoad={this.iFrameLoaded.bind(this)}
    //               >
    //               </iframe>
    //             </div>
    //           </div>
    //         }
    //         <div className="ansarada-loading">
    //           <span className="css-1cnp6ed-LoadingIndicator"></span>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div>
        {this.props.loggingIn !== false &&
          <LoadingOverlay title='Logging in' />
        }
        {Boolean(!this.props.loggingIn && !this.props.universalLogin) &&
          <Dialog maxWidth='sm' fullWidth={true} open={!this.props.loggingIn}>
            <DialogTitle style={{ paddingRight: '10px', paddingBottom: 0 }} className='vote-item-modal-title'>
              {this.props.lockScreen ? 'Screen Locked: ' : ''}Enter your Ansarada Board password
              <IconButton aria-label="close" onClick={this.onExit}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '30px 20px' }}>
              <DialogContentText style={{ paddingBottom: '10px', fontSize: '15px', color: 'var(--dark-grey)' }}>Please enter your Ansarada Board password</DialogContentText>
              <input type="hidden" name="password" />
              <FormControl fullWidth={true} variant="standard" style={{ paddingBottom: '10px' }}>
                <InputLabel htmlFor="login-box-password">Password</InputLabel>
                <Input
                  id='login-box-password'
                  name='phash'
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.phash}
                  onChange={this.handleChangep}
                  onKeyPress={(e) => { if (e.key == 'Enter') { this.Submit() } }}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {this.props.error !== "" &&
                <div className="error" style={{ textAlign: 'left' }}>{this.props.error === 'Unauthorised' ? "Password is wrong" : this.props.error}</div>
              }
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' onClick={this.Submit}>Submit</MuiButton>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alias, customerId, username, deviceId, deviceHash, error, failed, keys, loggedIn, loggingOut, loggingIn, lockScreen, mode, universalLogin, universalRedirect } = state.authentication;
  return {
    alias,
    customerId,
    username,
    deviceId,
    deviceHash,
    keys,
    error,
    failed,
    loggingIn,
    loggingOut,
    lockScreen,
    loggedIn,
    mode,
    universalLogin,
    universalRedirect,
  };
}

const connectedLoginBoxDialog = connect(mapStateToProps)(LoginBoxDialogBase);
export { connectedLoginBoxDialog as LoginBoxDialog };
