import { baseBackupService } from '@services/backup.base.service';
import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';
import { kvpActions } from './admin';
import { documentAnnotationsConstants } from '../constants/appuser';

export const baseBackupActions = {
    getAllAnnotations,
    addAnnotations,
    modifyAnnotations,
    deleteAnnotations,
    getSharedAnnotations,
    getUsersDocumentCanBeSharedTo,
    postSharedAnnotation,
};

async function UnwrapAnnotations(getState, decryptedAnnotations, item, isDelete = false, shouldFilterDeleted = true) {
    return new Promise(async (resolve, reject) => {
        const pako = require('pako');
        var key = CrytpoLib.base64StringToArrayBuffer(item.key);
        var decryptedAES = '';
        var customerDecryptionKey = getState().authentication.keys[getState().authentication.customerId].kUser;
        try {
            decryptedAES = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerDecryptionKey, key);
        } catch (e) { }

        if (!decryptedAES) {
            var keys = Object.keys(getState().authentication.keys);
            for (let i = 0; i < keys.length && !decryptedAES; i++) {
                try {
                    decryptedAES = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, getState().authentication.keys[keys[i]].kUser, key);
                } catch (e) { }
            }
            if (!decryptedAES) { resolve(); return; }
        }

        for (var i of item.annotations) {
            var decryptedAnnotationAB = await CrytpoLib.AESDecrypt(decryptedAES, CrytpoLib.base64StringToArrayBuffer(i.annotation));
            const output = pako.inflateRaw(decryptedAnnotationAB);
            var decryptedAnnotationJSON = JSON.parse(CrytpoLib.arrayBufferToText(output));
            if (decryptedAnnotationJSON) {
                decryptedAnnotationJSON.id = decryptedAnnotationJSON.name;
                decryptedAnnotationJSON.customData = {
                    documentId: item.documentId,
                    itemId: item.itemId
                }
                if (isDelete) {
                    if (shouldFilterDeleted) {
                        for (var i = 0; i < decryptedAnnotations.length; i++) {
                            if (decryptedAnnotations[i] && decryptedAnnotations[i].name == decryptedAnnotationJSON.name) { decryptedAnnotations.splice(i, 1); i--; }
                        }
                    } else {
                        decryptedAnnotationJSON.isDeleted = true;
                        decryptedAnnotations.push(decryptedAnnotationJSON);
                    }
                } else {
                    decryptedAnnotations.push(decryptedAnnotationJSON);
                }
            }
            resolve(decryptedAnnotationJSON);
        };
    })
}

function getAllAnnotations(itemId, usePNSController = false) {
    return async (dispatch, getState) => {
        var response = usePNSController
            ? await baseBackupService.getAnnotation(itemId).catch(e => {
                throw e;
            })
            : await baseBackupService.getAllAnnotations(itemId).catch(e => {
                throw e;
            });

        var decryptedAnnotations = [];

        if (response.hasOwnProperty('Add') || response.hasOwnProperty('add')) {
            const addList = response.Add || response.add;
            for (var item of addList) {
                await UnwrapAnnotations(getState, decryptedAnnotations, item);
            };
        }
        if (response.hasOwnProperty('Modify') || response.hasOwnProperty('modify')) {
            const modifyList = response.Modify || response.modify;
            for (var item of modifyList) {
                await UnwrapAnnotations(getState, decryptedAnnotations, item);
            };
        }
        if (response.hasOwnProperty('Delete') || response.hasOwnProperty('delete')) {
            const deleteList = response.Delete || response.delete;
            for (var item of deleteList) {
                await UnwrapAnnotations(getState, decryptedAnnotations, item, true, usePNSController ? false : true);
            }
        };

        const sortByDate = (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        decryptedAnnotations = decryptedAnnotations.sort(sortByDate).reverse();
        var uniqueIds = [];
        var uniqueAnnotations = [];
        for (var da of decryptedAnnotations) {
            if (!uniqueIds.includes(da.name)) {
                uniqueIds.push(da.name);
                delete da.pnsType;
                if (da.type === "pspdfkit/note" && da.color === "#FFFFFF") {
                    da.color = "#FFD83F";
                }
                uniqueAnnotations.push(da);
            }
        }
        if (uniqueAnnotations && uniqueAnnotations.length) {
            dispatch(kvpActions.setIsAnnotatedItemId(itemId));
        }
        return uniqueAnnotations;
    }
}

function createAnnotationsDTO(id, details, annotations, kUserPublic, callback) {
    var aesKey = CrytpoLib.GenerateRandom(32);
    var wrappedAnnotations = [];
    const pako = require('pako');
    annotations.forEach(async annotation => {
        var jsonString = JSON.stringify(annotation);
        var Uint8View = CrytpoLib.textToArrayBuffer(jsonString);
        const output = pako.deflateRaw(Uint8View);
        await CrytpoLib.AESEncrypt(aesKey, output)
            .then(function (encryptedData) {
                wrappedAnnotations.push({
                    id: annotation.id,
                    annotation: CrytpoLib.arrayBufferToBase64String(encryptedData),
                    page: annotation.pageIndex
                })
            });
    });
    return CrytpoLib.importPublicKey(kUserPublic, CrytpoLib.defaultRSAAlgorithmMethod)
        .then((publicEncryptionKey) => {
            return CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicEncryptionKey, aesKey)
                .then(function (KAESEncpyt) {
                    var dto = {
                        id: id,
                        date: moment().utc().format(),
                        itemId: details.itemId,
                        documentId: details.documentId,
                        deviceId: details.deviceId,
                        customerId: details.customerId,
                        key: CrytpoLib.arrayBufferToBase64String(KAESEncpyt),
                        annotations: wrappedAnnotations, //CrytpoLib.arrayBufferToBase64String(encryptedData),
                        type: details.type,
                        class: 'annotations',
                    }
                    return callback(dto);
                });
        })
}

function addAnnotations(id, details, annotations) {
    return (dispatch, getState) => {
        dispatch(kvpActions.setIsAnnotatedItemId(details.itemId));
        details.type = 'add';
        var userIds = getState().authentication.userIds;
        var kUserPublic = '';
        userIds.forEach((uid, index) => {
            if (getState().users.data[uid] && (getState().users.data[uid].customerId == getState().authentication.customerId || index == userIds.length)) {
                kUserPublic = getState().users.data[uid].kUserPublic;
            }
        });
        return createAnnotationsDTO(id, details, annotations, kUserPublic, (dto) => { return baseBackupService.addAnnotations(id, dto) });
    };
}

function modifyAnnotations(id, details, annotations) {
    return (dispatch, getState) => {
        details.type = 'modify';
        var userIds = getState().authentication.userIds;
        var kUserPublic = '';
        userIds.forEach((uid, index) => {
            if (getState().users.data[uid] && (getState().users.data[uid].customerId == getState().authentication.customerId || index == userIds.length)) {
                kUserPublic = getState().users.data[uid].kUserPublic;
            }
        });
        return createAnnotationsDTO(id, details, annotations, kUserPublic, (dto) => { return baseBackupService.modifyAnnotations(id, dto) });
    };
}

function deleteAnnotations(id, details, annotations) {
    return (dispatch, getState) => {
        details.type = 'delete';
        var userIds = getState().authentication.userIds;
        var kUserPublic = '';
        userIds.forEach((uid, index) => {
            if (getState().users.data[uid] && (getState().users.data[uid].customerId == getState().authentication.customerId || index == userIds.length)) {
                kUserPublic = getState().users.data[uid].kUserPublic;
            }
        });
        return createAnnotationsDTO(id, details, annotations, kUserPublic, (dto) => { return baseBackupService.deleteAnnotations(id, dto) });
    };
}

function getUsersDocumentCanBeSharedTo(documentId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseBackupService.getUsersAnnotationsCanBeSharedTo(documentId)
                .then((response) => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e);
                })
        });
    }
}

function getSharedAnnotations(documentId, forceRefresh = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!documentId) { reject(); return; }
            if (getState().documentAnnotations.sharedAnnotations
                && getState().documentAnnotations.sharedAnnotations[documentId]
                && !forceRefresh
            ) {
                resolve(getState().documentAnnotations.sharedAnnotations[documentId]);
                return;
            }

            dispatch({
                type: documentAnnotationsConstants.CLEAR_SHARED_ANNOTATIONS_FOR_DOCUMENTID,
                documentId: documentId
            });

            baseBackupService.getSharedAnnotations(documentId)
                .then(async (response) => {
                    if (!response || !response.length) {
                        dispatch({
                            type: documentAnnotationsConstants.SET_SHARED_ANNOTATIONS_FOR_DOCUMENTID,
                            documentId: documentId,
                            payload: response
                        })
                        resolve(response);
                        return;
                    }

                    const pako = require('pako');
                    function unwrapAnnotations(item, isDelete = false) {
                        return new Promise(async (resolve, reject) => {
                            try {
                                var decryptedAnnotations = [];
                                var key = CrytpoLib.base64StringToArrayBuffer(item.key);
                                var decryptedAES = '';
                                var customerDecryptionKey = getState().authentication.keys[getState().authentication.customerId].kUser;
                                try {
                                    decryptedAES = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerDecryptionKey, key);
                                } catch (e) { }

                                if (!decryptedAES) {
                                    var keys = Object.keys(getState().authentication.keys);
                                    for (let i = 0; i < keys.length && !decryptedAES; i++) {
                                        try {
                                            decryptedAES = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, getState().authentication.keys[keys[i]].kUser, key);
                                        } catch (e) { }
                                    }
                                    if (!decryptedAES) { return; }
                                }

                                for (var i of item.annotations) {
                                    var decryptedAnnotationAB = await CrytpoLib.AESDecrypt(decryptedAES, CrytpoLib.base64StringToArrayBuffer(i.annotation));
                                    const output = pako.inflateRaw(decryptedAnnotationAB);
                                    var decryptedAnnotationJSON = JSON.parse(CrytpoLib.arrayBufferToText(output));
                                    if (decryptedAnnotationJSON) {
                                        decryptedAnnotationJSON.id = decryptedAnnotationJSON.name;
                                        decryptedAnnotationJSON.isDeletable = false;
                                        decryptedAnnotationJSON.isEditable = false;
                                        decryptedAnnotationJSON.creatorName = item.sharedByAttendee ? item.sharedByAttendee.userId : '';
                                        if (decryptedAnnotationJSON.type === "pspdfkit/note" && decryptedAnnotationJSON.color === "#FFFFFF") {
                                            decryptedAnnotationJSON.color = "#FFD83F";
                                        }
                                        // custom data v2 schema? requires library update
                                        // decryptedAnnotationJSON.customData = {
                                        //     isSharedAnnotation: true,
                                        //     createdByUserId: item.sharedByAttendee ? item.sharedByAttendee.userId : ''
                                        // };
                                        if (isDelete) {
                                            decryptedAnnotations = decryptedAnnotations.filter(i => i.name !== decryptedAnnotationJSON.name);
                                        } else {
                                            decryptedAnnotations.push(decryptedAnnotationJSON);
                                        }
                                    }
                                };

                                item.decryptedAnnotations = decryptedAnnotations;
                                resolve(item);
                            } catch (e) {
                                log('Shared annotation decryp error', e, item);
                                resolve(item);
                                // reject(); 
                            }
                        });
                    }

                    var promises = [];
                    response.forEach(async item => {
                        if (item.annotations) {
                            item.annotations = _.uniqBy(item.annotations, (a) => { return a.id || a.name });
                        }
                        promises.push(unwrapAnnotations(item));
                    });
                    await Promise.all(promises);

                    dispatch({
                        type: documentAnnotationsConstants.SET_SHARED_ANNOTATIONS_FOR_DOCUMENTID,
                        documentId: documentId,
                        payload: response
                    })
                    resolve(response);
                })
        })
    }
}

function postSharedAnnotation(details) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            baseBackupService.postSharedAnnotation(details)
                .then((response) => {
                    resolve()
                })
                .catch(() => {
                    reject();
                })
        });
    }
}