import { authHeader, GetURL, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, viewAs } from '@lib/';

export const statusService = {
  getStatus,
  setStatus,
  getUsage,
  uploadUsageLogs,
  uploadUsageLog
}

function getStatus(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'StatusSync'+viewAs('?'), requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function setStatus(items, token){
  const requestOptions = {
    method: 'PATCH',
    headers: token ? {
      'Authorization': token,
      'Content-Type': 'application/json'
  } : authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'StatusSync', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getUsage(params = { customerId: null, itemId: null, boardId: null, binderId: null, startDate: null, endDate: null }) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let paramList = [];

  if (params.customerId) { paramList.push(`customerId=${params.customerId}`); }
  if (params.itemId) { paramList.push(`itemId=${params.itemId}`); }
  if (params.boardId) { paramList.push(`boardId=${params.boardId}`); }
  if (params.binderId) { paramList.push(`binderId=${params.binderId}`); }
  if (params.startDate) { paramList.push(`startDate=${params.startDate}`); }
  if (params.endDate) { paramList.push(`endDate=${params.endDate}`); }

  let url = 'Usage' + params;

  if (window.demo) {
    url = 'Usage/' + customerId
  }

  return fetch(GetURL() + `Usage?${paramList.join('&')}`, requestOptions).then((r) => { if (token && r.status == 401) { return; } return handleJsonResponse(r); }).then(handleDataResponse).catch((e) => { if (token) { return; } return handleCatch(e); });
}


function uploadUsageLogs(items){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'Usage/Populated', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function uploadUsageLog(item){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Usage', requestOptions).then(handleStandResponse).catch(handleCatch);
}

