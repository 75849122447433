/* global appConfig, __BUILD_DATE__, Version */

export function GetURL() {
  if(window.demo){
    return appConfig.demoURL
  }
  try {
    if (location.origin.includes('dev1.') && window.useBoardAPI) {
      return window.useBoardAPI;
    }
  } catch { }
//  return "http://localhost:3000/api/V1.0/";
//  return "http://localhost:8888/api/V1.0/";
//return "https://api-155.dev-athena.lockboxtech.net/api/V1.0/";
//return "https://api.dev-athena.lockboxtech.net/api/V1.0/";
//return "https://api.athenaboard.net/api/V1.0/";
//return "https://api.athenaboard.net/api/V1.0/"
  return appConfig.apiURL
}

export function GetFrontendHelperURL() {
  return appConfig.frontendHelperURL
}

export function GetWebSocURL() {
  return appConfig.webSocketURL
}

export function isDev(){
  if (appConfig.env === 'dev'){
    return true
  }
  return false
}

export function getHelp(){
  return 1.1
}

export function getVer(){
  return 1.2
}

function getHash(){
  const h = __BUILD_DATE__
  if(h !== undefined)
     return h
  return ""
}

export function getVersion(){
  if (typeof Version === 'function'){
    if (appConfig.env === 'dev')
      return Version()+" "+getHash()
    return Version()
  }
  return ""
}
