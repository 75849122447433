import { v4 as uuidv4 } from 'uuid';
import * as CrytpoLib from '@lib/cryptojs';
import { resolutionService } from "../../services/resolution.service";
import { resolutionsConstants } from '../../reducers/admin/resolutions.reducer';
import { queueService } from '../../services/admin';
import { BLANK_GUID, basename } from '../../lib';
import { webConstants } from '../../constants/admin';
import { queueActions } from './queue.actions';
import { commonConstants } from '../../constants';
import { popoverAction } from './popover.actions';

const Errors = {
    AllResultsReceived: '1',
    QuorumReached: '2',
    DeadlineReached: '3',
    FinalDocumentSet: "4",
}

export const resolutionsActions = {
    Errors,
    getResolutionsForBoard,
    removeCircularResolutionFromRedux,
    getCircularResolutionResolution,
    getMyResolutions,
    saveCircularResolution,
    deleteCircularResolution,
    updateCircularResolution,
    uploadResolutionDocument,
    convertFile,
    actionResolution,
    loadDocument,
}

function removeCircularResolutionFromRedux(circularResolutionId, boardId, customerId, showNotification = false) {
    return (dispatch, getState) => {
        if (showNotification) {
            var existingResolution;
            try {
                existingResolution = getState().resolutions[boardId][circularResolutionId];
            } catch { }
            var boardName = '';
            var customerName = '';
            try {
                if (boardId) {
                    var board = getState().board.allBoards.find(b => b.id == boardId);
                    if (board) {
                        boardName = board.name;
                        customerName = board.customerName;
                    }
                } else {
                    customerName = getState().company[customerId].companyName;
                }
            } catch (e) { console.log(e); }

            var messageParts = [`Circular Resolution ${existingResolution ? existingResolution.displayName : ''} has been removed ${boardName ? ` from ${boardName}` : ''}${customerName ? ` for ${customerName}` : ''}.`];
            dispatch(popoverAction.showToast('success', uuidv4(), messageParts));
        }

        dispatch({
            type: resolutionsConstants.DELETE_RESOLUTION,
            payload: { id: circularResolutionId, boardId }
        })
    }
}

function getCircularResolutionResolution(id, customerId, boardId, showNotification = false, includeUserNames = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var existingResolution;
            try {
                existingResolution = getState().resolutions[boardId][id];
            } catch { }
            resolutionService.getCircularResolution(id, includeUserNames)
                .then((response) => {
                    dispatch({
                        type: resolutionsConstants.ADD_RESOLUTIONS,
                        payload: [response]
                    });

                    if (showNotification) {
                        var boardName = '';
                        var customerName = '';
                        try {
                            if (boardId) {
                                var board = getState().board.allBoards.find(b => b.id == boardId);
                                if (board) {
                                    boardName = board.name;
                                    customerName = board.customerName;
                                }
                            } else {
                                customerName = getState().company[customerId].companyName;
                            }
                        } catch (e) { console.log(e); }

                        var messageParts = [`Circular Resolution${response ? ` ${response.displayName}` : ''} has been updated ${boardName ? ` in ${boardName}` : ''}${customerName ? ` for ${customerName}` : ''}.`];
                        dispatch(popoverAction.showToast('success', uuidv4(), messageParts));
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject();
                })
        });
    }
}

function getMyResolutions() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            resolutionService.getMyResolutions()
                .then(async (response) => {
                    dispatch({
                        type: resolutionsConstants.ADD_RESOLUTIONS,
                        payload: response
                    })
                    resolve();
                }, (error) => {
                    reject(error);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}

function loadDocument(resolution, download = false, useFinalDocumentId = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!resolution.documentId) { reject(); return; }
            var documentIdToDownload = resolution.documentId;
            var documentsize = resolution.documentSize;

            if (useFinalDocumentId && resolution.finalDocumentId) {
                documentIdToDownload = resolution.finalDocumentId;
                documentsize = resolution.finalDocumentSize;
            }

            try {
                const customerGenSecKey = getState().authentication.keys[getState().authentication.customerId];
                if (!customerGenSecKey) { reject(); return; }

                const bc = new BroadcastChannel(documentIdToDownload);
                bc.onmessage = (event) => {
                    if (event.data) {
                        switch (event.data.message) {
                            case 'download_error': reject(); bc.close(); break;
                            case 'download_complete': if (event.data.blob) { resolve(event.data.blob); } bc.close(); break;
                            default: return;
                        }
                    }
                };

                dispatch(queueActions.downloadFile({
                    id: documentIdToDownload,
                    skipSendingsUsage: false,
                    documentId: documentIdToDownload,
                    fileName: resolution.fileName,
                    fileSize: documentsize,
                    processType: download ? webConstants.DOWNLOAD_FILE : webConstants.DOWNLOAD_STORE,
                    kUser: customerGenSecKey.pUserGenSec,
                    // kUser: customerGenSecKey.kUserGenSec, //resolution.userCircularResolutions.find(ucr => ucr.userId == BLANK_GUID).key,
                    key: resolution.userCircularResolutions.find(ucr => ucr.userId == BLANK_GUID).key,
                    boardId: resolution.boardId,
                    broadcastChannelId: documentIdToDownload
                }));
            } catch {
                reject();
            }
        })
    }
}

function deleteCircularResolution(circularResolutionId, boardId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            resolutionService.deleteCircularResolution(circularResolutionId)
                .then(async (response) => {
                    dispatch({
                        type: resolutionsConstants.DELETE_RESOLUTION,
                        payload: { id: circularResolutionId, boardId }
                    })
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}

function convertFile(file, fileId, customerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            queueService.convertToPdf(file, customerId, false, fileId, uuidv4(), undefined)
                .then(async (response) => {
                    const arrBuffDoc = await CrytpoLib.base64StringToArrayBuffer(response.data);
                    const decryptedArrBuffDoc = await CrytpoLib.AESDecrypt(response.docAES, arrBuffDoc);
                    let blob;
                    blob = new Blob([decryptedArrBuffDoc], { type: 'application/pdf' });
                    blob.lastModified = file.lastModified;
                    blob.lastModifiedDate = file.lastModifiedDate;
                    var fileName = basename(file.name) + '.pdf';
                    blob.name = fileName;
                    // blob.id = item.id;
                    resolve(blob);
                    return blob;
                })
                .catch((e) => { console.log(e); reject(e); });
        });
    }
}

function uploadResolutionDocument(file, documentId, key, customerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            resolutionService.uploadFileChunks(file, documentId, key, customerId)
                .then((response) => {
                    resolve(response);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }
}

function getResolutionsForBoard(boardId, userId, includeUserDetails = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            resolutionService.getResolutionsForBoard(boardId, userId, includeUserDetails)
                .then((response) => {
                    dispatch({
                        type: resolutionsConstants.ADD_RESOLUTIONS,
                        payload: response
                    })
                    resolve(response);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }
}

function saveCircularResolution(circularResolution) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var toSave = { ...circularResolution };
            if (!toSave.dateRequired) {
                toSave.dateRequired = commonConstants.SET_NULL_DATE;
            }
            resolutionService.createCircularResolution(toSave)
                .then((response) => {
                    dispatch({
                        type: resolutionsConstants.SET_RESOLUTION,
                        payload: { ...circularResolution }
                    })
                    resolve(response);
                }, (error) => {
                    reject(error)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })
        })
    }
}

function updateCircularResolution(circularResolution, updateReduxOnly = false, sendDate = true) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (updateReduxOnly) {
                dispatch({
                    type: resolutionsConstants.SET_RESOLUTION,
                    payload: { ...circularResolution }
                })
                resolve();
                return;
            }
            var toSave = { ...circularResolution };
            if (!toSave.dateRequired) {
                toSave.dateRequired = commonConstants.SET_NULL_DATE;
            }

            if(!sendDate) {
                delete toSave.dateRequired;
            }

            resolutionService.updateCircularResolution(toSave)
                .then((response) => {
                    dispatch({
                        type: resolutionsConstants.SET_RESOLUTION,
                        payload: { ...circularResolution }
                    })
                    resolve(response);
                }, (error) => {
                    reject(error)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })
        })
    }
}

function actionResolution(circularResolutionResult) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            resolutionService.actionResolution(circularResolutionResult)
                .then((response) => {
                    resolve();
                }, (err) => {
                    console.log(err)
                    reject(err);
                }).catch((err) => {
                    console.log(err)
                    reject(err);
                });
        })
    }
}