import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { FaRegQuestionCircle as QuestionIcon } from 'react-icons/fa';

const MuiSelect = (props) => {
  var menuStyle = props.menuStyle || {}
    return (
      <div className='boardpanel' style={{...(props.containerStyle || {})}}>
        <div style={{width: props.detail !== undefined && props.detail !== "" ? '90%' : "100%"}}>
          <FormControl fullWidth disabled={props.disabled === true} variant={props.variant ? props.variant : 'outlined'} size={props.size || 'medium'}>
            <InputLabel>{props.label}</InputLabel>
            <Select
              {...props}
              MenuProps={{ disablePortal: props.disablePortal || false, PaperProps: { sx: { maxHeight: '50vh', ...menuStyle } } }}
              onChange={e => {
                if(props.onChange){
                  props.onChange(e.target.value)
                }
              }}
            >
              {props.options.map((item, index) => {
                return item ? <MenuItem key={index} value={item.value}>{item.label ? item.label : item.value}</MenuItem>:null
              })}
            </Select>
          </FormControl>
        </div>
        {props.detail !== undefined && props.detail !== "" && (
          <div style={{width: '10%', paddingLeft: 10}}>
            <Tooltip title={props.detail} enterTouchDelay={100} leaveTouchDelay={5000}>
              <div><QuestionIcon className="link" color="#999999" /></div>
            </Tooltip>
          </div>
        )}
      </div>
    )
}

export default MuiSelect;