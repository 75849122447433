import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { viewAs, authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';
import { CHUNKSIZE } from '../lib/limits';

export const resolutionService = {
    getMyResolutions,
    getCircularResolution,
    getResolutionsForBoard,
    deleteCircularResolution,
    createCircularResolution,
    updateCircularResolution,
    uploadFileChunks,
    actionResolution
}

function createCircularResolution(circularResolution) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(circularResolution)
    };

    return fetch(GetURL() + 'CircularResolutions', requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function deleteCircularResolution(circularResolutionId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `CircularResolutions/${circularResolutionId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return true;
        })
        .catch(() => {
            throw '';
        });
}

function updateCircularResolution(circularResolution) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(circularResolution)
    };

    return fetch(GetURL() + `CircularResolutions/${circularResolution.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return true;
        })
        .catch(handleCatch);
}

function getCircularResolution(id, includeUserDetails = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    var params = '';
    if (includeUserDetails) {
        params += '?includeUserDetails=true';
    }

    return fetch(GetURL() + `CircularResolutions/${id}${params}`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

// CircularResolutions/Board/{boardId}?userId=createFromDate=&includeUserDetails=true/false
function getResolutionsForBoard(boardId, userId, includeUserDetails) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = 'CircularResolutions/Board/' + boardId;
    var param = ''

    if (userId) {
        params += '?userId=' + userId;
        if (includeUserDetails) {
            params += '&includeUserDetails=true'
        }
    }

    return fetch(GetURL() + url + param, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function getMyResolutions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `CircularResolutions?includeUserDetails=true`, requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}


// ----------------------------------
// ----------------------------------
// --------- Result upload ----------
// ----------------------------------
// ----------------------------------

function actionResolution(circularResolutionResult) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(circularResolutionResult)
    };

    return fetch(GetURL() + `CircularResolutions/Results`, requestOptions)
        .then(async (response) => {
            if (!response.ok) { throw await response.json(); }
            return response;
        })
        .catch((err) => {
            throw err;
        });
}


// ----------------------------------
// ----------------------------------
// ---------- File upload -----------
// ----------------------------------
// ----------------------------------

function uploadChunk(documentId, chunkNumber, base64data, headerOptions = {}) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(headerOptions),
            body: JSON.stringify({ data: base64data })
        };

        return fetch(GetURL() + `Document/${documentId}/${chunkNumber}`, requestOptions)
            .then(async (response) => {
                if (!response.ok) {
                    var error = await response.text();
                    console.log(error);
                    throw error;
                } else {
                    resolve();
                }
            })
            .catch((e) => {
                reject(e);
            });
    })
}

function uploadDocument(documentId, base64Data, headerOptions = {}) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(headerOptions),
            body: JSON.stringify({ data: base64Data })
        };

        return fetch(GetURL() + `Document/${documentId}`, requestOptions)
            .then(async (response) => {
                if (!response.ok) {
                    var error = await response.text();
                    console.log(error);
                    throw error;
                } else {
                    resolve();
                }
            })
            .catch((e) => {
                reject(e);
            });
    })
}

function uploadFileChunks(file, documentId, aesKey, customerId) {
    //Documents/{documentId}/{chunkNumber}
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
            //convert the buffer to bytes
            var fileAsArrayBuffer = new Uint8Array(reader.result);
            try {
                var encryptedBuffer = await CrytpoLib.AESEncrypt(aesKey, fileAsArrayBuffer);
                var chunkSize = CHUNKSIZE;
                var numberOfChunks = Math.ceil(encryptedBuffer.byteLength / chunkSize, chunkSize);
                var chunkData = [];

                var currentChunk = 0;
                while (currentChunk < numberOfChunks) {
                    let offset = currentChunk * chunkSize;
                    let chunkBytes = encryptedBuffer.slice(offset, offset + chunkSize);
                    let b64Chunk = CrytpoLib.arrayBufferToBase64String(chunkBytes);
                    chunkData.push({
                        chunk: chunkBytes,
                        size: chunkBytes.byteLength,
                        b64: b64Chunk,
                        chunkBytesHash: CrytpoLib.MD5(chunkBytes)
                    });
                    currentChunk++;
                }

                console.log("uploading chunks", numberOfChunks);

                if (numberOfChunks == 1) {
                    console.log("1 chunk, upload in full");
                    await uploadDocument(documentId, chunkData[0].b64, {
                        // "Authorization": sessionToken,
                        "CustomerId": customerId,
                        "ChunkHash": chunkData[0].chunkBytesHash,
                        "Content-Type": 'application/json',
                    }).catch((e) => { throw e; });
                    resolve({ encryptedFileSize: encryptedBuffer.byteLength, encryptedData: encryptedBuffer });
                    return;
                }

                console.log("multiple chunks");
                let docMD5Hash = CrytpoLib.MD5(chunkData.map(c => c.b64).join());

                let chunkNumber = 0;
                for (let cData of chunkData) {
                    try {
                        await uploadChunk(documentId, chunkNumber, cData.b64, {
                            // "Authorization": sessionToken,
                            "DocumentChunkSize": cData.size,
                            "DocumentHash": docMD5Hash,
                            "ChunkHash": cData.chunkBytesHash,
                            "IsLastChunk": chunkNumber == numberOfChunks - 1,
                            "CustomerId": customerId,
                            "Content-Type": 'application/json'
                        }).catch((e) => { throw { error: e, chunkNumber }; });
                        chunkNumber++;
                    } catch (e) { throw { error: e, chunkNumber }; }
                }
                resolve({ encryptedFileSize: encryptedBuffer.byteLength, encryptedData: encryptedBuffer });
            } catch (e) { reject(e); return; }
        }
        reader.readAsArrayBuffer(file);
    })
}