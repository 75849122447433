import fetch from '../lib/fetch-retry';
import moment from 'moment';
import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleCatch } from '@lib';

export const baseBackupService = {
    getAllAnnotations,
    addAnnotations,
    modifyAnnotations,
    deleteAnnotations,
    getSharedAnnotations,
    getUsersAnnotationsCanBeSharedTo,
    postSharedAnnotation,
    getAnnotation,
}

function getAnnotation(id) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(GetURL() + `PNS/${id}`, requestOptions)
            .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(e => { reject(e) });
    });
}

function getAllAnnotations(itemId) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(GetURL() + `Backup/PNS?date=${moment().year(2000).utc().format()}&itemId=${itemId}&class=annotations`, requestOptions)
            .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(e => { reject(e) });
    });
}

function PostAnnotations(id, dto, type) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(dto)
        };

        return fetch(GetURL() + `Backup/PNS/${type}/${id}`, requestOptions)
            // .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(handleCatch);
    });
}

function addAnnotations(id, dto) {
    return PostAnnotations(id, dto, 'Add');
}

function modifyAnnotations(id, dto) {
    return PostAnnotations(id, dto, 'Modify');
}

function deleteAnnotations(id, dto) {
    return PostAnnotations(id, dto, 'Deleted');
}

function getSharedAnnotations(documentId) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(GetURL() + `AnnotationsShare?documentId=${documentId}`, requestOptions)
            .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(handleCatch);
    });
}

function getUsersAnnotationsCanBeSharedTo(documentId) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(GetURL() + `AnnotationsShare/Document/${documentId}/Users`, requestOptions)
            .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(handleCatch);
    });
}

function postSharedAnnotation(details) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(details)
        };

        return fetch(GetURL() + `AnnotationsShare`, requestOptions)
            .then(handleJsonResponse)
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch(handleCatch);
    });
}